// import React from "react";
import DateRangePicker from "./DateRangePicker.mdx";
import Guidelines from "./Guidelines.mdx";
// import Accessibility from "./Accessibility.mdx";
// import Code from "./Code.mdx";

const DateRangePickerPage = {
  mdx: DateRangePicker,
  breadcrumbs: "Components/Web Responsive Library/Forms",
  alias: "components/web-responsive-library/forms/date-range-picker",
  pathExact: false,
  title: "Date Range Picker",
  menuLabel: "Date Range Picker",
  published: true,
  date: "2023-04-20", // Show when last updated
  summary: "",

  tabs: [
    {
      label: "Guidelines",
      mdx: Guidelines,
      summary: "Date Range Picker is used to select or specify the start and end of a range of dates.",
      alias: "/components/web-responsive-library/forms/date-range-picker/guidelines",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Form",
        "Forms",
        "Date Range Picker",
        "Design",
        "Guidelines",
        "HTML",
        "React",
        "Angular",
      ],
      meta: {
        title: "Guidelines - Date Range Picker - Forms - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    /*{
      label: "Accessibility",
      mdx: Accessibility,
      summary: <></>,
      alias: "/components/web-responsive-library/forms/date-range-picker/accessibility",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Form",
        "Forms",
        "Date Range Picker",
        "Accessibility",
        "HTML",
        "React",
        "Angular",
      ],
      meta: {
        title: "Accessibility - Date Range Picker - Forms - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    {
      label: "Code",
      mdx: Code,
      summary: <></>,
      alias: "/components/web-responsive-library/forms/date-range-picker/code",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Form",
        "Forms",
        "Date Range Picker",
        "Code",
        "HTML",
        "React",
        "Angular",
      ],
      meta: {
        title: "Code - Date Range Picker - Forms - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },*/
  ],

  // SEO and Search
  tags: [],
  meta: {
    title: "Date Range Picker - Forms - Web Responsive Library - Components",
    description: "",
    keywords: [],
  },

  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "",
    },
    react: {
      status: "na",
      version: "v1.0.0",
      link: "//index.html?path=/story/components-forms-text-input--default",
    },
    angular: {
      status: "na",
      version: "v1.0.0",
      link: "index.html?path=/story/components-forms-DateRangePicker--default",
    },
    figma: {
      status: "released",
      version: "",
      link: "https://www.figma.com/file/mj41OHGCokcJ9N7D0q8D9Q/CBDS-UI-Kit-V1.1?node-id=1794%3A6349&t=EtO1hs1cXNxMVxRj-1",
    },
  },
};

export default DateRangePickerPage;
