const createId = text => {

  // Lowercase all characters
  let id = text.toLowerCase();

  // Replace "& " with empty space
  id = id.replace(/& /g, "");

  // Replace white space with hyphens
  id = id.replace(/ /g, "-");

  // Replace special characters, except letters, numbers and hyphens with empty space
  id = id.replace(/[^a-zA-z\-$]/g, "");

  return id;
};

export default createId;
