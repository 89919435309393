import React from 'react';
import PropTypes from 'prop-types';
import TokenRow from './TokenRow';
import classNames from 'classnames';

const TokensTable = ({
  a11yLabel,
  rowData,
  tableHeadings
}) => {
  const rowDataEntries = Object.entries(rowData.dataEntries);
  // const elementTokenData = rowDataEntries[0][1];
  {/*let heading = null;

  if(elementTokenData.meta) {
    heading = elementTokenData.meta.name;
  } else if (elementTokenData.misc) {
  heading = elementTokenData.misc.element;
  }*/}
  return (
    <div className="cbdsws-u-table__responsiveWrapper">

      {/* {hasHeading && heading && (
      {heading && (
        <h2>{heading}</h2>
      )}*/}

      <table className="cbdsws-c-tokensTable  cbdsws-c-tokensTable--basic" aria-label={a11yLabel}>

        <thead>
          <tr>
            {tableHeadings.map((item, index) => {
              return (
                // lowercase and remove non-chars and non-digits
                <th key={index} className={classNames("cbdsws-c-tokensTable__tableHead", `cbdsws-c-tokensTable__${item.toLowerCase().replaceAll(/[^A-Za-z0-9]/ig, '')}Head`)}>{item}</th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {rowDataEntries.map((item, index) => {

            if (!Object.prototype.hasOwnProperty.call(item[1], 'meta')) {
              const subItems1 = Object.entries(item[1]);

              // nested loop
              return subItems1.map((item2, index2) => {
                return (
                  <TokenRow
                    key={index2}
                    descMetaName={item2[1].meta.name}
                    tokenName={item2[1].name}
                    value={item2[1].value}
                    aliasValue={item2[1].original.value}
                    color={item2[1].rgb}
                    status={item2[1].status}
                    release={item2[1].release}
                    tableType={rowData.tableType}
                    tknCat={rowData.category}
                    tknType={rowData.type}
                    subType={item2[1].attributes.item}
                    subItem={item2[1].attributes.subitem}
                    tknName={item2[1].attributes.item}
                    platform={rowData.platform}
                    misc={!!item[1].misc && ( item[1].misc )}
                    tableHeadings={tableHeadings}
                  />
                );
              });
            } else {
              return (
                <TokenRow
                  key={index}
                  descMetaName={!!item[1].meta && (item[1].meta.name)}
                  tokenName={item[1].name}
                  value={item[1].value}
                  aliasValue={item[1].original.value}
                  color={item[1].rgb}
                  status={item[1].status}
                  release={item[1].release}
                  tableType={rowData.tableType}
                  tknCat={rowData.category}
                  tknType={rowData.type}
                  subType={item[1].attributes.item}
                  subItem={item[1].attributes.subitem}
                  tknName={item[1].attributes.item}
                  platform={rowData.platform}
                  misc={!!item[1].misc && ( item[1].misc )}
                  tableHeadings={tableHeadings}
                />
              );
            }
          })}
        </tbody>
      </table>
    </div>
  );
};

TokensTable.propTypes = {
  rowData: PropTypes.object.isRequired,
  tableHeadings: PropTypes.array.isRequired,
  hasCaption: PropTypes.bool
};

TokensTable.defaultProps = {
  rowData: [],
  tableHeadings: ['Description', 'Token', 'Value']
};

export default TokensTable;
