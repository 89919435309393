import React from 'react';
import Text from './Text.mdx';

const TextPage = {
  mdx: Text,
  breadcrumbs: "Foundations/Utilities",
  alias: "foundations/utilities/text",
  pathExact: false,
  title: "Text",
  menuLabel: 'Text',
  published: true,
  order: 7,
  date: '2023-02-08', // Show when last updated
  summary: <>Text utility classes include sizing, styling and alignment.</>,

  // SEO and Search
  tags: [
    'CBDS',
    'Foundations',
    'Text',
    'Utility',
    'Utility Classes System',
    'Text  Utility Classes for Developers',
    'Text Sizing',
    'Alignment',
  ],
  meta: {
    title: 'Text - Utilities - Foundations',
    description: '',
    keywords: [],
  },
};

export default TextPage;
