import React from "react";
import Banner from "./Banner.mdx";
import Guidelines from "./Guidelines.mdx";
import Accessibility from "./Accessibility.mdx";
// import Code from "./Code.mdx";

const BannerPage = {
  mdx: Banner,
  breadcrumbs: "Components/Web Responsive Library",
  alias: "components/web-responsive-library/banner",
  pathExact: false,
  title: "Banner",
  menuLabel: "Banner",
  published: true,
  date: "2024-02-22", // Show when last updated
  summary: "",
  tabs: [
    {
      label: "Guidelines",
      mdx: Guidelines,
      summary: "Banners display a message at the top of the screen.",
      alias: "/components/web-responsive-library/banner/guidelines",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Banner",
        'Message',
        'Messaging',
        'Error',
        'Notification',
        'Warning',
        "Design",
        "Guidelines",
        "Angular",
        "alert",
        "Snackbar",
      ],
      meta: {
        title: "Guidelines - Banner - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    {
      label: "Accessibility",
      mdx: Accessibility,
      summary: <></>,
      alias: "/components/web-responsive-library/banner/accessibility",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Banner",
        'Message',
        'Messaging',
        'Error',
        'Notification',
        'Warning',
        "Accessibility",
        "Angular",
        "Alert",
        "Snackbar",
      ],
      meta: {
        title: "Accessibility - Banner - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    /*{
      label: "Code",
      mdx: Code,
      summary: <></>,
      alias: "/components/web-responsive-library/banner/code",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Banner",
        'Message',
        'Messaging',
        'Error',
        'Notification',
        'Warning',
        "Code",
        "Angular",
      ],
      meta: {
        title: "Code - Banner - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },*/
  ],
  // SEO and Search
  tags: [],
  meta: {
    title: "Banner - Web Responsive Library - Components",
    description: "",
    keywords: [],
  },

  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "index.html?path=/story/components-Overlay",
    },
    react: {
      status: "released",
      version: "v1.0.0",
      link: "index.html?path=/story/components-banner--default",
    },
    angular: {
      status: "released",
      version: "v1.0.0",
      link: "index.html?path=/story/components-banner--default",
    },
    figma: {
      status: "released",
      version: "",
      link: "https://www.figma.com/design/mj41OHGCokcJ9N7D0q8D9Q/CDS-UI-Kit-V1.1?m=auto&node-id=2961-20514&t=XyqXBNsOTQ56qExN-1",
    },
  },
};

export default BannerPage;
