import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
import classNames from "classnames";

import { CbdsIcon, CbdsTabs } from '@cbds/cbds-components-react';

import ButtonInlineCode from '../ButtonInlineCode';
import CSSCodeSnippets from '../../constants/cbds-components-css';
import MarkdownExtended from '../MarkdownExtended';

import prettier from "prettier/standalone";
import babelPlugin from "prettier/plugins/babel";
import estreePlugin from "prettier/plugins/estree";

// TODO: setup toast notification of code being copied

const CodeSnippet = ({ customClass, childComponents, originalData, showTabs }) => {
  /* eslint-disable */
  const [activeTab, setActiveTab] = useState(1);
  /* eslint-enable  */
  const [codeSnippet, setCodeSnippet] = useState('');
  const [copyType, setCopyType] = useState('');
  const [HTMLCodeSnippet, setHTMLCodeSnippet] = useState('');

  /* eslint-disable */
  const eventOnCopy = (text, result) => {
    // console.log('copy event', text, result);

    // Aria live screen reader notification. Might be overly verbose when toast notifications are implemented.
    document.getElementById("targetPageTitle").innerHTML = `<div role="alert">Code snippet copied to clipboard</div>`;
  };
  /* eslint-enable */

  const CSSSnippet = () => {
    if (childComponents[0].customCssSnippet) {
      return childComponents[0].customCssSnippet;
    }

    const CSSCodeFile = childComponents[0].childComponentKey;
    return CSSCodeSnippets[CSSCodeFile];
  };

  useEffect(() => {
    if (showTabs.html) {
      onSetActiveTab(1);
      return;
    }

    if (showTabs.react) {
      onSetActiveTab(2);
      return;
    }

    if (showTabs.angular) {
      onSetActiveTab(3);
      return;
    }

    if (showTabs.css) {
      onSetActiveTab(4);
      return;
    }

  }, [childComponents]);

  const onSetActiveTab = value => {
    setActiveTab(value);

    switch(value) {
      case 1:
        (async () => {
          const element = childComponents[0].childComponentElement;
          const data = originalData || childComponents[0].childComponentData;
          const component = React.cloneElement(element, data);
          const formatCode = await prettier.format(ReactDOMServer.renderToString(component), {
            parser: "babel",
            plugins: [babelPlugin, estreePlugin],
          });
          setCodeSnippet(formatCode);
        })();
        setCopyType('__copyCodeBlock');
        break;
      case 2:
        setCodeSnippet("");
        setCopyType('__copyCodeBlock');
        break;
      case 3:
        setCodeSnippet("");
        setCopyType('__copyCodeBlock');
        break;
      case 4:
        setCodeSnippet(CSSSnippet());
        setCopyType('__copyCSS');
        break;
    }
  };

  // Dynamically build list of tabs
  const tabsList = [];

  if (showTabs.html) {
    (async () => {
      const element = childComponents[0].childComponentElement;
      const data = originalData || childComponents[0].childComponentData;
      const component = React.cloneElement(element, data);
      const formatCode = await prettier.format(ReactDOMServer.renderToString(component), {
        parser: "babel",
        plugins: [babelPlugin, estreePlugin],
      });

      setHTMLCodeSnippet(formatCode);
    })();

    tabsList.push({
      id: 1,
      order: 1,
      label: 'HTML',
      content: (
        <MarkdownExtended data={`
~~~html
${HTMLCodeSnippet}
~~~
`} />
      ),
    });
  }

  if (showTabs.react) {
    tabsList.push({
      id: 2,
      order: 2,
      label: 'React',
      content: "TODO: Add iframe of CBDS React storybook component"
    });
  }

  if (showTabs.angular) {
    tabsList.push({
      id: 3,
      order: 3,
      label: 'Angular',
      content: "TODO: Add iframe of CBDS Angular storybook component"
    });
  }

  if (showTabs.css) {
    tabsList.push({
      id: 4,
      order: 4,
      label: 'CSS',
      content: (
        <MarkdownExtended data={`
~~~css
${CSSSnippet()}
~~~
`} />
      ),
    });
  }


  return (

    <div className={classNames('cbdsws-c-codeSnippet', customClass )}>
      <CbdsTabs
        classes="cbdsws-c-codeSnippet__tabs"
        tabList={tabsList}
        onSetActive={onSetActiveTab}
      />
      <ButtonInlineCode
        customClass="cbdsws-c-codeSnippet__copyCodeBtn"
        type="snippet"
        codeSnippet={codeSnippet}
        copyType={copyType}
        onCopy={eventOnCopy}
      />
    </div>
  );
};

CodeSnippet.propTypes = {
  customClass: PropTypes.string,
  originalData: PropTypes.object,
  childComponents: PropTypes.arrayOf(PropTypes.shape({
    childComponentKey: PropTypes.string,
    childComponentElement: PropTypes.element,
    childComponentData: PropTypes.object,
  })),
  showTabs: PropTypes.shape({
    html: PropTypes.bool,
    react: PropTypes.bool,
    angular: PropTypes.bool,
    css: PropTypes.bool,
  })
};

CodeSnippet.defaultProps = {
  childComponents: [
    {
      childComponentKey: 'cbds-icon',
      childComponentElement: <CbdsIcon />,
      childComponentData: {
        iconName: 'close',
        a11yRole: 'img',
      },
    }
  ],
  showTabs: {
    html: true,
    react: true,
    angular: true,
    css: false,
  }
};

export default CodeSnippet;
