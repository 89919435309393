import React from 'react';
import Purpose from './Purpose.mdx';

const PurposePage = {
  mdx: Purpose,
  breadcrumbs: "Brand Guidelines/Wealth Management Guidelines",
  alias: "brand-guidelines/wealth-management-guidelines/purpose",
  pathExact: false,
  title: "Purpose",
  menuLabel: 'Purpose',
  published: true,
  order: 1,
  date: '2023-12-01', // Show when last updated
  summary: <>This guidance document provides the details of a design system for Citizens Wealth Management®, Citizens Private Client™, Citizens Securities, Inc.SM, and Clarfeld | Citizens Private Wealth™.</>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Wealth Management',
    'Guidelines',
    'Wealth Management Guidelines',
    'Purpose',
  ],
  meta: {
    title: 'Purpose - Wealth Management - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default PurposePage;
