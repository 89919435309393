import React from "react";
import Form from "./Form.mdx";
// import Guidelines from "./Guidelines.mdx";
// import Accessibility from "./Accessibility.mdx";
import Code from "./Code.mdx";

const FormPage = {
  mdx: Form,
  breadcrumbs: "Components/Web Responsive Library/Forms",
  alias: "components/web-responsive-library/forms/form",
  pathExact: false,
  title: "Form",
  menuLabel: "Form",
  published: true,
  date: "2023-05-11", // Show when last updated
  summary: "",

  tabs: [
    /*{
      label: "Guidelines",
      mdx: Guidelines,
      summary: "",
      alias: "/components/web-responsive-library/forms/form/guidelines",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Form",
        "Forms",
        "Form",
        "Design",
        "Guidelines",
        "HTML",
        "Angular",
      ],
      meta: {
        title: "Guidelines - Form - Forms - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    /*{
      label: "Accessibility",
      mdx: Accessibility,
      summary: <></>,
      alias: "/components/web-responsive-library/forms/form/accessibility",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Form",
        "Forms",
        "Form",
        "Accessibility",
        "HTML",
        "Angular",
      ],
      meta: {
        title: "Accessibility - Form - Forms - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },*/
    {
      label: "Code",
      mdx: Code,
      summary: <>CBDS Form is a container element used to wrap all elements and controls of a form, allowing users to input, configure, and submit information.</>,
      alias: "/components/web-responsive-library/forms/form/code",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Form",
        "Forms",
        "Form",
        "Code",
        "HTML",
        "React",
        "Angular",
      ],
      meta: {
        title: "Code - Form - Forms - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
  ],

  // SEO and Search
  tags: [],
  meta: {
    title: "Form - Forms - Web Responsive Library - Components",
    description: "",
    keywords: [],
  },

  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "",
    },
    react: {
      status: "na",
      version: "v1.0.0",
      link: "",
    },
    angular: {
      status: "released",
      version: "v1.0.0",
      link: "///index.html?path=/story/components-forms-form--default",
    },
    figma: {
      status: "na",
      version: "",
      link: "https://www.figma.com/file/mj41OHGCokcJ9N7D0q8D9Q/CBDS-UI-Kit-V1.1?node-id=1794%3A6349&t=EtO1hs1cXNxMVxRj-1",
    },
  },
};

export default FormPage;
