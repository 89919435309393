import Developers from "./Developers.mdx";

const DevelopersPage = {
  mdx: Developers,
  breadcrumbs: "Accessibility/Getting Started",
  alias: "accessibility/getting-started/developers",
  pathExact: false,
  title: "Developers",
  menuLabel: "Developers",
  published: true,
  order: 2,
  date: "2024-09-12", // Show when last updated
  summary: "",


  // SEO and Search
  tags: [
    'Accessibility',
    'A11Y',
    'Developers',
    'Developing',
  ],
  meta: {
    title: "Developers - Getting Started - Accessibility",
    description: "",
    keywords: [],
  },
};

export default DevelopersPage;
