import React from 'react';
import Misc from './Misc.mdx';

const MiscPage = {
  mdx: Misc,
  breadcrumbs: "Foundations/Utilities",
  alias: "foundations/utilities/misc",
  pathExact: false,
  title: "Miscellaneous",
  menuLabel: 'Misc',
  published: true,
  order: 5,
  date: '2023-02-09', // Show when last updated
  summary: <>Misc utility classes include classes that don't fit into other categories. </>,

  // SEO and Search
  tags: [
    'CBDS',
    'Foundations',
    'Misc',
    'Utility',
    'Utility Classes System',
    'Misc  Utility Classes for Developers',
    'Additional Classes',
    'Clearfix',
    'Width',
    'Greybox',
  ],
  meta: {
    title: 'Misc - Utilities - Foundations',
    description: '',
    keywords: [],
  },
};

export default MiscPage;
