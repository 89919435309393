import CdsWebsite from './CdsWebsite.mdx';

const CdsWebsitePage = {
  mdx: CdsWebsite,
  breadcrumbs: "About/Changelogs",
  alias: "about/changelogs/cds-website",
  pathExact: false,
  title: "CDS Website Changelog",
  menuLabel: 'CDS Website',
  published: true,
  order: 2,
  date: '2024-01-24', // Show when last updated
  summary: "",

  // SEO and Search
  tags: [
    'CBDS',
    'About',
    'CDS Website',
    'Changelog',
    'Release Notes'
  ],
  meta: {
    description: '',
    title: 'CDS Website - Changelogs - About',
    keywords: [],
  },
}; 
export default CdsWebsitePage;
