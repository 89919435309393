import React from 'react';
import Installation from './Installation.mdx';

const InstallationPage = {
  mdx: Installation,
  breadcrumbs: "Getting Started/For Developers/Web",
  alias: "getting-started/for-developers/web/installation",
  pathExact: false,
  title: "Installation",
  menuLabel: 'Installation',
  published: true,
  order: 1,
  date: '2024-01-31', // Show when last updated
  summary: <>Installation of the Citizens Design System (CDS) is a straight forward operation leveraging standards and best practices of the web development eco-system.</>,
  tags: [
    'CBDS',
    'CDS',
    'getting started',
    'For Developers',
    'installation',
    'help',
  ],
  meta: {
    title: 'Installation - Web - For Developers - Getting Started',
    description: '',
    keywords: [],
  },
};

export default InstallationPage;
