import React from "react";
import ColorPicker from "./ColorPicker.mdx";
// import Guidelines from "./Guidelines.mdx";
// import Accessibility from "./Accessibility.mdx";
import Code from "./Code.mdx";

const ColorPickerPage = {
  mdx: ColorPicker,
  breadcrumbs: "Components/Web Responsive Library/Forms",
  alias: "components/web-responsive-library/forms/color-picker",
  pathExact: false,
  title: "Color Picker",
  menuLabel: "Color Picker",
  published: true,
  date: "2023-06-07", // Show when last updated
  summary: "",

  tabs: [
    /*{
      label: "Guidelines",
      mdx: Guidelines,
      summary: "",
      alias: "/components/web-responsive-library/forms/color-picker/guidelines",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Form",
        "Forms",
        "Color Picker",
        "Color",
        "Picker",
        "Design",
        "Guidelines",
        "HTML",
        "Angular",
        "React",
      ],
      meta: {
        title: "Guidelines - Color Picker - Forms - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    /*{
      label: "Accessibility",
      mdx: Accessibility,
      summary: <></>,
      alias: "/components/web-responsive-library/forms/color-picker/accessibility",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Form",
        "Forms",
        "Color Picker",
        "Color",
        "Picker",
        "Accessibility",
        "HTML",
        "Angular",
        "React",
      ],
      meta: {
        title: "Accessibility - Color Picker - Forms - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },*/
    {
      label: "Code",
      mdx: Code,
      summary: <>CBDS Color Picker is a form control used to select or specify a color.</>,
      alias: "/components/web-responsive-library/forms/color-picker/code",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Form",
        "Forms",
        "Color Picker",
        "Color",
        "Picker",
        "Code",
        "HTML",
        "React",
        "Angular",
      ],
      meta: {
        title: "Code - Color Picker - Forms - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
  ],

  // SEO and Search
  tags: [],
  meta: {
    title: "Color Picker - Forms - Web Responsive Library - Components",
    description: "",
    keywords: [],
  },

  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "",
    },
    react: {
      status: "released",
      version: "v1.0.0",
      link: "/?path=/story/components-forms-color-picker--default",
    },
    angular: {
      status: "released",
      version: "v1.0.0",
      link: "/index.html?path=/story/components-forms-colorpicker--default",
    },
    figma: {
      status: "na",
      version: "",
      link: "https://www.figma.com/file/mj41OHGCokcJ9N7D0q8D9Q/CBDS-UI-Kit-V1.1?node-id=1794%3A6349&t=EtO1hs1cXNxMVxRj-1",
    },
  },
};

export default ColorPickerPage;
