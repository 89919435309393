import React from 'react';
import PropTypes from 'prop-types';
/* eslint-disable */
import { Fancybox } from "@fancyapps/ui";
/* eslint-enable */

const ScreenshotLink = ({
  headingAbove,
  imgSrc,
  imgAltText,
  // htmlLink,
  // angularLink,
  // reactLink
}) => {

  const fancyboxCaption = imgAltText ? imgAltText : null;
  //const siteURL = process.env.SITE_URL;

  return (
    <div className='cbdsws-c-screenshotLink'>
      {headingAbove
        ? <h3 className="cbdsws-c-screenshotLink__heading">{headingAbove}</h3>
        : null
      }
      <a
        href={imgSrc}
        className="cbds-c-link cbensightenevent"
        aria-hidden={!imgAltText ? "true" : "false"}
        cbdata-type="image"
        cbdata-reason="__screenshotLinkFancybox"
        data-fancybox="galleryScreenshotLink"
        data-caption={fancyboxCaption}
        data-src={imgSrc}
      >
        <img
          className="cbdsws-c-screenshotLink__image"
          src={imgSrc}
          alt={imgAltText ? `${imgAltText} (Opens in dialog)` : ''}
          role={imgAltText ? 'img' : 'presentation'}
        />
      </a>
      {/*
      Links for variants/states commented out
      on 12/18/2024 as part of UX-17945.

      <ul className="cbdsws-c-screenshotLink__layout">

        {htmlLink && (
          <li className="cbdsws-c-screenshotLink__layoutLink">
            <img
              className="cbdsws-c-screenshotLink__layoutIconHTML"
              src="/assets/images/icons/HTMLIcon.svg"
              alt=""
            />
            <a
              href={`/components-html/${htmlLink}`}
              className="cbds-c-link cbensightenevent"
              cbdata-type="link"
              cbdata-reason="__screenshotLink: Link Html"
              aria-label={headingAbove ? `HTML Open ${headingAbove} Storybook Variant in new tab` : "HTML (Opens in New Tab)"}
              target="_blank"
            >
              HTML
            </a>
          </li>
        )}

        {angularLink && (
          <li className="cbdsws-c-screenshotLink__layoutLink">
            <img
              className="cbdsws-c-screenshotLink__layoutIcon"
              src="/assets/images/icons/AngularIcon.svg"
              alt=""
            />
            <a
              href={`/components-angular/${angularLink}`}
              className="cbds-c-link cbensightenevent bleh"
              cbdata-type="link"
              cbdata-reason="__screenshotLink: Link Angular"
              aria-label={headingAbove ? `Angular Open ${headingAbove} Storybook Variant in new tab` : "Angular (Opens in New Tab)"}
              target="_blank"
            >
              Angular
            </a>
          </li>
        )}

        {reactLink && (
          <li className="cbdsws-c-screenshotLink__layoutLink">
            <img
              className="cbdsws-c-screenshotLink__layoutIcon"
              src="/assets/images/icons/ReactIcon.svg"
              alt=""
            />
            <a
              href={`/components-react/${reactLink}`}
              className="cbds-c-link cbensightenevent"
              cbdata-type="link"
              cbdata-reason="__screenshotLink: Link React"
              aria-label={headingAbove ? `React Open ${headingAbove} Storybook Variant in new tab` : "React (Opens in New Tab)"}
              target="_blank"
            >
              React
            </a>
          </li>
        )}

      </ul>
      */}
    </div>
  );
};

ScreenshotLink.propTypes = {
  headingAbove: PropTypes.string,
  imgSrc: PropTypes.string.isRequired,
  imgAltText: PropTypes.string,
  htmlLinkHref: PropTypes.string,
  angularLinkHref: PropTypes.string,
  reactLinkHref: PropTypes.string,
};

export default ScreenshotLink;
