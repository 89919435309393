import React from 'react';
import WealthWrappedInWarmth from './WealthWrappedInWarmth.mdx';

const WealthWrappedInWarmthPage = {
  mdx: WealthWrappedInWarmth,
  breadcrumbs: "Brand Guidelines/Wealth Management Guidelines",
  alias: "brand-guidelines/wealth-management-guidelines/wealth-wrapped-in-warmth",
  pathExact: false,
  title: "Wealth Wrapped in Warmth",
  menuLabel: 'Wealth Wrapped in Warmth',
  published: true,
  order: 1,
  date: '2023-12-01', // Show when last updated
  summary: <>“We see you” means we don’t cater to the masses. We see our clients as oneof-
  a-kind individuals with unique personalities, investing histories, aspirations,
  and goals. We place value in relationship building first, as a bridge to the lives
  they’ve planned.</>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Wealth Management',
    'Guidelines',
    'Wealth Management Guidelines',
    'Wealth Wrapped in Warmth',
  ],
  meta: {
    title: 'Wealth Wrapped in Warmth - Wealth Management - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default WealthWrappedInWarmthPage;
