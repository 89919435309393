import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";

const Blockquote = ({ customClass, quote, footer }) => {
  return (
    <>
      <blockquote className={classNames("cbdsws-c-blockquote", customClass)}>
        <p>{quote}</p>
        {footer && (
          <footer className="cbdsws-c-blockquote__footer">{footer}</footer>
        )}
      </blockquote>
    </>
  );
};

Blockquote.propTypes = {
  customClass: PropTypes.string,
  quote: PropTypes.string,
  footer: PropTypes.string,
};

export default Blockquote;
