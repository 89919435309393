import React from 'react';
import Cbds2022Cycle02Newsletter from './Cbds2022Cycle02Newsletter.mdx';

const Cbds2022Cycle02NewsletterPage = {
  mdx: Cbds2022Cycle02Newsletter,
  breadcrumbs: "About/Newsletter",
  alias: "about/newsletters/cbds-2022-cycle02-newsletter",
  pathExact: false,
  title: "CBDS 2022 Cycle 02 Newsletter",
  menuLabel: 'Cbds 2022 Cycle 02 Newsletter',
  published: true,
  hideFromNav: true,
  //order: 3,
  date: '2023-02-17', // Show when last updated
  summary: <><strong><em>Timeline:</em></strong> <em>March 02, 2022 &rarr; April 26, 2022<br/></em><br/>Spring has sprung, bringing warmer weather, new blooms and some new CBDS updates! In this cycle, we focused on 3 main initiatives, including:</>,

  // SEO and Search
  tags: [
    'CBDS',
    'About',
    'Newsletter',
    'Updates',
    'CBDS 2022 Cycle 02 Newsletter',
    'Release Notes',
  ],
  meta: {
    description: '',
    title: 'CBDS 2022 Cycle 02 Newsletter - Newsletters - About',
    keywords: [],
  },
};

export default Cbds2022Cycle02NewsletterPage;
