import React from 'react';
import PrinciplesOfTheJourney from './PrinciplesOfTheJourney.mdx';
const PrinciplesOfTheJourneyPage = {
  mdx: PrinciplesOfTheJourney,
  breadcrumbs: "Brand Guidelines/Enterprise Guidelines",
  alias: "brand-guidelines/enterprise-guidelines/principles-of-the-journey",
  pathExact: false,
  title: "Principles Of The Journey",
  menuLabel: 'Principles Of The Journey',
  published: true,
  order: 7,
  date: '2023-08-08', // Show when last updated
  summary: <></>,
  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Enterprise',
    'Guidelines',
    'Principles of the Journey',
  ],
  meta: {
    title: 'Principles of the Journey - Enterprise - Brand Guidelines',
    description: '',
    keywords: [],
  },
};
export default PrinciplesOfTheJourneyPage;
