import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Tag = ({
  htmlTag,
  color,
  size,
  isPill,
  fontWeight,
  classes,
  text
}) => {
  const variantColorClass = `cbdsws-c-tag--${color}`;
  const pillClass = isPill ? `cbdsws-c-tag--pill` : null;
  const sizeClass = size ? `cbdsws-c-tag--${size}` : null;
  const fwClass = fontWeight ? `cbdsws-c-tag--fwNormal` : null;

  if (htmlTag === 'div') {
    return (
      <div className={classNames("cbdsws-c-tag", variantColorClass, sizeClass, pillClass, fwClass, classes)}>
        { text }
      </div>
    );
  } else {
    return (
      <span className={classNames("cbdsws-c-tag", variantColorClass, sizeClass, pillClass, fwClass, classes)}>
        { text }
      </span>
    );
  }
};

Tag.propTypes = {
  htmlTag: PropTypes.oneOf(['div', 'span']),
  isPill: PropTypes.bool,
  size: PropTypes.oneOf(['sm', null]),
  fontWeight: PropTypes.oneOf(['normal', null])
};

Tag.defaultProps = {
  htmlTag: 'span',
  text: 'Tag'
};

export default Tag;
