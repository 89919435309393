export default {
  colorPalette: {
    primary: [
      {
        meta: {
          name: 'Deep Green'
        },
        rgb: '0, 100, 80',
        value: '#006450',
        cmyk: '99, 36, 74, 26',
        pms: '336',
        wcagBlack: {
          aa: 'false',
          aaLarge: 'true',
          ratio: '3.48'
        },
        wcagWhite: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '4.68'
        }
      },
      {
        meta: {
          name: 'White'
        },
        rgb: '255, 255, 255',
        value: '#fff',
        cmyk: '0, 0, 0, 0',
        pms: null,
        wcagBlack: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '16.30'
        },
        wcagWhite: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '0.00'
        }
      },
      {
        meta: {
          name: 'Neutral 100'
        },
        rgb: '233, 233, 233',
        value: '#e9e9e9',
        cmyk: '0, 0, 0, 9',
        pms: '663 C',
        wcagBlack: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '17.19'
        },
        wcagWhite: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '1.21'
        }
      }
    ],
    secondary: [
      {
        meta: {
          name: 'Dark Gray'
        },
        rgb: '77, 79, 83',
        value: '#555659',
        cmyk: '48, 36, 24, 66, or 80% black',
        pms: 'Cool Gray 11',
        wcagBlack: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '0.00'
        },
        wcagWhite: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '16.30'
        }
      },
      {
        meta: {
          name: 'Black'
        },
        rgb: '35, 31, 32',
        value: '#231f20',
        cmyk: '74, 70, 64, 100',
        pms: null,
        wcagBlack: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '0.00'
        },
        wcagWhite: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '16.30'
        }
      },
      {
        meta: {
          name: 'Citizens Green'
        },
        rgb: '0, 133, 85',
        value: '#008555',
        cmyk: '100, 0, 74, 0',
        pms: '340',
        wcagBlack: {
          aa: 'false',
          aaLarge: 'true',
          ratio: '3.48'
        },
        wcagWhite: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '4.68'
        }
      },
      {
        meta: {
          name: 'Light Green'
        },
        rgb: '84, 165, 135',
        value: '#54a587',
        cmyk: '71, 14, 58, 1',
        pms: '7723',
        wcagBlack: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '5.52'
        },
        wcagWhite: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '2.95'
        }
      }
    ],
    tertiary: [
      {
        meta: {
          name: 'Citizens Green'
        },
        rgb: '0, 133, 85',
        value: '#008555',
        cmyk: '100, 0, 74, 0',
        pms: '340',
        wcagBlack: {
          aa: 'false',
          aaLarge: 'true',
          ratio: '3.48'
        },
        wcagWhite: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '4.68'
        }
      },
      {
        meta: {
          name: 'Light Green'
        },
        rgb: '84, 165, 135',
        value: '#54a587',
        cmyk: '71, 14, 58, 1',
        pms: '7723',
        wcagBlack: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '5.52'
        },
        wcagWhite: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '2.95'
        }
      }
    ]
  }
};
