import React from 'react';
import BringingItTogether from './BringingItTogether.mdx';

const BringingItTogetherPage = {
  mdx: BringingItTogether,
  breadcrumbs: "Brand Guidelines/Wealth Management Guidelines",
  alias: "brand-guidelines/wealth-management-guidelines/bringing-it-together",
  pathExact: false,
  title: "Bringing It Together",
  menuLabel: 'Bringing It Together',
  published: true,
  order: 1,
  date: '2023-12-01', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Wealth Management',
    'Guidelines',
    'Wealth Management Guidelines',
    'Bringing It Together',
  ],
  meta: {
    title: 'Bringing it Together - Wealth Management - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default BringingItTogetherPage;
