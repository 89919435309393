import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Link from '../Link';

import CardContent from './CardContent';

const Card = props => {
  const layoutDirClass = props.layoutDir ? `cbdsws-c-card--${props.layoutDir}` : null;
  const sizeClass = props.size ? `cbdsws-c-card--${props.size}` : null;
  const variantClass = props.variant ? `cbdsws-c-card--${props.variant}` : null;
  const isActionClass = (props.url && !props.isDisabled) ? 'cbdsws-c-card--isAction' : null;
  const isDisabledClass = (props.isDisabled) ? 'cbdsws-c-card--isDisabled' : null;

  // Disabled Card - Used to show we have the Component, but we aren't allowing you to jump to doc pages
  const disabledAttrValue = props.isDisabled ? 'disabled' : null;

  if (props.url && !props.isDisabled) {
    return (
      <div className="cbdsws-c-cardContainerFix">
        <Link internalUrl={props.url} overrideStyles classes={classNames("cbdsws-c-card", variantClass, layoutDirClass, sizeClass, isActionClass, isDisabledClass, props.classes, "cbensightenevent")} cbdata-type="link" target={props.targetAttr} cbdata-reason={props.cbdataReasonAttr} aria-label={props.ariaLabel} disabled={disabledAttrValue}>
          <CardContent data={props} />
        </Link>
      </div>
    );
  } else {
    return (
      <div className={classNames("cbdsws-c-card", variantClass, layoutDirClass, sizeClass, isActionClass, isDisabledClass, props.classes)}>
        <CardContent data={props} />
      </div>
    );
  }
};

Card.propTypes = {
  urlType: PropTypes.oneOf(['email', 'external']),
  media: PropTypes.shape({
    type: PropTypes.oneOf([null, 'icon', 'illustration', 'photo', 'component']),
    srcUrl: PropTypes.string
  }),
  analytics: PropTypes.shape({
    reason: PropTypes.string
  }),
  size: PropTypes.oneOf(['small']),
  variant: PropTypes.oneOf(['list']),
  mediaIcon: PropTypes.shape({
    name: PropTypes.string
  })
};

Card.defaultProps = {
  a11yLabel: null,
  analytics: {},
  heading: 'Card heading',
  media: {
    type: null,
    srcUrl: ""
  },
  mediaIcon: {
    name: ""
  }
};

export default Card;
