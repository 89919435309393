import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const SkipToId = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      const idTag = location.search.replace('?id=', '');
      const target = document.getElementById(idTag);
      if (target) {
        target.setAttribute('tabindex', 1);
        target.focus();
        target.removeAttribute('tabindex');
      }
    }
  }, [location.search]);

  return (<></>);
};

export default SkipToId;
