// import React from "react";
import Accordion from "./Accordion.mdx";
import Guidelines from "./Guidelines.mdx";
// import Accessibility from "./Accessibility.mdx";
// import Code from "./Code.mdx";

const AccordionPage = {
  mdx: Accordion,
  breadcrumbs: "Components/Web Responsive Library",
  alias: "components/web-responsive-library/accordion",
  pathExact: false,
  title: "Accordion",
  menuLabel: "Accordion",
  published: true,
  date: "2023-10-16", // Show when last updated
  summary: "",
  tabs: [
    {
      label: "Guidelines",
      mdx: Guidelines,
      summary:
        "Accordions allow content to be revealed and hidden in a single section.",
      alias: "/components/web-responsive-library/accordion/guidelines",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Accordion",
        "Design",
        "Guidelines",
        "Expand",
        "Collapse",
        "HTML",
        "React",
        "Angular",
      ],
      meta: {
        title: "Guidelines - Accordion - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    /*{
      label: "Accessibility",
      mdx: Accessibility,
      summary: <></>,
      alias: "/components/web-responsive-library/accordion/accessibility",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Accordion",
        "Accessibility",
        "Expand",
        "Collapse",
        "HTML",
        "React",
        "Angular",
      ],
      meta: {
        title: "Accessibility - Accordion - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    {
      label: "Code",
      mdx: Code,
      summary: <></>,
      alias: "/components/web-responsive-library/accordion/code",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Accordion",
        "Code",
        "Expand",
        "Collapse",
        "HTML",
        "React",
        "Angular",
      ],
      meta: {
        title: "Code - Accordion - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },*/
  ],
  // SEO and Search
  tags: [],
  meta: {
    title: "Accordion - Web Responsive Library - Components",
    description: "",
    keywords: [],
  },

  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "index.html?path=/stitry/components-Accordion",
    },
    react: {
      status: "released",
      version: "v1.0.0",
      link: "index.html?path=/stitry/components-Accordion--default",
    },
    angular: {
      status: "released",
      version: "v1.0.0",
      link: "index.html?path=/story/components-Accordion",
    },
    figma: {
      status: "released",
      version: "",
      link: "https://www.figma.com/file/mj41OHGCokcJ9N7D0q8D9Q/CBDS-UI-Kit-V1.1?node-id=1633%3A6407&t=c0P3NDyo4OqUOeuw-1",
    },
  },
};

export default AccordionPage;
