import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { HashRouter as Router, Route } from 'react-router-dom';
import './styles/styles.scss';
import "@fancyapps/ui/dist/fancybox.css";

import Sidebar from './components/Sidebar';
import MobileMenu from './components/MobileMenu';
import SearchResult from './components/SearchResult';
import JumpToTop from './components/JumpToTop';
import Pagination from './components/Pagination';

import HomePage from './pages/home';

import Viewer from './components/Viewer';
import PageTitle from './components/PageTitle';
import ScrollToTop from './components/ScrollToTop';
import Pages from './constants/pages.js';
import SkipToId from './components/SkipToId';
import ContentContainer from './components/ContentContainer';
import NotFound from './components/NotFound';

const createAlias = label => {
  let alias = label.replace(/([0-9]+_)/g, ''); // Remove index
  alias = alias.replace(/((?<=[a-z])[A-Z]|[A-Z](?=[a-z]))/g, "-$1"); // hyphen words by cap letters
  alias = alias.toLowerCase(); // Lowercase letters
  return alias;
};

const SubRoutes = ({ pages }) => {
  let routes = [];
  const keys = Object.keys(pages);

  const routeMap = data => {
    if (data.component) {
      return <Viewer key={`route_${data.component.alias}`} data={data.component} />;
    } else if (data.children) {
      return data.children.map(data => routeMap(data));
    }
  };

  keys.forEach(key => {
    if (key !== 'root') {

      routes.push(
        <Route key={key} path={`/${createAlias(key)}`} component={() => (
          <>
            {pages[key].map(data => routeMap(data))}
          </>
        )} />
      );
    }

    // Root level
    if (key === 'root') {
      pages[key].forEach(data => {
        routes.push(<Viewer key={`route_${data.component.alias}`} data={data.component} />);
      });
    }
  });

  return routes;
};

// TEMP FIX: We will switch back to Browser Router, once we have new server environment setup.
const skipToMainContent = e => {
  e.preventDefault();
  const target = document.getElementById('skipToMain');
  target.focus();
};

const App = () => {
  const [pageExist, setPageExist] = useState(true);
  const [mobileMenuExpand, setMobileMenuExpand] = useState(false);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const appRef = useRef();
  const sidebarRef = useRef();

  // Key tab navigation within modal until it is closed
  const tabWithinModal = e => {
    if (!mobileMenuExpand) {
      return;
    }
    const parentElement = document.getElementById('sidebar-nav');
    if (parentElement) {
      // Query all focusable elements
      const focusableEls = sidebarRef.current.querySelectorAll("a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type='text']:not([disabled]), input[type='radio']:not([disabled]), input[type='checkbox']:not([disabled]), select:not([disabled])");

      const firstFocusableEl = focusableEls[0];
      const lastFocusableEl = focusableEls[focusableEls.length - 1];

      // If shift tabbing, focus on previous element
      if (e.shiftKey && document.activeElement === firstFocusableEl) {
        lastFocusableEl.focus();
        e.preventDefault();
        return;
      }

      // If tabbing, focus on next element
      if (!e.shiftKey && document.activeElement === lastFocusableEl) {
        firstFocusableEl.focus();
        e.preventDefault();
        return;
      }
    }
  };

  const keyListenersMap = new Map([
    [27, () => toggleMenu(false, 'keydown')],
    [9, tabWithinModal],
  ]);

  function keyListener(e) {
    const listener = keyListenersMap.get(e.keyCode);
    return listener && listener(e);
  }

  const clickListener = e => {
    const openMenuButton = document.getElementById('mobile-open-menu-button');
    const isClickedOutside = !sidebarRef.current?.contains(e.target) && !openMenuButton.contains(e.target);
    if (mobileMenuExpand && isClickedOutside) {
      toggleMenu(false, 'click');
    }
  };

  const toggleMenu = (toggle, type = 'keydown') => {
    if (toggle) {
      setMobileMenuVisible(toggle);
      setTimeout(() => {
        setMobileMenuExpand(toggle);
        document.getElementById('mobile-close-menu-button').focus();
      }, 150);
    } else {
      setMobileMenuExpand(toggle);
      setTimeout(() => {
        setMobileMenuVisible(toggle);
        if (type === 'keydown') {
          document.getElementById('mobile-open-menu-button').focus();
        }
      }, 150);
    }
  };

  return (
    <Router>
      <SkipToId />
      <ScrollToTop appRef={appRef}>
        <div ref={appRef} tabIndex="-1" className="cbdsws-l-mainContainer"> <a href="#skipToMain" id="skipLink" onClick={skipToMainContent} className="cbds-u-screenReaderOnly cbds-u-screenReaderOnly--focusable cbds-u-text__h4 cbensightenevent" cbdata-type="link" cbdata-reason="__globalHeader: Skip TO MAIN" style={{ position: 'absolute' }}>Skip to Main Content</a>
          <div className={classNames(
            "cbdsws-l-sidebarContainer",
            {
              "cbdsws-l-sidebarContainer--expanded": mobileMenuExpand,
              "cbdsws-l-sidebarContainer--visible": mobileMenuVisible,
            }
          )}>
            <Sidebar
              pages={Pages}
              sidebarRef={sidebarRef}
              appRef={appRef}
              toggleMenu={toggleMenu}
            />
          </div>
          <MobileMenu
            mobileMenuExpand={mobileMenuExpand}
            toggleMenu={toggleMenu}
            keyListener={keyListener}
            clickListener={clickListener}
          />
          <ContentContainer>
            <main id="skipToMain" tabIndex="-1">
              <NotFound Pages={Pages} pageExist={pageExist} setPageExist={setPageExist} />
              {pageExist && (
                <>
                  <Route exact path="/">
                    <HomePage />
                  </Route>
                  <Route exact path="/search">
                    <SearchResult data={Pages} appRef={appRef} />
                  </Route>
                  <React.Suspense fallback="Loading pages...">
                    <SubRoutes pages={Pages} />
                  </React.Suspense>
                </>
              )}
              <Pagination />
            </main>
          </ContentContainer><JumpToTop />
        </div>
      </ScrollToTop>
      <PageTitle pages={Pages} />
    </Router>
  );
};

export default App;
