// import React from "react";
import ToggleSwitch from "./ToggleSwitch.mdx";
import Guidelines from "./Guidelines.mdx";
// import Accessibility from "./Accessibility.mdx";
// import Code from "./Code.mdx";

const ToggleSwitchPage = {
  mdx: ToggleSwitch,
  breadcrumbs: "Components/Web Responsive Library/Forms",
  alias: "components/web-responsive-library/forms/toggle-switch",
  pathExact: false,
  title: "Toggle Switch",
  menuLabel: "Toggle Switch",
  published: true,
  date: "2023-04-20", // Show when last updated
  summary: "",

  tabs: [
    {
      label: "Guidelines",
      mdx: Guidelines,
      summary: "Toggle Switch is used to choose between two options.",
      alias: "/components/web-responsive-library/forms/toggle-switch/guidelines",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Form",
        "Forms",
        "Toggle Switch",
        "Design",
        "Guidelines",
        "HTML",
        "React",
        "Angular",
      ],
      meta: {
        title: "Guidelines - Toggle Switch - Forms - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    /*{
      label: "Accessibility",
      mdx: Accessibility,
      summary: <></>,
      alias: "/components/web-responsive-library/forms/toggle-switch/accessibility",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Form",
        "Forms",
        "Toggle Switch",
        "Accessibility",
        "HTML",
        "React",
        "Angular",
      ],
      meta: {
        title: "Accessibility - Toggle Switch - Forms - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    {
      label: "Code",
      mdx: Code,
      summary: <></>,
      alias: "/components/web-responsive-library/forms/toggle-switch/code",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Form",
        "Forms",
        "Toggle Switch",
        "Code",
        "HTML",
        "React",
        "Angular",
      ],
      meta: {
        title: "Code - Toggle Switch - Forms - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },*/
  ],

  // SEO and Search
  tags: [],
  meta: {
    title: "Toggle Switch - Forms - Web Responsive Library - Components",
    description: "",
    keywords: [],
  },

  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "",
    },
    react: {
      status: "na",
      version: "v1.0.0",
      link: "//index.html?path=/story/components-forms-text-input--default",
    },
    angular: {
      status: "released",
      version: "v1.0.0",
      link: "//index.html?path=/story/components-toggle-switch--default",
    },
    figma: {
      status: "released",
      version: "",
      link: "https://www.figma.com/file/mj41OHGCokcJ9N7D0q8D9Q/CBDS-UI-Kit-V1.1?node-id=1794%3A6349&t=EtO1hs1cXNxMVxRj-1",
    },
  },
};

export default ToggleSwitchPage;
