export default {
  'cbds-alert__tokens': `// ==========================================================================
// Bootstrap Variables
// ==========================================================================

//
// Variables & Styles sourced from Bootstrap v5.0.0-beta2
// Includes MVP changes to original BS code to align w/ CBDS
//
// TODO: Re-factor variable naming conventions to align with CBDS methodology
//
`,
  'cbds-accordion__tokens': `// ==========================================================================
// Bootstrap Variables
// ==========================================================================

//
// Variables & Styles sourced from Bootstrap v5.0.0-beta2
// Includes MVP changes to original BS code to align w/ CBDS
//
// TODO: Re-factor variable naming conventions to align with CBDS methodology
//


$cbds-accordion-padding-y:                     16px;
$cbds-accordion-padding-x:                     16px;
$cbds-accordion-color:                         $cbds-tkn-color__neutral--900;
$cbds-accordion-bg:                            transparent;
$cbds-accordion-border-width:                  1px;
$cbds-accordion-border-color:                  $cbds-tkn-color__neutral--100;
$cbds-accordion-border-radius:                 4px;

$cbds-accordion-body-padding-y:                $cbds-accordion-padding-y;
$cbds-accordion-body-padding-x:                $cbds-accordion-padding-x;

$cbds-accordion-button-padding-y:              $cbds-accordion-padding-y;
$cbds-accordion-button-padding-x:              $cbds-accordion-padding-x;
$cbds-accordion-button-color:                  $cbds-accordion-color;
$cbds-accordion-button-bg:                     $cbds-accordion-bg;
$cbds-accordion-transition:                    color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
$cbds-accordion-button-active-bg:              $cbds-tkn-color__neutral--40;
$cbds-accordion-button-active-color:           $cbds-tkn-color__neutral--900;

$cbds-accordion-button-focus-border-color:     $cbds-tkn-color__neutral--100;

$cbds-accordion-icon-width:                    20px;
$cbds-accordion-icon-color:                    $cbds-accordion-color;
$cbds-accordion-icon-active-color:             $cbds-accordion-button-active-color;
$cbds-accordion-icon-transition:               transform .2s ease-in-out;
$cbds-accordion-icon-transform:                rotate(180deg);

$cbds-accordion-button-icon:                   url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$cbds-accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
$cbds-accordion-button-active-icon:            url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$cbds-accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
`,
  'cbds-accordion': `// ==========================================================================
// --------------------------------------------------------------------------
// #CBDS Accordion
// --------------------------------------------------------------------------
// ==========================================================================

@import 'cbds-accordion__tokens';










// ==========================================================================
// Base Layout & Styles
// ==========================================================================


.cbds-c-accordion {
  $c: &;

  @include cbds-box-sizing;
}


.cbds-c-accordion__button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: $cbds-accordion-button-padding-y $cbds-accordion-button-padding-x;
  @include cbds-elementText__500($fontWeight: semiBold);
  color: $cbds-accordion-button-color;
  background-color: $cbds-accordion-button-bg;
  border: $cbds-accordion-border-width solid $cbds-accordion-border-color;
  @include cbds-border-radius(0);
  overflow-anchor: none;
  @include cbds-transition($cbds-accordion-transition);
  cursor: pointer;

  &:focus {
    @include cbds-focus-outline;
    z-index: 3;
    color: $cbds-tkn-color__neutral--1000;
    text-decoration: underline;
    // border-color: $cbds-accordion-button-focus-border-color;
  }

  &:hover {
    z-index: 2;
    color: $cbds-tkn-color__neutral--1000;
    text-decoration: underline;
  }

  &:active {
    color: $cbds-tkn-color__neutral--1000;
    text-decoration: none;
  }

  // Expanded setup
  &.cbds-collapsed {
    border-bottom-width: 0;
  }

  &:not(.cbds-collapsed) {
    color: $cbds-accordion-button-active-color;
    background-color: $cbds-accordion-button-active-bg;

    &::after {
      background-image: cbds-escape-svg($cbds-accordion-button-active-icon);
      transform: $cbds-accordion-icon-transform;
    }
  }

  // Icon
  &::after {
    flex-shrink: 0;
    width: $cbds-accordion-icon-width;
    height: $cbds-accordion-icon-width;
    margin-left: auto;
    content: "";
    background-image: cbds-escape-svg($cbds-accordion-button-icon);
    background-repeat: no-repeat;
    background-size: $cbds-accordion-icon-width;
    @include cbds-transition($cbds-accordion-icon-transition);
  }
}


.cbds-c-accordion__header {
  margin: 0;
  padding: 0;
}


.cbds-c-accordion__item {
  &:first-of-type {
    .accordion-button {
      @include cbds-border-top-radius($cbds-accordion-border-radius);
    }
  }

  &:last-of-type {
    .cbds-c-accordion__button {
      // Only set a border-radius on the last item if the accordion is collapsed
      &.cbds-collapsed {
        border-bottom-width: $cbds-accordion-border-width;
        @include cbds-border-bottom-radius($cbds-accordion-border-radius);
      }
    }

    .cbds-c-accordion__collapse {
      border-bottom-width: $cbds-accordion-border-width;
      @include cbds-border-bottom-radius($cbds-accordion-border-radius);
    }
  }
}


.cbds-c-accordion__collapse {
  border: solid $cbds-accordion-border-color;
  border-width: 0 $cbds-accordion-border-width;
}


.cbds-c-accordion__body {
  @include cbds-bodyText__500;
  padding: $cbds-accordion-body-padding-y $cbds-accordion-body-padding-x;
  color: $cbds-tkn-body__color;
}








// ==========================================================================
// Flush Modifier
// ==========================================================================

// Remove borders and border-radius to keep accordion items edge-to-edge.

.cbds-c-accordion--flush {
  .cbds-c-accordion__button {
    border-right: 0;
    border-left: 0;
    @include cbds-border-radius(0);
  }

  .cbds-c-accordion__collapse {
    border-width: 0;
  }

  .cbds-c-accordion__item {
    &:first-of-type {
      .cbds-c-accordion__button {
        border-top-width: 0;
        @include cbds-border-top-radius(0);
      }
    }

    &:last-of-type {
      .cbds-c-accordion__button.cbds-collapsed {
        border-bottom-width: 0;
        @include cbds-border-bottom-radius(0);
      }
    }
  }
}
`,
  'cbds-breadcrumb__tokens': ``,
  'cbds-badge': `// ==========================================================================
// --------------------------------------------------------------------------
// #CBDS Badge
// --------------------------------------------------------------------------
// ==========================================================================

@import 'cbds-badge__tokens';








// ==========================================================================
// Base Layout & Styles
// ==========================================================================


.cbds-c-badge {
  @include cbds-box-sizing;

  display: inline-flex;
  align-items: center;
  min-width: 24px;
  height: 24px;
  margin: 0;
  // Padding setup to get 24px height
  padding: $cbds-badge-padding-y $cbds-badge-padding-x;
  @include cbds-elementText__300($fontWeight: semiBold);
  text-align: center;
  white-space: nowrap;
  color: $cbds-badge-color;
  background-color: $cbds-tkn-color__neutral--60;
  border-radius: $cbds-badge-border-radius;

  &--primary {
    color: $cbds-tkn-color__white;
    background-color: $cbds-tkn-color__green--600;
  }

  &--secondary {
    color: $cbds-tkn-color__white;
    background-color: $cbds-tkn-color__neutral--700;
  }

  // Semantic colors - Match CBDS Button colors
  &--success {
    color: $cbds-tkn-color__white;
    background-color: $cbds-tkn-color__green--700;
  }

  &--error {
    color: $cbds-tkn-color__white;
    background-color: $cbds-tkn-color__red--700;
  }

  &--warning {
    color: $cbds-tkn-color__white;
    background-color: $cbds-tkn-color__gold--800;
  }

  &--info {
    color: $cbds-tkn-color__white;
    background-color: $cbds-tkn-color__fadedBlue--700;
  }

  // Other theme colors (from Bootstrap)
  &--light {
    color: $cbds-tkn-color__neutral--900;
    background-color: $cbds-tkn-color__neutral--60;
  }

  &--dark {
    color: $cbds-tkn-color__white;
    background-color: $cbds-tkn-color__neutral--900;
  }
}




// ==========================================================================
// Modifiers
// ==========================================================================

.cbds-c-badge--pill {
  border-radius: 9999px;
}
`,
  'cbds-alert': `// ==========================================================================
// --------------------------------------------------------------------------
// #CBDS Alert
// --------------------------------------------------------------------------
// ==========================================================================

@import 'cbds-alert__tokens';








// ==========================================================================
// Base Layout & Styles
// ==========================================================================


.cbds-c-alert {
  @include cbds-box-sizing;

  display: flex;
  position: relative;
  width: 100%;
  background-color: $cbds-tkn-color__white;
  border: $cbds-tkn-borderWidth__xs solid $cbds-tkn-color__neutral--100;
  border-radius: 4px;
  // Needed to round the bar
  overflow: hidden;

  &::before {
    top: 0;
    left: 0;
    right: 0;
    content: '';
    width: auto;
    height: 4px;
    position: absolute;
    background-color: $cbds-tkn-color__neutral--900;
  }
}


.cbds-c-alert__icon {
  display: block;
  position: relative;
  top: -2px;
  width: 24px;
  height: 24px;
  fill: $cbds-tkn-color__neutral--900;
}


.cbds-c-alert__heading {
  @include cbds-headingText__500;
  color: $cbds-tkn-textColor__secondary;
}


.cbds-c-alert__message {
  display: block;
  margin: 4px 0 0 0;
  @include cbds-elementText__400;
  color: $cbds-tkn-textColor__tertiary;
}


.cbds-c-alert__rail {
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  max-width: 48px;
  // Less top padding to pull icon up a bit
  // Less bot padding to account for weird box bug
  padding: 16px 8px 12px 16px;
}


.cbds-c-alert__body {
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  padding: 16px 16px 16px 0;
}






// ==========================================================================
// Modifiers
// ==========================================================================

.cbds-c-alert--dismissable {
  $c: 'cbds-c-alert';

  .#{$c}__dismissButton {
    position: absolute;
    top: 8px;
    right: 4px;
  }

  .#{$c}__body {
    padding-right: 44px;
  }
}






// ==========================================================================
// Type(s)
// ==========================================================================

//
// Error Type
// ==========================================================================

.cbds-c-alert--error {
  $c: 'cbds-c-alert';

  &::before {
    background-color: $cbds-tkn-color__red--700;
  }

  .#{$c}__icon {
    fill: $cbds-tkn-color__red--700;
  }

  &.#{$c}--tinted {
    background-color: $cbds-tkn-color__red--60;
  }
}




//
// Info Type
// ==========================================================================

.cbds-c-alert--info {
  $c: 'cbds-c-alert';

  &::before {
    background-color: $cbds-tkn-color__fadedBlue--700;
  }

  .#{$c}__icon {
    fill: $cbds-tkn-color__fadedBlue--700;
  }

  &.#{$c}--tinted {
    background-color: $cbds-tkn-color__fadedBlue--60;
  }
}




//
// Success Type
// ==========================================================================

.cbds-c-alert--success {
  $c: 'cbds-c-alert';

  &::before {
    background-color: $cbds-tkn-color__green--700;
  }

  .#{$c}__icon {
    fill: $cbds-tkn-color__green--700;
  }

  &.#{$c}--tinted {
    background-color: $cbds-tkn-color__green--60;
  }
}




//
// Warning Type
// ==========================================================================

.cbds-c-alert--warning {
  $c: 'cbds-c-alert';

  &::before {
    background-color: $cbds-tkn-color__gold--700;
  }

  .#{$c}__icon {
    fill: $cbds-tkn-color__gold--700;
  }

  &.#{$c}--tinted {
    background-color: $cbds-tkn-color__gold--60;
  }
}
`,
  'cbds-badge__tokens': `// ==========================================================================
// Bootstrap Variables
// ==========================================================================

//
// Variables & Styles sourced from Bootstrap v5.0.0-beta2
// Includes MVP changes to original BS code to align w/ CBDS
//
// TODO: Re-factor variable naming conventions to align with CBDS methodology
//


$cbds-badge-color:                       $cbds-tkn-color__neutral--900;
$cbds-badge-padding-y:                   $cbds-tkn-space__1x;
$cbds-badge-padding-x:                   $cbds-tkn-space__2x;
$cbds-badge-border-radius:               $cbds-tkn-borderRadius__md;
`,
  'cbds-breadcrumb': `// ==========================================================================
// --------------------------------------------------------------------------
// #BREADCRUMB
// --------------------------------------------------------------------------
// ==========================================================================

@import 'cbds-breadcrumb__tokens';








// ==========================================================================
// Base Layout & Styles
// ==========================================================================


.cbds-c-breadcrumb {
  $c: &;

  @include cbds-box-sizing;
  @include cbds-elementText__400;
  vertical-align: initial;
}


.cbds-c-breadcrumb__list {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;

  &--mobile {
    display: block;
    list-style: none;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.cbds-c-breadcrumb__item {
  display: flex;
  align-items: baseline;
  margin-right: 4px;

  &::after {
    content: '>';
    color: $cbds-tkn-color__neutral--700;
    margin-left: 4px;
  }

  &--active {
    &::after {
      content: '';
      margin-left: 0;
    }
  }

    &--mobile {
        &::before {
        content: '<';
        color: $cbds-tkn-color__neutral--700;
        margin-left: 4px;
    }
  }
}


.cbds-c-breadcrumb__link {
  position: relative;
  padding: 4px 0;
  @include cbds-actionText__400;
  vertical-align: initial;
  text-decoration: underline;
  color: $cbds-tkn-color__neutral--700;
  border: 0;
  background-color: transparent;
  outline: 0;
  appearance: none;
  cursor: pointer;

  &:visited,
  &:focus,
	&:hover,
	&:active {
		text-decoration: underline;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__neutral--600;
  }

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__neutral--1000;
    text-decoration: underline;
    background: transparent;
    border: none;
    @include cbds-focus-outline;
  }

	&:hover,
	&.cbds-is-hover {
		color: $cbds-tkn-color__neutral--1000;
  }

	&:active,
	&.cbds-is-active {
		color: $cbds-tkn-color__neutral--1100;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
		color: $cbds-tkn-color__neutral--600;
		text-decoration: none;
		cursor: not-allowed;
	}
}


// Element introduced so height of active page matches links
// and when items wrap there is consistent space vertically
.cbds-c-breadcrumb__active {
  padding: 4px 0;
  @include cbds-elementText__400;
  color: $cbds-tkn-color__neutral--700;
}


// Phones
@media screen and (max-width: $cbds-tkn-breakpoint__sm) {
    .cbds-c-breadcrumb__list {
        display: none;
    }
}

// Tablets and Desktops
@media screen and (min-width: $cbds-tkn-breakpoint__sm) {
    .cbds-c-breadcrumb__list--mobile {
        display: none;
    }
}`,
  'cbds-color-picker': `// ==========================================================================
// --------------------------------------------------------------------------
// #CBDS Input
// --------------------------------------------------------------------------
// ==========================================================================


.cbds-c-colorPicker {
  $c: &;

  @include cbds-box-sizing;

  display: block;
  width: 64px;
  max-width: 64px;
  height: 48px;
  margin: 0;
  padding: 2px;
  @include cbds-elementText__500;
  color: $cbds-tkn-textColor__secondary;
  background-color: $cbds-tkn-color__white;
  // Adding because Autoprefixer doesn't support border-radius
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: solid $cbds-tkn-borderWidth__xs $cbds-tkn-color__neutral--500;
  transition: border 0.1s linear, background-color 0.1s linear;
  appearance: none;

  &:focus,
  &.cbds-is-focus {
    @include cbds-focus-outline;
    border-color: $cbds-tkn-color__neutral--800;
  }

  &:hover,
  &.cbds-is-hover {
    border-color: $cbds-tkn-color__neutral--800;
  }

  &:active,
  &.cbds-is-active {
    border-color: $cbds-tkn-color__neutral--1100;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-textColor__quat;
    background-color: $cbds-tkn-color__neutral--60;
    border-color: $cbds-tkn-color__neutral--200;
    cursor: not-allowed;
  }

  &::placeholder {
    color: $cbds-tkn-textColor__quat;
    opacity: 1;
  }

  // &.cbds-is-invalid,
  // &.cbds-has-error {
  //   border-color: $cbds-tkn-color__red--700;
  // }
}
`,
  'cbds-checkbox-group': `// ==========================================================================
// --------------------------------------------------------------------------
// #CBDS Checkbox Group
// --------------------------------------------------------------------------
// ==========================================================================


$cbds-checkboxGroup-item__stack: 16px;
$cbds-checkboxGroup-item__mr: 24px;

.cbds-c-checkboxGroup {
  // margin-top: -$cbds-checkboxGroup-item__stack;

  // .cbds-c-checkbox {
  .cbds-c-checkbox {
    margin: $cbds-checkboxGroup-item__stack 0 0 0;

    &:first-child {
      margin-top: 0;
    }
  }

  &--horizontal {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: -$cbds-checkboxGroup-item__stack;
    font-size: 0; // Remove space in-between inline elements

    .cbds-c-checkbox {
      display: inline-flex;
      margin-right: $cbds-checkboxGroup-item__mr;

      &:first-child {
        margin-top: $cbds-checkboxGroup-item__stack;
      }
    }
  }
}
`,
  'cbds-date-picker': `// ==========================================================================
// --------------------------------------------------------------------------
// #CBDS Date Picker
// --------------------------------------------------------------------------
// ==========================================================================


.cbds-c-datePicker {
  @include cbds-box-sizing;

  position: relative;
  height: 48px;
  margin: 0;
}


.cbds-c-datePicker__input {
  $c: &;

  @include cbds-box-sizing;

  position: relative;
  display: block;
  width: 100%;
  height: 48px;
  margin: 0;
  padding: 8px 12px 8px 12px;
  @include cbds-elementText__500;
  color: $cbds-tkn-textColor__secondary;
  background-color: $cbds-tkn-color__white;
  // Adding because Autoprefixer doesn't support border-radius
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: solid $cbds-tkn-borderWidth__xs $cbds-tkn-color__neutral--500;
  transition: border 0.1s linear, background-color 0.1s linear;
  appearance: none;
  overflow: hidden;
  z-index: 0;

  &:focus,
  &.cbds-is-focus {
    @include cbds-focus-outline;
    border-color: $cbds-tkn-color__neutral--800;
  }

  &:hover,
  &.cbds-is-hover {
    border-color: $cbds-tkn-color__neutral--800;
  }

  &:active,
  &.cbds-is-active {
    border-color: $cbds-tkn-color__neutral--1100;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-textColor__quat;
    background-color: $cbds-tkn-color__neutral--60;
    border-color: $cbds-tkn-color__neutral--200;
    cursor: not-allowed;

    + .cbds-c-datePicker__open {
      background-color: $cbds-tkn-color__neutral--60;

      .cbds-c-datePicker__icon {
        fill: $cbds-tkn-textColor__quat;
      }
    }
  }

  &::placeholder {
    color: $cbds-tkn-textColor__quat;
    opacity: 1;
  }

  // Hide icon in IE11 and below
  &::-ms-expand {
    display: none;
  }

  // Chrome Custom Styling
  &::-webkit-calendar-picker-indicator {
    margin: 0;
    padding: 0;
    width: 24px;
    height: 24px;
    // background-color: red;
  }
}


.cbds-c-datePicker__indicator {
  position: absolute;
  bottom: 12px;
  top: 12px;
  right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  background-color: $cbds-tkn-color__white;
  content: '';
  z-index: 4;
  pointer-events: none;
}


.cbds-c-datePicker__indicatorIcon {
  width: 24px;
  height: 24px;
  fill: $cbds-tkn-textColor__secondary;
  pointer-events: none;
}
`,
  'cbds-checkbox': `// ==========================================================================
// --------------------------------------------------------------------------
// #CBDS Checkbox
// --------------------------------------------------------------------------
// ==========================================================================


.cbds-c-checkbox {
  $c: 'cbds-c-checkbox';

  @include cbds-box-sizing;

  display: flex;
  align-items: flex-start;

  [type="checkbox"] {
    position: absolute;
    left: 0;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    visibility: visible;
    white-space: nowrap;
    pointer-events: auto;
  }

  // Focus
  [type="checkbox"]:focus + .#{$c}__control,
  [type="checkbox"]:focus ~ .#{$c}__control,
  &.cbds-is-focus [type="checkbox"] + .#{$c}__control,
  &.cbds-is-focus [type="checkbox"] ~ .#{$c}__control {
    .#{$c}__faux {
      @include cbds-focus-outline;
      border-color: $cbds-tkn-color__neutral--800;
      background-color: $cbds-tkn-color__white;
    }
  }

  // Hover
  [type="checkbox"]:not([disabled]):hover + .#{$c}__control,
  [type="checkbox"]:not([disabled]):hover ~ .#{$c}__control,
  &.cbds-is-hover [type="checkbox"]:not([disabled]) + .#{$c}__control,
  &.cbds-is-hover [type="checkbox"]:not([disabled]) ~ .#{$c}__control {
    .#{$c}__faux {
      border-color: $cbds-tkn-color__neutral--800;
      background-color: $cbds-tkn-color__white;
      box-shadow: 0 0 0 4px rgba($cbds-tkn-color__neutral--900, .24);
    }
  }

  // Active
  [type="checkbox"]:active + .#{$c}__control,
  [type="checkbox"]:active ~ .#{$c}__control,
  &.cbds-is-active [type="checkbox"] + .#{$c}__control,
  &.cbds-is-active [type="checkbox"] ~ .#{$c}__control {
    .#{$c}__faux {
      border-color: $cbds-tkn-color__neutral--1100;
      background-color: $cbds-tkn-color__white;
    }
  }

  // Disabled
  &.cbds-is-disabled {
    cursor: not-allowed;
  }

  [type="checkbox"][disabled] + .#{$c}__control,
  [type="checkbox"][disabled] ~ .#{$c}__control,
  &.cbds-is-disabled [type="checkbox"] + .#{$c}__control,
  &.cbds-is-disabled [type="checkbox"] ~ .#{$c}__control {
    cursor: not-allowed;

    .#{$c}__faux {
      border-color: $cbds-tkn-color__neutral--200;
      background-color: $cbds-tkn-color__white;
      cursor: not-allowed;
    }

    .#{$c}__label {
      color: $cbds-tkn-textColor__quat;
      cursor: not-allowed;
    }
  }

  // Error
  &.cbds-is-invalid [type="checkbox"] + .#{$c}__control,
  &.cbds-is-invalid [type="checkbox"] ~ .#{$c}__control {
    .#{$c}__faux {
      border-color: $cbds-tkn-color__red--700;
      background-color: $cbds-tkn-color__white;
    }
  }

  // Error Hover
  &.cbds-is-invalid [type="checkbox"]:not([disabled]):hover + .#{$c}__control,
  &.cbds-is-invalid [type="checkbox"]:not([disabled]):hover ~ .#{$c}__control,
  &.cbds-is-invalid-hover [type="checkbox"]:not([disabled]) + .#{$c}__control,
  &.cbds-is-invalid-hover [type="checkbox"]:not([disabled]) ~ .#{$c}__control  {
    .#{$c}__faux {
      border-color: $cbds-tkn-color__red--900;
      background-color: $cbds-tkn-color__white;
      box-shadow: 0 0 0 4px rgba($cbds-tkn-color__red--700, .24);
    }
  }

  // Error Focus
  &.cbds-is-invalid [type="checkbox"]:focus + .#{$c}__control,
  &.cbds-is-invalid [type="checkbox"]:focus ~ .#{$c}__control,
  &.cbds-is-invalid-focus [type="checkbox"] + .#{$c}__control,
  &.cbds-is-invalid-focus [type="checkbox"] ~ .#{$c}__control {
    .#{$c}__faux {
      @include cbds-focus-outline;
      border-color: $cbds-tkn-color__red--900;
      background-color: $cbds-tkn-color__white;
    }
  }

  // Error Active
  &.cbds-is-invalid [type="checkbox"]:active + .#{$c}__control,
  &.cbds-is-invalid [type="checkbox"]:active ~ .#{$c}__control,
  &.cbds-is-invalid-active [type="checkbox"] + .#{$c}__control,
  &.cbds-is-invalid-active [type="checkbox"] ~ .#{$c}__control {
    .#{$c}__faux {
      border-color: $cbds-tkn-color__red--1100;
      background-color: $cbds-tkn-color__white;
    }
  }

  // Checked
  [type="checkbox"]:checked + .#{$c}__control,
  [type="checkbox"]:checked ~ .#{$c}__control,
  &.cbds-is-checked [type="checkbox"] + .#{$c}__control,
  &.cbds-is-checked [type="checkbox"] ~ .#{$c}__control {
    .#{$c}__faux {
      border-color: $cbds-tkn-color__green--600;
      background-color: $cbds-tkn-color__green--600;
    }

    .#{$c}__checkIcon {
      display: block;
    }
  }

  // Checked Focus
  [type="checkbox"]:checked:focus + .#{$c}__control,
  [type="checkbox"]:checked:focus ~ .#{$c}__control,
  &.cbds-is-checked-focus [type="checkbox"]:checked + .#{$c}__control,
  &.cbds-is-checked-focus [type="checkbox"]:checked ~ .#{$c}__control {
    .#{$c}__faux {
      @include cbds-focus-outline;
      border-color: $cbds-tkn-color__green--800;
      background-color: $cbds-tkn-color__green--800;
    }

    .#{$c}__checkIcon {
      display: block;
    }
  }

  // Checked Hover
  [type="checkbox"]:not([disabled]):checked:hover + .#{$c}__control,
  [type="checkbox"]:not([disabled]):checked:hover ~ .#{$c}__control,
  &.cbds-is-checked-hover [type="checkbox"]:not([disabled]):checked + .#{$c}__control,
  &.cbds-is-checked-hover [type="checkbox"]:not([disabled]):checked ~ .#{$c}__control {
    .#{$c}__faux {
      border-color: $cbds-tkn-color__green--800;
      background-color: $cbds-tkn-color__green--800;
      box-shadow: 0 0 0 4px rgba($cbds-tkn-color__green--600, .24);
    }

    .#{$c}__checkIcon {
      display: block;
    }
  }

  // Checked Active
  [type="checkbox"]:checked:active + .#{$c}__control,
  [type="checkbox"]:checked:active ~ .#{$c}__control,
  &.cbds-is-checked-active [type="checkbox"]:checked + .#{$c}__control,
  &.cbds-is-checked-active [type="checkbox"]:checked ~ .#{$c}__control {
    .#{$c}__faux {
      border-color: $cbds-tkn-color__green--1000;
      background-color: $cbds-tkn-color__green--1000;
    }

    .#{$c}__checkIcon {
      display: block;
    }
  }

  // Checked Disabled
  &.cbds-is-checked-disabled {
    cursor: not-allowed;
  }

  [type="checkbox"]:checked[disabled] + .#{$c}__control,
  [type="checkbox"]:checked[disabled] ~ .#{$c}__control,
  &.cbds-is-checked-disabled [type="checkbox"] + .#{$c}__control,
  &.cbds-is-checked-disabled [type="checkbox"] ~ .#{$c}__control {
    cursor: not-allowed;

    .#{$c}__faux {
      border-color: $cbds-tkn-color__neutral--200;
      background-color: $cbds-tkn-color__neutral--200;
      cursor: not-allowed;
    }

    .#{$c}__checkIcon {
      display: block;
      fill: $cbds-tkn-color__white;
    }
  }

  // Checked Error
  &.cbds-is-invalid [type="checkbox"]:checked + .#{$c}__control,
  &.cbds-is-invalid [type="checkbox"]:checked ~ .#{$c}__control,
  &.cbds-is-checked-invalid [type="checkbox"]:checked + .#{$c}__control,
  &.cbds-is-checked-invalid [type="checkbox"]:checked ~ .#{$c}__control {
    .#{$c}__faux {
      border-color: $cbds-tkn-color__red--700;
      background-color: $cbds-tkn-color__red--700;
    }

    .#{$c}__checkIcon {
      display: block;
    }
  }

  // Checked Error Hover
  &.cbds-is-invalid [type="checkbox"]:not([disabled]):checked:hover + .#{$c}__control,
  &.cbds-is-invalid [type="checkbox"]:not([disabled]):checked:hover ~ .#{$c}__control,
  &.cbds-is-checked-invalid-hover [type="checkbox"]:not([disabled]):checked + .#{$c}__control,
  &.cbds-is-checked-invalid-hover [type="checkbox"]:not([disabled]):checked ~ .#{$c}__control {
    .#{$c}__faux {
      border-color: $cbds-tkn-color__red--900;
      background-color: $cbds-tkn-color__red--900;
      box-shadow: 0 0 0 4px rgba($cbds-tkn-color__red--700, .24);
    }

    .#{$c}__checkIcon {
      display: block;
    }
  }

  // Checked Error Focus
  &.cbds-is-invalid [type="checkbox"]:checked:focus + .#{$c}__control,
  &.cbds-is-invalid [type="checkbox"]:checked:focus ~ .#{$c}__control,
  &.cbds-is-checked-invalid-focus [type="checkbox"]:checked + .#{$c}__control,
  &.cbds-is-checked-invalid-focus [type="checkbox"]:checked ~ .#{$c}__control {
    .#{$c}__faux {
      @include cbds-focus-outline;
      border-color: $cbds-tkn-color__red--900;
      background-color: $cbds-tkn-color__red--900;
    }

    .#{$c}__checkIcon {
      display: block;
    }
  }

  // Checked Active
  &.cbds-is-invalid [type="checkbox"]:checked:active + .#{$c}__control,
  &.cbds-is-invalid [type="checkbox"]:checked:active ~ .#{$c}__control,
  &.cbds-is-checked-invalid-active [type="checkbox"]:checked + .#{$c}__control,
  &.cbds-is-checked-invalid-active [type="checkbox"]:checked ~ .#{$c}__control {
    .#{$c}__faux {
      border-color: $cbds-tkn-color__red--1100;
      background-color: $cbds-tkn-color__red--1100;
    }

    .#{$c}__checkIcon {
      display: block;
    }
  }
}


.cbds-c-checkbox__control {
  display: inline-flex;
  align-items: flex-start;
  cursor: pointer;
}


.cbds-c-checkbox__faux {
  position: relative;
  flex: 0 0 auto;
  display: block;
  top: 0;
  left: 0;
  margin: 0 16px 0 0;
  // padding: 2px;
  width: 24px;
  height: 24px;
  border: $cbds-tkn-borderWidth__sm solid $cbds-tkn-color__neutral--500;
  border-radius: 2px;
  background-color: $cbds-tkn-color__white;
  cursor: pointer;
}


.cbds-c-checkbox__checkIcon {
  display: none;
  width: 20px;
  height: 20px;
  fill: $cbds-tkn-color__white;
}

.cbds-c-checkbox__input:checked + .cbds-c-checkbox__control {
  .cbds-c-checkbox__faux {
    background-color: $cbds-tkn-color__green--600;
  }
}


.cbds-c-checkbox__label {
  margin-top: 2px;
  @include cbds-elementText__500;
  color: $cbds-tkn-textColor__secondary;
  cursor: pointer;
}
`,
  'cbds-disclaimer': `// ==========================================================================
// --------------------------------------------------------------------------
// #DISCLAIMER
// --------------------------------------------------------------------------
// ==========================================================================


.cbds-c-disclaimer {
  @include cbds-box-sizing;
  padding: $cbds-tkn-space__4x;
  @include cbds-elementText__400;
  color: $cbds-tkn-textColor__quat;
  border-width: $cbds-tkn-borderWidth__xs;
  border-color: $cbds-tkn-color__neutral--100;
  border-style: solid;
}


.cbds-c-disclaimer__heading {
  @include cbds-headingText__400;
  text-align: center;
  color: $cbds-tkn-textColor__quat;
}


.cbds-c-disclaimer__list {
  margin: $cbds-tkn-space__stack--2x;
  padding: 0;
  list-style: none;
  font-size: 0; // Hack to remove inline space between each list item
  line-height: 1;
  text-align: center;
}


.cbds-c-disclaimer__text {
  margin: $cbds-tkn-space__stack--2x;
  @include cbds-elementText__400;
  text-transform: uppercase;
  text-align: center;
  color: $cbds-tkn-textColor__quat;
}


.cbds-c-disclaimer__item {
  display: inline;
  @include cbds-elementText__400;
  text-transform: uppercase;
  color: $cbds-tkn-textColor__quat;

  &:not(:last-child):after {
    display: inline-block;
    margin: 0 $cbds-tkn-space__1x;
    content: "•";
  }
}
`,
  'cbds-disclosure': `// ==========================================================================
// --------------------------------------------------------------------------
// #DISCLOSURE
// --------------------------------------------------------------------------
// ==========================================================================


.cbds-c-disclosure {
  @include cbds-box-sizing;
  overflow: hidden;

  *:not(.cbds-u-screenReaderOnly) + .cbds-c-disclosureContent {
    margin: $cbds-tkn-space__stack--12x;
  }

  *:not(.cbds-u-screenReaderOnly) + .cbds-c-disclosure__disclaimer {
    margin: $cbds-tkn-space__stack--12x;
  }
}


.cbds-c-disclosureNav {
  margin: 0;
  padding: 0;
}


.cbds-c-disclosureNav__heading {
  @include cbds-headingText__500;
  color: $cbds-tkn-textColor__quat;
}


.cbds-c-disclosureNav__list {
  margin: $cbds-tkn-space__stack--2x;
  padding: 0;
  list-style: none;

  @include cbds-media-breakpoint-up(xs) {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$cbds-tkn-space__4x;
    margin-right: -$cbds-tkn-space__4x;
  }
}


.cbds-c-disclosureNav__item {
  margin: 0;
  padding: 0;
  list-style: none;
  @include cbds-elementText__400;
  color: $cbds-tkn-textColor__quat;

  @include cbds-media-breakpoint-up(xs) {
    width: 50%;
    padding: 0 $cbds-tkn-space__4x;
  }

  @include cbds-media-breakpoint-up(md) {
    width: 33%;
  }
}


.cbds-c-disclosureNav__link {
  display: inline-block;
  min-height: $cbds-tkn-unit * 9;
  padding: ($cbds-tkn-space__2x + 1) 0;
  @include cbds-actionText__400;
  background: transparent;
  color: $cbds-tkn-color__neutral--700;
  text-decoration: underline;

  &:visited {
    color: $cbds-tkn-color__neutral--700;
  }

  &:hover {
    color: $cbds-tkn-color__neutral--1000;
  }

  &:focus {
    // Sketch uses $cbds-tkn-color__neutral--900 @ 24% (#37535)
    // used neutral--200 token as closest approximation
    // outline: $cbds-tkn-borderWidth__md solid $cbds-tkn-color__neutral--200;
    @include cbds-focus-outline;
    border-radius: 0;
    color: $cbds-tkn-color__neutral--1000;
  }

  &:active {
    color: $cbds-tkn-color__neutral--1100;
  }
}


// Only add top margin if footnote isn't 1st element in the stack
.cbds-c-disclosureNav + .cbds-c-disclosure__footnote {
  margin: $cbds-tkn-space__stack--12x;
}


.cbds-c-disclosure__footnote {
  // Override footnote item color when in disclosure for consistency
  .cbds-c-footnote__item {
    color: $cbds-tkn-textColor__quat;
  }
}


.cbds-c-disclosureCompliance {
  margin: $cbds-tkn-space__stack--6x;
}


.cbds-c-disclosureCompliance__list {
  //Hostile prevention
  padding: 0;
  margin: 0;
  line-height: normal;
  text-indent: 0;
  //***********
  list-style: none;
  padding: 0;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  @include media(">=sm"){
    text-align: left;
    margin-bottom: 0;
    align-items: center;
  }
}


.cbds-c-disclosureCompliance__item {
  height: $cbds-tkn-unit * 9;
  display: inline;
  list-style: none;
  margin-right: $cbds-tkn-space__6x;
  margin-top: $cbds-tkn-space__6x;
  padding: 0;
  @include media(">=sm"){
    margin-right: $cbds-tkn-space__8x;
  }
}


.cbds-c-disclosureCompliance__logo {
  fill: $cbds-tkn-textColor__quat;
  margin: 0 auto;
  width: auto;
  height: $cbds-tkn-unit__size * 8;
  display: block;
  max-width: $cbds-tkn-unit__size * 22;
  @include media(">=sm"){
    margin: 0 0 0 auto;
  }
}


.cbds-c-disclosureContent {
  @include cbds-elementText__400;
  color: $cbds-tkn-textColor__quat;
}


.cbds-c-disclosureContent__heading {
  @include cbds-headingText__500;
  color: $cbds-tkn-textColor__quat;
}


.cbds-c-disclosureContent__text {
  @include cbds-bodyText__400;
  color: $cbds-tkn-textColor__quat;

  & + .cbds-c-disclosureContent__text {
    margin: $cbds-tkn-space__stack--6x;
  }
}


.cbds-c-disclosure__copyright {
  margin: $cbds-tkn-space__stack--12x;
  @include cbds-bodyText__400;
  color: $cbds-tkn-textColor__quat;
}
`,
  'cbds-figure__tokens': `// ==========================================================================
// Bootstrap Variables
// ==========================================================================

//
// Variables & Styles sourced from Bootstrap v5.0.0-beta2
// Includes MVP changes to original BS code to align w/ CBDS
//
// TODO: Re-factor variable naming conventions to align with CBDS methodology
//


$cbds-figure-caption-font-size:          $cbds-tkn-typeScale__400;
$cbds-figure-caption-color:              $cbds-tkn-textColor__tertiary;
`,
  'cbds-button': `// ==========================================================================
// --------------------------------------------------------------------------
// #BUTTON
// --------------------------------------------------------------------------
// ==========================================================================


.cbds-c-button {
  $c: &;

  @include cbds-box-sizing;

  z-index: 0;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 48px;
  margin: 0;
  padding: 12px 20px; // Shave 2px off width of each size due to border to total 24px
  @include cbds-actionText__500($fontWeight: semiBold);
  white-space: nowrap;
  color: $cbds-tkn-color__white;
  border-width: 1px;
  border-style: solid;
  border-color: $cbds-tkn-color__orange--700;
  // Adding because Autoprefixer doesn't support border-radius
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background: $cbds-tkn-color__orange;
  box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);
  appearance: none;
  cursor: pointer;
  overflow: visible; // Normalize: Show the overflow in Edge

  // Normalize: Remove the inner border and padding in Firefox.
  &::-moz-focus-inner,
  &[type="button"]::-moz-focus-inner,
  &[type="reset"]::-moz-focus-inner,
  &[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  // Normalize: Restore the focus styles unset by the previous rule.
  &:-moz-focusring,
  &[type="button"]:-moz-focusring,
  &[type="reset"]:-moz-focusring,
  &[type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  &:visited,
  &:focus,
  &:hover,
  &:active {
    text-decoration: none;
  }

  &:focus,
  &.cbds-is-focus {
    text-decoration: underline;
    color: $cbds-tkn-color__white;
    border-color: $cbds-tkn-color__orange--800;
    background: $cbds-tkn-color__orange--700;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .12);
    @include cbds-focus-outline($cbds-tkn-color__orange);
  }

  &:hover,
  &.cbds-is-hover {
    text-decoration: underline;
    color: $cbds-tkn-color__white;
    border-color: $cbds-tkn-color__orange--800;
    background: $cbds-tkn-color__orange--700;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .12);
  }

  &:active,
  &.cbds-is-active {
    text-decoration: underline;
    color: $cbds-tkn-color__white;
    border-color: $cbds-tkn-color__orange--900;
    background: $cbds-tkn-color__orange--900;
    box-shadow: none;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__white;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    background: $cbds-tkn-color__neutral--200;
    border-color: $cbds-tkn-color__neutral--300;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .2);
    cursor: not-allowed;

    &:visited,
    &:focus,
    &:hover,
    &:active {
      text-decoration: none;
    }
  }

  &.cbds-is-loading {
    padding-top: 0;
    padding-bottom: 0;
    pointer-events: none;
  }
}


.cbds-c-button__icon {
  display: inline-flex;
  width: 20px;
  height: 20px;
  fill: currentColor;
}


.cbds-c-button__spinner {
  width: 20px;
  height: 20px;
  border-color: $cbds-tkn-color__white;
  &::after,
  &::before {
    background: $cbds-tkn-color__white;
  }
}


// Setup Icon & Spinner Positions
.cbds-c-button {
  & > .cbds-c-button__icon + .cbds-c-button__label,
  & > .cbds-c-button__label + .cbds-c-button__icon {
    margin-left: 6px;
  }

  & > .cbds-c-button__spinner + .cbds-c-button__label,
  & > .cbds-c-button__label + .cbds-c-button__spinner {
    margin-left: 6px;
  }
}






// ==========================================================================
// Variant: Contained
// ==========================================================================


//
// Color: Default
// ==========================================================================

.cbds-c-button--contained {
// .cbds-c-button--containedDefault {
  $c: 'cbds-c-button';

  color: $cbds-tkn-color__white;
  border-color: $cbds-tkn-color__orange--700;
  background: $cbds-tkn-color__orange;
  box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);

  &:focus,
  &.cbds-is-focus {
    text-decoration: underline;
    color: $cbds-tkn-color__white;
    border-color: $cbds-tkn-color__orange--800;
    background: $cbds-tkn-color__orange--700;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .12);
    @include cbds-focus-outline($cbds-tkn-color__orange);
  }

  &:hover,
  &.cbds-is-hover {
    text-decoration: underline;
    color: $cbds-tkn-color__white;
    border-color: $cbds-tkn-color__orange--800;
    background: $cbds-tkn-color__orange--700;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .12);
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__white;
    border-color: $cbds-tkn-color__orange--900;
    background: $cbds-tkn-color__orange--900;
    box-shadow: none;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__white;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    background: $cbds-tkn-color__neutral--200;
    border-color: $cbds-tkn-color__neutral--300;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__neutral, .2);
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__white;
    &::after,
    &::before {
      background: $cbds-tkn-color__white;
    }
  }
}


//
// Color: Default On Dark
// ==========================================================================

// .cbds-c-button--containedOnDark,
// .cbds-c-button--containedDefaultOnDark {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__neutral--900;
//   border-color: $cbds-tkn-color__neutral--100;
//   background: $cbds-tkn-color__white;
//   box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .4);

//   &:focus,
//   &.cbds-is-focus {
//     text-decoration: underline;
//     color: $cbds-tkn-color__neutral--1000;
//     border-color: $cbds-tkn-color__neutral--100;
//     background: $cbds-tkn-color__white;
//     box-shadow: none;
//     @include cbds-focus-outline($cbds-tkn-color__white);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     text-decoration: underline;
//     color: $cbds-tkn-color__neutral--1000;
//     border-color: $cbds-tkn-color__neutral--100;
//     background: $cbds-tkn-color__white;
//     box-shadow: none;
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__neutral--1100;
//     border-color: $cbds-tkn-color__neutral--100;
//     background: $cbds-tkn-color__white;
//     box-shadow: none;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__neutral--900;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     background: $cbds-tkn-color__neutral--200;
//     border-color: $cbds-tkn-color__neutral--300;
//     box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .2);
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__neutral--900;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__neutral--900;
//     }
//   }
// }


// //
// // Color: Primary
// // ==========================================================================

// .cbds-c-button--containedPrimary {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__white;
//   border-color: $cbds-tkn-color__orange--700;
//   background: $cbds-tkn-color__orange;
//   box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__white;
//     background: $cbds-tkn-color__orange--700;
//     border-color: $cbds-tkn-color__orange--800;
//     box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .12);
//     @include cbds-focus-outline($cbds-tkn-color__orange);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__white;
//     background: $cbds-tkn-color__orange--700;
//     border-color: $cbds-tkn-color__orange--800;
//     box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .12);
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__white;
//     background: $cbds-tkn-color__orange--900;
//     border-color: $cbds-tkn-color__orange--900;
//     box-shadow: none;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__white;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     background: $cbds-tkn-color__neutral--200;
//     border-color: $cbds-tkn-color__neutral--300;
//     box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .2);
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__white;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__white;
//     }
//   }
// }


//
// Color: Primary On Dark
// ==========================================================================

// .cbds-c-button--containedPrimaryOnDark {
//   $c: 'cbdsws-c-button';

//   color: $cbds-tkn-color__orange--600;
//   border-color: $cbds-tkn-color__neutral--100;
//   background: $cbds-tkn-color__white;
//   box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__orange--700;
//     border-color: $cbds-tkn-color__neutral--100;
//     background: $cbds-tkn-color__white;
//     box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);
//     @include cbds-focus-outline($cbds-tkn-color__white);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__orange--700;
//     border-color: $cbds-tkn-color__neutral--100;
//     background: $cbds-tkn-color__white;
//     box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__orange--900;
//     border-color: $cbds-tkn-color__neutral--100;
//     background: $cbds-tkn-color__white;
//     box-shadow: none;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__orange--600;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     background: $cbds-tkn-color__neutral--200;
//     border-color: $cbds-tkn-color__neutral--300;
//     box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .2);
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__white;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__white;
//     }
//   }
// }

// //
// // Color: Secondary
// // ==========================================================================

// .cbds-c-button--containedSecondary {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__neutral--900;
//   border-color: $cbds-tkn-color__neutral--300;
//   background: $cbds-tkn-color__neutral--40;
//   box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .4);

//   &:focus,
//   &.cbds-is-focus {
//     text-decoration: underline;
//     color: $cbds-tkn-color__neutral--1000;
//     border-color: $cbds-tkn-color__neutral--300;
//     background: $cbds-tkn-color__neutral--100;
//     box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .2);
//     @include cbds-focus-outline;
//   }

//   &:hover,
//   &.cbds-is-hover {
//     text-decoration: underline;
//     color: $cbds-tkn-color__neutral--1000;
//     border-color: $cbds-tkn-color__neutral--300;
//     background: $cbds-tkn-color__neutral--100;
//     box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .2);
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__neutral--1100;
//     border-color: $cbds-tkn-color__neutral--200;
//     background: $cbds-tkn-color__neutral--200;
//     box-shadow: none;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__neutral--900;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     background: $cbds-tkn-color__neutral--200;
//     border-color: $cbds-tkn-color__neutral--300;
//     box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .2);
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__neutral--900;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__neutral--900;
//     }
//   }
// }


// //
// // Color: Secondary On Dark
// // ==========================================================================

// .cbds-c-button--containedSecondaryOnDark {
//   $c: 'cbdsws-c-button';

//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__neutral--900;
//   border-color: $cbds-tkn-color__neutral--100;
//   background: $cbds-tkn-color__white;
//   box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .4);

//   &:focus,
//   &.cbds-is-focus {
//     text-decoration: underline;
//     color: $cbds-tkn-color__neutral--1000;
//     border-color: $cbds-tkn-color__neutral--100;
//     background: $cbds-tkn-color__white;
//     box-shadow: none;
//     @include cbds-focus-outline($cbds-tkn-color__white);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     text-decoration: underline;
//     color: $cbds-tkn-color__neutral--1000;
//     border-color: $cbds-tkn-color__neutral--100;
//     background: $cbds-tkn-color__white;
//     box-shadow: none;
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__neutral--1100;
//     border-color: $cbds-tkn-color__neutral--100;
//     background: $cbds-tkn-color__white;
//     box-shadow: none;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__neutral--900;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     background: $cbds-tkn-color__neutral--200;
//     border-color: $cbds-tkn-color__neutral--300;
//     box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .2);
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__neutral--900;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__neutral--900;
//     }
//   }
// }


// //
// // Color: Tertiary
// // ==========================================================================

// .cbds-c-button--containedTertiary {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__white;
//   border-color: $cbds-tkn-color__neutral--1100;
//   background: $cbds-tkn-color__neutral--900;
//   box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__white;
//     border-color: $cbds-tkn-color__neutral--1100;
//     background: $cbds-tkn-color__neutral--1000;
//     box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);
//     @include cbds-focus-outline($cbds-tkn-color__neutral--900);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__white;
//     border-color: $cbds-tkn-color__neutral--1100;
//     background: $cbds-tkn-color__neutral--1000;
//     box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__white;
//     border-color: $cbds-tkn-color__neutral--1100;
//     background: $cbds-tkn-color__neutral--1100;
//     box-shadow: none;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__white;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     background: $cbds-tkn-color__neutral--200;
//     border-color: $cbds-tkn-color__neutral--300;
//     box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .2);
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__white;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__white;
//     }
//   }
// }


// //
// // Color: Tertiary On Dark
// // ==========================================================================

// .cbds-c-button--containedTertiaryOnDark {
//   $c: 'cbdsws-c-button';

//   color: $cbds-tkn-color__neutral--900;
//   border-color: $cbds-tkn-color__neutral--100;
//   background: $cbds-tkn-color__white;
//   box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__neutral--1000;
//     border-color: $cbds-tkn-color__neutral--100;
//     background: $cbds-tkn-color__white;
//     box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);
//     @include cbds-focus-outline($cbds-tkn-color__white);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__neutral--1000;
//     border-color: $cbds-tkn-color__neutral--100;
//     background: $cbds-tkn-color__white;
//     box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__neutral--1100;
//     border-color: $cbds-tkn-color__neutral--100;
//     background: $cbds-tkn-color__white;
//     box-shadow: none;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__neutral--900;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     background: $cbds-tkn-color__neutral--200;
//     border-color: $cbds-tkn-color__neutral--300;
//     box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .2);
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__neutral--900;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__neutral--900;
//     }
//   }
// }


// //
// // Color: Error
// // ==========================================================================

// .cbds-c-button--containedError {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__white;
//   border-color: $cbds-tkn-color__red--800;
//   background: $cbds-tkn-color__red--700;
//   box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__white;
//     border-color: $cbds-tkn-color__red--900;
//     background: $cbds-tkn-color__red--800;
//     box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);
//     @include cbds-focus-outline($cbds-tkn-color__red--700);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__white;
//     border-color: $cbds-tkn-color__red--900;
//     background: $cbds-tkn-color__red--800;
//     box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__white;
//     border-color: $cbds-tkn-color__red--1100;
//     background: $cbds-tkn-color__red--1000;
//     box-shadow: none;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__white;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     background: $cbds-tkn-color__neutral--200;
//     border-color: $cbds-tkn-color__neutral--300;
//     box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .2);
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__white;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__white;
//     }
//   }
// }


// //
// // Color: Warning
// // ==========================================================================

// .cbds-c-button--containedWarning {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__white;
//   border-color: $cbds-tkn-color__gold--900;
//   background: $cbds-tkn-color__gold--800;
//   box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__white;
//     border-color: $cbds-tkn-color__gold--1000;
//     background: $cbds-tkn-color__gold--900;
//     box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);
//     @include cbds-focus-outline($cbds-tkn-color__gold--800);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__white;
//     border-color: $cbds-tkn-color__gold--1000;
//     background: $cbds-tkn-color__gold--900;
//     box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__white;
//     border-color: $cbds-tkn-color__gold--1100;
//     background: $cbds-tkn-color__gold--1000;
//     box-shadow: none;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__white;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     background: $cbds-tkn-color__neutral--200;
//     border-color: $cbds-tkn-color__neutral--300;
//     box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .2);
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__white;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__white;
//     }
//   }
// }



// //
// // Color: Success
// // ==========================================================================

// .cbds-c-button--containedSuccess {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__white;
//   border-color: $cbds-tkn-color__green--800;
//   background: $cbds-tkn-color__green--700;
//   box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__white;
//     border-color: $cbds-tkn-color__green--900;
//     background: $cbds-tkn-color__green--800;
//     box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);
//     @include cbds-focus-outline($cbds-tkn-color__green--700);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__white;
//     border-color: $cbds-tkn-color__green--900;
//     background: $cbds-tkn-color__green--800;
//     box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__white;
//     border-color: $cbds-tkn-color__green--1100;
//     background: $cbds-tkn-color__green--1000;
//     box-shadow: none;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__white;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     background: $cbds-tkn-color__neutral--200;
//     border-color: $cbds-tkn-color__neutral--300;
//     box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .2);
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__white;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__white;
//     }
//   }
// }



// //
// // Color: Information
// // ==========================================================================

// .cbds-c-button--containedInfo {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__white;
//   border-color: $cbds-tkn-color__fadedBlue--800;
//   background: $cbds-tkn-color__fadedBlue--700;
//   box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__white;
//     border-color: $cbds-tkn-color__fadedBlue--900;
//     background: $cbds-tkn-color__fadedBlue--800;
//     box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);
//     @include cbds-focus-outline($cbds-tkn-color__fadedBlue--700);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__white;
//     border-color: $cbds-tkn-color__fadedBlue--900;
//     background: $cbds-tkn-color__fadedBlue--800;
//     box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__white;
//     border-color: $cbds-tkn-color__fadedBlue--1100;
//     background: $cbds-tkn-color__fadedBlue--1000;
//     box-shadow: none;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__white;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     background: $cbds-tkn-color__neutral--200;
//     border-color: $cbds-tkn-color__neutral--300;
//     box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .2);
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__white;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__white;
//     }
//   }
// }






// ==========================================================================
// Variant: Outlined
// ==========================================================================


//
// Color: Default
// ==========================================================================

.cbds-c-button--outlined {
// .cbds-c-button--outlinedDefault {
  $c: 'cbds-c-button';

  color: $cbds-tkn-color__orange;
  background: transparent;
  border-color: $cbds-tkn-color__orange--300;
  box-shadow: none;

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__orange--700;
    background: transparent;
    border-color: $cbds-tkn-color__orange--700;
    box-shadow: none;
    @include cbds-focus-outline($cbds-tkn-color__orange--700);
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__orange--700;
    background: transparent;
    border-color: $cbds-tkn-color__orange--700;
    box-shadow: none;
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__orange--900;
    background: transparent;
    border-color: $cbds-tkn-color__orange--900;
    box-shadow: none;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__orange;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    background: transparent;
    border-color: $cbds-tkn-color__neutral--300;
    box-shadow: none;
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__neutral--900;
    &::after,
    &::before {
      background: $cbds-tkn-color__neutral--900;
    }
  }
}


//
// Color: onDark
// ==========================================================================

// .cbds-c-button--outlinedOnDark,
// .cbds-c-button--outlinedDefaultOnDark {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__white;
//   background: transparent;
//   border-color: $cbds-tkn-color__white;
//   box-shadow: none;

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__white;
//     background: transparent;
//     border-color: $cbds-tkn-color__white;
//     box-shadow: none;
//     text-decoration: underline;
//     @include cbds-focus-outline($cbds-tkn-color__white);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__white;
//     background: transparent;
//     border-color: $cbds-tkn-color__white;
//     box-shadow: none;
//     text-decoration: underline;
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__white;
//     background: transparent;
//     border-color: $cbds-tkn-color__white;
//     box-shadow: none;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__white;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: rgba($cbds-tkn-color__white, .8);
//     background: transparent;
//     border-color: rgba($cbds-tkn-color__white, .8);
//     box-shadow: none;
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__white;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__white;
//     }
//   }
// }


// //
// // Color: Primary
// // ==========================================================================

// .cbds-c-button--outlinedPrimary {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__orange;
//   background: transparent;
//   border-color: $cbds-tkn-color__orange--300;
//   box-shadow: none;

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__orange--700;
//     background: transparent;
//     border-color: $cbds-tkn-color__orange--700;
//     box-shadow: none;
//     @include cbds-focus-outline($cbds-tkn-color__orange--700);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__orange--700;
//     background: transparent;
//     border-color: $cbds-tkn-color__orange--700;
//     box-shadow: none;
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__orange--900;
//     background: transparent;
//     border-color: $cbds-tkn-color__orange--900;
//     box-shadow: none;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__orange;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     background: transparent;
//     border-color: $cbds-tkn-color__neutral--300;
//     box-shadow: none;
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__orange;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__orange;
//     }
//   }
// }


// //
// // Color: Primary On Dark
// // ==========================================================================

// .cbds-c-button--outlinedPrimaryOnDark {
//   $c: 'cbds-c-button';

//   @extend .cbds-c-button--outlinedOnDark;
// }


// //
// // Color: Secondary
// // ==========================================================================

// .cbds-c-button--outlinedSecondary {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__neutral--900;
//   background: transparent;
//   border-color: $cbds-tkn-color__neutral--300;
//   box-shadow: none;

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__neutral--1000;
//     background: transparent;
//     border-color: $cbds-tkn-color__neutral--500;
//     box-shadow: none;
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__neutral--1000;
//     background: transparent;
//     border-color: $cbds-tkn-color__neutral--500;
//     box-shadow: none;
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__neutral--1100;
//     background: transparent;
//     border-color: $cbds-tkn-color__neutral--700;
//     box-shadow: none;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__neutral--900;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     background: transparent;
//     border-color: $cbds-tkn-color__neutral--300;
//     box-shadow: none;
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__neutral--900;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__neutral--900;
//     }
//   }
// }


// //
// // Color: Secondary On Dark
// // ==========================================================================

// .cbds-c-button--outlinedSecondaryOnDark {
//   $c: 'cbds-c-button';

//   @extend .cbds-c-button--outlinedOnDark;
// }


// //
// // Color: Tertiary
// // ==========================================================================

// .cbds-c-button--outlinedTertiary {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__neutral--900;
//   background: transparent;
//   border-color: $cbds-tkn-color__neutral--500;
//   box-shadow: none;

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__neutral--1000;
//     background: transparent;
//     border-color: $cbds-tkn-color__neutral--1000;
//     box-shadow: none;
//     @include cbds-focus-outline($cbds-tkn-color__neutral--900);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__neutral--1000;
//     background: transparent;
//     border-color: $cbds-tkn-color__neutral--1000;
//     box-shadow: none;
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__neutral--1100;
//     background: transparent;
//     border-color: $cbds-tkn-color__neutral--1100;
//     box-shadow: none;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__neutral;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     background: transparent;
//     border-color: $cbds-tkn-color__neutral--300;
//     box-shadow: none;
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__neutral--900;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__neutral--900;
//     }
//   }
// }


// //
// // Color: Tertiary On Dark
// // ==========================================================================

// .cbds-c-button--outlinedTertiaryOnDark {
//   $c: 'cbds-c-button';

//   @extend .cbds-c-button--outlinedOnDark;
// }


// //
// // Color: Error
// // ==========================================================================

// .cbds-c-button--outlinedError {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__red--700;
//   background: transparent;
//   border-color: $cbds-tkn-color__red--300;
//   box-shadow: none;

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__red--800;
//     background: transparent;
//     border-color: $cbds-tkn-color__red--800;
//     box-shadow: none;
//     @include cbds-focus-outline($cbds-tkn-color__red--700);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__red--800;
//     background: transparent;
//     border-color: $cbds-tkn-color__red--800;
//     box-shadow: none;
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__red--1000;
//     background: transparent;
//     border-color: $cbds-tkn-color__red--1000;
//     box-shadow: none;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__red--700;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     background: transparent;
//     border-color: $cbds-tkn-color__neutral--300;
//     box-shadow: none;
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__red--700;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__red--700;
//     }
//   }
// }


// //
// // Color: Warning
// // ==========================================================================

// .cbds-c-button--outlinedWarning {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__gold--800;
//   background: transparent;
//   border-color: $cbds-tkn-color__gold--400;
//   box-shadow: none;

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__gold--900;
//     background: transparent;
//     border-color: $cbds-tkn-color__gold--900;
//     box-shadow: none;
//     @include cbds-focus-outline($cbds-tkn-color__gold--800);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__gold--900;
//     background: transparent;
//     border-color: $cbds-tkn-color__gold--900;
//     box-shadow: none;
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__gold--1000;
//     background: transparent;
//     border-color: $cbds-tkn-color__gold--1000;
//     box-shadow: none;
//   }

//   &.cbds-is-visited {
//     color: $cbds-tkn-color__gold--800;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     background: transparent;
//     border-color: $cbds-tkn-color__neutral--300;
//     box-shadow: none;
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__gold--800;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__gold--800;
//     }
//   }
// }


// //
// // Color: Success
// // ==========================================================================

// .cbds-c-button--outlinedSuccess {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__green--700;
//   background: transparent;
//   border-color: $cbds-tkn-color__green--300;
//   box-shadow: none;

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__green--800;
//     background: transparent;
//     border-color: $cbds-tkn-color__green--800;
//     box-shadow: none;
//     @include cbds-focus-outline($cbds-tkn-color__green--700);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__green--800;
//     background: transparent;
//     border-color: $cbds-tkn-color__green--800;
//     box-shadow: none;
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__green--1000;
//     background: transparent;
//     border-color: $cbds-tkn-color__green--1000;
//     box-shadow: none;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__green--700;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     background: transparent;
//     border-color: $cbds-tkn-color__neutral--300;
//     box-shadow: none;
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__green--700;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__green--700;
//     }
//   }
// }


// //
// // Color: Information
// // ==========================================================================

// .cbds-c-button--outlinedInfo {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__fadedBlue--700;
//   background: transparent;
//   border-color: $cbds-tkn-color__fadedBlue--300;
//   box-shadow: none;

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__fadedBlue--800;
//     background: transparent;
//     border-color: $cbds-tkn-color__fadedBlue--800;
//     box-shadow: none;
//     @include cbds-focus-outline($cbds-tkn-color__fadedBlue--700);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__fadedBlue--800;
//     background: transparent;
//     border-color: $cbds-tkn-color__fadedBlue--800;
//     box-shadow: none;
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__fadedBlue--1000;
//     background: transparent;
//     border-color: $cbds-tkn-color__fadedBlue--1000;
//     box-shadow: none;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__fadedBlue--700;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     background: transparent;
//     border-color: $cbds-tkn-color__neutral--300;
//     box-shadow: none;
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__fadedBlue--700;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__fadedBlue--700;
//     }
//   }
// }






// ==========================================================================
// Variant: Ghost
// ==========================================================================


.cbds-c-button--ghost {
// .cbds-c-button--ghostDefault,
// .cbds-c-button--ghostPrimary,
// .cbds-c-button--ghostSecondary,
// .cbds-c-button--ghostTertiary,
// .cbds-c-button--ghostLink,
// .cbds-c-button--ghostError,
// .cbds-c-button--ghostWarning,
// .cbds-c-button--ghostSuccess,
// .cbds-c-button--ghostInfo,
// .cbds-c-button--ghostOnDark,
// .cbds-c-button--ghostDefaultOnDark,
// .cbds-c-button--ghostPrimaryOnDark,
// .cbds-c-button--ghostSecondaryOnDark,
// .cbds-c-button--ghostTertiaryOnDark,
// .cbds-c-button--ghostLinkOnDark {
  background: transparent;
  border-color: transparent;
  box-shadow: none;

  // Only show underline if button doesn't contain an icon or spinner
  & .cbds-c-button__label:only-child,
  &:visited .cbds-c-button__label:only-child,
  &:focus .cbds-c-button__label:only-child,
  &:hover .cbds-c-button__label:only-child,
  &:active .cbds-c-button__label:only-child {
    text-decoration: underline;
  }
}


//
// Ghost: Default
// ==========================================================================

.cbds-c-button--ghost {
// .cbds-c-button--ghostDefault {
  $c: 'cbds-c-button';

  color: $cbds-tkn-color__neutral--900;

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__neutral--900;
    background: $cbds-tkn-color__neutral--100;
    border-color: transparent;
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__neutral--900;
    background: $cbds-tkn-color__neutral--100;
    border-color: transparent;
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__neutral--900;
    background: $cbds-tkn-color__neutral--300;
    border-color: transparent;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__neutral--900;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--500;
    background: transparent;
    border-color: transparent;
    box-shadow: none;
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__neutral--900;
    &::after,
    &::before {
      background: $cbds-tkn-color__neutral--900;
    }
  }
}


//
// Color: Ghost On Dark
// ==========================================================================

// .cbds-c-button--ghostOnDark,
// .cbds-c-button--ghostDefaultOnDark,
// .cbds-c-button--ghostPrimaryOnDark,
// .cbds-c-button--ghostSecondaryOnDark,
// .cbds-c-button--ghostTertiaryOnDark,
// .cbds-c-button--ghostLinkOnDark {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__white;

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__white;
//     background: rgba($cbds-tkn-color__white, .08);
//     border-color: transparent;
//     box-shadow: none;
//     text-decoration: underline;
//     @include cbds-focus-outline($cbds-tkn-color__white);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__white;
//     background: rgba($cbds-tkn-color__white, .08);
//     border-color: transparent;
//     box-shadow: none;
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__white;
//     background: rgba($cbds-tkn-color__white, .12);
//     border-color: transparent;
//     box-shadow: none;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__white;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: rgba($cbds-tkn-color__white, .8);
//     background: transparent;
//     border-color: transparent;
//     box-shadow: none;
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__white;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__white;
//     }
//   }
// }


//
// Color: Primary
// ==========================================================================

// .cbds-c-button--ghostPrimary {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__orange;

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__orange--700;
//     background: $cbds-tkn-color__orange--40;
//     border-color: transparent;
//     @include cbds-focus-outline($cbds-tkn-color__orange);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__orange--700;
//     background: $cbds-tkn-color__orange--40;
//     border-color: transparent;
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__orange--900;
//     background: $cbds-tkn-color__orange--80;
//     border-color: transparent;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__orange;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     background: transparent;
//     border-color: transparent;
//     box-shadow: none;
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__orange;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__orange;
//     }
//   }
// }


// //
// // Color: Secondary
// // ==========================================================================

// .cbds-c-button--ghostSecondary {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__neutral--900;

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__neutral--1000;
//     background: $cbds-tkn-color__neutral--40;
//     border-color: transparent;
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__neutral--1000;
//     background: $cbds-tkn-color__neutral--40;
//     border-color: transparent;
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__neutral--1100;
//     background: $cbds-tkn-color__neutral--80;
//     border-color: transparent;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__neutral--900;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     background: transparent;
//     border-color: transparent;
//     box-shadow: none;
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__neutral--900;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__neutral--900;
//     }
//   }
// }


// //
// // Color: Tertiary
// // ==========================================================================

// .cbds-c-button--ghostTertiary {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__neutral--900;

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__neutral--1000;
//     background: $cbds-tkn-color__neutral--40;
//     border-color: transparent;
//     @include cbds-focus-outline($cbds-tkn-color__neutral--900);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__neutral--1000;
//     background: $cbds-tkn-color__neutral--40;
//     border-color: transparent;
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__neutral--1100;
//     background: $cbds-tkn-color__neutral--80;
//     border-color: transparent;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__neutral;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     background: transparent;
//     border-color: transparent;
//     box-shadow: none;
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__neutral--900;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__neutral--900;
//     }
//   }
// }


// //
// // Color: Link
// // ==========================================================================

// .cbds-c-button--ghostLink {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__blue;

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__blue--700;
//     background: $cbds-tkn-color__blue--40;
//     border-color: transparent;
//     @include cbds-focus-outline($cbds-tkn-color__blue);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__blue--700;
//     background: $cbds-tkn-color__blue--40;
//     border-color: transparent;
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__blue--900;
//     background: $cbds-tkn-color__blue--80;
//     border-color: transparent;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__blue;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     background: transparent;
//     border-color: transparent;
//     box-shadow: none;
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__blue;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__blue;
//     }
//   }
// }


// //
// // Color: Error
// // ==========================================================================

// .cbds-c-button--ghostError {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__red--700;

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__red--800;
//     background: $cbds-tkn-color__red--40;
//     border-color: transparent;
//     @include cbds-focus-outline($cbds-tkn-color__red--700);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__red--800;
//     background: $cbds-tkn-color__red--40;
//     border-color: transparent;
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__red--1000;
//     background: $cbds-tkn-color__red--80;
//     border-color: transparent;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__red--700;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     background: transparent;
//     border-color: transparent;
//     box-shadow: none;
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__red--700;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__red--700;
//     }
//   }
// }


// //
// // Color: Warning
// // ==========================================================================

// .cbds-c-button--ghostWarning {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__gold--800;

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__gold--900;
//     background: $cbds-tkn-color__gold--60;
//     border-color: transparent;
//     @include cbds-focus-outline($cbds-tkn-color__gold--800);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__gold--900;
//     background: $cbds-tkn-color__gold--60;
//     border-color: transparent;
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__gold--1000;
//     background: $cbds-tkn-color__gold--100;
//     border-color: transparent;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__gold--800;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     background: transparent;
//     border-color: transparent;
//     box-shadow: none;
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__gold--800;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__gold--800;
//     }
//   }
// }


// //
// // Color: Success
// // ==========================================================================

// .cbds-c-button--ghostSuccess {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__green--700;

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__green--800;
//     background: $cbds-tkn-color__green--40;
//     border-color: transparent;
//     @include cbds-focus-outline($cbds-tkn-color__green--700);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__green--800;
//     background: $cbds-tkn-color__green--40;
//     border-color: transparent;
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__green--1000;
//     background: $cbds-tkn-color__green--80;
//     border-color: transparent;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__green--700;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     background: transparent;
//     border-color: transparent;
//     box-shadow: none;
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__green--700;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__green--700;
//     }
//   }
// }


// //
// // Color: Information
// // ==========================================================================

// .cbds-c-button--ghostInfo {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__fadedBlue--700;

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__fadedBlue--800;
//     background: $cbds-tkn-color__fadedBlue--40;
//     border-color: transparent;
//     @include cbds-focus-outline($cbds-tkn-color__fadedBlue--700);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__fadedBlue--800;
//     background: $cbds-tkn-color__fadedBlue--40;
//     border-color: transparent;
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__fadedBlue--1000;
//     background: $cbds-tkn-color__fadedBlue--80;
//     border-color: transparent;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__fadedBlue--700;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     background: transparent;
//     border-color: transparent;
//     box-shadow: none;
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__fadedBlue--700;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__fadedBlue--700;
//     }
//   }
// }






// ==========================================================================
// Variant: Text
// ==========================================================================


// .cbds-c-button--text,
// .cbds-c-button--textDefault,
// .cbds-c-button--textPrimary,
// .cbds-c-button--textSecondary,
// .cbds-c-button--textTertiary,
// .cbds-c-button--textTertiary,
// .cbds-c-button--textLink,
// .cbds-c-button--textError,
// .cbds-c-button--textWarning,
// .cbds-c-button--textSuccess,
// .cbds-c-button--textInfo,
// .cbds-c-button--textOnDark,
// .cbds-c-button--textDefaultOnDark,
// .cbds-c-button--textPrimaryOnDark,
// .cbds-c-button--textSecondaryOnDark,
// .cbds-c-button--textTertiaryOnDark,
// .cbds-c-button--textLinkOnDark {
//   padding-left: 0;
//   padding-right: 0;
//   background: transparent;
//   border-color: transparent;
//   box-shadow: none;

//   &:focus,
//   &.cbds-is-focus,
//   &:hover,
//   &.cbds-is-hover,
//   &:active,
//   &.cbds-is-active,
//   &:visited,
//   &.cbds-is-visited,
//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     background: transparent;
//     border-color: transparent;
//     box-shadow: none;
//   }

//   // Only show underline if button doesn't contain an icon or spinner
//   & .cbds-c-button__label:only-child,
//   &:visited .cbds-c-button__label:only-child,
//   &:focus .cbds-c-button__label:only-child,
//   &:hover .cbds-c-button__label:only-child,
//   &:active .cbds-c-button__label:only-child {
//     text-decoration: underline;
//   }
// }


// //
// // Text: Default
// // ==========================================================================

// .cbds-c-button--text,
// .cbds-c-button--textDefault {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__neutral--900;

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__neutral--1000;
//     @include cbds-focus-outline($cbds-tkn-color__neutral--900);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__neutral--1000;
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__neutral--1100;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__neutral--900;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__neutral--900;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__neutral--900;
//     }
//   }
// }


// //
// // Text: On Dark
// // ==========================================================================

// .cbds-c-button--textOnDark,
// .cbds-c-button--textDefaultOnDark,
// .cbds-c-button--textPrimaryOnDark,
// .cbds-c-button--textSecondaryOnDark,
// .cbds-c-button--textTertiaryOnDark {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__white;

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__white;
//     @include cbds-focus-outline($cbds-tkn-color__white);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__white;
//     @include cbds-focus-outline($cbds-tkn-color__white);
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__white;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__white;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: rgba($cbds-tkn-color__white, .8);
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__white;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__white;
//     }
//   }
// }


// //
// // Color: Primary
// // ==========================================================================

// .cbds-c-button--textPrimary {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__orange;

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__orange--700;
//     @include cbds-focus-outline($cbds-tkn-color__orange);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__orange--700;
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__orange--900;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__orange;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__orange;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__orange;
//     }
//   }
// }


// //
// // Color: Primary On Dark
// // ==========================================================================

// .cbds-c-button--textPrimaryOnDark {
//   $c: 'cbds-c-button';

//   @extend .cbds-c-button--textOnDark;
// }


// //
// // Color: Secondary
// // ==========================================================================

// .cbds-c-button--textSecondary {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__neutral--900;

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__neutral--1000;
//     @include cbds-focus-outline($cbds-tkn-color__neutral--900);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__neutral--1000;
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__neutral--1100;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__neutral--900;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__neutral--900;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__neutral--900;
//     }
//   }
// }


// //
// // Color: Secondary On Dark
// // ==========================================================================

// .cbds-c-button--textSecondaryOnDark {
//   $c: 'cbds-c-button';

//   @extend .cbds-c-button--textOnDark;
// }


// //
// // Color: Tertiary
// // ==========================================================================

// .cbds-c-button--textTertiary {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__neutral--900;

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__neutral--1000;
//     @include cbds-focus-outline($cbds-tkn-color__neutral--900);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__neutral--1000;
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__neutral--1100;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__neutral;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__neutral--900;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__neutral--900;
//     }
//   }
// }


// //
// // Color: Tertiary On Dark
// // ==========================================================================

// .cbds-c-button--textTertiaryOnDark {
//   $c: 'cbds-c-button';

//   @extend .cbds-c-button--textOnDark;
// }


// //
// // Color: Link
// // ==========================================================================

// .cbds-c-button--textLink {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__blue;

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__blue--700;
//     @include cbds-focus-outline($cbds-tkn-color__blue);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__blue--700;
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__blue--900;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__blue;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__blue;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__blue;
//     }
//   }
// }


// //
// // Color: Link On Dark
// // ==========================================================================

// .cbds-c-button--textLinkOnDark {
//   $c: 'cbds-c-button';

//   @extend .cbds-c-button--textOnDark;
// }



// //
// // Color: Error
// // ==========================================================================

// .cbds-c-button--textError {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__red--700;

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__red--800;
//     @include cbds-focus-outline($cbds-tkn-color__red--700);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__red--800;
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__red--1000;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__red--700;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__red--700;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__red--700;
//     }
//   }
// }


// //
// // Color: Warning
// // ==========================================================================

// .cbds-c-button--textWarning {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__gold--800;

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__gold--900;
//     @include cbds-focus-outline($cbds-tkn-color__gold--800);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__gold--900;
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__gold--1000;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__gold--800;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__gold--800;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__gold--800;
//     }
//   }
// }


// //
// // Color: Success
// // ==========================================================================

// .cbds-c-button--textSuccess {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__green--700;

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__green--800;
//     @include cbds-focus-outline($cbds-tkn-color__green--700);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__green--800;
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__green--1000;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__green--700;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__green--700;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__green--700;
//     }
//   }
// }


// //
// // Color: Information
// // ==========================================================================

// .cbds-c-button--textInfo {
//   $c: 'cbds-c-button';

//   color: $cbds-tkn-color__fadedBlue--700;

//   &:focus,
//   &.cbds-is-focus {
//     color: $cbds-tkn-color__fadedBlue--800;
//     @include cbds-focus-outline($cbds-tkn-color__fadedBlue--700);
//   }

//   &:hover,
//   &.cbds-is-hover {
//     color: $cbds-tkn-color__fadedBlue--800;
//   }

//   &:active,
//   &.cbds-is-active {
//     color: $cbds-tkn-color__fadedBlue--1000;
//   }

//   &:visited,
//   &.cbds-is-visited {
//     color: $cbds-tkn-color__fadedBlue--700;
//   }

//   &[disabled],
//   &[aria-disabled="true"],
//   &.cbds-is-disabled {
//     color: $cbds-tkn-color__neutral--600;
//     cursor: not-allowed;

//     .#{$c}__icon {
//       fill: currentColor;
//     }
//   }

//   .#{$c}__spinner {
//     border-color: $cbds-tkn-color__fadedBlue--700;
//     &::after,
//     &::before {
//       background: $cbds-tkn-color__fadedBlue--700;
//     }
//   }
// }






// ==========================================================================
// Button Shadow
// ==========================================================================

.cbds-c-button--shadow {
  box-shadow: 0 2px 6px 0 rgba($cbds-tkn-color__black, .1);
}






// ==========================================================================
// Button Sizing
// ==========================================================================

.cbds-c-button {
  $c: 'cbds-c-button';


  &--sm {
    height: 36px;
    padding: 8px 12px; // Shave 2px off width of each side due to border to total 16px
    @include cbds-actionFontSize__400;

    .#{$c}__icon {
      width: 16px;
      height: 16px;
    }

    .#{$c}__spinner {
      padding: 0;
      width: 16px;
      height: 16px;
    }

    // Setup Icon & Spinner Positions
    & > .cbds-c-button__icon + .cbds-c-button__label,
    & > .cbds-c-button__label + .cbds-c-button__icon {
      margin-left: 4px;
    }

    & > .cbds-c-button__spinner + .cbds-c-button__label,
    & > .cbds-c-button__label + .cbds-c-button__spinner {
      margin-left: 4px;
    }
  }


  &--lg {
    height: 64px;
    padding: 18px 30px; // Shave 2px off width of each side due to border to total 32px
    @include cbds-actionFontSize__700;

    .#{$c}__icon {
      width: 28px;
      height: 28px;
    }

    .#{$c}__spinner {
      padding: 0;
      width: 28px;
      height: 28px;
    }

    // Setup Icon & Spinner Positions
    & > .cbds-c-button__icon + .cbds-c-button__label,
    & > .cbds-c-button__label + .cbds-c-button__icon {
      margin-left: 8px;
    }

    & > .cbds-c-button__spinner + .cbds-c-button__label,
    & > .cbds-c-button__label + .cbds-c-button__spinner {
      margin-left: 8px;
    }
  }


  &--fullWidth {
    width: 100%;
  }
}
`,
  'cbds-figure': `// ==========================================================================
// --------------------------------------------------------------------------
// #CBDS Figure
// --------------------------------------------------------------------------
// ==========================================================================

@import 'cbds-figure__tokens';








// ==========================================================================
// Base Layout & Styles
// ==========================================================================


.cbds-c-figure {
  @include cbds-box-sizing;
  margin: 0;
  padding: 0;

  // Ensures the caption's text aligns with the image.
  display: inline-block;
}

.cbds-c-figure__image {
  line-height: 1;
}

.cbds-c-figure__caption {
  margin-top: 8px;
  @include cbds-bodyText__400;
  color: $cbds-figure-caption-color;
}
`,
  'cbds-footnote': `// ==========================================================================
// --------------------------------------------------------------------------
// #FOOTNOTE
// --------------------------------------------------------------------------
// ==========================================================================


.cbds-c-footnote {
  @include cbds-box-sizing;
  @include cbds-bodyText__400;
}


.cbds-c-footnote__heading {
  margin-bottom: $cbds-tkn-space__4x;
  @include cbds-headingText__700;
  color: $cbds-tkn-textColor__secondary;
}


.cbds-c-footnote__list {
  margin: 0;
  padding: 0;
  list-style: none;
  // counter-reset: cbds-footnote-counter;
}


.cbds-c-footnote__item {
  position: relative;
  margin: 0;
  padding: 0 0 0 $cbds-tkn-space__4x;
  list-style: none;
  @include cbds-bodyText__400;
  color: $cbds-tkn-textColor__secondary;

  &:not(:first-child) {
    margin: $cbds-tkn-space__stack--2x;
  }
}


.cbds-c-footnote__cite {
  position: absolute;
  top: 0;
  left: 0;
  @include cbds-elementText__100;
}






// ==========================================================================
// Modifiers
// ==========================================================================

//
// CSS Counter
// ==========================================================================

// .cbds-c-footnote--counter {
//   .cbds-c-footnote__item {
//     counter-increment: cbds-footnote-counter;

//     &:before {
//       position: absolute;
//       left: 0;
//       width: 12px;
//       height: $cbds-tkn-unit__size * 3;
//       font-size: $cbds-tkn-typeScale__100;
//       font-weight: $cbds-tkn-fontWeight__normal;
//       line-height: $cbds-tkn-unit__lineHeight * 4;
//       content: counter(cbds-footnote-counter);
//     }
//   }
// }
`,
  'cbds-file-input': `// ==========================================================================
// --------------------------------------------------------------------------
// #CBDS File Input
// --------------------------------------------------------------------------
// ==========================================================================


.cbds-c-fileInput {
  $c: &;

  @include cbds-box-sizing;

  display: block;
  width: 100%;
  height: 48px;
  margin: 0;
  padding: 8px 12px;
  @include cbds-elementText__400;
  color: $cbds-tkn-textColor__secondary;
  background-color: $cbds-tkn-color__white;
  // Adding because Autoprefixer doesn't support border-radius
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: solid $cbds-tkn-borderWidth__xs $cbds-tkn-color__neutral--500;
  transition: border 0.1s linear, background-color 0.1s linear;
  appearance: none;

  &:focus,
  &.cbds-is-focus {
    @include cbds-focus-outline;
    border-color: $cbds-tkn-color__neutral--800;
  }

  &:hover,
  &.cbds-is-hover {
    border-color: $cbds-tkn-color__neutral--800;
  }

  &:active,
  &.cbds-is-active {
    border-color: $cbds-tkn-color__neutral--1100;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-textColor__quat;
    background-color: $cbds-tkn-color__neutral--60;
    border-color: $cbds-tkn-color__neutral--200;
    cursor: not-allowed;
  }

  &::placeholder {
    color: $cbds-tkn-textColor__quat;
    opacity: 1;
  }

  // &[readonly],
  // &.cbds-is-readonly {
  // }

  // &.cbds-is-invalid,
  // &.cbds-has-error {
  //   border-color: $cbds-tkn-color__red--700;
  // }
}
`,
  'cbds-form': `// ==========================================================================
// --------------------------------------------------------------------------
// #CBDS Form
// --------------------------------------------------------------------------
// ==========================================================================


.cbds-c-form {
  $c: &;

  @include cbds-box-sizing;

  .cbds-c-formFieldset,
  .cbds-c-formElement {
    margin-top: 32px;
  }
}
`,
  'cbds-form-element': `// ==========================================================================
// --------------------------------------------------------------------------
// #FORM ELEMENT
// --------------------------------------------------------------------------
// ==========================================================================


.cbds-c-formElement {
  position: relative;
  margin: 0;
  padding: 0;
  border: none;
}


.cbds-c-formElement {
  $c: &;

  .cbds-c-formLabel {
    margin-bottom: $cbds-tkn-space__2x;
  }

  .cbds-c-formMicrocopy {
    // Default 2-way vert margins to account for various spacing scenarios
    margin-top: $cbds-tkn-space__2x;
    margin-bottom: $cbds-tkn-space__2x;
  }

  .cbds-c-formMicroalert {
    margin-top: $cbds-tkn-space__2x;
  }

  // Tighten space when no form label w/ microcopy above control
  .cbds-c-formMicrocopy:first-of-type {
    margin-top: 0;
  }

  // Tighten space when both appear for more uniformed spacing
  .cbds-c-formLabel + .cbds-c-formMicrocopy {
    margin-top: -$cbds-tkn-space__1x;
    margin-bottom: $cbds-tkn-space__2x;
  }

  // Tighten space when both appear for more uniformed spacing
  .cbds-c-formMicroalert + .cbds-c-formMicrocopy {
    margin-top: $cbds-tkn-space__1x;
  }

  // &__l-counterHelp {
  //   display: flex;
  //   justify-content: space-between;

  //   #{$c}__counter {
  //     flex-shrink: 0;
  //   }
  // }
}






// ==========================================================================
// Form Control Validation
// ==========================================================================


///
// @TODO: Refactor at some point
//
// Below can be done much better and dried up; works for now!
//

// For JS validation
// I'd argue we just go with applying validation css classes vs rely on
// pseudo so we don't have 2 different methods. If we are doing server-side
// its required so might as well simplify.
//
// .was-validated {
//   .cbds-c-colorPicker:invalid,
//   .cbds-c-datePicker:invalid,
//   .cbds-c-fileInput:invalid,
//   .cbds-c-numberInput:invalid,
//   .cbds-c-select .cbds-c-select__select:invalid,
//   .cbds-c-textInput:invalid,
//   .cbds-c-textarea:invalid {
//     border-color: $cbds-tkn-color__red--700;
//   }
// }

// For server-side validation
.cbds-c-colorPicker,
.cbds-c-datePicker .cbds-c-datePicker__input,
.cbds-c-fileInput,
.cbds-c-numberInput,
.cbds-c-select .cbds-c-select__select,
.cbds-c-textInput,
.cbds-c-textarea {
  &.cbds-is-invalid {
    border-color: $cbds-tkn-color__red--700;
  }
}
`,
  'cbds-form-fieldset': `// ==========================================================================
// --------------------------------------------------------------------------
// #CBDS Form Fieldset
// --------------------------------------------------------------------------
// ==========================================================================


.cbds-c-formFieldset {
  @include cbds-box-sizing;

  margin: 0;
  padding: 0;
  border: 0;
}


.cbds-c-formFieldset {
  $c: &;

  .cbds-c-formLegend {
    margin-bottom: $cbds-tkn-space__2x;
  }

  .cbds-c-formMicrocopy {
    // Default 2-way vert margins to account for various spacing scenarios
    margin-top: $cbds-tkn-space__2x;
    margin-bottom: $cbds-tkn-space__2x;
  }

  .cbds-c-formMicroalert {
    margin-top: $cbds-tkn-space__2x;
  }

  // Tighten space when no form label w/ microcopy above control
  .cbds-c-formMicrocopy:first-of-type {
    margin-top: 0;
  }

  // Tighten space when both appear for more uniformed spacing
  .cbds-c-formLegend + .cbds-c-formMicrocopy {
    margin-top: -$cbds-tkn-space__1x;
    margin-bottom: $cbds-tkn-space__2x;
  }

  // Tighten space when both appear for more uniformed spacing
  .cbds-c-formMicroalert + .cbds-c-formMicrocopy {
    margin-top: $cbds-tkn-space__1x;
  }

  // &__l-counterHelp {
  //   display: flex;
  //   justify-content: space-between;

  //   #{$c}__counter {
  //     flex-shrink: 0;
  //   }
  // }
}
`,
  'cbds-form-label': `// ==========================================================================
// --------------------------------------------------------------------------
// #CBDS Form Label
// --------------------------------------------------------------------------
// ==========================================================================


.cbds-c-formLabel {
  @include cbds-box-sizing;
  display: block;
  @include cbds-elementText__500($fontWeight: semiBold);
  color: $cbds-tkn-textColor__secondary;
}


.cbds-c-formLabel__optional {
  padding-left: $cbds-tkn-space__1x;
  font-weight: $cbds-tkn-fontWeight__light;
  color: $cbds-tkn-textColor__tertiary;
}
`,
  'cbds-form-microcopy': `// ==========================================================================
// --------------------------------------------------------------------------
// #CBDS Form Microcopy
// --------------------------------------------------------------------------
// ==========================================================================


.cbds-c-formMicrocopy {
  @include cbds-box-sizing;
  display: block;
  @include cbds-elementText__400;
  color: $cbds-tkn-textColor__quat;
}
`,
  'cbds-form-legend': `// ==========================================================================
// --------------------------------------------------------------------------
// #CBDS Form Legend
// --------------------------------------------------------------------------
// ==========================================================================


.cbds-c-formLegend {
  @include cbds-box-sizing;
  display: block;
  @include cbds-elementText__500($fontWeight: semiBold);
  color: $cbds-tkn-textColor__secondary;
}


.cbds-c-formLegend__optional {
  padding-left: $cbds-tkn-space__1x;
  font-weight: $cbds-tkn-fontWeight__light;
  color: $cbds-tkn-textColor__tertiary;
}
`,
  'cbds-form-microalert': `// ==========================================================================
// --------------------------------------------------------------------------
// #CBDS Form Microalert
// --------------------------------------------------------------------------
// ==========================================================================


.cbds-c-formMicroalert {
  @include cbds-box-sizing;
  display: flex;
  @include cbds-elementText__400;
  color: $cbds-tkn-color__red--700;
}


.cbds-c-formMicroalert__icon {
  position: relative;
  top: -2px;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: $cbds-tkn-space__1x;
}
`,
  'cbds-global-footer__tools': `// ==========================================================================
// --------------------------------------------------------------------------
// #CBDS Global Footer: Tools
// --------------------------------------------------------------------------
// ==========================================================================


@mixin cbds-globalFooter-list() {
  margin: 0;
  padding: 0;
  list-style: none;
}
`,
  'cbds-image__tokens': `// ==========================================================================
// Bootstrap Variables
// ==========================================================================

//
// Variables & Styles sourced from Bootstrap v5.0.0-beta2
// Includes MVP changes to original BS code to align w/ CBDS
//
// TODO: Re-factor variable naming conventions to align with CBDS methodology
//
`,
  'cbds-icon': `// ==========================================================================
// --------------------------------------------------------------------------
// #ICON
// --------------------------------------------------------------------------
// ==========================================================================


//
// Base setup
//
// 1. Flow with text content
// 2. Use the parent font-size for width and height
// 3. Vertically align icon with adjacent text
// 4. Inherit the parent text color
// 5. Remove strokes
// 6. Inherit the parent text color
//

.cbds-c-icon {
  @include cbds-box-sizing;
  position: relative;
  display: inline-block; // [1]
  width: 1em; // [2]
  height: 1em;
  vertical-align: middle; // [3]
  fill: currentColor; // [4]
  stroke-width: 0; // [5]
  stroke: currentColor; // [6]
}






// ==========================================================================
// Icon Sizing
// ==========================================================================


.cbds-c-icon--sm {
  width: $cbds-tkn-icon__size--24;
  height: $cbds-tkn-icon__size--24;
}

.cbds-c-icon--md {
  width: $cbds-tkn-icon__size--48;
  height: $cbds-tkn-icon__size--48;
}

.cbds-c-icon--12 {
  width: $cbds-tkn-icon__size--12;
  height: $cbds-tkn-icon__size--12;
}

.cbds-c-icon--16 {
  width: $cbds-tkn-icon__size--16;
  height: $cbds-tkn-icon__size--16;
}

.cbds-c-icon--20 {
  width: $cbds-tkn-icon__size--20;
  height: $cbds-tkn-icon__size--20;
}

.cbds-c-icon--24 {
  width: $cbds-tkn-icon__size--24;
  height: $cbds-tkn-icon__size--24;
}

.cbds-c-icon--28 {
  width: $cbds-tkn-icon__size--28;
  height: $cbds-tkn-icon__size--28;
}

.cbds-c-icon--32 {
  width: $cbds-tkn-icon__size--32;
  height: $cbds-tkn-icon__size--32;
}

.cbds-c-icon--40 {
  width: $cbds-tkn-icon__size--40;
  height: $cbds-tkn-icon__size--40;
}

.cbds-c-icon--48 {
  width: $cbds-tkn-icon__size--48;
  height: $cbds-tkn-icon__size--48;
}

.cbds-c-icon--60 {
  width: $cbds-tkn-icon__size--60;
  height: $cbds-tkn-icon__size--60;
}

.cbds-c-icon--80 {
  width: $cbds-tkn-icon__size--80;
  height: $cbds-tkn-icon__size--80;
}

.cbds-c-icon--120 {
  width: $cbds-tkn-icon__size--120;
  height: $cbds-tkn-icon__size--120;
}






// ==========================================================================
// Icon Color(s)
// ==========================================================================


.cbds-c-icon--primary {
  fill: $cbds-tkn-icon__color--primary;
}

.cbds-c-icon--light {
  fill: $cbds-tkn-icon__color--light;
}

.cbds-c-icon--dark {
  fill: $cbds-tkn-icon__color--dark;
}

.cbds-c-icon--wire {
  fill: $cbds-tkn-color__neutral--900;
}
`,
  'cbds-image': `// ==========================================================================
// --------------------------------------------------------------------------
// #CBDS Image
// --------------------------------------------------------------------------
// ==========================================================================

@import 'cbds-image__tokens';








// ==========================================================================
// Base Layout & Styles
// ==========================================================================


.cbds-c-image {
  @include cbds-box-sizing;
  display: block;
  margin: 0;
  padding: 0;
}








// ==========================================================================
// Modifiers
// ==========================================================================


// Responsive images (ensure images don't scale beyond their parents)
//
// This is purposefully opt-in via an explicit class rather than being the default for all \`<img>\`s.
// We previously tried the "images are responsive by default" approach in Bootstrap v2,
// and abandoned it in Bootstrap v3 because it breaks lots of third-party widgets (including Google Maps)
// which weren't expecting the images within themselves to be involuntarily resized.
// See also https://github.com/twbs/bootstrap/issues/18178

.cbds-c-image--fluid {
  @include cbds-img-fluid();
}


.cbds-c-image--rounded {
  &None,
  &0 {
    border-radius: $cbds-tkn-borderRadius__sm;
  }
  &10 {
    border-radius: $cbds-tkn-borderRadius__sm;
  }
  &20 {
    border-radius: $cbds-tkn-borderRadius__md;
  }
  &30 {
    border-radius: $cbds-tkn-borderRadius__lg;
  }
}
`,
  'cbds-global-footer': `// ==========================================================================
// --------------------------------------------------------------------------
// #CBDS Global Footer
// --------------------------------------------------------------------------
// ==========================================================================


@import 'cbds-global-footer__tools';








// ==========================================================================
// Base Layout & Styles
// ==========================================================================

.cbds-c-globalFooter {
  @include cbds-box-sizing;
  @include cbds-elementText__500;
}


.cbds-c-globalFooter__primarySection {
  padding-top: $cbds-tkn-space__6x;
  padding-bottom: $cbds-tkn-space__4x; // So space looks even visually
}


.cbds-c-globalFooter__main {
  justify-content: space-between;
}


.cbds-c-globalFooter__actions {
  margin-top: $cbds-tkn-space__6x;
}


.cbds-c-globalFooter__secondarySection {
  padding-top: $cbds-tkn-space__6x;
  padding-bottom: $cbds-tkn-space__6x;
  background-color: $cbds-tkn-color__neutral--60;

  .cbds-c-globalFooterSocialMedia + .cbds-c-globalFooterUtilityNav {
    margin: $cbds-tkn-space__stack--1x;
  }
}




//
// Logo
// ==========================================================================

.cbds-c-globalFooter__logo {
  margin: $cbds-tkn-space__stack--2x;
  width: 140px;
  height: 23px;
}




//
// Primary Nav
// ==========================================================================

.cbds-c-globalFooterPrimaryNav {
  .cbds-l-grid__row {
    @include media('>=md') {
      justify-content: flex-end;
    }
  }
}


.cbds-c-globalFooterPrimaryNav__column {
  width: 50%;
  margin-top: $cbds-tkn-space__12x;
  padding: 0 $cbds-tkn-space__2x;

  @include media('>=md') {
    margin-top: 0;
    width: 33%;
    padding: 0 $cbds-tkn-space__3x;
  }

  @include media('>=lg') {
    padding: 0 $cbds-tkn-space__4x;
  }
}


.cbds-c-globalFooterPrimaryNav__heading {
  display: inline-block;
  // Diff than DCOM
  // CBDS -> matches Sketch component design
  @include cbds-headingText__600;
  color: $cbds-tkn-textColor__secondary;

  // Only add margin-top when horizontally aligned to logo
  @include media('>=md') {
    margin: $cbds-tkn-space__2x 0 0 0;
  }
}


.cbds-c-globalFooterPrimaryNav__list {
  width: 100%;
  margin: $cbds-tkn-space__2x 0 0;
  padding: 0;
}

.cbds-c-globalFooterPrimaryNav__item {
  margin: 0;
  padding: 0;
  list-style: none;
}


.cbds-c-globalFooterPrimaryNav__link {
  // Vert Padding + Line-Height to get 36px default touch area

  display: inline-block;
  padding: ($cbds-tkn-space__2x - 1) 0;
  @include cbds-actionText__500;
  vertical-align: initial;
  color: $cbds-tkn-color__neutral--900;
  border: 0;
  background-color: transparent;
  outline: 0;
  appearance: none;
  cursor: pointer;

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__neutral--900;
    text-decoration: none;
  }

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__neutral--1000;
    text-decoration: underline;
    @include cbds-focus-outline;
  }

	&:hover,
	&.cbds-is-hover {
		color: $cbds-tkn-color__neutral--1000;
    text-decoration: underline;
  }

	&:active,
	&.cbds-is-active {
		color: $cbds-tkn-color__neutral--1100;
  }
}

//
// Action Button
// ==========================================================================
.cbds-c-globalFooter__actionButton {
  padding: 0;
  height: auto;
  color: $cbds-tkn-color__black !important;
  background: transparent !important;
}

//
// Social Nav
// ==========================================================================

.cbds-c-globalFooterSocialMedia {
  @include cbds-globalFooter-list;

  text-align: center;

  @include media('>=sm') {
    display: inline-block;
    margin-left: -$cbds-tkn-space__2x; // pull left to align properly
    text-align: left;
  }
}


.cbds-c-globalFooterSocialMedia__item {
  display: inline-block;
}




//
// Secondary Nav
// ==========================================================================

.cbds-c-globalFooterUtilityNav {

}


.cbds-c-globalFooterUtilityNav__list {
  @include cbds-globalFooter-list;
  text-align: center;

  @include media('>=sm') {
    margin-left: -$cbds-tkn-space__2x; // pull left to align properly
    text-align: left;
  }
}


.cbds-c-globalFooterUtilityNav__item {
  display: inline;
  margin: 0;
  font-size: initial;
  line-height: normal;

  &::before {
    content: " ";
  }

  &::after {
    content: " ";
    white-space: normal;
    word-spacing: -2px;
    background-color: $cbds-tkn-color__neutral--200;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 1;
    font-size: small;
  }
}


.cbds-c-globalFooterUtilityNav__link {
  // Vert Padding + Line-Height to get 48px default touch area

  display: inline-block; /* to allow padding */
  padding: ($cbds-tkn-space__4x - 1) $cbds-tkn-space__2x;
  @include cbds-actionText__400;
  white-space: nowrap; /* here */
  vertical-align: initial;
  color: $cbds-tkn-color__neutral--900;
  border: 0;
  background-color: transparent;
  outline: 0;
  appearance: none;
  cursor: pointer;

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__neutral--900;
    text-decoration: none;
  }

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__neutral--1000;
    text-decoration: underline;
    @include cbds-focus-outline;
  }

	&:hover,
	&.cbds-is-hover {
		color: $cbds-tkn-color__neutral--1000;
    text-decoration: underline;
  }

	&:active,
	&.cbds-is-active {
		color: $cbds-tkn-color__neutral--1100;
  }
}
`,
  'cbds-layout-grid__tools.grid': `// ==========================================================================
// --------------------------------------------------------------------------
// #GRID
// --------------------------------------------------------------------------
// ==========================================================================


//
// The CBDS Grid system is a customized port of
// Bootstrap Grid v4.4.1 (https://getbootstrap.com/)
//
// These mixins originate from BS4 [scss/mixins/_grid.scss] file
//

// Grid system
//
// Generate semantic grid columns with these mixins.

@mixin cbds-make-row($gutter: $cbds-grid-gutter-width) {
  display: flex;
  flex-wrap: wrap;
  margin-right: calc(calc($gutter * -1) / 2);
  margin-left: calc(calc($gutter * -1) / 2);
}

@mixin cbds-make-col-ready($gutter: $cbds-grid-gutter-width) {
  position: relative;
  // Prevent columns from becoming too narrow when at smaller grid tiers by
  // always setting \`width: 100%;\`. This works because we use \`flex\` values
  // later on to override this initial width.
  width: 100%;
  padding-right: calc($gutter / 2);
  padding-left: calc($gutter / 2);
}

@mixin cbds-make-col($size, $columns: $cbds-grid-columns) {
  flex: 0 0 percentage(calc($size / $columns));
  // Add a \`max-width\` to ensure content within each column does not blow out
  // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
  // do not appear to require this.
  max-width: percentage(calc($size / $columns));
}

@mixin cbds-make-col-auto() {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; // Reset earlier grid tiers
}

@mixin cbds-make-col-offset($size, $columns: $cbds-grid-columns) {
  $num: calc($size / $columns);
  margin-left: if($num == 0, 0, percentage($num));
}

// Row columns
//
// Specify on a parent element(e.g., .row) to force immediate children into NN
// numberof columns. Supports wrapping to new lines, but does not do a Masonry
// style grid.
@mixin cbds-row-cols($count) {
  & > * {
    flex: 0 0 calc(100% / $count);
    max-width: calc(100% / $count);
  }
}

@mixin cbds-make-container($containerPadding: $cbds-grid-containerPadding-width, $gutter: $cbds-grid-gutter-width) {
  @include cbds-box-sizing;
  width: 100%;
  padding-right: calc($gutter / 2) + calc($containerPadding / 2);
  padding-left: calc($gutter / 2) + calc($containerPadding / 2);
  margin-right: auto;
  margin-left: auto;
}


// For each breakpoint, define the maximum width of the container in a media query
@mixin cbds-make-container-max-widths($max-widths: $cbds-container-max-widths, $breakpoints: $cbds-grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include cbds-media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}
`,
  'cbds-layout-grid__tools.framework': `// ==========================================================================
// --------------------------------------------------------------------------
// #GRID-FRAMEWORK
// --------------------------------------------------------------------------
// ==========================================================================


//
// The CBDS Grid system is a customized port of
// Bootstrap Grid v4.4.1 (https://getbootstrap.com/)
//
// These mixins originate from BS4 [scss/mixins/_grid-framework.scss] file
//


// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of \`$grid__columns\`.
//
// cbds Modifications
// 1. Add [cbds] prefix and [l] namespace

@mixin cbds-make-grid-columns($columns: $cbds-grid-columns, $gutter: $cbds-grid-gutter-width, $breakpoints: $cbds-grid-breakpoints) {
  // Common properties for all breakpoints
  %grid-column {
    position: relative;
    width: 100%;
    padding-right: calc($gutter / 2);
    padding-left: calc($gutter / 2);
  }

  @each $breakpoint in map-keys($breakpoints) {
    $infix: cbds-breakpoint-infix($breakpoint, $breakpoints);

    // Allow columns to stretch full width below their breakpoints
    @for $i from 1 through $columns {
      //.col#{$infix}-#{$i} {
      //  @extend %grid__column;
      // }
      @if $infix == "" {
        .cbds-l-grid__col--#{$i} { // [1]
          @extend %grid-column;
        }
      } @else {
        .cbds-l-grid__col--#{$i}\@#{$infix} { // [1]
          @extend %grid-column;
        }
      }
    }

    // .col#{$infix},
    // .col#{$infix}-auto {
    //   @extend %grid__column;
    // }
    @if $infix == "" {
      .cbds-l-grid__col, // [1]
      .cbds-l-grid__col--auto { // [1]
        @extend %grid-column;
      }
    } @else {
      .cbds-l-grid__col--\@#{$infix}, // [1]
      .cbds-l-grid__col--auto\@#{$infix} { // [1]
        @extend %grid-column;
      }
    }


    @include cbds-media-breakpoint-up($breakpoint, $breakpoints) {
      // Provide basic \`.col-{bp}\` classes for equal-width flexbox columns
      // .col#{$infix} {
      //   flex-basis: 0;
      //   flex-grow: 1;
      //   min-width: 0; // See https://github.com/twbs/bootstrap/issues/25410
      //   max-width: 100%;
      // }

      @if $infix == "" {
        .cbds-l-grid__col { // [1]
          flex-basis: 0;
          flex-grow: 1;
          min-width: 0; // See https://github.com/twbs/bootstrap/issues/25410
          max-width: 100%;
        }
      } @else {
        .cbds-l-grid__col--\@#{$infix} { // [1]
          flex-basis: 0;
          flex-grow: 1;
          min-width: 0; // See https://github.com/twbs/bootstrap/issues/25410
          max-width: 100%;
        }
      }

      @for $i from 1 through $cbds-grid-row-columns {
        @if $infix == "" {
          .cbds-l-grid__rowCols--#{$i} { // [1]
            @include cbds-row-cols($i);
          }
        } @else {
          .cbds-l-grid__rowCols--#{$i}\@#{$infix} { // [1]
            @include cbds-row-cols($i);
          }
        }
      }

      // .col#{$infix}-auto {
      //   flex: 0 0 auto;
      //   width: auto;
      //   max-width: none; // Reset earlier grid tiers
      // }
      @if $infix == "" {
        .cbds-l-grid__col--auto { // [1]
          flex: 0 0 auto;
          width: auto;
          max-width: none; // Reset earlier grid tiers
        }
      } @else {
        .cbds-l-grid__col--auto\@#{$infix} { // [1]
          flex: 0 0 auto;
          width: auto;
          max-width: none; // Reset earlier grid tiers
        }
      }

      @for $i from 1 through $columns {
        // .col#{$infix}-#{$i} {
        //   @include make__col($i, $columns);
        // }
        @if $infix == "" {
          .cbds-l-grid__col--#{$i} { // [1]
            @include cbds-make-col($i, $columns);
          }
        } @else {
          .cbds-l-grid__col--#{$i}\@#{$infix} { // [1]
            @include cbds-make-col($i, $columns);
          }
        }
      }

      // .order#{$infix}-first { order: -1; }
      @if $infix == "" {
        .cbds-l-grid__col--orderFirst { order: -1; } // [1]
      } @else {
        .cbds-l-grid__col--orderFirst\@#{$infix} { order: -1; } // [1]
      }

      // .order#{$infix}-last { order: $columns + 1; }
      @if $infix == "" {
        .cbds-l-grid__col--orderLast { order: $columns + 1; } // [1]
      } @else {
        .cbds-l-grid__col--orderLast\@#{$infix} { order: $columns + 1; } // [1]
      }

      @for $i from 0 through $columns {
        // .order#{$infix}-#{$i} { order: $i; }
        @if $infix == "" {
          .cbds-l-grid__col--order#{$i} { order: $i; } // [1]
        } @else {
          .cbds-l-grid__col--order#{$i}\@#{$infix} { order: $i; } // [1]
        }
      }

      // \`$columns - 1\` because offsetting by the width of an entire row isn't possible
      @for $i from 0 through ($columns - 1) {
        @if not ($infix == "" and $i == 0) { // Avoid emitting useless .offset-0
          // .offset#{$infix}-#{$i} {
          //   @include make__col-offset($i, $columns);
          // }
          @if $infix == "" {
            .cbds-l-grid__col--offset#{$i} {  // [1]
              @include cbds-make-col-offset($i, $columns);
            }
          } @else {
            .cbds-l-grid__col--offset#{$i}\@#{$infix} {  // [1]
              @include cbds-make-col-offset($i, $columns);
            }
          }
        }
      }
    }
  }
}
`,
  'cbds-link': `// ==========================================================================
// --------------------------------------------------------------------------
// #LINK
// --------------------------------------------------------------------------
// ==========================================================================


// ==========================================================================
// CBDS Link Base Style & Layout
// ==========================================================================

.cbds-c-link {
  $c: &;

  @include cbds-box-sizing;

  margin: 0;
  padding: 0;
  font-family: inherit;
  font-style: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  letter-spacing: normal;
  text-indent: 0;
  text-align: initial;
  text-shadow: none;
  text-transform: inherit;
  text-decoration: underline;
  white-space: normal;
  vertical-align: initial;
  color: $cbds-tkn-color__blue;
  border: 0;
  background-color: transparent;
  outline: 0;
  appearance: none;
  cursor: pointer;

  &:visited,
  &:focus,
	&:hover,
	&:active {
		text-decoration: underline;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__purple;
  }

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__blue;
    text-decoration: underline;
    background: transparent;
    border: none;
    @include cbds-focus-outline;
  }

	&:hover,
	&.cbds-is-hover {
		color: $cbds-tkn-color__blue;
  }

	&:active,
	&.cbds-is-active {
		color: $cbds-tkn-color__blue;
  }
}


.cbds-c-link__icon {
	position: relative;
	top: -1px;
	display: inline-block;
	width: 1.25em;
	height: 1.25em;
	vertical-align: middle;
	fill: currentColor;
}






// ==========================================================================
// CBDS Link Color Variants
// ==========================================================================


//
// Primary
// ==========================================================================

// .cbds-c-link {
// 	$c: &;

// 	&--primary {
// 		color: $cbds-tkn-color__orange--600;

// 		&:visited,
// 		&.cbds-is-visited {
// 		  color: $cbds-tkn-color__neutral--600;
// 		}

// 		&:focus,
// 		&.cbds-is-focus {
//       text-decoration: underline;
//       background: transparent;
//       color: $cbds-tkn-color__orange--700;
// 		}

// 		&:hover,
// 		&.cbds-is-hover {
// 			color: $cbds-tkn-color__orange--700;
//     }

// 		&:active,
// 		&.cbds-is-active {
// 			color: $cbds-tkn-color__orange--900;
//     }
// 	}
// }



// //
// // Secondary
// // ==========================================================================

// .cbds-c-link {
// 	$c: &;

// 	&--secondary {
// 		color: $cbds-tkn-color__neutral--900;

// 		&:visited,
// 		&.cbds-is-visited {
// 		  color: $cbds-tkn-color__neutral--600;
// 		}

// 		&:focus,
// 		&.cbds-is-focus {
//       text-decoration: underline;
//       background: transparent;
//       color: $cbds-tkn-color__neutral--1000;
// 		}

// 		&:hover,
// 		&.cbds-is-hover {
// 			color: $cbds-tkn-color__neutral--1000;
//     }

// 		&:active,
// 		&.cbds-is-active {
// 			color: $cbds-tkn-color__neutral--1100;
//     }
//   }
// }



// //
// // Tertiary
// // ==========================================================================

// .cbds-c-link {
// 	$c: &;

// 	&--tertiary {
// 		color: $cbds-tkn-color__neutral--900;

// 		&:visited,
// 		&.cbds-is-visited {
// 		  color: $cbds-tkn-color__neutral--600;
// 		}

// 		&:focus,
// 		&.cbds-is-focus {
//       text-decoration: underline;
//       background: transparent;
//       color: $cbds-tkn-color__neutral--1000;
// 		}

// 		&:hover,
// 		&.cbds-is-hover {
// 			color: $cbds-tkn-color__neutral--1000;
//     }

// 		&:active,
// 		&.cbds-is-active {
// 			color: $cbds-tkn-color__neutral--1100;
//     }
// 	}
// }




// //
// // Error
// // ==========================================================================

// .cbds-c-link {
// 	$c: &;

// 	&--error {
// 		color: $cbds-tkn-color__red--700;

// 		&:visited,
// 		&.cbds-is-visited {
// 		  color: $cbds-tkn-color__neutral--600;
// 		}

// 		&:focus,
// 		&.cbds-is-focus {
//       text-decoration: underline;
//       background: transparent;
//       color: $cbds-tkn-color__red--800;
// 		}

// 		&:hover,
// 		&.cbds-is-hover {
// 			color: $cbds-tkn-color__red--800;
//     }

// 		&:active,
// 		&.cbds-is-active {
// 			color: $cbds-tkn-color__red--1000;
//     }
// 	}
// }




// //
// // Info
// // ==========================================================================

// .cbds-c-link {
// 	$c: &;

// 	&--info {
//     color: $cbds-tkn-color__fadedBlue--700;

// 		&:visited,
// 		&.cbds-is-visited {
// 		  color: $cbds-tkn-color__neutral--600;
// 		}

// 		&:focus,
// 		&.cbds-is-focus {
//       text-decoration: underline;
//       background: transparent;
//       color: $cbds-tkn-color__fadedBlue--800;
// 		}

// 		&:hover,
// 		&.cbds-is-hover {
// 			color: $cbds-tkn-color__fadedBlue--800;
//     }

// 		&:active,
// 		&.cbds-is-active {
// 			color: $cbds-tkn-color__fadedBlue--1000;
//     }
// 	}
// }




// //
// // Success
// // ==========================================================================

// .cbds-c-link {
// 	$c: &;

// 	&--success {
//     color: $cbds-tkn-color__green--700;

// 		&:visited,
// 		&.cbds-is-visited {
// 		  color: $cbds-tkn-color__neutral--600;
// 		}

// 		&:focus,
// 		&.cbds-is-focus {
//       text-decoration: underline;
//       background: transparent;
//       color: $cbds-tkn-color__green--800;
// 		}

// 		&:hover,
// 		&.cbds-is-hover {
// 			color: $cbds-tkn-color__green--800;
//     }

// 		&:active,
// 		&.cbds-is-active {
// 			color: $cbds-tkn-color__green--1000;
//     }
// 	}
// }




// //
// // Warning
// // ==========================================================================

// .cbds-c-link {
// 	$c: &;

// 	&--warning {
//     color: $cbds-tkn-color__gold--800;

// 		&:visited,
// 		&.cbds-is-visited {
// 		  color: $cbds-tkn-color__neutral--600;
// 		}

// 		&:focus,
// 		&.cbds-is-focus {
//       text-decoration: underline;
//       background: transparent;
//       color: $cbds-tkn-color__gold--900;
// 		}

// 		&:hover,
// 		&.cbds-is-hover {
// 			color: $cbds-tkn-color__gold--900;
//     }

// 		&:active,
// 		&.cbds-is-active {
// 			color: $cbds-tkn-color__gold--1000;
//     }
// 	}
// }




// //
// // On Dark Background
// // ==========================================================================

// .cbds-c-link {
//   $c: &;

//   &--onDark {
//     color: $cbds-tkn-colorBrand__white;

//     &:visited,
//     &.cbds-is-visited {
//       color: $cbds-tkn-colorBrand__white;
//     }

//     &:focus,
//     &.cbds-is-focus {
//       outline: none;
//       color: $cbds-tkn-colorBrand__white;
//     }

//     &:hover,
//     &.cbds-is-hover {
//       color: $cbds-tkn-colorBrand__white;
//     }

//     &:active,
//     &.cbds-is-active {
//       color: $cbds-tkn-colorBrand__white;
//     }
//   }
// }
`,
  'cbds-icon-button': `// ==========================================================================
// --------------------------------------------------------------------------
// #ICON BUTTON
// --------------------------------------------------------------------------
// ==========================================================================


.cbds-c-iconButton {
  $c: &;

  @include cbds-box-sizing;

  z-index: 0;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  margin: 0;
  padding: 0;
  @include cbds-actionText__500($fontWeight: semiBold);
  white-space: nowrap;
  color: $cbds-tkn-color__neutral--900;
  border-width: 1px;
  border-style: solid;
  border-color: $cbds-tkn-color__neutral--300;
  // Adding because Autoprefixer doesn't support border-radius
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background: $cbds-tkn-color__neutral--40;
  box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .4);
  appearance: none;
  cursor: pointer;
  overflow: visible; // Normalize: Show the overflow in Edge

  // Normalize: Remove the inner border and padding in Firefox.
  &::-moz-focus-inner,
  &[type="button"]::-moz-focus-inner,
  &[type="reset"]::-moz-focus-inner,
  &[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  // Normalize: Restore the focus styles unset by the previous rule.
  &:-moz-focusring,
  &[type="button"]:-moz-focusring,
  &[type="reset"]:-moz-focusring,
  &[type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  &:visited,
  &:focus,
  &:hover,
  &:active {
    text-decoration: none;
  }

  &:focus,
  &.cbds-is-focus {
    text-decoration: underline;
    color: $cbds-tkn-color__neutral--1000;
    border-color: $cbds-tkn-color__neutral--300;
    background: $cbds-tkn-color__neutral--100;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .2);
    @include cbds-focus-outline;
  }

  &:hover,
  &.cbds-is-hover {
    text-decoration: underline;
    color: $cbds-tkn-color__neutral--1000;
    border-color: $cbds-tkn-color__neutral--300;
    background: $cbds-tkn-color__neutral--100;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .2);
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__neutral--1100;
    border-color: $cbds-tkn-color__neutral--200;
    background: $cbds-tkn-color__neutral--200;
    box-shadow: none;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__neutral--900;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    background: $cbds-tkn-color__neutral--200;
    border-color: $cbds-tkn-color__neutral--300;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .2);
    cursor: not-allowed;

    &:visited,
    &:focus,
    &:hover,
    &:active {
      text-decoration: none;
    }
  }

  &.cbds-is-loading {
    padding-top: 0;
    padding-bottom: 0;
    pointer-events: none;
  }
}


.cbds-c-iconButton__icon {
  display: inline-flex;
  width: 24px;
  height: 24px;
  fill: currentColor;
}


.cbds-c-iconButton__spinner {
  width: 24px;
  height: 24px;
  border-color: $cbds-tkn-color__neutral--900;
  &::after,
  &::before {
    background: $cbds-tkn-color__neutral--900;
  }
}






// ==========================================================================
// Variant: Contained
// ==========================================================================


//
// Color: Default
// ==========================================================================

.cbds-c-iconButton--contained,
.cbds-c-iconButton--containedDefault {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__neutral--900;
  border-color: $cbds-tkn-color__neutral--300;
  background: $cbds-tkn-color__neutral--40;
  box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .4);

  &:focus,
  &.cbds-is-focus {
    text-decoration: underline;
    color: $cbds-tkn-color__neutral--1000;
    border-color: $cbds-tkn-color__neutral--300;
    background: $cbds-tkn-color__neutral--100;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .2);
    @include cbds-focus-outline;
  }

  &:hover,
  &.cbds-is-hover {
    text-decoration: underline;
    color: $cbds-tkn-color__neutral--1000;
    border-color: $cbds-tkn-color__neutral--300;
    background: $cbds-tkn-color__neutral--100;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .2);
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__neutral--1100;
    border-color: $cbds-tkn-color__neutral--200;
    background: $cbds-tkn-color__neutral--200;
    box-shadow: none;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__neutral--900;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    background: $cbds-tkn-color__neutral--200;
    border-color: $cbds-tkn-color__neutral--300;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .2);
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__neutral--900;
    &::after,
    &::before {
      background: $cbds-tkn-color__neutral--900;
    }
  }
}


//
// Color: Default On Dark
// ==========================================================================

.cbds-c-iconButton--containedOnDark,
.cbds-c-iconButton--containedDefaultOnDark {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__neutral--900;
  border-color: $cbds-tkn-color__neutral--100;
  background: $cbds-tkn-color__white;
  box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .4);

  &:focus,
  &.cbds-is-focus {
    text-decoration: underline;
    color: $cbds-tkn-color__neutral--1000;
    border-color: $cbds-tkn-color__neutral--100;
    background: $cbds-tkn-color__white;
    box-shadow: none;
    @include cbds-focus-outline($cbds-tkn-color__white);
  }

  &:hover,
  &.cbds-is-hover {
    text-decoration: underline;
    color: $cbds-tkn-color__neutral--1000;
    border-color: $cbds-tkn-color__neutral--100;
    background: $cbds-tkn-color__white;
    box-shadow: none;
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__neutral--1100;
    border-color: $cbds-tkn-color__neutral--100;
    background: $cbds-tkn-color__white;
    box-shadow: none;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__neutral--900;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    background: $cbds-tkn-color__neutral--200;
    border-color: $cbds-tkn-color__neutral--300;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .2);
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__neutral--900;
    &::after,
    &::before {
      background: $cbds-tkn-color__neutral--900;
    }
  }
}


//
// Color: Primary
// ==========================================================================

.cbds-c-iconButton--containedPrimary {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__white;
  border-color: $cbds-tkn-color__orange--700;
  background: $cbds-tkn-color__orange;
  box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__white;
    background: $cbds-tkn-color__orange--700;
    border-color: $cbds-tkn-color__orange--800;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .12);
    @include cbds-focus-outline($cbds-tkn-color__orange);
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__white;
    background: $cbds-tkn-color__orange--700;
    border-color: $cbds-tkn-color__orange--800;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .12);
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__white;
    background: $cbds-tkn-color__orange--900;
    border-color: $cbds-tkn-color__orange--900;
    box-shadow: none;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__white;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    background: $cbds-tkn-color__neutral--200;
    border-color: $cbds-tkn-color__neutral--300;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .2);
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__white;
    &::after,
    &::before {
      background: $cbds-tkn-color__white;
    }
  }
}


//
// Color: Primary On Dark
// ==========================================================================

.cbds-c-iconButton--containedPrimaryOnDark {
  $c: 'cbdsws-c-button';

  color: $cbds-tkn-color__orange--600;
  border-color: $cbds-tkn-color__neutral--100;
  background: $cbds-tkn-color__white;
  box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__orange--700;
    border-color: $cbds-tkn-color__neutral--100;
    background: $cbds-tkn-color__white;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);
    @include cbds-focus-outline($cbds-tkn-color__white);
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__orange--700;
    border-color: $cbds-tkn-color__neutral--100;
    background: $cbds-tkn-color__white;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__orange--900;
    border-color: $cbds-tkn-color__neutral--100;
    background: $cbds-tkn-color__white;
    box-shadow: none;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__orange--600;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    background: $cbds-tkn-color__neutral--200;
    border-color: $cbds-tkn-color__neutral--300;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .2);
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__white;
    &::after,
    &::before {
      background: $cbds-tkn-color__white;
    }
  }
}

//
// Color: Secondary
// ==========================================================================

.cbds-c-iconButton--containedSecondary {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__neutral--900;
  border-color: $cbds-tkn-color__neutral--300;
  background: $cbds-tkn-color__neutral--40;
  box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .4);

  &:focus,
  &.cbds-is-focus {
    text-decoration: underline;
    color: $cbds-tkn-color__neutral--1000;
    border-color: $cbds-tkn-color__neutral--300;
    background: $cbds-tkn-color__neutral--100;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .2);
    @include cbds-focus-outline;
  }

  &:hover,
  &.cbds-is-hover {
    text-decoration: underline;
    color: $cbds-tkn-color__neutral--1000;
    border-color: $cbds-tkn-color__neutral--300;
    background: $cbds-tkn-color__neutral--100;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .2);
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__neutral--1100;
    border-color: $cbds-tkn-color__neutral--200;
    background: $cbds-tkn-color__neutral--200;
    box-shadow: none;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__neutral--900;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    background: $cbds-tkn-color__neutral--200;
    border-color: $cbds-tkn-color__neutral--300;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .2);
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__neutral--900;
    &::after,
    &::before {
      background: $cbds-tkn-color__neutral--900;
    }
  }
}


//
// Color: Secondary On Dark
// ==========================================================================

.cbds-c-iconButton--containedSecondaryOnDark {
  $c: 'cbdsws-c-button';

  color: $cbds-tkn-color__neutral--900;
  border-color: $cbds-tkn-color__neutral--100;
  background: $cbds-tkn-color__white;
  box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .4);

  &:focus,
  &.cbds-is-focus {
    text-decoration: underline;
    color: $cbds-tkn-color__neutral--1000;
    border-color: $cbds-tkn-color__neutral--100;
    background: $cbds-tkn-color__white;
    box-shadow: none;
    @include cbds-focus-outline($cbds-tkn-color__white);
  }

  &:hover,
  &.cbds-is-hover {
    text-decoration: underline;
    color: $cbds-tkn-color__neutral--1000;
    border-color: $cbds-tkn-color__neutral--100;
    background: $cbds-tkn-color__white;
    box-shadow: none;
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__neutral--1100;
    border-color: $cbds-tkn-color__neutral--100;
    background: $cbds-tkn-color__white;
    box-shadow: none;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__neutral--900;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    background: $cbds-tkn-color__neutral--200;
    border-color: $cbds-tkn-color__neutral--300;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .2);
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__neutral--900;
    &::after,
    &::before {
      background: $cbds-tkn-color__neutral--900;
    }
  }
}


//
// Color: Tertiary
// ==========================================================================

.cbds-c-iconButton--containedTertiary {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__white;
  border-color: $cbds-tkn-color__neutral--1100;
  background: $cbds-tkn-color__neutral--900;
  box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__white;
    border-color: $cbds-tkn-color__neutral--1100;
    background: $cbds-tkn-color__neutral--1000;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);
    @include cbds-focus-outline($cbds-tkn-color__neutral--900);
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__white;
    border-color: $cbds-tkn-color__neutral--1100;
    background: $cbds-tkn-color__neutral--1000;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__white;
    border-color: $cbds-tkn-color__neutral--1100;
    background: $cbds-tkn-color__neutral--1100;
    box-shadow: none;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__white;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    background: $cbds-tkn-color__neutral--200;
    border-color: $cbds-tkn-color__neutral--300;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .2);
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__white;
    &::after,
    &::before {
      background: $cbds-tkn-color__white;
    }
  }
}


//
// Color: Tertiary On Dark
// ==========================================================================

.cbds-c-iconButton--containedTertiaryOnDark {
  $c: 'cbdsws-c-button';

  color: $cbds-tkn-color__neutral--900;
  border-color: $cbds-tkn-color__neutral--100;
  background: $cbds-tkn-color__white;
  box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__neutral--1000;
    border-color: $cbds-tkn-color__neutral--100;
    background: $cbds-tkn-color__white;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);
    @include cbds-focus-outline($cbds-tkn-color__white);
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__neutral--1000;
    border-color: $cbds-tkn-color__neutral--100;
    background: $cbds-tkn-color__white;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__neutral--1100;
    border-color: $cbds-tkn-color__neutral--100;
    background: $cbds-tkn-color__white;
    box-shadow: none;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__neutral--900;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    background: $cbds-tkn-color__neutral--200;
    border-color: $cbds-tkn-color__neutral--300;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .2);
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__neutral--900;
    &::after,
    &::before {
      background: $cbds-tkn-color__neutral--900;
    }
  }
}


//
// Color: Error
// ==========================================================================

.cbds-c-iconButton--containedError {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__white;
  border-color: $cbds-tkn-color__red--800;
  background: $cbds-tkn-color__red--700;
  box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__white;
    border-color: $cbds-tkn-color__red--900;
    background: $cbds-tkn-color__red--800;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);
    @include cbds-focus-outline($cbds-tkn-color__red--700);
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__white;
    border-color: $cbds-tkn-color__red--900;
    background: $cbds-tkn-color__red--800;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__white;
    border-color: $cbds-tkn-color__red--1100;
    background: $cbds-tkn-color__red--1000;
    box-shadow: none;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__white;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    background: $cbds-tkn-color__neutral--200;
    border-color: $cbds-tkn-color__neutral--300;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .2);
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__white;
    &::after,
    &::before {
      background: $cbds-tkn-color__white;
    }
  }
}


//
// Color: Warning
// ==========================================================================

.cbds-c-iconButton--containedWarning {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__white;
  border-color: $cbds-tkn-color__gold--900;
  background: $cbds-tkn-color__gold--800;
  box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__white;
    border-color: $cbds-tkn-color__gold--1000;
    background: $cbds-tkn-color__gold--900;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);
    @include cbds-focus-outline($cbds-tkn-color__gold--800);
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__white;
    border-color: $cbds-tkn-color__gold--1000;
    background: $cbds-tkn-color__gold--900;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__white;
    border-color: $cbds-tkn-color__gold--1100;
    background: $cbds-tkn-color__gold--1000;
    box-shadow: none;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__white;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    background: $cbds-tkn-color__neutral--200;
    border-color: $cbds-tkn-color__neutral--300;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .2);
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__white;
    &::after,
    &::before {
      background: $cbds-tkn-color__white;
    }
  }
}



//
// Color: Success
// ==========================================================================

.cbds-c-iconButton--containedSuccess {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__white;
  border-color: $cbds-tkn-color__green--800;
  background: $cbds-tkn-color__green--700;
  box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__white;
    border-color: $cbds-tkn-color__green--900;
    background: $cbds-tkn-color__green--800;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);
    @include cbds-focus-outline($cbds-tkn-color__green--700);
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__white;
    border-color: $cbds-tkn-color__green--900;
    background: $cbds-tkn-color__green--800;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__white;
    border-color: $cbds-tkn-color__green--1100;
    background: $cbds-tkn-color__green--1000;
    box-shadow: none;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__white;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    background: $cbds-tkn-color__neutral--200;
    border-color: $cbds-tkn-color__neutral--300;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .2);
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__white;
    &::after,
    &::before {
      background: $cbds-tkn-color__white;
    }
  }
}



//
// Color: Information
// ==========================================================================

.cbds-c-iconButton--containedInfo {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__white;
  border-color: $cbds-tkn-color__fadedBlue--800;
  background: $cbds-tkn-color__fadedBlue--700;
  box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__white;
    border-color: $cbds-tkn-color__fadedBlue--900;
    background: $cbds-tkn-color__fadedBlue--800;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);
    @include cbds-focus-outline($cbds-tkn-color__fadedBlue--700);
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__white;
    border-color: $cbds-tkn-color__fadedBlue--900;
    background: $cbds-tkn-color__fadedBlue--800;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .06);
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__white;
    border-color: $cbds-tkn-color__fadedBlue--1100;
    background: $cbds-tkn-color__fadedBlue--1000;
    box-shadow: none;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__white;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    background: $cbds-tkn-color__neutral--200;
    border-color: $cbds-tkn-color__neutral--300;
    box-shadow: inset 0 2px 0 0 rgba($cbds-tkn-color__white, .2);
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__white;
    &::after,
    &::before {
      background: $cbds-tkn-color__white;
    }
  }
}






// ==========================================================================
// Variant: Outlined
// ==========================================================================


//
// Color: Default
// ==========================================================================

.cbds-c-iconButton--outlined,
.cbds-c-iconButton--outlinedDefault {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__neutral--900;
  background: transparent;
  border-color: $cbds-tkn-color__neutral--300;
  box-shadow: none;

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__neutral--1000;
    background: transparent;
    border-color: $cbds-tkn-color__neutral--500;
    box-shadow: none;
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__neutral--1000;
    background: transparent;
    border-color: $cbds-tkn-color__neutral--500;
    box-shadow: none;
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__neutral--1100;
    background: transparent;
    border-color: $cbds-tkn-color__neutral--700;
    box-shadow: none;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__neutral--900;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    background: transparent;
    border-color: $cbds-tkn-color__neutral--300;
    box-shadow: none;
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__neutral--900;
    &::after,
    &::before {
      background: $cbds-tkn-color__neutral--900;
    }
  }
}


//
// Color: onDark
// ==========================================================================

.cbds-c-iconButton--outlinedOnDark,
.cbds-c-iconButton--outlinedDefaultOnDark {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__white;
  background: transparent;
  border-color: $cbds-tkn-color__white;
  box-shadow: none;

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__white;
    background: transparent;
    border-color: $cbds-tkn-color__white;
    box-shadow: none;
    text-decoration: underline;
    @include cbds-focus-outline($cbds-tkn-color__white);
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__white;
    background: transparent;
    border-color: $cbds-tkn-color__white;
    box-shadow: none;
    text-decoration: underline;
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__white;
    background: transparent;
    border-color: $cbds-tkn-color__white;
    box-shadow: none;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__white;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: rgba($cbds-tkn-color__white, .8);
    background: transparent;
    border-color: rgba($cbds-tkn-color__white, .8);
    box-shadow: none;
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__white;
    &::after,
    &::before {
      background: $cbds-tkn-color__white;
    }
  }
}


//
// Color: Primary
// ==========================================================================

.cbds-c-iconButton--outlinedPrimary {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__orange;
  background: transparent;
  border-color: $cbds-tkn-color__orange--300;
  box-shadow: none;

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__orange--700;
    background: transparent;
    border-color: $cbds-tkn-color__orange--700;
    box-shadow: none;
    @include cbds-focus-outline($cbds-tkn-color__orange--700);
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__orange--700;
    background: transparent;
    border-color: $cbds-tkn-color__orange--700;
    box-shadow: none;
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__orange--900;
    background: transparent;
    border-color: $cbds-tkn-color__orange--900;
    box-shadow: none;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__orange;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    background: transparent;
    border-color: $cbds-tkn-color__neutral--300;
    box-shadow: none;
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__orange;
    &::after,
    &::before {
      background: $cbds-tkn-color__orange;
    }
  }
}


//
// Color: Primary On Dark
// ==========================================================================

.cbds-c-iconButton--outlinedPrimaryOnDark {
  $c: 'cbds-c-iconButton';

  @extend .cbds-c-iconButton--outlinedOnDark;
}


//
// Color: Secondary
// ==========================================================================

.cbds-c-iconButton--outlinedSecondary {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__neutral--900;
  background: transparent;
  border-color: $cbds-tkn-color__neutral--300;
  box-shadow: none;

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__neutral--1000;
    background: transparent;
    border-color: $cbds-tkn-color__neutral--500;
    box-shadow: none;
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__neutral--1000;
    background: transparent;
    border-color: $cbds-tkn-color__neutral--500;
    box-shadow: none;
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__neutral--1100;
    background: transparent;
    border-color: $cbds-tkn-color__neutral--700;
    box-shadow: none;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__neutral--900;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    background: transparent;
    border-color: $cbds-tkn-color__neutral--300;
    box-shadow: none;
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__neutral--900;
    &::after,
    &::before {
      background: $cbds-tkn-color__neutral--900;
    }
  }
}


//
// Color: Secondary On Dark
// ==========================================================================

.cbds-c-iconButton--outlinedSecondaryOnDark {
  $c: 'cbds-c-iconButton';

  @extend .cbds-c-iconButton--outlinedOnDark;
}


//
// Color: Tertiary
// ==========================================================================

.cbds-c-iconButton--outlinedTertiary {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__neutral--900;
  background: transparent;
  border-color: $cbds-tkn-color__neutral--500;
  box-shadow: none;

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__neutral--1000;
    background: transparent;
    border-color: $cbds-tkn-color__neutral--1000;
    box-shadow: none;
    @include cbds-focus-outline($cbds-tkn-color__neutral--900);
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__neutral--1000;
    background: transparent;
    border-color: $cbds-tkn-color__neutral--1000;
    box-shadow: none;
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__neutral--1100;
    background: transparent;
    border-color: $cbds-tkn-color__neutral--1100;
    box-shadow: none;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__neutral;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    background: transparent;
    border-color: $cbds-tkn-color__neutral--300;
    box-shadow: none;
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__neutral--900;
    &::after,
    &::before {
      background: $cbds-tkn-color__neutral--900;
    }
  }
}


//
// Color: Tertiary On Dark
// ==========================================================================

.cbds-c-iconButton--outlinedTertiaryOnDark {
  $c: 'cbds-c-iconButton';

  @extend .cbds-c-iconButton--outlinedOnDark;
}


//
// Color: Error
// ==========================================================================

.cbds-c-iconButton--outlinedError {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__red--700;
  background: transparent;
  border-color: $cbds-tkn-color__red--300;
  box-shadow: none;

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__red--800;
    background: transparent;
    border-color: $cbds-tkn-color__red--800;
    box-shadow: none;
    @include cbds-focus-outline($cbds-tkn-color__red--700);
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__red--800;
    background: transparent;
    border-color: $cbds-tkn-color__red--800;
    box-shadow: none;
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__red--1000;
    background: transparent;
    border-color: $cbds-tkn-color__red--1000;
    box-shadow: none;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__red--700;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    background: transparent;
    border-color: $cbds-tkn-color__neutral--300;
    box-shadow: none;
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__red--700;
    &::after,
    &::before {
      background: $cbds-tkn-color__red--700;
    }
  }
}


//
// Color: Warning
// ==========================================================================

.cbds-c-iconButton--outlinedWarning {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__gold--800;
  background: transparent;
  border-color: $cbds-tkn-color__gold--400;
  box-shadow: none;

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__gold--900;
    background: transparent;
    border-color: $cbds-tkn-color__gold--900;
    box-shadow: none;
    @include cbds-focus-outline($cbds-tkn-color__gold--800);
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__gold--900;
    background: transparent;
    border-color: $cbds-tkn-color__gold--900;
    box-shadow: none;
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__gold--1000;
    background: transparent;
    border-color: $cbds-tkn-color__gold--1000;
    box-shadow: none;
  }

  &.cbds-is-visited {
    color: $cbds-tkn-color__gold--800;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    background: transparent;
    border-color: $cbds-tkn-color__neutral--300;
    box-shadow: none;
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__gold--800;
    &::after,
    &::before {
      background: $cbds-tkn-color__gold--800;
    }
  }
}


//
// Color: Success
// ==========================================================================

.cbds-c-iconButton--outlinedSuccess {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__green--700;
  background: transparent;
  border-color: $cbds-tkn-color__green--300;
  box-shadow: none;

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__green--800;
    background: transparent;
    border-color: $cbds-tkn-color__green--800;
    box-shadow: none;
    @include cbds-focus-outline($cbds-tkn-color__green--700);
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__green--800;
    background: transparent;
    border-color: $cbds-tkn-color__green--800;
    box-shadow: none;
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__green--1000;
    background: transparent;
    border-color: $cbds-tkn-color__green--1000;
    box-shadow: none;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__green--700;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    background: transparent;
    border-color: $cbds-tkn-color__neutral--300;
    box-shadow: none;
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__green--700;
    &::after,
    &::before {
      background: $cbds-tkn-color__green--700;
    }
  }
}


//
// Color: Information
// ==========================================================================

.cbds-c-iconButton--outlinedInfo {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__fadedBlue--700;
  background: transparent;
  border-color: $cbds-tkn-color__fadedBlue--300;
  box-shadow: none;

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__fadedBlue--800;
    background: transparent;
    border-color: $cbds-tkn-color__fadedBlue--800;
    box-shadow: none;
    @include cbds-focus-outline($cbds-tkn-color__fadedBlue--700);
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__fadedBlue--800;
    background: transparent;
    border-color: $cbds-tkn-color__fadedBlue--800;
    box-shadow: none;
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__fadedBlue--1000;
    background: transparent;
    border-color: $cbds-tkn-color__fadedBlue--1000;
    box-shadow: none;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__fadedBlue--700;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    background: transparent;
    border-color: $cbds-tkn-color__neutral--300;
    box-shadow: none;
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__fadedBlue--700;
    &::after,
    &::before {
      background: $cbds-tkn-color__fadedBlue--700;
    }
  }
}






// ==========================================================================
// Variant: Ghost
// ==========================================================================


.cbds-c-iconButton--ghost,
.cbds-c-iconButton--ghostDefault,
.cbds-c-iconButton--ghostPrimary,
.cbds-c-iconButton--ghostSecondary,
.cbds-c-iconButton--ghostTertiary,
.cbds-c-iconButton--ghostError,
.cbds-c-iconButton--ghostWarning,
.cbds-c-iconButton--ghostSuccess,
.cbds-c-iconButton--ghostInfo,
.cbds-c-iconButton--ghostOnDark,
.cbds-c-iconButton--ghostDefaultOnDark,
.cbds-c-iconButton--ghostPrimaryOnDark,
.cbds-c-iconButton--ghostSecondaryOnDark,
.cbds-c-iconButton--ghostTertiaryOnDark {
  background: transparent;
  border-color: transparent;
  box-shadow: none;

  // Only show underline if button doesn't contain an icon or spinner
  & .cbds-c-iconButton__label:only-child,
  &:visited .cbds-c-iconButton__label:only-child,
  &:focus .cbds-c-iconButton__label:only-child,
  &:hover .cbds-c-iconButton__label:only-child,
  &:active .cbds-c-iconButton__label:only-child {
    text-decoration: underline;
  }
}


//
// Ghost: Default
// ==========================================================================

.cbds-c-iconButton--ghost,
.cbds-c-iconButton--ghostDefault {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__neutral--900;

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__neutral--1000;
    background: $cbds-tkn-color__neutral--40;
    border-color: transparent;
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__neutral--1000;
    background: $cbds-tkn-color__neutral--40;
    border-color: transparent;
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__neutral--1100;
    background: $cbds-tkn-color__neutral--80;
    border-color: transparent;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__neutral--900;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    background: transparent;
    border-color: transparent;
    box-shadow: none;
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__neutral--900;
    &::after,
    &::before {
      background: $cbds-tkn-color__neutral--900;
    }
  }
}


//
// Color: Ghost On Dark
// ==========================================================================

.cbds-c-iconButton--ghostOnDark,
.cbds-c-iconButton--ghostDefaultOnDark,
.cbds-c-iconButton--ghostPrimaryOnDark,
.cbds-c-iconButton--ghostSecondaryOnDark,
.cbds-c-iconButton--ghostTertiaryOnDark {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__white;

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__white;
    background: rgba($cbds-tkn-color__white, .08);
    border-color: transparent;
    box-shadow: none;
    text-decoration: underline;
    @include cbds-focus-outline($cbds-tkn-color__white);
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__white;
    background: rgba($cbds-tkn-color__white, .08);
    border-color: transparent;
    box-shadow: none;
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__white;
    background: rgba($cbds-tkn-color__white, .12);
    border-color: transparent;
    box-shadow: none
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__white;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: rgba($cbds-tkn-color__white, .8);
    background: transparent;
    border-color: transparent;
    box-shadow: none;
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__white;
    &::after,
    &::before {
      background: $cbds-tkn-color__white;
    }
  }
}


//
// Color: Primary
// ==========================================================================

.cbds-c-iconButton--ghostPrimary {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__orange;

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__orange--700;
    background: $cbds-tkn-color__orange--40;
    border-color: transparent;
    @include cbds-focus-outline($cbds-tkn-color__orange);
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__orange--700;
    background: $cbds-tkn-color__orange--40;
    border-color: transparent;
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__orange--900;
    background: $cbds-tkn-color__orange--80;
    border-color: transparent;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__orange;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    background: transparent;
    border-color: transparent;
    box-shadow: none;
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__orange;
    &::after,
    &::before {
      background: $cbds-tkn-color__orange;
    }
  }
}


//
// Color: Secondary
// ==========================================================================

.cbds-c-iconButton--ghostSecondary {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__neutral--900;

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__neutral--1000;
    background: $cbds-tkn-color__neutral--40;
    border-color: transparent;
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__neutral--1000;
    background: $cbds-tkn-color__neutral--40;
    border-color: transparent;
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__neutral--1100;
    background: $cbds-tkn-color__neutral--80;
    border-color: transparent;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__neutral--900;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    background: transparent;
    border-color: transparent;
    box-shadow: none;
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__neutral--900;
    &::after,
    &::before {
      background: $cbds-tkn-color__neutral--900;
    }
  }
}


//
// Color: Tertiary
// ==========================================================================

.cbds-c-iconButton--ghostTertiary {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__neutral--900;

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__neutral--1000;
    background: $cbds-tkn-color__neutral--40;
    border-color: transparent;
    @include cbds-focus-outline($cbds-tkn-color__neutral--900);
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__neutral--1000;
    background: $cbds-tkn-color__neutral--40;
    border-color: transparent;
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__neutral--1100;
    background: $cbds-tkn-color__neutral--80;
    border-color: transparent;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__neutral;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    background: transparent;
    border-color: transparent;
    box-shadow: none;
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__neutral--900;
    &::after,
    &::before {
      background: $cbds-tkn-color__neutral--900;
    }
  }
}


//
// Color: Error
// ==========================================================================

.cbds-c-iconButton--ghostError {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__red--700;

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__red--800;
    background: $cbds-tkn-color__red--40;
    border-color: transparent;
    @include cbds-focus-outline($cbds-tkn-color__red--700);
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__red--800;
    background: $cbds-tkn-color__red--40;
    border-color: transparent;
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__red--1000;
    background: $cbds-tkn-color__red--80;
    border-color: transparent;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__red--700;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    background: transparent;
    border-color: transparent;
    box-shadow: none;
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__red--700;
    &::after,
    &::before {
      background: $cbds-tkn-color__red--700;
    }
  }
}


//
// Color: Warning
// ==========================================================================

.cbds-c-iconButton--ghostWarning {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__gold--800;

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__gold--900;
    background: $cbds-tkn-color__gold--60;
    border-color: transparent;
    @include cbds-focus-outline($cbds-tkn-color__gold--800);
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__gold--900;
    background: $cbds-tkn-color__gold--60;
    border-color: transparent;
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__gold--1000;
    background: $cbds-tkn-color__gold--100;
    border-color: transparent;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__gold--800;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    background: transparent;
    border-color: transparent;
    box-shadow: none;
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__gold--800;
    &::after,
    &::before {
      background: $cbds-tkn-color__gold--800;
    }
  }
}


//
// Color: Success
// ==========================================================================

.cbds-c-iconButton--ghostSuccess {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__green--700;

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__green--800;
    background: $cbds-tkn-color__green--40;
    border-color: transparent;
    @include cbds-focus-outline($cbds-tkn-color__green--700);
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__green--800;
    background: $cbds-tkn-color__green--40;
    border-color: transparent;
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__green--1000;
    background: $cbds-tkn-color__green--80;
    border-color: transparent;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__green--700;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    background: transparent;
    border-color: transparent;
    box-shadow: none;
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__green--700;
    &::after,
    &::before {
      background: $cbds-tkn-color__green--700;
    }
  }
}


//
// Color: Information
// ==========================================================================

.cbds-c-iconButton--ghostInfo {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__fadedBlue--700;

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__fadedBlue--800;
    background: $cbds-tkn-color__fadedBlue--40;
    border-color: transparent;
    @include cbds-focus-outline($cbds-tkn-color__fadedBlue--700);
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__fadedBlue--800;
    background: $cbds-tkn-color__fadedBlue--40;
    border-color: transparent;
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__fadedBlue--1000;
    background: $cbds-tkn-color__fadedBlue--80;
    border-color: transparent;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__fadedBlue--700;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    background: transparent;
    border-color: transparent;
    box-shadow: none;
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__fadedBlue--700;
    &::after,
    &::before {
      background: $cbds-tkn-color__fadedBlue--700;
    }
  }
}






// ==========================================================================
// Variant: Text
// ==========================================================================


.cbds-c-iconButton--text,
.cbds-c-iconButton--textDefault,
.cbds-c-iconButton--textPrimary,
.cbds-c-iconButton--textSecondary,
.cbds-c-iconButton--textTertiary,
.cbds-c-iconButton--textError,
.cbds-c-iconButton--textWarning,
.cbds-c-iconButton--textSuccess,
.cbds-c-iconButton--textInfo,
.cbds-c-iconButton--textOnDark,
.cbds-c-iconButton--textDefaultOnDark,
.cbds-c-iconButton--textPrimaryOnDark,
.cbds-c-iconButton--textSecondaryOnDark,
.cbds-c-iconButton--textTertiaryOnDark,
.cbds-c-iconButton--textLink {
  width: auto;
  padding-left: 0;
  padding-right: 0;
  background: transparent;
  border-color: transparent;
  box-shadow: none;

  &:focus,
  &.cbds-is-focus,
  &:hover,
  &.cbds-is-hover,
  &:active,
  &.cbds-is-active,
  &:visited,
  &.cbds-is-visited,
  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    background: transparent;
    border-color: transparent;
    box-shadow: none;
  }

  // Only show underline if button doesn't contain an icon or spinner
  & .cbds-c-iconButton__label:only-child,
  &:visited .cbds-c-iconButton__label:only-child,
  &:focus .cbds-c-iconButton__label:only-child,
  &:hover .cbds-c-iconButton__label:only-child,
  &:active .cbds-c-iconButton__label:only-child {
    text-decoration: underline;
  }
}


//
// Text: Default
// ==========================================================================

.cbds-c-iconButton--text,
.cbds-c-iconButton--textDefault {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__neutral--900;

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__neutral--1000;
    @include cbds-focus-outline($cbds-tkn-color__neutral--900);
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__neutral--1000;
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__neutral--1100;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__neutral--900;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__neutral--900;
    &::after,
    &::before {
      background: $cbds-tkn-color__neutral--900;
    }
  }
}


//
// Text: On Dark
// ==========================================================================

.cbds-c-iconButton--textOnDark,
.cbds-c-iconButton--textDefaultOnDark,
.cbds-c-iconButton--textPrimaryOnDark,
.cbds-c-iconButton--textSecondaryOnDark,
.cbds-c-iconButton--textTertiaryOnDark {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__white;

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__white;
    @include cbds-focus-outline($cbds-tkn-color__white);
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__white;
    @include cbds-focus-outline($cbds-tkn-color__white);
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__white;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__white;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: rgba($cbds-tkn-color__white, .8);
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__white;
    &::after,
    &::before {
      background: $cbds-tkn-color__white;
    }
  }
}


//
// Color: Primary
// ==========================================================================

.cbds-c-iconButton--textPrimary {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__orange;

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__orange--700;
    @include cbds-focus-outline($cbds-tkn-color__orange);
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__orange--700;
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__orange--900;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__orange;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__orange;
    &::after,
    &::before {
      background: $cbds-tkn-color__orange;
    }
  }
}


//
// Color: Primary On Dark
// ==========================================================================

.cbds-c-iconButton--textPrimaryOnDark {
  $c: 'cbds-c-iconButton';

  @extend .cbds-c-iconButton--textOnDark;
}


//
// Color: Secondary
// ==========================================================================

.cbds-c-iconButton--textSecondary {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__neutral--900;

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__neutral--1000;
    @include cbds-focus-outline($cbds-tkn-color__neutral--900);
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__neutral--1000;
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__neutral--1100;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__neutral--900;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__neutral--900;
    &::after,
    &::before {
      background: $cbds-tkn-color__neutral--900;
    }
  }
}


//
// Color: Secondary On Dark
// ==========================================================================

.cbds-c-iconButton--textSecondaryOnDark {
  $c: 'cbds-c-iconButton';

  @extend .cbds-c-iconButton--textOnDark;
}


//
// Color: Tertiary
// ==========================================================================

.cbds-c-iconButton--textTertiary {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__neutral--900;

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__neutral--1000;
    @include cbds-focus-outline($cbds-tkn-color__neutral--900);
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__neutral--1000;
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__neutral--1100;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__neutral;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__neutral--900;
    &::after,
    &::before {
      background: $cbds-tkn-color__neutral--900;
    }
  }
}


//
// Color: Tertiary On Dark
// ==========================================================================

.cbds-c-iconButton--textTertiaryOnDark {
  $c: 'cbds-c-iconButton';

  @extend .cbds-c-iconButton--textOnDark;
}


//
// Color: Error
// ==========================================================================

.cbds-c-iconButton--textError {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__red--700;

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__red--800;
    @include cbds-focus-outline($cbds-tkn-color__red--700);
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__red--800;
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__red--1000;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__red--700;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__red--700;
    &::after,
    &::before {
      background: $cbds-tkn-color__red--700;
    }
  }
}


//
// Color: Warning
// ==========================================================================

.cbds-c-iconButton--textWarning {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__gold--800;

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__gold--900;
    @include cbds-focus-outline($cbds-tkn-color__gold--800);
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__gold--900;
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__gold--1000;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__gold--800;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__gold--800;
    &::after,
    &::before {
      background: $cbds-tkn-color__gold--800;
    }
  }
}


//
// Color: Success
// ==========================================================================

.cbds-c-iconButton--textSuccess {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__green--700;

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__green--800;
    @include cbds-focus-outline($cbds-tkn-color__green--700);
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__green--800;
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__green--1000;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__green--700;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__green--700;
    &::after,
    &::before {
      background: $cbds-tkn-color__green--700;
    }
  }
}


//
// Color: Information
// ==========================================================================

.cbds-c-iconButton--textInfo {
  $c: 'cbds-c-iconButton';

  color: $cbds-tkn-color__fadedBlue--700;

  &:focus,
  &.cbds-is-focus {
    color: $cbds-tkn-color__fadedBlue--800;
    @include cbds-focus-outline($cbds-tkn-color__fadedBlue--700);
  }

  &:hover,
  &.cbds-is-hover {
    color: $cbds-tkn-color__fadedBlue--800;
  }

  &:active,
  &.cbds-is-active {
    color: $cbds-tkn-color__fadedBlue--1000;
  }

  &:visited,
  &.cbds-is-visited {
    color: $cbds-tkn-color__fadedBlue--700;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-color__neutral--600;
    cursor: not-allowed;

    .#{$c}__icon {
      fill: currentColor;
    }
  }

  .#{$c}__spinner {
    border-color: $cbds-tkn-color__fadedBlue--700;
    &::after,
    &::before {
      background: $cbds-tkn-color__fadedBlue--700;
    }
  }
}






// ==========================================================================
// Button Shadow
// ==========================================================================

.cbds-c-iconButton--shadow {
  box-shadow: 0 2px 6px 0 rgba($cbds-tkn-color__black, .1);
}






// ==========================================================================
// Button Sizing
// ==========================================================================

.cbds-c-iconButton {
  $c: 'cbds-c-iconButton';


  &--sm {
    width: 36px;
    height: 36px;
    @include cbds-actionFontSize__400;

    .#{$c}__icon {
      width: 20px;
      height: 20px;
    }

    .#{$c}__spinner {
      padding: 0;
      width: 20px;
      height: 20px;
    }

    // Setup Icon & Spinner Positions
    & > .cbds-c-iconButton__icon + .cbds-c-iconButton__label,
    & > .cbds-c-iconButton__label + .cbds-c-iconButton__icon {
      margin-left: 4px;
    }

    & > .cbds-c-iconButton__spinner + .cbds-c-iconButton__label,
    & > .cbds-c-iconButton__label + .cbds-c-iconButton__spinner {
      margin-left: 4px;
    }
  }


  &--lg {
    width: 64px;
    height: 64px;
    @include cbds-actionFontSize__700;

    .#{$c}__icon {
      width: 32px;
      height: 32px;
    }

    .#{$c}__spinner {
      padding: 0;
      width: 32px;
      height: 32px;
    }

    // Setup Icon & Spinner Positions
    & > .cbds-c-iconButton__icon + .cbds-c-iconButton__label,
    & > .cbds-c-iconButton__label + .cbds-c-iconButton__icon {
      margin-left: 8px;
    }

    & > .cbds-c-iconButton__spinner + .cbds-c-iconButton__label,
    & > .cbds-c-iconButton__label + .cbds-c-iconButton__spinner {
      margin-left: 8px;
    }
  }
}
`,
  'cbds-masthead': `// ==========================================================================
// --------------------------------------------------------------------------
// #CBDS Global Header
// --------------------------------------------------------------------------
// ==========================================================================



@import "cbds-masthead__tools";







// ==========================================================================
// Base Layout & Styles
// =========================================================================

.cbds-c-masthead {
  @include cbds-box-sizing;
  @include cbds-bodyText__500;
  background-color: $cbds-tkn-colorBrand__white;
  color: $cbds-tkn-textColor__primary;
  position: relative;
  z-index: 1;
  @include cbds-mkt-box-shadow();
}




//
// Masthead
// ==========================================================================

.cbds-c-mastheadContent {
  min-height: 68px;
  padding: $cbds-tkn-space__2x 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  flex-wrap: wrap;
}




//
// Logo
// ==========================================================================

.cbds-c-mastheadBrand {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 52px;
    padding: $cbds-tkn-space__2x 0;

  @include media('>=xs') {
    flex-direction: row;
    align-items: center;
  }

  @include media('>=md') {
    flex-direction: column;
    align-items: flex-start;
  }

  @include media('>=lg') {
    flex-direction: row;
    align-items: center;
  }
}

.cbds-c-mastheadBrand__logo {
  width: 148px;
  height: 24px;

  &--citizensPay {
    width: 185px;
  }
}

.cbds-c-mastheadBrand__logoButton {
  .cbds-c-logoButton__logo {
    width: 148px;
    height: 24px;
  }

  &--citizensPay {
    .cbds-c-logoButton__logo {
      width: 185px;
    }
  }
}

.cbds-c-mastheadBrand__heading {
  @include cbds-elementText__500;
  margin-top: $cbds-tkn-space__1x;
  color: $cbds-tkn-textColor__secondary;

  @include media('>=xs') {
    margin-top: 0;
    margin-left: $cbds-tkn-space__2x;
  }

  @include media('>=md') {
    margin-top: $cbds-tkn-space__1x;
    margin-left: 0;
  }

  @include media('>=lg') {
    margin-top: 0;
    margin-left: $cbds-tkn-space__2x;
    font-size: $cbds-tkn-fontSize__40;
    line-height: 24px;
  }
}




//
// Actions
// ==========================================================================

.cbds-c-mastheadActions {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 0;
  padding: $cbds-tkn-space__halfX;
  margin: -$cbds-tkn-space__halfX;
  list-style: none;
  overflow: hidden;
  visibility: hidden;
  transition: max-height 0.15s ease-out, visibility 0.15s ease-out;

  @include media('<md') {
    &.cbds-is-expanded {
      max-height: 300px;
      visibility: visible;
      overflow: visible;
      animation: menu-expand 0.25s ease-in;
    }
  }

  @include media('>=md') {
    flex-direction: row;
    justify-content: space-between;
    width: auto;
    max-height: unset;
    margin: 0;
    padding: 0;
    overflow: visible;
    visibility: visible;
  }
}

.cbds-c-mastheadActions__item {
  padding:0;
  margin: 0;
  list-style: none;

  @include media('>=md') {
    margin-right: $cbds-tkn-space__8x;
    &:last-of-type {
      margin-right: 0;
    }
  }
}

.cbds-c-mastheadActions__button {
  width: 100%;
  height: auto;
  justify-content: flex-start;

  .cbds-c-button__icon {
    flex-shrink: 0;
  }

  .cbds-c-button__label {
    display: inline-block;
    white-space: normal;
  }
}




//
// Mobile Hamburger
// ==========================================================================

.cbds-c-masthead__menuButton {
  margin-right: -$cbds-tkn-space__2x;

  @include media('>=md') {
    display: none !important;
  }
}




//
// Action Mobile Menu Animation
// ==========================================================================

@keyframes menu-collapse {
  0% {
    max-height: 300px;
  }

  100% {
    max-height: 0;
  }
}

@keyframes menu-expand {
  0% {
    max-height: 0;
    overflow: hidden;
  }

  99% {
    overflow: hidden;
  }

  100% {
    max-height: 300px;
    overflow: visible;
  }
}
`,
  'cbds-masthead__tools': `// ==========================================================================
// --------------------------------------------------------------------------
// #CBDS Global Header: Tools
// --------------------------------------------------------------------------
// ==========================================================================




@mixin cbds-mkt-box-shadow() {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2);
}
`,
  'cbds-logo': `// ==========================================================================
// --------------------------------------------------------------------------
// #LOGO
// --------------------------------------------------------------------------
// ==========================================================================

// Logo Aspect Ratio Variables
// $cbds-logoAspectRatio__citizensHorz: 0.16363636363636364;
// $cbds-logoAspectRatio__citizensVert: 0.58791208791208791;
// $cbds-logoAspectRatio__citizensAccessHorz: 0.30368763557483731;
// $cbds-logoAspectRatio__clarfeldStacked: 0.22377622377622378;


//
// Base setup
//
// 1. Insure the logo flows with contentin
// 2. Inherit the parent text color
// 3. Remove strokes
// 4. Inherit the parent text color
//


.cbds-c-logo {
  @include cbds-box-sizing;
  position: relative;
  display: inline-block; // [1]
  margin: 0;
  padding: 0;
  text-align: initial;
  border-width: 0;
}



// .cbds-c-logo__citizensHorz {
//   width: 220px;
//   height: 36px;

//   // For future built-in css modifiers for sizing
//   // &--xs {
//   //   width: (4 * $cbds-tkn-unit__size) / $cbds-logoAspectRatio__citizensHorz;
//   //   height: 4 * $cbds-tkn-unit__size;
//   // }

//   // &--sm {
//   //   width: (6 * $cbds-tkn-unit__size) / $cbds-logoAspectRatio__citizensHorz;
//   //   height: 6 * $cbds-tkn-unit__size;
//   // }

//   // &--md {
//   //   width: (9 * $cbds-tkn-unit__size) / $cbds-logoAspectRatio__citizensHorz;
//   //   height: 9 * $cbds-tkn-unit__size;
//   // }

//   // &--lg {
//   //   width: (16 * $cbds-tkn-unit__size) / $cbds-logoAspectRatio__citizensHorz;
//   //   height: 16 * $cbds-tkn-unit__size
//   // }

//   // &--xl {
//   //   width: (24 * $cbds-tkn-unit__size) / $cbds-logoAspectRatio__citizensHorz;
//   //   height: 24 * $cbds-tkn-unit__size;
//   // }
// }

// .cbds-c-logo__citizensVert {
//   width: 182px;
//   height: 107px;

//   // For future built-in css modifiers for sizing
//   // &--xs {
//   //   width: (12 * $cbds-tkn-unit__size) / $cbds-logoAspectRatio__citizensVert;
//   //   height: 12 * $cbds-tkn-unit__size;
//   // }

//   // &--sm {
//   //   width: (18 * $cbds-tkn-unit__size) / $cbds-logoAspectRatio__citizensVert;
//   //   height: 18 * $cbds-tkn-unit__size;
//   // }

//   // &--md {
//   //   width: (27 * $cbds-tkn-unit__size) / $cbds-logoAspectRatio__citizensVert;
//   //   height: 27 * $cbds-tkn-unit__size;
//   // }

//   // &--lg {
//   //   width: (38 * $cbds-tkn-unit__size) / $cbds-logoAspectRatio__citizensVert;
//   //   height: 38 * $cbds-tkn-unit__size;
//   // }

//   // &--xl {
//   //   width: (52 * $cbds-tkn-unit__size) / $cbds-logoAspectRatio__citizensVert;
//   //   height: 52 * $cbds-tkn-unit__size;
//   // }
// }

// .cbds-c-logo__citizensAccessHorz {
//   width: 461px;
//   height: 140px;

//   // For future built-in css modifiers for sizing
//   // &--xs {
//   //   width: (8 * $cbds-tkn-unit__size) / $cbds-logoAspectRatio__citizensAccessHorz;
//   //   height: 8 * $cbds-tkn-unit__size;
//   // }

//   // &--sm {
//   //   width: (16 * $cbds-tkn-unit__size) / $cbds-logoAspectRatio__citizensAccessHorz;
//   //   height: 16 * $cbds-tkn-unit__size;
//   // }

//   // &--md {
//   //   width: (32 * $cbds-tkn-unit__size) / $cbds-logoAspectRatio__citizensAccessHorz;
//   //   height: 32 * $cbds-tkn-unit__size;
//   // }

//   // &--lg {
//   //   width: (48 * $cbds-tkn-unit__size) / $cbds-logoAspectRatio__citizensAccessHorz;
//   //   height: 48 * $cbds-tkn-unit__size
//   // }

//   // &--xl {
//   //   width: (64 * $cbds-tkn-unit__size) / $cbds-logoAspectRatio__citizensAccessHorz;
//   //   height: 64 * $cbds-tkn-unit__size;
//   // }
// }

// .cbds-c-logo__clarfeldStacked {
//   width: 572px;
//   height: 128px;

//   // For future built-in css modifiers for sizing
//   // &--xs {
//   //   width: (8 * $cbds-tkn-unit__size) / $cbds-logoAspectRatio__clarfeldStacked;
//   //   height: 8 * $cbds-tkn-unit__size;
//   // }

//   // &--sm {
//   //   width: (16 * $cbds-tkn-unit__size) / $cbds-logoAspectRatio__clarfeldStacked;
//   //   height: 16 * $cbds-tkn-unit__size;
//   // }

//   // &--md {
//   //   width: (32 * $cbds-tkn-unit__size) / $cbds-logoAspectRatio__clarfeldStacked;
//   //   height: 32 * $cbds-tkn-unit__size;
//   // }

//   // &--lg {
//   //   width: (48 * $cbds-tkn-unit__size) / $cbds-logoAspectRatio__clarfeldStacked;
//   //   height: 48 * $cbds-tkn-unit__size
//   // }

//   // &--xl {
//   //   width: (64 * $cbds-tkn-unit__size) / $cbds-logoAspectRatio__clarfeldStacked;
//   //   height: 64 * $cbds-tkn-unit__size;
//   // }
// }
`,
  'cbds-logo-button': `// ==========================================================================
// --------------------------------------------------------------------------
// #LOGO
// --------------------------------------------------------------------------
// ==========================================================================

//
// Base setup
//
// 1. Insure the logo flows with content
// 2. Inherit the parent text color
// 3. Remove strokes
// 4. Inherit the parent text color
//


.cbds-c-logoButton {
  $c: &;

  @include cbds-box-sizing;

  z-index: 0;
  position: relative;
  display: inline-block; // [1]
  margin: 0;
  padding: 0;
  line-height: 0;
  text-align: initial;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  text-decoration: none;
  white-space: nowrap;
  border: 0;
  background: none;
  appearance: none;
  cursor: pointer;
  overflow: visible;

  // Normalize: Remove the inner border and padding in Firefox.
  &::-moz-focus-inner,
  &[type="button"]::-moz-focus-inner,
  &[type="reset"]::-moz-focus-inner,
  &[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  // Normalize: Restore the focus styles unset by the previous rule.
  &:-moz-focusring,
  &[type="button"]:-moz-focusring,
  &[type="reset"]:-moz-focusring,
  &[type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  &:focus {
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }
}

.cbds-c-logoButton__logo {
  display: inline-block;
  fill: initial;
}
`,
  'cbds-modal': `// ==========================================================================
// --------------------------------------------------------------------------
// #CBDS Modal
// --------------------------------------------------------------------------
// ==========================================================================


$cbds-modal-header-padding: 24px 48px 24px 32px;
$cbds-modal-body-padding: 32px 32px 32px 32px;
$cbds-modal-body-padding-no-footer: 0 16px 32px 16px;
$cbds-modal-footer-padding: 12px 32px;


.cbds-c-modal {
  @include cbds-box-sizing;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  padding-left: $cbds-tkn-space__2x;
  padding-right: $cbds-tkn-space__2x;
  outline: 0;
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @include media(">=sm") {
    padding-left: $cbds-tkn-space__8x;
    padding-right: $cbds-tkn-space__8x;
  }

  &.cbds-is-open {
    display: block;
  }
}


.cbds-c-modal__dialog {
  position: relative;
  width: auto;
  max-width: 720px;
  margin: $cbds-tkn-space__2x auto;
  z-index: 1000;
  // allow clicks to pass through for custom click handling to close modal
  pointer-events: none;

  @include media(">=sm") {
    margin: $cbds-tkn-space__8x auto;
  }
}


.cbds-c-modal__content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  // counteract the pointer-events: none; in the .cbds-c-modal__dialog
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: $cbds-tkn-borderRadius__lg;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, .2);
  // Remove focus outline from opened modal
  outline: 0;
}


.cbds-c-modal__header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  min-height: 76px;
  padding: $cbds-modal-header-padding;
  border-bottom: $cbds-tkn-borderWidth__xs solid $cbds-tkn-color__neutral--100;
  border-top-left-radius: $cbds-tkn-borderRadius__lg;
  border-top-right-radius: $cbds-tkn-borderRadius__lg;
}


.cbds-c-modal__heading {
  @include cbds-headingText__800;
  color: $cbds-tkn-textColor__primary;
}


.cbds-c-modal__closeBtn {
  position: absolute;
  top: 14px;
  right: 16px;
}


.cbds-c-modal__body {
  position: relative;
  flex: 1 1 auto;
  padding: $cbds-modal-body-padding;
}


.cbds-c-modal__footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: $cbds-modal-footer-padding;
  border-top: $cbds-tkn-borderWidth__xs solid $cbds-tkn-color__neutral--100;
  border-bottom-left-radius: $cbds-tkn-borderRadius__lg;
  border-bottom-right-radius: $cbds-tkn-borderRadius__lg;

  .cbds-c-button {
    margin-top: $cbds-tkn-space__1x;
    margin-bottom: $cbds-tkn-space__1x;
  }

  .cbds-c-button + .cbds-c-button {
    margin-left: $cbds-tkn-space__3x;
  }
}








// ==========================================================================
// Modal Centered
// ==========================================================================


.cbds-c-modal__dialog--centered {
  display: flex;
  align-items: center;
  // Calc top/bot margins applied
  min-height: calc(100% - (#{$cbds-tkn-space__2x} * 2));

  @include media(">=sm") {
    // Calc top/bot margins applied
    min-height: calc(100% - (#{$cbds-tkn-space__8x} * 2));
  }
}






// ==========================================================================
// Modal Sizing
// ==========================================================================

.cbds-c-modal__dialog--xs {
  max-width: 320px;
}

.cbds-c-modal__dialog--sm {
  max-width: 540px;
}

.cbds-c-modal__dialog--md {
  max-width: 720px;
}


@include media(">=md") {
  .cbds-c-modal__dialog--sm\@mi    { max-width: 540px; }
  .cbds-c-modal__dialog--md\@mi    { max-width: 720px; }
}








// ==========================================================================
// Modal Fullscreen
// ==========================================================================


.cbds-c-modal--fullScreen {
  padding: 0;

  .cbds-c-modal__dialog {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .cbds-c-modal__content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .cbds-c-modal__header,
  .cbds-c-modal__footer {
    border-radius: 0;
  }

  .cbds-c-modal__body {
    overflow-y: auto;
  }
}








// ==========================================================================
// Modal Typography
// ==========================================================================

.cbds-c-modal__body--typography {
  @include cbds-bodyText__500;
  p {
    margin-top: 16px;

    &:first-child {
      margin-top: 0;
    }
  }
}
`,
  'cbds-layout-grid': `// ==========================================================================
// --------------------------------------------------------------------------
// #LAYOUT-GRID
// --------------------------------------------------------------------------
// ==========================================================================


//
// The CBDS Grid system is a customized port of
// Bootstrap Grid v4.4.1 (https://getbootstrap.com/)
//


// TOOLS
// --------------------------------------------------------------------------
//
// Grid-framework.......Mixins for grid system columns.
// Grid.................Mixins for grid system.

@import 'cbds-layout-grid__tools.framework';
@import 'cbds-layout-grid__tools.grid';




// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.
//
// CBDS Modifications
// 1. Add [cbds] prefix and [l] namespace
// We also break down media queries based on mobile, tablet, and desktop groups.

@if $cbds-enable-grid-classes {
  // .container-fixed {
  .cbds-l-grid--fixed {  // [1]
    @include cbds-make-container();
    @each $breakpoint, $containerPadding in $cbds-grid-containerPadding-widths {
      @include cbds-media-breakpoint-up($breakpoint) {
        @include cbds-make-container($containerPadding, map-get($cbds-grid-gutter-widths, $breakpoint));
      }
    }
  }
}



// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but with 100% width for
// fluid, full width layouts.
// CBDS Modifications
// 1. Add [cbds] prefix and [l] namespace

@if $cbds-enable-grid-classes {
  // .container {
  .cbds-l-grid {  // [1]
    @include cbds-make-container();
    @each $breakpoint, $containerPadding in $cbds-grid-containerPadding-widths {
      @include cbds-media-breakpoint-up($breakpoint) {
        @include cbds-make-container($containerPadding, map-get($cbds-grid-gutter-widths, $breakpoint));
      }
    }
  }
}


// Responsive Container Classes
//
// Responsive containers that are 100% wide until a breakpoint

@if $cbds-enable-grid-classes {

  @each $breakpoint, $cbds-container-max-width in $cbds-container-max-widths {
    .cbds-l-grid--\@#{$breakpoint} {
      @include cbds-make-container($cbds-grid-containerPadding-width, $cbds-grid-gutter-width);
    }

    @include cbds-media-breakpoint-up($breakpoint, $cbds-grid-breakpoints) {
      %responsive-container-#{$breakpoint} {
        max-width: $cbds-container-max-width;
      }

      $infix: cbds-breakpoint-infix($breakpoint, $cbds-grid-breakpoints);

      .cbds-l-grid--fixed, .cbds-l-grid--\@#{$infix} {
        @extend %responsive-container-#{$breakpoint};
      }
    }
  }

  // Make containers for tablet breakpoints by removing 2xs, xs and sm
  @include cbds-media-breakpoint-up(md) {
    @each $breakpoint, $cbds-container-max-width in map-remove($cbds-container-max-widths, "2xs", "xs", "sm") {
      .cbds-l-grid--\@#{$breakpoint} {
        @include cbds-make-container($cbds-tkn-grid__containerPadding--tablet, $cbds-tkn-grid__gutter--tablet);
      }
    }
  }

  // Make containers for desktop breakpoints by removing 2xs, xs, sm, md, and lg
  @include cbds-media-breakpoint-up(xl) {
    @each $breakpoint, $cbds-container-max-width in map-remove($cbds-container-max-widths, "2xs", "xs", "sm", "md", "lg") {
      .cbds-l-grid--\@#{$breakpoint} {
        @include cbds-make-container($cbds-tkn-grid__containerPadding--desktop, $cbds-tkn-grid__gutter--desktop);
      }
    }
  }

}


// Row
//
// Rows contain and clear the floats of your columns.
// CBDS Modifications
// 1. Add [cbds] prefix and [l] namespace

@if $cbds-enable-grid-classes {
  // .row {
  .cbds-l-grid__row {  // [1]
    @include cbds-make-row();
    @each $breakpoint, $gutter in $cbds-grid-gutter-widths {
      @include cbds-media-breakpoint-up($breakpoint) {
        @include cbds-make-row($gutter);
      }
    }
  }

  .cbds-l-grid__row > .cbds-l-grid__col,
  .cbds-l-grid__row > [class*='cbds-l-grid__col--'] {
    padding-left: calc($cbds-grid-gutter-width / 2);
    padding-right: calc($cbds-grid-gutter-width / 2);
    @each $breakpoint, $gutter in $cbds-grid-gutter-widths {
      @include cbds-media-breakpoint-up($breakpoint) {
        padding-left: calc($gutter / 2);
        padding-right: calc($gutter / 2);
      }
    }
  }

  // Create rows for each breakpoint container
  @each $breakpoint, $cbds-container-max-width in $cbds-container-max-widths {
    .cbds-l-grid--\@#{$breakpoint} {
      .cbds-l-grid__row {
        @include cbds-make-row($cbds-grid-gutter-width)
      }
      .cbds-l-grid__row > .cbds-l-grid__col,
      .cbds-l-grid__row > [class*='cbds-l-grid__col--'] {
        padding-left: calc($cbds-grid-gutter-width / 2);
        padding-right: calc($cbds-grid-gutter-width / 2);
      }
    }
  }

  // create container--@bp rows for tablet and desktop for breakpoint-up(md)
  @include cbds-media-breakpoint-up(md) {
    @each $breakpoint, $cbds-container-max-width in map-remove($cbds-container-max-widths, "2xs","xs","sm") {
      .cbds-l-grid--\@#{$breakpoint} {
        .cbds-l-grid__row {
          @include cbds-make-row($cbds-tkn-grid__gutter--tablet)
        }
        .cbds-l-grid__row > .cbds-l-grid__col,
        .cbds-l-grid__row > [class*='cbds-l-grid__col--'] {
          padding-left: calc($cbds-tkn-grid__gutter--tablet / 2);
          padding-right: calc($cbds-tkn-grid__gutter--tablet/ 2);
          }
      }
    }
  }

  // create container--@bp rows for desktop for breakpoint-up(xl)
  @include cbds-media-breakpoint-up(xl) {
    @each $breakpoint, $cbds-container-max-width in map-remove($cbds-container-max-widths, "2xs","xs","sm","md","lg") {
      .cbds-l-grid--\@#{$breakpoint} {
        .cbds-l-grid__row {
          @include cbds-make-row($cbds-tkn-grid__gutter--desktop)
        }
        .cbds-l-grid__row > .cbds-l-grid__col,
        .cbds-l-grid__row > [class*='cbds-l-grid__col--'] {
          padding-left: calc($cbds-tkn-grid__gutter--desktop / 2);
          padding-right: calc($cbds-tkn-grid__gutter--desktop/ 2);
        }
      }
    }
  }


  // Remove the negative margin from default .row, then the horizontal padding
  // from all immediate children columns (to prevent runaway style inheritance).
  //
  // CBDS Modifications
  // 1. Add [cbds] prefix and [l] namespace

  // .no-gutters {
  .cbds-l-grid__row--noGutters {  // [1]
    margin-right: 0;
    margin-left: 0;

    // > .col,
    // > [class*="col-"] {
    > .cbds-l-grid__col, // [1]
    > [class*="cbds-l-grid__col--"] { // [1]
      padding-right: 0;
      padding-left: 0;
    }
  }
}


// Columns
//
// Common styles for small and large grid columns

@if $cbds-enable-grid-classes {
  @include cbds-make-grid-columns();
}
`,
  'cbds-pagination': `// ==========================================================================
// --------------------------------------------------------------------------
// #CBDS Pagination
// --------------------------------------------------------------------------
// ==========================================================================

@import 'cbds-pagination__tools';
@import 'cbds-pagination__tokens';








// ==========================================================================
// Base Layout & Styles
// ==========================================================================


.cbds-c-pagination {
  @include cbds-box-sizing;
  display: block;
  margin: 0;
  padding: 0;
  line-height: 0;
}

.cbds-c-pagination__list {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}


.cbds-c-pagination__item {
  margin: 0;
  padding: 0;
  list-style: none;

  &:not(:first-child) .cbds-c-pagination__link {
    margin-left: $cbds-pagination-margin-start;
  }

  &.cbds-is-selected .cbds-c-pagination__link {
    z-index: 3;
    color: $cbds-pagination-selected-color;
    background-color: $cbds-pagination-selected-bg;
    border-color: $cbds-pagination-selected-border-color;
  }

  &.cbds-is-disabled .cbds-c-pagination__link {
    color: $cbds-pagination-disabled-color;
    pointer-events: none;
    background-color: $cbds-pagination-disabled-bg;
    border-color: $cbds-pagination-disabled-border-color;
  }
}


.cbds-c-pagination__link {
  position: relative;
  display: block;
  @include cbds-actionText__500;
  text-decoration: none;
  color: $cbds-pagination-color;
  border: $cbds-pagination-border-width solid $cbds-pagination-border-color;
  background-color: $cbds-pagination-bg;
  appearance: none;
  cursor: pointer;
  @include cbds-transition($cbds-pagination-transition);

  &:focus {
    @include cbds-focus-outline;
    z-index: 3;
    text-decoration: underline;
    color: $cbds-pagination-focus-color;
    background-color: $cbds-pagination-focus-bg;
    // outline: $cbds-pagination-focus-outline;
    // box-shadow: $cbds-pagination-focus-box-shadow;
  }

  &:hover {
    z-index: 2;
    text-decoration: underline;
    color: $cbds-pagination-hover-color;
    background-color: $cbds-pagination-hover-bg;
    // border-color: $cbds-pagination-hover-border-color;
  }

  &:active {
    z-index: 2;
    text-decoration: none;
    color: $cbds-pagination-active-color;
    background-color: $cbds-pagination-active-bg;
    // border-color: $cbds-pagination-hover-border-color;
  }
}








// ==========================================================================
// Modifiers
// ==========================================================================


//
// Sizing
// ==========================================================================

@include cbds-pagination-size($cbds-pagination-padding-y, $cbds-pagination-padding-x, $cbds-tkn-typeScale__500, $cbds-pagination-border-radius);

// .pagination-lg {
//   @include cbds-pagination-size($cbds-pagination-padding-y-lg, $cbds-pagination-padding-x-lg, $font-size-lg, $cbds-pagination-border-radius-lg);
// }

// .pagination-sm {
//   @include cbds-pagination-size($cbds-pagination-padding-y-sm, $cbds-pagination-padding-x-sm, $font-size-sm, $cbds-pagination-border-radius-sm);
// }
`,
  'cbds-number-input': `// ==========================================================================
// --------------------------------------------------------------------------
// #CBDS Number Input
// --------------------------------------------------------------------------
// ==========================================================================


.cbds-c-numberInput {
  $c: &;

  @include cbds-box-sizing;

  display: block;
  width: 100%;
  height: 48px;
  margin: 0;
  padding: 8px 12px;
  @include cbds-elementText__500;
  color: $cbds-tkn-textColor__secondary;
  background-color: $cbds-tkn-color__white;
  // Adding because Autoprefixer doesn't support border-radius
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: solid $cbds-tkn-borderWidth__xs $cbds-tkn-color__neutral--500;
  transition: border 0.1s linear, background-color 0.1s linear;
  appearance: none;

  &:focus,
  &.cbds-is-focus {
    @include cbds-focus-outline;
    border-color: $cbds-tkn-color__neutral--800;
  }

  &:hover,
  &.cbds-is-hover {
    border-color: $cbds-tkn-color__neutral--800;
  }

  &:active,
  &.cbds-is-active {
    border-color: $cbds-tkn-color__neutral--1100;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-textColor__quat;
    background-color: $cbds-tkn-color__neutral--60;
    border-color: $cbds-tkn-color__neutral--200;
    cursor: not-allowed;
  }

  &::placeholder {
    color: $cbds-tkn-textColor__quat;
    opacity: 1;
  }

  // &[readonly],
  // &.cbds-is-readonly {
  // }

  // &.cbds-is-invalid,
  // &.cbds-has-error {
  //   border-color: $cbds-tkn-color__red--700;
  // }
}
`,
  'cbds-pagination__tools': `// ==========================================================================
// Bootstrap Mixins
// ==========================================================================

//
// Mixins sourced from Bootstrap v5.0.0-beta2
// Includes MVP changes to original BS code to align w/ CBDS
//
// TODO: Re-factor mixin naming conventions to align with CBDS methodology
//


@mixin cbds-pagination-size($padding-y, $padding-x, $font-size, $border-radius) {
  .cbds-c-pagination__link {
    padding: $padding-y $padding-x;
    font-size: $font-size;
  }

  .cbds-c-pagination__item {
    @if $cbds-pagination-margin-start == (-$cbds-pagination-border-width) {
      &:first-child {
        .cbds-c-pagination__link {
          @include cbds-border-start-radius($border-radius);
        }
      }

      &:last-child {
        .cbds-c-pagination__link {
          @include cbds-border-end-radius($border-radius);
        }
      }
    } @else {
      //Add border-radius to all pageLinks in case they have left margin
      .cbds-c-pagination__link {
        @include cbds-border-radius($border-radius);
      }
    }
  }
}
`,
  'cbds-pagination__tokens': `// ==========================================================================
// Bootstrap Variables
// ==========================================================================

//
// Variables & Styles sourced from Bootstrap v5.0.0-beta2
// Includes MVP changes to original BS code to align w/ CBDS
//
// TODO: Re-factor variable naming conventions to align with CBDS methodology
//


$cbds-pagination-padding-y:               6px;
$cbds-pagination-padding-x:               12px;
// $cbds-pagination-padding-y-sm:           .25rem;
// $cbds-pagination-padding-x-sm:           .5rem;
// $cbds-pagination-padding-y-lg:           .75rem;
// $cbds-pagination-padding-x-lg:           1.5rem;

$cbds-pagination-color:                   $cbds-tkn-color__neutral--900;
$cbds-pagination-bg:                      $cbds-tkn-color__white;
$cbds-pagination-border-width:            $cbds-tkn-borderWidth__xs;
$cbds-pagination-border-radius:           $cbds-tkn-borderRadius__md;
$cbds-pagination-margin-start:            -$cbds-pagination-border-width;
$cbds-pagination-border-color:            $cbds-tkn-color__neutral--100;

$cbds-pagination-focus-color:             $cbds-tkn-color__neutral--1000;
$cbds-pagination-focus-bg:                $cbds-tkn-color__neutral--40;
$cbds-pagination-focus-box-shadow:        none;
$cbds-pagination-focus-outline:           0;

$cbds-pagination-hover-color:             $cbds-tkn-color__neutral--1000;
$cbds-pagination-hover-bg:                $cbds-tkn-color__neutral--40;
$cbds-pagination-hover-border-color:      $cbds-tkn-color__neutral--100;

$cbds-pagination-active-color:            $cbds-tkn-color__neutral--1100;
$cbds-pagination-active-bg:               $cbds-tkn-color__neutral--80;
$cbds-pagination-active-border-color:     $cbds-tkn-color__neutral--100;

$cbds-pagination-selected-color:          $cbds-tkn-color__white;
$cbds-pagination-selected-bg:             $cbds-tkn-color__green--600;
$cbds-pagination-selected-border-color:   $cbds-tkn-color__green--600;

$cbds-pagination-disabled-color:          $cbds-tkn-color__neutral--600;
$cbds-pagination-disabled-bg:             $cbds-tkn-color__white;
$cbds-pagination-disabled-border-color:   $cbds-tkn-color__neutral--100;

$cbds-pagination-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$cbds-pagination-border-radius-sm:        $cbds-tkn-borderRadius__sm;
$cbds-pagination-border-radius-lg:        $cbds-tkn-borderRadius__lg;
`,
  'cbds-overlay': `// ==========================================================================
// --------------------------------------------------------------------------
// #CBDS Overlay
// --------------------------------------------------------------------------
// ==========================================================================


.cbds-c-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;

  z-index: 900;

  &.cbds-is-open {
    display: block;
  }

  &--light {
    background-color: rgba($color: $cbds-tkn-color__white, $alpha: .8);
  }

  &--dark {
    background-color: rgba($color: $cbds-tkn-color__black, $alpha: .8);
  }
}
`,
  '_recaptcha': `// ==========================================================================
// --------------------------------------------------------------------------
// #RECAPTCHA
// --------------------------------------------------------------------------
// ==========================================================================


.cbds-c-recaptcha,
.cbds-c-recaptcha > div {
  position: relative;
}
`,
  'cbds-requiredfield-key': `// ==========================================================================
// --------------------------------------------------------------------------
// #CBDS Required Field Key
// --------------------------------------------------------------------------
// ==========================================================================


.cbds-c-requiredFieldKey {
  @include cbds-box-sizing;
  display: block;
  @include cbds-bodyText__500;
  font-style: $cbds-tkn-fontStyle__italic;
  color: $cbds-tkn-textColor__secondary;
}
`,
  'cbds-radio-button': `// ==========================================================================
// --------------------------------------------------------------------------
// #CBDS Radio Button
// --------------------------------------------------------------------------
// ==========================================================================


.cbds-c-radioButton {
  $c: 'cbds-c-radioButton';

  @include cbds-box-sizing;

  display: flex;
  // align-items: center;
  align-items: flex-start;
  border: 0;
  cursor: pointer;

  [type="radio"] {
    position: absolute;
    left: 0;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    visibility: visible;
    white-space: nowrap;
    pointer-events: auto;
    appearance: none;
  }

  // Focus
  [type="radio"]:focus + .#{$c}__control,
  [type="radio"]:focus ~ .#{$c}__control,
  &.cbds-is-focus [type="radio"] + .#{$c}__control,
  &.cbds-is-focus [type="radio"] ~ .#{$c}__control {
    .#{$c}__faux {
      @include cbds-focus-outline;
      border-color: $cbds-tkn-color__neutral--800;
      background-color: $cbds-tkn-color__white;
    }
  }

  // Hover
  [type="radio"]:not([disabled]):hover + .#{$c}__control,
  [type="radio"]:not([disabled]):hover ~ .#{$c}__control,
  &.cbds-is-hover [type="radio"]:not([disabled]) + .#{$c}__control,
  &.cbds-is-hover [type="radio"]:not([disabled]) ~ .#{$c}__control {
    .#{$c}__faux {
      border-color: $cbds-tkn-color__neutral--800;
      background-color: $cbds-tkn-color__white;
      box-shadow: 0 0 0 4px rgba($cbds-tkn-color__neutral--900, .24);
    }
  }

  // Active
  [type="radio"]:active + .#{$c}__control,
  [type="radio"]:active ~ .#{$c}__control,
  &.cbds-is-active [type="radio"] + .#{$c}__control,
  &.cbds-is-active [type="radio"] ~ .#{$c}__control {
    .#{$c}__faux {
      border-color: $cbds-tkn-color__neutral--1100;
      background-color: $cbds-tkn-color__white;
    }
  }

  // Disabled
  &.cbds-is-disabled {
    cursor: not-allowed;
  }

  [type="radio"][disabled] + .#{$c}__control,
  [type="radio"][disabled] ~ .#{$c}__control,
  &.cbds-is-disabled [type="radio"] + .#{$c}__control,
  &.cbds-is-disabled [type="radio"] ~ .#{$c}__control {
    cursor: not-allowed;

    .#{$c}__faux {
      border-color: $cbds-tkn-color__neutral--200;
      background-color: $cbds-tkn-color__white;
      cursor: not-allowed;
    }

    .#{$c}__label {
      color: $cbds-tkn-textColor__quat;
      cursor: not-allowed;
    }
  }

  // Error
  &.cbds-is-invalid [type="radio"] + .#{$c}__control,
  &.cbds-is-invalid [type="radio"] ~ .#{$c}__control {
    .#{$c}__faux {
      border-color: $cbds-tkn-color__red--700;
      background-color: $cbds-tkn-color__white;
    }
  }

  // Error Hover
  &.cbds-is-invalid [type="radio"]:not([disabled]):hover + .#{$c}__control,
  &.cbds-is-invalid [type="radio"]:not([disabled]):hover ~ .#{$c}__control,
  &.cbds-is-invalid-hover [type="radio"]:not([disabled]) + .#{$c}__control,
  &.cbds-is-invalid-hover [type="radio"]:not([disabled]) ~ .#{$c}__control  {
    .#{$c}__faux {
      border-color: $cbds-tkn-color__red--900;
      background-color: $cbds-tkn-color__white;
      box-shadow: 0 0 0 4px rgba($cbds-tkn-color__red--700, .24);
    }
  }

  // Error Focus
  &.cbds-is-invalid [type="radio"]:focus + .#{$c}__control,
  &.cbds-is-invalid [type="radio"]:focus ~ .#{$c}__control,
  &.cbds-is-invalid-focus [type="radio"] + .#{$c}__control,
  &.cbds-is-invalid-focus [type="radio"] ~ .#{$c}__control {
    .#{$c}__faux {
      @include cbds-focus-outline;
      border-color: $cbds-tkn-color__red--900;
      background-color: $cbds-tkn-color__white;
    }
  }

  // Error Active
  &.cbds-is-invalid [type="radio"]:active + .#{$c}__control,
  &.cbds-is-invalid [type="radio"]:active ~ .#{$c}__control,
  &.cbds-is-invalid-active [type="radio"] + .#{$c}__control,
  &.cbds-is-invalid-active [type="radio"] ~ .#{$c}__control {
    .#{$c}__faux {
      border-color: $cbds-tkn-color__red--1100;
      background-color: $cbds-tkn-color__white;
    }
  }

  // Checked
  [type="radio"]:checked + .#{$c}__control,
  [type="radio"]:checked ~ .#{$c}__control,
  &.cbds-is-checked [type="radio"] + .#{$c}__control,
  &.cbds-is-checked [type="radio"] ~ .#{$c}__control {
    .#{$c}__faux {
      border-color: $cbds-tkn-color__green--600;
      background-color: $cbds-tkn-color__white;
    }
    .#{$c}__faux:after {
      width: 16px;
      height: 16px;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      border-radius: 50%;
      background: $cbds-tkn-color__green--600;
    }
  }

  // Checked Focus
  [type="radio"]:checked:focus + .#{$c}__control,
  [type="radio"]:checked:focus ~ .#{$c}__control,
  &.cbds-is-checked-focus [type="radio"]:checked + .#{$c}__control,
  &.cbds-is-checked-focus [type="radio"]:checked ~ .#{$c}__control {
    .#{$c}__faux {
      @include cbds-focus-outline;
      border-color: $cbds-tkn-color__green--800;
      background-color: $cbds-tkn-color__white;
    }
    .#{$c}__faux:after {
      background: $cbds-tkn-color__green--800;
    }
  }

  // Checked Hover
  [type="radio"]:not([disabled]):checked:hover + .#{$c}__control,
  [type="radio"]:not([disabled]):checked:hover ~ .#{$c}__control,
  &.cbds-is-checked-hover [type="radio"]:not([disabled]):checked + .#{$c}__control,
  &.cbds-is-checked-hover [type="radio"]:not([disabled]):checked ~ .#{$c}__control {
    .#{$c}__faux {
      border-color: $cbds-tkn-color__green--800;
      background-color: $cbds-tkn-color__white;
      box-shadow: 0 0 0 4px rgba($cbds-tkn-color__green--600, .24);
    }
    .#{$c}__faux:after {
      background: $cbds-tkn-color__green--800;
    }
  }

  // Checked Active
  [type="radio"]:checked:active + .#{$c}__control,
  [type="radio"]:checked:active ~ .#{$c}__control,
  &.cbds-is-checked-active [type="radio"]:checked + .#{$c}__control,
  &.cbds-is-checked-active [type="radio"]:checked ~ .#{$c}__control {
    .#{$c}__faux {
      border-color: $cbds-tkn-color__green--1000;
      background-color: $cbds-tkn-color__white;
    }
    .#{$c}__faux:after {
      background: $cbds-tkn-color__green--1000;
    }
  }

  // Checked Disabled
  &.cbds-is-checked-disabled {
    cursor: not-allowed;
  }

  [type="radio"]:checked[disabled] + .#{$c}__control,
  [type="radio"]:checked[disabled] ~ .#{$c}__control,
  &.cbds-is-checked-disabled [type="radio"] + .#{$c}__control,
  &.cbds-is-checked-disabled [type="radio"] ~ .#{$c}__control {
    cursor: not-allowed;

    .#{$c}__faux {
      border-color: $cbds-tkn-color__neutral--200;
      background-color: $cbds-tkn-color__white;
      cursor: not-allowed;
    }
    .#{$c}__faux:after {
      background: $cbds-tkn-color__neutral--600;
    }
  }

  // Checked Error
  &.cbds-is-invalid [type="radio"]:checked + .#{$c}__control,
  &.cbds-is-invalid [type="radio"]:checked ~ .#{$c}__control,
  &.cbds-is-checked-invalid [type="radio"]:checked + .#{$c}__control,
  &.cbds-is-checked-invalid [type="radio"]:checked ~ .#{$c}__control {
    .#{$c}__faux {
      border-color: $cbds-tkn-color__red--700;
      background-color: $cbds-tkn-color__white;
    }
    .#{$c}__faux:after {
      background: $cbds-tkn-color__red--700;
    }
  }

  // Checked Error Hover
  &.cbds-is-invalid [type="radio"]:not([disabled]):checked:hover + .#{$c}__control,
  &.cbds-is-invalid [type="radio"]:not([disabled]):checked:hover ~ .#{$c}__control,
  &.cbds-is-checked-invalid-hover [type="radio"]:not([disabled]):checked + .#{$c}__control,
  &.cbds-is-checked-invalid-hover [type="radio"]:not([disabled]):checked ~ .#{$c}__control {
    .#{$c}__faux {
      border-color: $cbds-tkn-color__red--900;
      background-color: $cbds-tkn-color__white;
      box-shadow: 0 0 0 4px rgba($cbds-tkn-color__red--700, .24);
    }
    .#{$c}__faux:after {
      background: $cbds-tkn-color__red--900;
    }
  }

  // Checked Error Focus
  &.cbds-is-invalid [type="radio"]:checked:focus + .#{$c}__control,
  &.cbds-is-invalid [type="radio"]:checked:focus ~ .#{$c}__control,
  &.cbds-is-checked-invalid-focus [type="radio"]:checked + .#{$c}__control,
  &.cbds-is-checked-invalid-focus [type="radio"]:checked ~ .#{$c}__control {
    .#{$c}__faux {
      @include cbds-focus-outline;
      border-color: $cbds-tkn-color__red--900;
      background-color: $cbds-tkn-color__white;
    }
    .#{$c}__faux:after {
      background: $cbds-tkn-color__red--900;
    }
  }

  // Checked Active
  &.cbds-is-invalid [type="radio"]:checked:active + .#{$c}__control,
  &.cbds-is-invalid [type="radio"]:checked:active ~ .#{$c}__control,
  &.cbds-is-checked-invalid-active [type="radio"]:checked + .#{$c}__control,
  &.cbds-is-checked-invalid-active [type="radio"]:checked ~ .#{$c}__control {
    .#{$c}__faux {
      border-color: $cbds-tkn-color__red--1100;
      background-color: $cbds-tkn-color__white;
    }
    .#{$c}__faux:after {
      background: $cbds-tkn-color__red--1100;
    }
  }
}


.cbds-c-radioButton__control {
  display: inline-flex;
  align-items: center;
  position: relative;
  border: 0;
  cursor: pointer;

  &:before {
    position: absolute;
    content: '';
    top: -6px;
    bottom: -6px;
  }
}


.cbds-c-radioButton__faux {
  align-items: center;
  flex: 0 0 auto;
  justify-content: center;
  width: 24px;
  height: 24px;
  display: block;
  position: relative;
  margin: 0 16px 0 0;
  border: $cbds-tkn-borderWidth__sm solid $cbds-tkn-color__neutral--500;
  border-radius: 50%;
  background-color: $cbds-tkn-color__white;
}


.cbds-c-radioButton__label {
  margin-top: 2px;
  @include cbds-elementText__500;
  color: $cbds-tkn-textColor__secondary;
  cursor: pointer;
}
`,
  'cbds-radio-button-group': `// ==========================================================================
// --------------------------------------------------------------------------
// #CBDS Radio Button Group
// --------------------------------------------------------------------------
// ==========================================================================


$cbds-radioButtonGroup-item__stack: 16px;
$cbds-radioButtonGroup-item__mr: 24px;

.cbds-c-radioButtonGroup {
  // margin-top: -$cbds-radioButtonGroup-item__stack;

  .cbds-c-radioButton {
    margin: $cbds-checkboxGroup-item__stack 0 0 0;

    &:first-child {
      margin-top: 0;
    }
  }

  &--horizontal {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: -$cbds-radioButtonGroup-item__stack;
    font-size: 0; // Remove space in-between inline elements

    .cbds-c-radioButton {
      display: inline-flex;
      margin-right: $cbds-radioButtonGroup-item__mr;

      &:first-child {
        margin-top: $cbds-radioButtonGroup-item__stack;
      }
    }
  }
}
`,
  'cbds-select': `// ==========================================================================
// --------------------------------------------------------------------------
// #SELECT
// --------------------------------------------------------------------------
// ==========================================================================


.cbds-c-select {
  @include cbds-box-sizing;

  position: relative;
  height: 48px;
  margin: 0;
}


.cbds-c-select__select {
  $c: &;

  @include cbds-box-sizing;

  position: relative;
  display: block;
  width: 100%;
  height: 48px;
  margin: 0;
  padding: 8px 48px 8px 12px;
  @include cbds-elementText__500;
  color: $cbds-tkn-textColor__secondary;
  background: $cbds-tkn-color__white;
  // Adding because Autoprefixer doesn't support border-radius
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: solid $cbds-tkn-borderWidth__xs $cbds-tkn-color__neutral--500;
  transition: border 0.1s linear, background-color 0.1s linear;
  appearance: none;
  cursor: pointer;
  overflow: hidden;
  z-index: 0;

  &:focus,
  &.cbds-is-focus {
    @include cbds-focus-outline;
    border-color: $cbds-tkn-color__neutral--800;
  }

  &:hover,
  &.cbds-is-hover {
    border-color: $cbds-tkn-color__neutral--800;
  }

  &:active,
  &.cbds-is-active {
    border-color: $cbds-tkn-color__neutral--1100;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-textColor__quat;
    background-color: $cbds-tkn-color__neutral--60;
    border-color: $cbds-tkn-color__neutral--200;
    cursor: not-allowed;

    .cbds-c-select__icon {
      fill: $cbds-tkn-textColor__quat;
    }
  }

  // Hide icon in IE11 and below
  &::-ms-expand {
    display: none;
  }
}


.cbds-c-select__indicator {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  content: '';
  z-index: 4;
  pointer-events: none;
}


.cbds-c-select__indicatorIcon {
  width: 24px;
  height: 24px;
  fill: $cbds-tkn-textColor__secondary;
  pointer-events: none;
}
`,
  'cbds-spinner': `// ==========================================================================
// --------------------------------------------------------------------------
// #SPINNER
// --------------------------------------------------------------------------
// ==========================================================================



.cbds-c-spinner {
  @include cbds-box-sizing;
  display: block;
  position: relative;
  width: $cbds-tkn-spinner__size--md;
  height: $cbds-tkn-spinner__size--md;
  border: $cbds-tkn-spinner__borderWidth solid $cbds-tkn-spinner__color--primary;
  border-right-color: transparent !important;
  border-radius: $cbds-tkn-borderRadius__circle;
  animation: cbds-c-spinner__rotate $cbds-tkn-spinner__duration linear infinite;

  &::after,
  &::before {
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    background: $cbds-tkn-spinner__color--primary;
    border-radius: $cbds-tkn-borderRadius__circle;
  }

  &::after {
    top: -4%;
    left: 81%;
  }

  &::before {
    top: 83%;
    left: 82%;
  }
}






// ==========================================================================
// Spinner Sizing
// ==========================================================================

.cbds-c-spinner {
  &--xs {
    width: $cbds-tkn-spinner__size--xs;
    height: $cbds-tkn-spinner__size--xs;

    &::after {
      top: -26%;
      left: 80%;
    }

    &::before {
      top: 81%;
      left: 76%;
    }
  }

  &--sm {
    width: $cbds-tkn-spinner__size--sm;
    height: $cbds-tkn-spinner__size--sm;

    &::after {
      top: -13%;
      left: 80%;
    }

    &::before {
      top: 82%;
      left: 79%;
    }
  }
}






// ==========================================================================
// Spinner Color(s)
// ==========================================================================

//
// @TODO:
// Update Spinner component color tokens
//

.cbds-c-spinner {

  &,
  &--primary {
    border-color: $cbds-tkn-spinner__color--primary;

    &::after,
    &::before {
      background: $cbds-tkn-spinner__color--primary;
    }
  }

  &--light {
    border-color: $cbds-tkn-spinner__color--light;

    &::after,
    &::before {
      background: $cbds-tkn-spinner__color--light;
    }
  }

  &--dark {
    border-color: $cbds-tkn-spinner__color--dark;

    &::after,
    &::before {
      background: $cbds-tkn-spinner__color--dark;
    }
  }

  &--wire {
    border-color: $cbds-tkn-color__neutral--900;

    &::after,
    &::before {
      background: $cbds-tkn-color__neutral--900;
    }
  }
}






// ==========================================================================
// Spinner Animation
// ==========================================================================

@keyframes cbds-c-spinner__rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
`,
  'cbds-table__tokens': `// ==========================================================================
// Bootstrap Variables
// ==========================================================================

//
// Variables & Styles sourced from Bootstrap v5.0.0-beta2
// Includes MVP changes to original BS code to align w/ CBDS
//
// TODO: Re-factor variable naming conventions to align with CBDS methodology
//


// Tables
//
// Customizes the \`.table\` component with basic values, each used across all table variations.
$variable-prefix: 'cbds';

$cbds-table-cell-padding-y:        $cbds-tkn-space__4x;
$cbds-table-cell-padding-x:        $cbds-tkn-space__4x;
$cbds-table-cell-padding-y-sm:     $cbds-tkn-space__2x;
$cbds-table-cell-padding-x-sm:     $cbds-tkn-space__2x;

$cbds-table-cell-vertical-align:   top;

$cbds-table-color:                 $cbds-tkn-textColor__primary;
$cbds-table-bg:                    transparent;

$cbds-table-th-font-weight:        null;

$cbds-table-striped-color:         $cbds-table-color;
// $cbds-table-striped-bg-factor:     .05;
$cbds-table-striped-bg:            $cbds-tkn-color__neutral--40;

$cbds-table-active-color:          $cbds-table-color;
// $cbds-table-active-bg-factor:      .1;
$cbds-table-active-bg:             $cbds-tkn-color__green--60;

$cbds-table-hover-color:           $cbds-table-color;
// $cbds-table-hover-bg-factor:       .075;
$cbds-table-hover-bg:              $cbds-tkn-color__neutral--80;

// $cbds-table-border-factor:         .1;
$cbds-table-border-width:          $cbds-tkn-borderWidth__xs;
$cbds-table-border-color:          $cbds-tkn-color__neutral--100;

$cbds-table-striped-order:         odd;

$cbds-table-group-separator-color: currentColor;

$cbds-table-caption-color:         $cbds-tkn-textColor__quat;

$cbds-table-bg-scale:              -80%;
`,
  'cbds-table': `// ==========================================================================
// --------------------------------------------------------------------------
// #CBDS Image
// --------------------------------------------------------------------------
// ==========================================================================

@import 'cbds-table__tools';
@import 'cbds-table__tokens';








// ==========================================================================
// Base Layout & Styles
// ==========================================================================


.cbds-c-table {
  @include cbds-box-sizing;

  width: 100%;
  margin: 0;
  padding: 0;
  @include cbds-elementText__500;
  color: $cbds-table-color;
  vertical-align: $cbds-table-cell-vertical-align;
  border-color: $cbds-table-border-color;

  // Since we don't have a reset
  caption-side: bottom;
  border-collapse: collapse;

  tbody, td, tfoot, th, thead, tr {
    // Inherit to ensure formatting resiliency from consuming application
    font-family: inherit;
    font-style: inherit;
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    color: inherit;
    vertical-align: inherit;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
  }

  // Target th & td
  // We need the child combinator to prevent styles leaking to nested tables which doesn't have a \`.table\` class.
  // We use the universal selectors here to simplify the selector (else we would need 6 different selectors).
  // Another advantage is that this generates less code and makes the selector less specific making it easier to override.
  // stylelint-disable-next-line selector-max-universal
  > :not(caption) > * > * {
    padding: $cbds-table-cell-padding-y $cbds-table-cell-padding-x;
    background-color: $cbds-table-bg;
    border-bottom-width: $cbds-table-border-width;
  }

  > tbody {
    vertical-align: inherit;
  }

  > thead {
    vertical-align: bottom;

    th {
      font-weight: $cbds-tkn-fontWeight__semiBold;
    }
  }

  > caption {
    padding: $cbds-tkn-space__3x 0 0 0;
    @include cbds-elementText__400;
    color: $cbds-table-caption-color;
    text-align: left;
  }

  // Highlight border color between thead, tbody and tfoot.
  > :not(:last-child) > :last-child > * {
    border-bottom-color: $cbds-table-group-separator-color;
  }
}








// ==========================================================================
// Modifiers
// ==========================================================================


//
// Size
// ==========================================================================

//
// Condensed table w/ half padding
//

.cbds-c-table--sm {
  > :not(caption) > * > * {
    padding: $cbds-table-cell-padding-y-sm $cbds-table-cell-padding-x-sm;
  }
}




//
// Row Styles
// ==========================================================================

// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.cbds-c-table--striped {
  > tbody > tr:nth-of-type(#{$cbds-table-striped-order}) {
    color: $cbds-table-striped-color;
    background-color: $cbds-table-striped-bg;
  }
}

// Active table
//
// The \`.cbds-is-active\` class can be added to highlight rows or cells

.cbds-c-table th.cbds-is-active
.cbds-c-table tr.cbds-is-active,
.cbds-c-table td.cbds-is-active {
  color: $cbds-table-active-color;
  background-color: $cbds-table-active-bg;
}

// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.cbds-c-table--hover {
  > tbody > tr:hover {
    color: $cbds-table-hover-color;
    background-color: $cbds-table-hover-bg;
  }
}




//
// THEAD Styles
// ==========================================================================

.cbds-c-table thead.cbds-c-table__thead {
  &--light {
    color: $cbds-table-color;
    background-color: $cbds-tkn-color__neutral--40;
  }

  &--dark {
    color: $cbds-tkn-color__white;
    background-color: $cbds-tkn-color__neutral--900;
  }
}




//
// Borders
// ==========================================================================

// Border versions
//
// Add or remove borders all around the table and between all the columns.
//
// When borders are added on all sides of the cells, the corners can render odd when
// these borders do not have the same color or if they are semi-transparent.
// Therefor we add top and border bottoms to the \`tr\`s and left and right borders
// to the \`td\`s or \`th\`s

.cbds-c-table--bordered {
  > :not(caption) > * {
    border-width: $cbds-table-border-width 0;

    > * {
      border-width: 0 $cbds-table-border-width;
    }
  }
}

.cbds-c-table--borderless {
  > :not(caption) > * > * {
    border-bottom-width: 0;
  }
}




//
// Caption
// ==========================================================================

.cbds-c-table--captionTop {
  caption-side: top;

  > caption {
    padding: 0 0 $cbds-tkn-space__3x 0;
  }
}




//
// Responsive
// ==========================================================================

.cbds-c-tableResponsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
`,
  'cbds-table__tools': `// ==========================================================================
// Bootstrap Mixins
// ==========================================================================

//
// Mixins & Styles sourced from Bootstrap v5.0.0-beta2
// Includes MVP changes to original BS code to align w/ CBDS
//
// TODO: Re-factor variable naming conventions to align with CBDS methodology
//


// @mixin table-variant($state, $background) {
//   .table-#{$state} {
//     $color: color-contrast(opaque($cbds-tkn-color__white, $background));
//     $hover-bg: mix($color, $background, percentage($cbds-table-hover-bg-factor));
//     $striped-bg: mix($color, $background, percentage($cbds-table-striped-bg-factor));
//     $active-bg: mix($color, $background, percentage($cbds-table-active-bg-factor));

//     --#{$variable-prefix}table-bg: #{$background};
//     --#{$variable-prefix}table-striped-bg: #{$striped-bg};
//     --#{$variable-prefix}table-striped-color: #{color-contrast($striped-bg)};
//     --#{$variable-prefix}table-active-bg: #{$active-bg};
//     --#{$variable-prefix}table-active-color: #{color-contrast($active-bg)};
//     --#{$variable-prefix}table-hover-bg: #{$hover-bg};
//     --#{$variable-prefix}table-hover-color: #{color-contrast($hover-bg)};

//     color: $color;
//     border-color: mix($color, $background, percentage($cbds-table-border-factor));
//   }
// }
`,
  'cbds-textarea': `// ==========================================================================
// --------------------------------------------------------------------------
// #CBDS Textarea
// --------------------------------------------------------------------------
// ==========================================================================


.cbds-c-textarea {
  $c: &;

  @include cbds-box-sizing;

  display: block;
  width: 100%;
  min-height: 88px; // Set to mimic 3 rows
  margin: 0;
  padding: 8px 12px;
  @include cbds-elementText__500;
  color: $cbds-tkn-textColor__secondary;
  background-color: $cbds-tkn-color__white;
  // Adding because Autoprefixer doesn't support border-radius
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: solid $cbds-tkn-borderWidth__xs $cbds-tkn-color__neutral--500;
  transition: border 0.1s linear, background-color 0.1s linear;
  appearance: none;
  resize: vertical;

  &:focus,
  &.cbds-is-focus {
    @include cbds-focus-outline;
    border-color: $cbds-tkn-color__neutral--800;
  }

  &:hover,
  &.cbds-is-hover {
    border-color: $cbds-tkn-color__neutral--800;
  }

  &:active,
  &.cbds-is-active {
    border-color: $cbds-tkn-color__neutral--1100;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-textColor__quat;
    background-color: $cbds-tkn-color__neutral--60;
    border-color: $cbds-tkn-color__neutral--200;
    cursor: not-allowed;
  }

  &::placeholder {
    color: $cbds-tkn-textColor__quat;
    opacity: 1;
  }

  // &[readonly],
  // &.cbds-is-readonly {
  // }

  // &.cbds-is-invalid,
  // &.cbds-has-error {
  //   border-color: $cbds-tkn-color__red--700;
  // }
}
`,
  'cbds-text-input': `// ==========================================================================
// --------------------------------------------------------------------------
// #CBDS Text Input
// --------------------------------------------------------------------------
// ==========================================================================


.cbds-c-textInput {
  $c: &;

  @include cbds-box-sizing;

  display: block;
  width: 100%;
  height: 48px;
  margin: 0;
  padding: 8px 12px;
  @include cbds-elementText__500;
  color: $cbds-tkn-textColor__secondary;
  background-color: $cbds-tkn-color__white;
  // Adding because Autoprefixer doesn't support border-radius
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: solid $cbds-tkn-borderWidth__xs $cbds-tkn-color__neutral--500;
  transition: border 0.1s linear, background-color 0.1s linear;
  appearance: none;

  &:focus,
  &.cbds-is-focus {
    @include cbds-focus-outline;
    border-color: $cbds-tkn-color__neutral--800;
  }

  &:hover,
  &.cbds-is-hover {
    border-color: $cbds-tkn-color__neutral--800;
  }

  &:active,
  &.cbds-is-active {
    border-color: $cbds-tkn-color__neutral--1100;
  }

  &[disabled],
  &[aria-disabled="true"],
  &.cbds-is-disabled {
    color: $cbds-tkn-textColor__quat;
    background-color: $cbds-tkn-color__neutral--60;
    border-color: $cbds-tkn-color__neutral--200;
    cursor: not-allowed;
  }

  &::placeholder {
    color: $cbds-tkn-textColor__quat;
    opacity: 1;
  }

  // &[readonly],
  // &.cbds-is-readonly {
  // }

  // &.cbds-is-invalid,
  // &.cbds-has-error {
  //   border-color: $cbds-tkn-color__red--700;
  // }
}
`,
  'cbds-section-text-block': `.cbds-c-sectionTextBlock {
  @include cbds-box-sizing;
  padding: $cbds-tkn-space__8x 0 $cbds-tkn-space__12x;

  @include media('>=md') {
    padding: $cbds-tkn-space__12x 0 $cbds-tkn-space__16x;
  }

  @include media('>=lg') {
    padding: $cbds-tkn-space__16x 0 $cbds-tkn-space__24x;
  }

  &--greyBg {
    background-color: $cbds-tkn-backgroundColor__neutral--lightest;
  }
}
`,
  'cbds-text-block': `.cbds-c-textBlock {
  @include cbds-box-sizing;

  @include media ('<md') {
    margin-top: $cbds-tkn-space__8x;
  }

  .cbds-c-textBlock__heading {
    @include cbds-mrk-heading-3;
    margin-bottom: $cbds-tkn-space__4x;
    @media screen and (min-width: $cbds-tkn-breakpoint__lg) {
      font-size: $cbds-tkn-fontSize__70;
    }
  }

  /*************************************************
      Typography for HTML content inside Text Block
  **************************************************/

  @include cbds-mrk-typography();

}
`,
};
