import React from 'react';
import BrandObjectives from './BrandObjectives.mdx';

const BrandObjectivesPage = {
  mdx: BrandObjectives,
  breadcrumbs: "Brand Guidelines/Enterprise Guidelines",
  alias: "brand-guidelines/enterprise-guidelines/brand-objectives",
  pathExact: false,
  title: "Brand Objectives",
  menuLabel: 'Brand Objectives',
  published: true,
  order: 5,
  date: '2023-01-27', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Enterprise',
    'Guidelines',
    'Brand objectives',
    'Objectives',
  ],
  meta: {
    title: 'Brand Objectives - Enterprise - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default BrandObjectivesPage;
