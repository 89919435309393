import React from 'react';
import Typefaces from './Typefaces.mdx';

const TypefacesPage = {
  mdx: Typefaces,
  breadcrumbs: "Brand Guidelines/Consumer Guidelines",
  alias: "brand-guidelines/consumer-guidelines/typefaces",
  pathExact: false,
  title: "Typefaces",
  menuLabel: 'Typefaces',
  published: true,
  order: 7,
  date: '2023-01-31', // Show when last updated
  summary: <>Owning unique, scalable fonts are a powerful tool for driving distinction and recognition and are the visual embodiment of our brand voice.<br />It allows us to show up uniquely and consistently in every message— from billboards to banners.</>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Consumer',
    'Guidelines',
    'Typefaces',
    'Fonts',
    'F37 Bobby',
    'FiraSans',
    'Typography',
    'Font Weights and Styles',
  ],
  meta: {
    title: 'Typefaces - Consumer - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default TypefacesPage;
