// import React from 'react';
// import Link from '../../../components/Link';
import CurrencyNotation from './CurrencyNotation.mdx';

const CurrencyNotationPage = {
  mdx: CurrencyNotation,
  breadcrumbs: "Patterns/Fields",
  alias: "patterns/fields/currency-notation",
  pathExact: false,
  title: "Currency Notation",
  menuLabel: 'Currency Notation',
  published: true,
  order: 2,
  date: '2024-06-03', // Show when last updated
  summary: "",

  // SEO and Search
  tags: [
    "Form",
    "Currency Notation",
    "Field",
    "Patterns",
    "Component",
    "Input",
  ],
  meta: {
    description: '',
    title: 'Currency Notation - Fields - Patterns',
    keywords: [],
  },
  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "",
    },
    react: {
      status: "na",
      version: "v1.0.0",
      link: "",
    },
    angular: {
      status: "released",
      version: "v1.0.0",
      link: "/?path=/story/patterns-forms-currency--default",
    },
    figma: {
      status: "released",
      version: "",
      link: "https://www.figma.com/file/mj41OHGCokcJ9N7D0q8D9Q/CBDS-UI-Kit-V1.1?node-id=1633%3A6407&t=c0P3NDyo4OqUOeuw-1",
    },
  },
};

export default CurrencyNotationPage;
