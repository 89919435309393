import React from "react";
import PropTypes from 'prop-types';

import { CbdsIcon } from '@cbds/cbds-components-react';
import Callout from '../Callout';
import ColorChip from '../ColorChip';

const ADAGuidelines = ({ black }) => (
  <section className="cbdsws-c-adaGuidelines">
    <h3>Accessibility Guidelines for Color Contrast</h3>
    <p>It is advised by W3C to provide enough contrast between text and its background so it can be read by people with moderately low vision (who do not use contrast-enhancing assistive technology). The minimum contrast success criterion <a href="https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html" target="_blank" aria-label="1.4.3 - Level AA (Opens in New Tab)">1.4.3 - Level AA</a> applies to text in the page.</p>
    <p>CBDS strives to meet <strong>WCAG 2.1 AA</strong> standards for color contrast, which means the minimum contrast ratio between text and background color must be at least:</p>

    <div className="cbdsws-u-table__responsiveWrapper">
      <table className="cbdsws-c-adaTable">
        <caption className="cbdsws-u-srOnly">ADA Color Contrast Ratio Meanings</caption>
        <thead>
          <tr>
            <th className="cbdsws-c-adaTable__badgeHead">Ratio</th>
            <th className="cbdsws-c-adaTable__meaningHead">Digital defined text size</th>
            <th className="cbdsws-c-adaTable__guidelinesHead">Print defined text size</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td data-cell="Ratio" className="cbdsws-c-adaTableCell">
              <strong><em>4.5:1</em> for normal text</strong>
            </td>
            <td data-cell="Digital defined text size" className="cbdsws-c-adaTableCell">
              Any value (e.g. 12px, 14px, etc.).
            </td>
            <td data-cell="Print defined text size" className="cbdsws-c-adaTableCell">
              <div>Any value (e.g. 8 point, 10 point, etc.).</div>
            </td>
          </tr>
          <tr>
            <td data-cell="Ratio" className="cbdsws-c-adaTableCell">
              <strong><em>3:1</em> for large text</strong>
            </td>
            <td data-cell="Digital defined text size" className="cbdsws-c-adaTableCell">
              <div>24px and larger, or 18.67px and larger <em>if</em> it is <strong>bold</strong>.</div>
            </td>
            <td data-cell="Print defined text size" className="cbdsws-c-adaTableCell">
              <div>18 point and larger, or 14 point and larger <em>if</em> it is <strong>bold</strong>.</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <details className="cbdsws-c-detailsCollapsible cbds-u-marginTop__6x">
      <summary className="cbdsws-c-detailsCollapsible__heading cbds-c-button cbds-c-button--outlinedPrimary cbds-c-button--fullWidth">
        <span className="cbds-c-button__label">More on Accessibility</span>
        <CbdsIcon
          classes="cbdsws-c-detailsCollapsible__icon cbds-c-button__icon"
          iconName="chevron-down"
          library="ui"
          a11yHidden={true}
        />
      </summary>
      <div className="cbdsws-c-detailsCollapsible__body">
        <h4>Color contrast requirements exceptions</h4>
        <p>The visual presentation of text and images of text has a contrast ratio of at least 4.5:1, except for the following:</p>
        <ul>
          <li><strong>Large Text:</strong> Large-scale text and images of large-scale text have a contrast ratio of at least 3:1;</li>
          <li><strong>Incidental:</strong> Text or images of text that are part of an inactive user interface component, that are pure decoration, that are not visible to anyone, or that are part of a picture that contains significant other visual content, have no contrast requirement.</li>
          <li><strong>Logotypes:</strong> Text that is part of a logo or brand name has no contrast requirement.</li>
        </ul>

        <Callout
          heading=""
          description={<>
            <ul>
              <li>Text that is larger and has wider character strokes is easier to read at lower contrast, and therefore has lower contrast requirements.</li>
              <li>In CSS, <strong>bold</strong> text is typically defined as <strong>font-weight: bold</strong> or <strong>font-weight: 700</strong> or greater.</li>
            </ul>
          </>}
          type="note"
        />

        <h4>Legend for Color Cards</h4>
        <p>The color cards on this page include badges with accessibility pass/fail status, and contrast ratio value.</p>

        <ul className="cbdsws-c-adaTextColorList">          
          <li className="cbdsws-c-adaTextColorListItem"><strong>Pass:</strong><br />
            <img src="../../assets/images/icons/pass.svg" />
          </li>       
          <li className="cbdsws-c-adaTextColorListItem"><strong>Fail:</strong><br />
            <img src="../../assets/images/icons/fail.svg" />
          </li>              
        </ul>
        <p>The contrast ratio displayed is between the specified color and the primary text colors within this color palette. The primary text colors are:</p>

        <ul className="cbdsws-c-adaTextColorList">
          <li className="cbdsws-c-adaTextColorListItem">
            <strong>Black:</strong><br />
            <ColorChip
              color={black}
              style="lg"
            />
          </li>
          <li className="cbdsws-c-adaTextColorListItem"><strong>White:</strong><br />
            <ColorChip
              color="ffffff"
              style="lg"
            />
          </li>                 
        </ul>

        <h4>Resources</h4>
        <ul>
          <li><a href="https://www.w3.org/" target="_blank" aria-label="World Wide Web Consortium Website (opens in a new tab)">W3C - World Wide Web Consortium</a></li>
          <li><a href="https://www.w3.org/WAI/WCAG21/Understanding/ " target="_blank" aria-label="WCAG - Web Content Accessibility Guidelines - Understanding WCAG 2.1 (opens in a new tab)">WCAG - Web Content Accessibility Guidelines - Understanding WCAG 2.1</a></li>
          <li><a href="https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html" target="_blank" aria-label="Contrast (Minimum) (opens in a new tab)">Contrast (Minimum)</a></li>
        </ul>

        <h4>Tools for Checking Color Contrast</h4>
        <ul>
          <li><a href="https://contrastchecker.com/" target="_blank" aria-label="Contrast Checker (opens in a new tab)">Contrast Checker</a></li>
          <li><a href="https://developer.paciellogroup.com/resources/contrastanalyser/" target="_blank" aria-label="Colour Contrast Analyser (opens in a new tab)">Color Contrast Analyser (CCA)</a></li>
          <li><a href="https://www.456bereastreet.com/archive/200709/10_colour_contrast_checking_tools_to_improve_the_accessibility_of_your_design/" target="_blank" aria-label="List of Color Contrast Tools (opens in a new tab)">List of color contrast tools</a></li>
        </ul>
      </div>
    </details>
  </section>
);

ADAGuidelines.propTypes = {
  black: PropTypes.string,
  textColorPrimary: PropTypes.string,
  textColorSecondary: PropTypes.string,
  textColorTertiary: PropTypes.string,
  textColorQuaternary: PropTypes.string,
};

ADAGuidelines.defaultProps = {
  black: '231f20',
  textColorPrimary: '231f20',
  textColorSecondary: '373535',
  textColorTertiary: '636262',
  textColorQuaternary: '757474',
};

export default ADAGuidelines;
