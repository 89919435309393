import React from 'react';
import Photography from './Photography.mdx';

const PhotographyPage = {
  mdx: Photography,
  breadcrumbs: "Brand Guidelines/Consumer Guidelines",
  alias: "brand-guidelines/consumer-guidelines/photography",
  pathExact: false,
  title: "Photography",
  menuLabel: 'Photography',
  published: true,
  order: 8,
  date: '2023-01-31', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Consumer',
    'Guidelines',
    'Photography',
    'Brand Photography',
  ],
  meta: {
    title: 'Photography - Consumer - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default PhotographyPage;
