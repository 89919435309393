import Layout from './Layout.mdx';

const LayoutPage = {
  mdx: Layout,
  breadcrumbs: "Foundations",
  alias: "foundations/layout",
  pathExact: false,
  title: "Layout",
  menuLabel: 'Layout',
  published: true,
  order: 5,
  date: '2023-08-17', // Show when last updated
  summary: 'The bones of any design are found in its Layout. By respecting common baselines and measurements across both Design and Development, we create consistent and quality products across the enterprise.',

  // SEO and Search
  tags: [
    'CBDS',
    'Foundations',
    'Layout',
    'Design',
  ],
  meta: {
    title: 'Layout - Foundations',
    description: '',
    keywords: [],
  },
};

export default LayoutPage;
