import React from 'react';
import Contacts from './Contacts.mdx';

const ContactsPage = {
  mdx: Contacts,
  breadcrumbs: "Brand Guidelines/Enterprise Guidelines",
  alias: "brand-guidelines/enterprise-guidelines/contacts",
  pathExact: false,
  title: "Contacts",
  menuLabel: 'Contacts',
  published: true,
  order: 15,
  date: '2023-01-27', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Enterprise',
    'Guidelines',
    'Contacts',
    'Greenhouse',
  ],
  meta: {
    title: 'Contacts - Enterprise - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default ContactsPage;
