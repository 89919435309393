import * as ReactDOMServer from 'react-dom/server';

function isEmpty(obj) {
  for(var prop in obj) {
    if(Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

export const getPropsChildrenForSummary = props => {
  if (typeof props === 'string') return props;

  if (props.props && !isEmpty(props.props)) {
    return getPropsChildrenForSummary(props.props);
  }

  if (props.children) {
    return ReactDOMServer.renderToStaticMarkup(props.children);
  }

  return '';
};

export const getPropsChildren = props => {
  if (typeof props.children === 'string') return props.children;

  if (props.children.props) return getPropsChildren(props.children.props);

  throw Error('No prop children available.');
};
