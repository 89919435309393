import Newsletter from './Newsletter.mdx';

const NewsletterPage = {
  mdx: Newsletter,
  breadcrumbs: "About",
  alias: "about/newsletter",
  pathExact: false,
  title: "Newsletter",
  menuLabel: 'Newsletter',
  published: true,
  order: 3,
  date: '2023-02-16', // Show when last updated
  summary: "Learn what’s going on at the Citizens Design System with our monthly newsletter!",

  // SEO and Search
  tags: [
    'CBDS',
    'About',
    'Newsletter',
    'Updates',
    'Cycle Newsletter',
    'Release Notes',
  ],
  meta: {
    description: '',
    title: 'Newsletter - About',
    keywords: [],
  },
};

export default NewsletterPage;
