import React from 'react';
import ConsumerColor from './ConsumerColor.mdx';

const ConsumerColorPage = {
  mdx: ConsumerColor,
  breadcrumbs: "Brand Guidelines/Consumer Guidelines",
  alias: "brand-guidelines/consumer-guidelines/color",
  pathExact: false,
  title: "Color",
  menuLabel: 'Color',
  published: true,
  order: 5,
  date: '2023-01-30', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Consumer',
    'Guidelines',
    'Color',
    'Color Palette',
    'Citizens Green',
    'Sage',
    'Lime Green',
    'Accessibility',
    'Illustration',
    'Text Color',
  ],
  meta: {
    title: 'Color - Consumer - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default ConsumerColorPage;
