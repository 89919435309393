import React from 'react';
import Overview from './Overview.mdx';

const OverviewPage = {
  mdx: Overview,
  breadcrumbs: "Foundations/Utilities",
  alias: "foundations/utilities/overview",
  pathExact: false,
  title: "Overview",
  menuLabel: 'Overview',
  published: true,
  order: 1,
  date: '2023-02-06', // Show when last updated
  summary: <>Utility classes are helper classes meant to be used when the styling of a layout or object needs additional adjustment by default or at a specific breakpoint.</>,

  // SEO and Search
  tags: [
    'CBDS',
    'Foundations',
    'Overview',
    'Utility',
    'Utility Classes System',
    'Utility Class Overview for Developers',
  ],
  meta: {
    title: 'Overview - Utilities - Foundations',
    description: '',
    keywords: [],
  },
};

export default OverviewPage;
