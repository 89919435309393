import React from 'react';
import Logo from './Logo.mdx';

const LogoPage = {
  mdx: Logo,
  breadcrumbs: "Brand Guidelines/Business Banking Guidelines",
  alias: "brand-guidelines/business-banking-guidelines/logo",
  pathExact: false,
  title: "Logo",
  menuLabel: 'Logo',
  published: true,
  order: 3,
  date: '2023-02-13', // Show when last updated
  summary: <>When on a background of Light Gray or white, the logo appears in Citizens Green, PMS 340. On a background of Deep Green
  the logo appears in white.</>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Business Banking',
    'Guidelines',
    'Business Banking Guidelines',
    'Logo'
  ],
  meta: {
    title: 'Logo - Business Banking - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default LogoPage;
