import React from 'react';
import Icons from './Icons.mdx';
import Link from '../../../components/Link';

const IconsPage = {
  mdx: Icons,
  breadcrumbs: "Foundations",
  alias: "foundations/icons",
  pathExact: false,
  title: "Icons",
  menuLabel: 'Icons',
  published: true,
  order: 4,
  date: '2023-01-20', // Show when last updated
  summary: <>The following collection of icons is available for use in Citizens digital properties. These icons can be used to highlight, enhance or help visually communicate content, calls to action, messaging, and more.<br /><br />The CBDS icon library is available as SVG sprite(s) via the <strong>CBDS Icons</strong> NPM package and as a FIGMA shared library. To use the icons, please read our implementation instructions in the <Link variant="lead" internalUrl="/foundations/icons#how-to-use-developer">how to use</Link> section.</>,

  // SEO and Search
  tags: [
    'CBDS',
    'Foundations',
    'Icons',
    'UI',
    'Brand',
    'svg',
  ],
  meta: {
    title: 'Icons - Foundations',
    description: '',
    keywords: [],
  },
};

export default IconsPage;
