import Developers from "./Developers.mdx";

const DevelopersPage = {
  mdx: Developers,
  breadcrumbs: "Accessibility/Testing",
  alias: "accessibility/testing/developers",
  pathExact: false,
  title: "Developers",
  menuLabel: "Developers",
  published: true,
  order: 4,
  date: "2024-09-17", // Show when last updated
  summary: "",


  // SEO and Search
  tags: [
    "Accessibility",
    "A11Y",
    "ADA",
    "Developers",
    "Access Assistant",
    "ANDI",
    "Accessibility Management Platform",
    "NVDA",
    "Non Visual Desktop Access",
    "Content Publisher",
    "Developer",
    "WCAG",
  ],
  meta: {
    title: "Developers - Testing - Accessibility",
    description: "",
    keywords: [],
  },
};

export default DevelopersPage;
