import React from 'react';
import Illustration from './Illustration.mdx';

const IllustrationPage = {
  mdx: Illustration,
  breadcrumbs: "Brand Guidelines/Consumer Guidelines",
  alias: "brand-guidelines/consumer-guidelines/illustration",
  pathExact: false,
  title: "Illustration",
  menuLabel: 'Illustration',
  published: true,
  order: 9,
  date: '2023-02-01', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Consumer',
    'Guidelines',
    'Illustration',
    'Brand Journey',
    'Digital Asset Management',
    'Brand Illustrations',
  ],
  meta: {
    title: 'Illustration - Consumer - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default IllustrationPage;
