import React from 'react';
import EnterpriseIcons from './EnterpriseIcons.mdx';

const EnterpriseIconsPage = {
  mdx: EnterpriseIcons,
  breadcrumbs: "Brand Guidelines/Enterprise Guidelines",
  alias: "brand-guidelines/enterprise-guidelines/icons",
  pathExact: false,
  title: "Icons",
  menuLabel: 'Icons',
  published: true,
  order: 14,
  date: '2023-01-27', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Enterprise',
    'Guidelines',
    'Icons',
  ],
  meta: {
    title: 'Icons - Enterprise - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default EnterpriseIconsPage;
