import React from 'react';
import Examples from './Examples.mdx';

const ExamplesPage = {
  mdx: Examples,
  breadcrumbs: "Brand Guidelines/Wealth Management Guidelines",
  alias: "brand-guidelines/wealth-management-guidelines/examples",
  pathExact: false,
  title: "Examples",
  menuLabel: 'Examples',
  published: true,
  order: 1,
  date: '2023-12-01', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Wealth Management',
    'Guidelines',
    'Wealth Management Guidelines',
    'Examples',
  ],
  meta: {
    title: 'Examples - Wealth Management - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default ExamplesPage;
