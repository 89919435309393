import React from 'react';
import VisualDesignSystem from './VisualDesignSystem.mdx';

const VisualDesignSystemPage = {
  mdx: VisualDesignSystem,
  breadcrumbs: "Brand Guidelines/Wealth Management Guidelines",
  alias: "brand-guidelines/wealth-management-guidelines/visual-design-system",
  pathExact: false,
  title: "Visual Design System",
  menuLabel: 'Visual Design System',
  published: true,
  order: 1,
  date: '2023-12-01', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Wealth Management',
    'Guidelines',
    'Wealth Management Guidelines',
    'Visual Design System',
  ],
  meta: {
    title: 'Visual Design System - Wealth Management - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default VisualDesignSystemPage;
