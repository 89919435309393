import React from 'react';
import Usage from './Usage.mdx';

const UsagePage = {
  mdx: Usage,
  breadcrumbs: "Getting Started/For Developers/Web",
  alias: "getting-started/for-developers/web/usage",
  pathExact: false,
  title: "Usage",
  menuLabel: 'Usage',
  published: true,
  order: 1,
  date: '2024-01-31', // Show when last updated
  summary: <>More specific usage guidelines are available to web developers for each of the Citizens Design System (CDS) libraries.</>,
  tags: [
    'CBDS',
    'CDS',
    'getting started',
    'For Developers',
    'usage',
    'help',
  ],
  meta: {
    title: 'Usage - Web - For Developers - Getting Started',
    description: '',
    keywords: [],
  },
};

export default UsagePage;
