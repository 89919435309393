import React from 'react';
import Purpose from './Purpose.mdx';

const PurposePage = {
  mdx: Purpose,
  breadcrumbs: "Brand Guidelines/Business Banking Guidelines",
  alias: "brand-guidelines/business-banking-guidelines/Purpose",
  pathExact: false,
  title: "Purpose",
  menuLabel: 'Purpose',
  published: true,
  order: 1,
  date: '2023-09-28', // Show when last updated
  summary: <>This guidance document provides the details of a design system for Citizens Business Banking, which is an extension of and further supports the brand enterprise platform focused on Citizens as the champion of your financial journey. </>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Business Banking',
    'Guidelines',
    'Business Banking Guidelines',
    'Purpose',
  ],
  meta: {
    title: 'Purpose - Business Banking - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default PurposePage;
