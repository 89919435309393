import React from 'react';
import Copy from './Copy.mdx';

const CopyPage = {
  mdx: Copy,
  breadcrumbs: "Brand Guidelines/Enterprise Guidelines",
  alias: "brand-guidelines/enterprise-guidelines/copy",
  pathExact: false,
  title: "Copy",
  menuLabel: 'Copy',
  published: true,
  order: 9,
  date: '2023-01-27', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Enterprise',
    'Guidelines',
    'Copy',
    'Style',
    'Guide',
    'Grammer',
    'Citizens',
    'Terminology',
    'Punctuation',
    'Voice',
  ],
  meta: {
    title: 'Copy - Enterprise - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default CopyPage;
