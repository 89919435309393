import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import IconInline from '../IconInline';

const Callout = ({ classes, type, spaceMT, a11yRole, a11yInvalid, a11yLive, uid, jiraTix, cbdsSite, heading, description, ...rest }) => {
  const typeClass = type ? `cbdsws-c-callout--${type}` : null;
  const spaceClass = spaceMT ? "cbdsws-u-sp__mt" : null;
  let iconName = null;
  let typeHeading = null;

  switch(type) {
    case 'note':
      iconName = "icon-note";
      typeHeading = "Note";
      break;
    case 'queue':
      iconName = "icon-queue";
      typeHeading = "In Queue";
      break;
    case 'todo':
      iconName = "icon-todo";
      typeHeading = "Todo";
      break;
    case 'deprecation':
      iconName = "icon-deprecation";
      typeHeading = "Deprecation Notice";
      break;
    case 'breaking':
      iconName = "icon-breaking";
      typeHeading = "Breaking Change";
      break;
    case 'new':
      iconName = "icon-new";
      typeHeading = "New";
      break;
    default:
      iconName = "icon-placeholder";
      typeHeading = "Placeholder";
      break;
  }

  return (
    <div
      className={classNames('cbdsws-c-callout', typeClass, spaceClass, classes)}
      role={a11yRole}
      aria-invalid={a11yInvalid}
      aria-live={a11yLive}
      id={uid}
      {...rest}
    >
      <div className="cbdsws-c-callout__rail">
        <IconInline
          baseClass="cbdsws-c-callout__icon"
          name={iconName}
          role="presentation"
        />
      </div>
      <div className="cbdsws-c-callout__body">
        <div className="cbdsws-c-callout__content">
          <div className="cbdsws-c-callout__eyebrow">
            {typeHeading.toUpperCase()}
            {jiraTix && cbdsSite.environment === "development" && (
              <a
                className="cbdsws-c-callout__jira cbdsws-c-callout__jira--link"
                href={`${cbdsSite.jiraUrl}/${jiraTix}`}
                target="_blank"
                rel="noopener"
              >
                <span className="cbdsws-u-srOnly">Jira ticket </span>{jiraTix}<span className="cbdsws-u-srOnly">(opens in a new tab)</span>
              </a>
            )}
            {jiraTix && cbdsSite.development !== 'development' && (
              <span className="cbdsws-c-callout__jira">: {jiraTix}</span>
            )}
          </div>
          {heading && (
            <div className="cbdsws-c-callout__heading">{heading}</div>
          )}
          {description && (
            <div className="cbdsws-c-callout__desc">{description}</div>
          )}
        </div>
      </div>
    </div>
  );
};

Callout.propTypes = {
  a11yRole: PropTypes.string,
  a11yInvalid: PropTypes.string,
  a11yLive: PropTypes.string,
  cbdsSite: PropTypes.string,
  classes: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  jiraTix: PropTypes.string,
  spaceMT: PropTypes.string,
  type: PropTypes.string,
  uid: PropTypes.string,
};

Callout.defaultProps = {
  heading: 'Callout heading',
  type: 'note',
};

export default Callout;
