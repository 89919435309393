import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import _setPageTitle from '../../_helpers/setPageTitle';

const setTitle = (_data, alias, setPageTitle) => {
  if (_data.component) {
    const data = _data.component;
    if (data.tabs) {
      data.tabs.forEach(tab => {
        if (alias === tab.alias) {
          if (tab.meta.title) {
            _setPageTitle(tab.meta.title,tab);
            setPageTitle(document.title);
          } else if (tab.label) {
            _setPageTitle(tab.label, tab);
            setPageTitle(document.title);
          } else {
            _setPageTitle(tab.alias, tab);
            setPageTitle(document.title);
          }
        }
      });
    } else if (alias === `/${data.alias}`) {
      if (data.meta.title) {
        _setPageTitle(data.meta.title, data);
        setPageTitle(document.title);
      } else if (data.title) {
        _setPageTitle(data.title, data);
        setPageTitle(document.title);
      } else {
        _setPageTitle(data.alias, data);
        setPageTitle(document.title);
      }
    }
  } else if (_data.children) {
    _data.children.forEach(_data2 => setTitle(_data2, alias, setPageTitle));
  }
};

const PageTitle = ({ pages }) => {
  const [pageTitle, setPageTitle] = useState('');
  const location = useLocation();
  const alias = location.pathname;

  useEffect(() => {
    const keys = Object.keys(pages);
    keys.forEach(key => {
      const data = pages[key];
      data.forEach(d => setTitle(d, alias, setPageTitle));
    });
  }, [alias]);

  return (<div><span className="cbds-u-screenReaderOnly">Live Region for Dynamic Screen Reader Announcements</span><div id="targetPageTitle" className="cbds-u-screenReaderOnly" aria-live="assertive">{pageTitle}</div></div>);
};

export default PageTitle;
