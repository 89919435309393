import CbdsFonts from './CbdsFonts.mdx';

const CbdsFontsPage = {
  mdx: CbdsFonts,
  breadcrumbs: "About/Changelogs",
  alias: "about/changelogs/cbds-fonts",
  pathExact: false,
  title: "CBDS Fonts Changelog",
  menuLabel: 'CBDS Fonts',
  published: true,
  order: 2,
  date: '2024-01-24', // Show when last updated
  summary: "",

  // SEO and Search
  tags: [
    'CBDS',
    'About',
    'CBDS Fonts',
    'Changelog',
    'Release Notes'
  ],
  meta: {
    description: '',
    title: 'CBDS Fonts - Changelogs - About',
    keywords: [],
  },
}; 
export default CbdsFontsPage;
