import React from 'react';
import { Route } from 'react-router-dom';
import { MDXProvider } from '@mdx-js/react';
import { getPropsChildren } from '../../_helpers/getPropsChildren';
import createId from '../../_helpers/createId';
// import ButtonCopy from '../ButtonCopy';

// Components
import AblePlayer from '../AblePlayer/AblePlayer';
import ButtonInlineCode from '../ButtonInlineCode';
import Callout from '../Callout';
import DoDont from '../DoDont';
import DoDontGrid from '../DoDontGrid';
import Header from '../Header';
import MarkdownExtended from '../MarkdownExtended';
import Figure from '../Figure';
import FigureGrid from '../FigureGrid';
import TokensTable from '../TokensTable';
import ColorCard from '../ColorCard';
import { CbdsAccordion, CbdsIcon, CbdsButton } from '@cbds/cbds-components-react';
import IconCard from '../IconCard';
import Link from '../Link';
import LogoCard from '../LogoCard';
import CodeDuo from '../CodeDuo';
import FontCard from '../FontCard';
import Tag from '../Tag';
import Card from '../Card';
import IconInline from '../IconInline/IconInline';
import Blockquote from '../Blockquote';
import CardGrid from '../CardGrid/CardGrid';
import ScreenshotLink from '../ScreenshotLink';

const Components = {
  // Basic Syntax
  h1: props => <h1 className="cbdsws-c-markdown__h1" {...props} />,
  h2: props => {
    const text = getPropsChildren(props);
    const uid = createId(text);
    return (
      <div className="cbdsws-c-markdown__heading cbdsws-c-markdown__heading--largeGap">
        <h2 id={uid} className="cbdsws-c-markdown__h2" {...props}>{props.children}</h2>
        {/* TEMP FIX: Disabling for now, until fixed */}
        {/* <ButtonCopy id={uid} /> */}
      </div>
    );
  },
  h3: props => {
    const text = getPropsChildren(props);
    const uid = createId(text);
    return (
      <div className="cbdsws-c-markdown__heading cbdsws-c-markdown__heading--smallGap">
        <h3 id={uid} className="cbdsws-c-markdown__h3" {...props}>{props.children}</h3>
        {/* TEMP FIX: Disabling for now, until fixed */}
        {/* <ButtonCopy id={uid} /> */}
      </div>
    );
  },
  h4: props => <h4 className="cbdsws-c-markdown__h4" {...props} />,
  h5: props => <h5 className="cbdsws-c-markdown__h5" {...props} />,
  h6: props => <h6 className="cbdsws-c-markdown__h6" {...props} />,
  p: props => <p className="cbdsws-c-markdown__p" {...props} />,
  strong: props => <strong className="cbdsws-c-markdown__strong" {...props} />,
  b: props => <b className="cbdsws-c-markdown__b" {...props} />,
  kbd: props => <kbd className="cbdsws-c-markdown__kbd" {...props} />,
  samp: props => <samp className="cbdsws-c-markdown__samp" {...props} />,
  a: props => {
    // TEMP FIX - will remove once we switch back to Browser Router, in new server environment setup
    const pattern = /^#/;
    const hasHashtag = props.href.match(pattern);
    const _onClick = hasHashtag ? e => {
      e.preventDefault();
      const target = document.getElementById(props.href.replace(pattern, ''));
      target.setAttribute('tabindex', 1);
      target.focus();
      target.removeAttribute('tabindex');
    } : null;

    return (
      <a onClick={_onClick} target='_blank' rel="noopener noreferrer" className="cbds-c-link cbdsws-c-markdown__a cbensightenevent" cbdata-type="link" cbdata-reason={`__link: ${props.children} - ${props.href}`} {...props}>
        {props.children}
      </a>
    );
  },
  // a: props => <a className="cbds-c-link cbdsws-c-markdown__a cbensightenevent" cbdata-type="link" cbdata-reason={`__link: ${props.children} - ${props.href}`} {...props} />,
  ul: props => <ul className="cbdsws-c-markdown__ul" {...props} />,
  ol: props => <ol className="cbdsws-c-markdown__ol" {...props} />,
  li: props => <li className="cbdsws-c-markdown__li" {...props} />,
  blockquote: props => <blockquote className="cbdsws-c-markdown__blockquote" {...props} />,
  code: props => <code className="cbdsws-c-markdown__code" {...props} />,
  hr: props => <hr className="cbdsws-c-markdown__hr" {...props} />,
  img: props => <img className="cbdsws-c-markdown__img" {...props} />,

  // Custom Components
  AblePlayer: AblePlayer,
  ButtonInlineCode: ButtonInlineCode,
  Callout: Callout,
  DoDont: DoDont,
  DoDontGrid: DoDontGrid,
  Header: Header,
  Extended: MarkdownExtended,
  Figure: Figure,
  FigureGrid: FigureGrid,
  Link: Link,
  TokensTable: TokensTable,
  ColorCard: ColorCard,
  CbdsAccordion: CbdsAccordion,
  CbdsButton: CbdsButton,
  CbdsIcon: CbdsIcon,
  CodeDuo: CodeDuo,
  IconCard: IconCard,
  LogoCard: LogoCard,
  FontCard: FontCard,
  Tag: Tag,
  Card: Card,
  IconInline: IconInline,
  Blockquote: Blockquote,
  CardGrid: CardGrid,
  ScreenshotLink: ScreenshotLink,
};

const RenderContent = ({ Content, data }) => (
  <>
    {!!Content && data.mdx && (
      <MDXProvider components={Components}>
        <Content data={data} />
      </MDXProvider>
    )}
    {!!Content && data.react && (
      <>
        {Content}
      </>
    )}
  </>
);

const Viewer = ({ data, children }) => {
  if (!data) {
    return null;
  }

  let Content = <></>;

  if (data.react) {
    Content = data.react;
  }

  if (data.mdx) {
    Content = data.mdx;
  }

  return (
    <Route path={`/${data.alias}`} component={() => (
      <>
        <RenderContent Content={Content} data={data} />
        {data.tabs && data.tabs.map(tab => {
          let SubContent = <></>;

          if (tab.react) {
            SubContent = tab.react;
          }

          if (tab.mdx) {
            SubContent = tab.mdx;
          }

          return (
            <Route key={`${data.alias}_${tab.alias}`} path={tab.alias}>
              <RenderContent Content={SubContent} data={tab} />
            </Route>
          );
        })}
        {children}
      </>
    )} />
  );
};

export default Viewer;
