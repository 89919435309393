import React from 'react';
import ToneAndLanguage from './ToneAndLanguage.mdx';

const ToneAndLanguagePage = {
  mdx: ToneAndLanguage,
  breadcrumbs: "Brand Guidelines/Private Banking Guidelines",
  alias: "brand-guidelines/private-banking-guidelines/tone-and-language",
  pathExact: false,
  title: "Tone and Language",
  menuLabel: 'Tone and Language',
  published: true,
  order: 2,
  date: '2024-01-24', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Private Banking',
    'Guidelines',
    'Private Banking Brand Guidelines',
    'Tone and Language',
  ],
  meta: {
    title: 'Tone and Language - Private Banking Guidelines - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default ToneAndLanguagePage;
