import React from 'react';
import Contact from './Contact.mdx';

const ContactPage = {
  mdx: Contact,
  breadcrumbs: "Brand Guidelines/Commercial Guidelines",
  alias: "brand-guidelines/commercial-guidelines/contact",
  pathExact: false,
  title: "Contact",
  menuLabel: 'Contact',
  published: true,
  order: 7,
  date: '2023-11-28', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Commercial',
    'Guidelines',
    'Commercial Guidelines',
    'Contact'
  ],
  meta: {
    title: 'Contact - Commercial - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default ContactPage;
