import React from 'react';
import { CbdsIconButton, CbdsIcon } from '@cbds/cbds-components-react';
import Logo from '../Logo';
import Search from '../Search';
import Navigation from '../Navigation';
import { Link } from 'react-router-dom';

const Sidebar = ({ pages, sidebarRef, sidebarLogoRef, appRef, toggleMenu }) => {
  return (
    <div id="sidebar-nav" ref={sidebarRef} className="cbdsws-l-sidebar">
      <header>
        <div className="cbdsws-l-sidebar__buttons">
          <Link to="/" className="cbds-c-button cbds-c-button--ghost">
            <CbdsIcon
              classes="cbds-c-button__icon"
              a11yHidden iconName="home" />
            <span className="cbds-c-button__label">Home</span>
          </Link>
          <div className="cbdsws-l-sidebar__closeMenuButton">
            <CbdsIconButton
              variant="ghost"
              uid="mobile-close-menu-button"
              a11yLabel="Close Navigation Menu"
              icon={{
                iconName: "close"
              }}
              onClick={() => toggleMenu(false)}
            />
          </div>
        </div>
        <Logo uid="menu-logo" sidebarLogoRef={sidebarLogoRef} />
        <Search
          appRef={appRef}
          uid="sidebar-search-input"
          toggleMenu={toggleMenu}
        />
        <nav aria-label="Primary">
          <Navigation pages={pages} toggleMenu={toggleMenu} />
        </nav>
      </header>
    </div>
  );
};

export default Sidebar;
