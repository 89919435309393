import ContactUs from './ContactUs.mdx';

const ContactUsPage = {
  mdx: ContactUs,
  breadcrumbs: "",
  alias: "contact-us",
  pathExact: false,
  title: "Contact Us",
  menuLabel: 'Contact Us',
  published: true,
  order: "",
  date: '2023-02-15', // Show when last updated
  summary: "The Citizens Design System team is here for you. If you aren’t finding what you are looking for, if there seems to be an issue with code or a design – contact us via email or Teams! ",

  // SEO and Search
  tags: [
    'CBDS',
    'Contact Us',
    'Help',
    'Requests',
    'Report a Bug',
    'Problem',
    'Email',
  ],
  meta: {
    description: '',
    keywords: [],
  },
};

export default ContactUsPage;
