import React from 'react';
import Link from '../../../components/Link';
import FlexLayouts from './FlexLayouts.mdx';

const FlexLayoutsPage = {
  mdx: FlexLayouts,
  breadcrumbs: "Patterns",
  alias: "patterns/flex-layouts",
  pathExact: false,
  title: "Layout Patterns Using Flexbox",
  menuLabel: 'Flexbox Layouts',
  published: true,
  order: 1,
  date: '2023-02-21', // Show when last updated
  summary: <>CBDS provides a <Link variant="lead" internalUrl="/components/web-responsive-library/layout-grid/overview">layout grid</Link> system to achieve consistent and responsive layouts for your projects. But what about the instances where you need to achieve a common layout pattern that the grid system does not easily account for? Well, due to the fact that the CBDS Layout Grid is built with flexbox, we have a number of <Link variant="lead" internalUrl="/foundations/utilities/flex">flex utility classes</Link> available to us to render the desired results.<br/><br/>Below are some common patterns that you can copy into your project without having to write a single line of CSS simply by having CBDS Components installed in your project. In each of the examples, click the 'Copy Code' button in the preview panel to copy and paste the code into your project.</>,

  // SEO and Search
  tags: [
    'CBDS',
    'Patterns',
    'Flexbox Layouts',
    'Layout Patterns Using Flexbox',
    'Flex Layouts',
    'Flex in a Grid',
    'Flex Instead of Grid',
    'Two Column Article Layout Using Grid',
  ],
  meta: {
    description: '',
    title: 'Layout Patterns Using Flexbox - Patterns',
    keywords: [],
  },
};

export default FlexLayoutsPage;
