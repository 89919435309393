import React from 'react';
import Strategy from './Strategy.mdx';

const StrategyPage = {
  mdx: Strategy,
  breadcrumbs: "Brand Guidelines/Business Banking Guidelines",
  alias: "brand-guidelines/business-banking-guidelines/strategy",
  pathExact: false,
  title: "Strategy",
  menuLabel: 'Strategy',
  published: true,
  order: 3,
  date: '2023-09-28', // Show when last updated
  summary: <>Always Up for the Moment</>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Business Banking',
    'Guidelines',
    'Business Banking Guidelines',
    'Strategy',
    'Strategy Placement',
  ],
  meta: {
    title: 'Strategy - Business Banking - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default StrategyPage;
