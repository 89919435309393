import React from 'react';
import Logos from './Logos.mdx';
import Link from '../../../components/Link';

const LogosPage = {
  mdx: Logos,
  breadcrumbs: "Foundations",
  alias: "foundations/logos",
  pathExact: false,
  title: "Logos",
  menuLabel: 'Logos',
  published: true,
  order: 6,
  date: '2024-01-11', // Show when last updated
  summary: <>The following collection of logos is available for use in Citizens digital properties. These logos can be used anywhere you want to display the logo.<br /><br />The CBDS Logos library is available as SVG sprite(s) via the <strong>CBDS Logos</strong> NPM package and as a FIGMA shared library. To use the logos, please read our implementation instructions in the <Link variant="lead" internalUrl="#how-to-use-developer">how to use</Link> section.</>,

  // SEO and Search
  tags: [
    'CBDS',
    'Foundations',
    'Logos',
    'UI',
    'Brand',
    'svg',
  ],
  meta: {
    title: 'Logos - Foundations',
    description: '',
    keywords: [],
  },
};

export default LogosPage;
