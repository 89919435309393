import React from 'react';
import Link from '../../../../components/Link';

import Spacing from './Spacing.mdx';

const SpacingPage = {
  mdx: Spacing,
  breadcrumbs: "Foundations/Utilities",
  alias: "foundations/utilities/spacing",
  pathExact: false,
  title: "Spacing",
  menuLabel: 'Spacing',
  published: true,
  order: 6,
  date: '2023-02-07', // Show when last updated
  summary: <>Spacing utility classes are divided into <strong>margin</strong> and <strong>padding</strong> types, and are used to control the respective spacing of an element. The values used within each class are based on the <Link variant="lead" internalUrl="/foundations/space#scale">CBDS Space scale</Link> and it's 4px base unit.</>,

  // SEO and Search
  tags: [
    'CBDS',
    'Foundations',
    'Spacing',
    'Utility',
    'Utility Classes System',
    'Spacing  Utility Classes for Developers',
    'margin',
    'padding',
    'scale',
  ],
  meta: {
    title: 'Spacing - Utilities - Foundations',
    description: '',
    keywords: [],
  },
};

export default SpacingPage;
