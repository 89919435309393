// import React from "react";
import VisualPicker from "./VisualPicker.mdx";
import Guidelines from "./Guidelines.mdx";
// import Accessibility from "./Accessibility.mdx";
// import Code from "./Code.mdx";

const VisualPickerPage = {
  mdx: VisualPicker,
  breadcrumbs: "Components/Salesforce Library",
  alias: "components/salesforce-library/visual-picker",
  pathExact: false,
  title: "Visual Picker",
  menuLabel: "Visual Picker",
  published: true,
  date: "2023-10-19", // Show when last updated
  summary: "",
  tabs: [
    {
      label: "Guidelines",
      mdx: Guidelines,
      summary: "",
      alias: "/components/salesforce-library/visual-picker/guidelines",
      // SEO and Search
      tags: [
        "Component",
        "Salesforce Library",
        "Visual Picker",
        "Design",
        "Guidelines",
      ],
      meta: {
        title: "Guidelines - Visual Picker - Salesforce Library - Components",
        description: "",
        keywords: [],
      },
    },
    /*{
      label: "Accessibility",
      mdx: Accessibility,
      summary: <></>,
      alias: "/components/salesforce-library/visual-picker/accessibility",
      // SEO and Search
      tags: [
        "Component",
        "Salesforce Library",
        "Visual Picker",
        "Accessibility",
      ],
      meta: {
        title: "Accessibility - Visual Picker - Salesforce Library - Components",
        description: "",
        keywords: [],
      },
    },
    {
      label: "Code",
      mdx: Code,
      summary: <></>,
      alias: "/components/salesforce-library/visual-picker/code",
      // SEO and Search
      tags: [
        "Component",
        "Salesforce Library",
        "Visual Picker",
        "Code",
      ],
      meta: {
        title: "Code - Visual Picker - Salesforce Library - Components",
        description: "",
        keywords: [],
      },
    },*/
  ],
  // SEO and Search
  tags: [],
  meta: {
    title: "Visual Picker - Salesforce Library - Components",
    description: "",
    keywords: [],
  },

  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "",
    },
    react: {
      status: "",
      version: "v1.0.0",
      link: "",
    },
    angular: {
      status: "na",
      version: "v1.0.0",
      link: "",
    },
    figma: {
      status: "released",
      version: "",
      link: "https://www.figma.com/file/mQVIM0Qm8vUTmJ10jRv31P/Salesforce-UI-Kit-V1.1?type=design&mode=design&t=GNGnfvt48eX5pPoc-0 ",
    },
  },
};

export default VisualPickerPage;
