import React from 'react';
import PropTypes from 'prop-types';
import TokenExample from '../TokenExample/TokenExample';
import ButtonInlineCode from '../../ButtonInlineCode/ButtonInlineCode';

const TokenRow = ({
  descMetaName,
  tokenName,
  value,
  aliasValue,
  color,
  status,
  release,
  tableType,
  tknCat,
  tknType,
  subType,
  subItem,
  tknName,
  platform,
  misc,
  tableHeadings
}) => {
  const codeType = (tableType === 'color') ? 'color' : 'code';
  const miscKeys = Object.keys(misc);
  const miscValues = Object.values(misc);
  const inlineCodeKeys = ['css rule', 'value', 'scss mixin'];
  const descriptiveHeadingKeys = ['description', 'element'];

  return (
    <tr className="cbdsws-c-tokensRow__grid">
      {(tableHeadings && tableHeadings.indexOf('Example') !== -1) && (
        <TokenExample
          tokenName={tokenName}
          value={value}
          aliasValue={aliasValue}
          color={color}
          status={status}
          release={release}
          tableType={tableType}
          tknCat={tknCat}
          tknType={tknType}
          subType={subType}
          subItem={subItem}
          tknName={tknName}
          platform={platform}
        />
      )}
      {(!!misc) && (
        /* eslint-disable no-console */
        console.log('Misc Keys & Values ', miscKeys, miscValues),
        <>
          {miscKeys.map((item, index) => {
            // empty value
            if (!miscValues[index]) {
              return <td className="cbdsws-c-tokensTable__blank" key={index}></td>;
            }

            // heading (custom or default)
            if (descriptiveHeadingKeys.indexOf(tableHeadings[index].toLowerCase()) !== -1) {
              if (tableHeadings[index].toLowerCase() === 'element') {
                return (
                  <td key={index} className="cbdsws-c-tokensTable__element" data-cell="Element">
                    <p className="cbdsws-c-tokensTable__descMetaName">{(misc && misc.element) ? misc.element : descMetaName}</p>
                  </td>
                );
              }
            }

            if (item.toLowerCase() === tableHeadings[index].toLowerCase()) {

              // Set data-cell values on mobile (responsive table row labels) based on table heading value
              if (tableHeadings[index].toLowerCase() === 'name') {
                return (
                  <td key={index} className="cbdsws-c-tokensTable__tokenName" data-cell="Name">
                    <ButtonInlineCode codeSnippet={miscValues[index]} type="text" />
                  </td>
                );
              } else if (tableHeadings[index].toLowerCase() === 'css rule') {
                return (
                  <td key={index} className="cbdsws-c-tokensTable__tokenName" data-cell="CSS Rule">
                    <ButtonInlineCode codeSnippet={miscValues[index]} type="text" />
                  </td>
                );
              } else if (tableHeadings[index].toLowerCase() === 'token') {
                return (
                  <td key={index} className="cbdsws-c-tokensTable__tokenName" data-cell="Token">
                    <ButtonInlineCode codeSnippet={miscValues[index]} type="text" />
                  </td>
                );
              } else if (tableHeadings[index].toLowerCase() === 'value') {
                return (
                  <td key={index} className="cbdsws-c-tokensTable__tokenName" data-cell="Value">
                    <ButtonInlineCode codeSnippet={miscValues[index]} type="text" />
                  </td>
                );
              } else if (tableHeadings[index].toLowerCase() === 'scss mixin') {
                return (
                  <td key={index} className="cbdsws-c-tokensTable__tokenName" data-cell="SCSS Mixin">
                    <ButtonInlineCode codeSnippet={miscValues[index]} type="text" />
                  </td>
                );
              }
              // END Set data-cell values on mobile


              // code snippet
              if (inlineCodeKeys.indexOf(tableHeadings[index].toLowerCase()) !== -1) {
                return (
                  <td key={index} className="cbdsws-c-tokensTable__value" data-use-inline-copy-button="true">
                    <div className="cbdsws-c-tokensTable__value">
                      <ButtonInlineCode codeSnippet={miscValues[index]} type={codeType} />
                    </div>
                  </td>
                );
              }

              // text
              return <td key={index}>{miscValues[index]}</td>;
            }

            return <td key={index}>{miscValues[index]}</td>;
          })}
        </>
      )}

      {(!misc) && (
        <>
          <td className="cbdsws-c-tokensTable__description" data-cell="Description">
            <p className="cbdsws-c-tokensTable__descMetaName">{descMetaName}</p>
          </td>
          <td className="cbdsws-c-tokensTable__tokenName" data-cell="Token">
            <ButtonInlineCode codeSnippet={tokenName} type="token" />
          </td>
          <td className="cbdsws-c-tokensTable__value" data-use-inline-copy-button="true" data-cell="Value">
            <div className="cbdsws-c-tokensTable__value">
              <ButtonInlineCode codeSnippet={value} type={codeType} />
            </div>
            {(aliasValue && aliasValue !== value)
              ? <div className="cbdsws-c-tokensTable__aliasValue">{aliasValue}</div>
              : null
            }
          </td>
        </>
      )}
    </tr>
  );
};

TokenRow.propTypes = {
  example: PropTypes.string,
  descMetaName: PropTypes.string.isRequired,
  tokenName: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  aliasValue: PropTypes.string,
  color: PropTypes.string,
  status: PropTypes.string,
  release: PropTypes.string,
  tableType: PropTypes.string,
  tknCat: PropTypes.string,
  tknType: PropTypes.string,
  tknName: PropTypes.string,
  platform: PropTypes.string,
};

TokenRow.defaultProps = {
  example: null,
  descMetaName: "Description row",
  tokenName: "Token row",
  value: "Value row",
};

export default TokenRow;
