// import React from 'react';
// import Link from '../../../components/Link';
import CreateAPin from './CreateAPin.mdx';

const CreateAPinPage = {
  mdx: CreateAPin,
  breadcrumbs: "Patterns/Fields",
  alias: "patterns/fields/create-a-pin",
  pathExact: false,
  title: "Create a PIN",
  menuLabel: 'Create a PIN',
  published: true,
  order: 2,
  date: '2025-02-13', // Show when last updated
  summary: "",

  // SEO and Search
  tags: [
    "Form",
    "PIN",
    "Field",
    "Patterns",
    "Component",
    "Input",
    "Create",
    "Match",
  ],
  meta: {
    description: '',
    title: 'Create a PIN - Fields - Patterns',
    keywords: [],
  },
  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "",
    },
    react: {
      status: "na",
      version: "v1.0.0",
      link: "",
    },
    angular: {
      status: "released",
      version: "v1.0.0",
      link: "/?path=/story/patterns-forms-create-a-pin--default",
    },
    figma: {
      status: "released",
      version: "",
      link: "https://www.figma.com/design/1mBwaeZVlOxKn2rc9hQH4N/Ross-Work-2025?node-id=210-14333&m=dev",
    },
  },
};

export default CreateAPinPage;
