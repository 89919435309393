import React from 'react';
import EnterpriseLogos from './EnterpriseLogos.mdx';

const EnterpriseLogosPage = {
  mdx: EnterpriseLogos,
  breadcrumbs: "Brand Guidelines/Enterprise Guidelines",
  alias: "brand-guidelines/enterprise-guidelines/logos",
  pathExact: false,
  title: "Logos",
  menuLabel: 'Logos',
  published: true,
  order: 10,
  date: '2023-01-27', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Enterprise',
    'Guidelines',
    'Logos',
    'architecture ',
    'Daisy',
    'Wheel',
    'Exclusion',
    'Zone',
  ],
  meta: {
    title: 'Logos - Enterprise - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default EnterpriseLogosPage;
