import React, { useEffect } from 'react';
import CardGrid from '../components/CardGrid/CardGrid';
// import Card from '../components/Card/Card';
import * as Data from '../constants/data';
import { CbdsButton } from '@cbds/cbds-components-react';
// import Header from '../components/Header';
import _setPageTitle from '../_helpers/setPageTitle';

const HomePage = () => {

  useEffect(() => {
    var tempADObj = { alias:'home' }; //Analytics Object
    _setPageTitle('Home', tempADObj);
    document.getElementById("targetPageTitle").innerHTML = `Home | Citizens Design System (v${process.env.VERSION})`;
  });

  return (
    <>

      <div className="cbdsws-c-hero">
        <div className="cbdsws-c-hero__inner">
          <h1 className="cbdsws-c-hero__heading">
            Let's Build Together!
          </h1>
          <p className="cbdsws-c-hero__intro">The Citizens Design System is here to help you create clean and accessible interfaces for Citizens digital experiences.</p>
          <div className="cbdsws-c-hero__actions">
            <CbdsButton
              as="a"
              classes="cbdsws-c-hero__cta cbdsws-c-whatsHappening__btnViewAll cbensightenevent"
              url="" // Ross will get links on Monday
              label="Get Started: Developers"
              variant='text'
              color='primary'
              iconNameRight='arrow-right'
              globalAttrs={{
                'cbdata-type': 'link',
                'cbdata-reason': '__link'
              }}
            />
            <CbdsButton
              as="a"
              classes="cbdsws-c-hero__cta cbdsws-c-whatsHappening__btnViewAll cbensightenevent"
              url="" // Ross will get links on Monday
              label="Get Started: Designers"
              variant='text'
              color='primary'
              iconNameRight='arrow-right'
              globalAttrs={{
                'cbdata-type': 'link',
                'cbdata-reason': '__link'
              }}
            />
          </div>
        </div>
      </div>

      <div className="cbdsws-c-exploreDigitalFoundations">
        <p>Feel free to poke around and check out what the design system has to assist you.</p>
        <CardGrid
          cardData={
            {
              dataEntries: {
                card: {
                  heading: "Color",
                  description: "Brand approved palette and guidelines encourage accessible, meaningful, and consistent application of color.",
                  url: '/foundations/color',
                  media: {
                    type: 'illustration',
                    srcUrl: 'assets/images/illustrations/home/home_color_card.png'
                  }
                },
                card2: {
                  heading: "Design Tokens",
                  description: "Build and sync visual design attributes consistently across platforms using tokens.",
                  url: '/foundations/tokens/overview',
                  media: {
                    type: 'illustration',
                    srcUrl: 'assets/images/illustrations/home/home_token_card.png'
                  }
                },
                card3: {
                  description: 'Establish clear, efficient and inclusive content and design hierarchy using our global type system.',
                  heading: 'Typography',
                  url: '/foundations/typography',
                  media: {
                    type: 'illustration',
                    srcUrl: 'assets/images/illustrations/home/home_typography_card.png'
                  }
                }
              }
            }
          }
          limitNumItems={3}
        />
      </div>

      <div className="cbdsws-c-latestNewsReleases">
        <h2>Latest News and Releases</h2>
        <p>Read about the latest news and releases from the Design System here!</p>
        <CardGrid
          cardData={Data.NEWSLETTER_DATA}
          limitNumItems={3}
        />
        <CbdsButton
          as="a"
          classes="cbdsws-c-whatsHappening__btnViewAll cbensightenevent"
          url="/#/about/newsletter"
          label="View All Newsletters"
          variant='contained'
          iconNameRight='arrow-right'
          globalAttrs={{
            'cbdata-type': 'link',
            'cbdata-reason': '__link'
          }}
        />
      </div>
      <div className="cbdsws-c-latestNewsReleases">
        <h2>Changelogs</h2>
        <CardGrid
          cardData={Data.RELEASE_NOTES_DATA}
          limitNumItems={3}
        />
        <br/>
        <CbdsButton
          as="a"
          classNames="cbdsws-c-whatsHappening__btnViewAll cbensightenevent"
          url="/#/about/changelog"
          label="View All Changelogs"
          variant="contained"
          iconNameRight="arrow-right"
          globalAttrs={{
            'cbdata-type': 'link',
            'cbdata-reason': '__link'
          }}
        />
      </div>
    </>
  );
};

export default HomePage;
