import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ButtonInlineCode from "../ButtonInlineCode";

import '../../assets/images/icons/pass.svg';
import '../../assets/images/icons/fail.svg';

const ColorCardYaml = ({
  classes,
  layout,
  displayType,
  wcagBlackAA,
  wcagBlackAALarge,
  wcagWhiteAA,
  wcagWhiteAALarge,
  name,
  hex,
  rgb,
  pms,
  cmyk,
  showTokenSCSS,
  scss,
  showTokenNative,
  ios,
  android,
  sketch,
  showPrint,
}) => {

  const pass = <img src="../../assets/images/icons/pass.svg" alt="pass" />;
  const fail = <img src="../../assets/images/icons/fail.svg" alt="fail" />;
  const layoutClass = layout ? `cbdsws-c-colorCard--${layout}` : null;
  const typeClass = displayType ? `cbdsws-c-colorCard--${displayType}` : null;
  showPrint = displayType === "tintStack" ? false : showPrint;
  const showSketch = displayType === "tintStack" ? false : sketch ? true : false;
  const showWCAG = displayType === "tintStack" ? false : true;
  const blackAAScore = wcagBlackAA === "true" ? pass : fail;
  const blackAALargeScore = wcagBlackAALarge === "true" ? pass : fail;
  const whiteAAScore = wcagWhiteAA === "true" ? pass : fail;
  const whiteAALargeScore = wcagWhiteAALarge === "true" ? pass : fail;


  return (
    <div
      className={classNames(
        "cbdsws-c-colorCard",
        typeClass,
        classes,
        layoutClass
      )}
    >
      {/* Color Chip */}
      <div
        className="cbdsws-c-colorCard__color"
        style={{ backgroundColor: hex }}>
      </div>

      {/* Card Body */}
      <div className="cbdsws-c-colorCard__body">
        <h4 className="cbdsws-c-colorCard__title">{name}</h4>
        <ul className="cbdsws-c-colorCard__meta">

          {hex && (
            <li className="cbdsws-c-colorCard__metaItem cbdsws-c-colorCard__metaItem--hex">
              Hex: <ButtonInlineCode codeSnippet={hex} type="color" />
            </li>
          )}
          {rgb && (
            <li className="cbdsws-c-colorCard__metaItem cbdsws-c-colorCard__metaItem--rgb">
              RGB: <ButtonInlineCode codeSnippet={rgb} type="color" />
            </li>
          )}
          {showPrint && (
            <>
              <li className="cbdsws-c-colorCard__metaItem cbdsws-c-colorCard__metaItem--cmyk">
                CMYK:{" "}
                {cmyk && <ButtonInlineCode codeSnippet={cmyk} type="color" />}
              </li>
              {pms && (
                <li className="cbdsws-c-colorCard__metaItem cbdsws-c-colorCard__metaItem--pms">
                  PMS:{" "}
                  {pms && <ButtonInlineCode codeSnippet={pms} type="color" />}
                </li>
              )}
            </>
          )}
          {showTokenSCSS && (
            <li className="cbdsws-c-colorCard__metaItem cbdsws-c-colorCard__metaItem--scss">
              Scss:{" "}
              {scss && <ButtonInlineCode codeSnippet={scss} type="token" />}
            </li>
          )}
          {showTokenNative && (
            <>
              <li className="cbdsws-c-colorCard__metaItem cbdsws-c-colorCard__metaItem--ios">
                iOS:{" "}
                {ios && <ButtonInlineCode codeSnippet={ios} type="color" />}
              </li>
              <li className="cbdsws-c-colorCard__metaItem cbdsws-c-colorCard__metaItem--android">
                Android:{" "}
                {android && (
                  <ButtonInlineCode codeSnippet={android} type="color" />
                )}
              </li>
            </>
          )}
          {showSketch && sketch && (
            <li className="cbdsws-c-colorCard__metaItem cbdsws-c-colorCard__metaItem--sketch">
              Sketch: {sketch}
            </li>
          )}
        </ul>
      </div>

      {/* Accessibility Scores */}
      {showWCAG && (
        <div className="cbdsws-c-colorCard__body__status">

          <div className="cbdsws-c-colorCard__table">
            <table className='cbdsws-c-colorCard__contrast'>
              <caption className="cbds-u-screenReaderOnly">Color Contrast: {name} over Black</caption>
              <thead>
                <tr>
                  <th scope="col">
                    <span
                      style={{ backgroundColor: hex }}
                      className="cbdsws-c-colorCardAda__level--black">
                      Black
                    </span>
                  </th>
                  <th scope="col">
                    <span>Contrast</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-cell="Black">
                    <span>Normal Text</span>
                  </td>
                  <td data-cell='Contrast'>
                    <span>{blackAAScore}</span>
                  </td>
                </tr>
                <tr>
                  <td data-cell="Black">
                    <span>
                      Large Text
                    </span></td>
                  <td data-cell="Contrast">
                    <span>{blackAALargeScore}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="cbdsws-c-colorCard__table">
            <table className='cbdsws-c-colorCard__contrast'>
              <caption className="cbds-u-screenReaderOnly">Color Contrast: {name} over white</caption>
              <thead>
                <tr>
                  <th scope="col">
                    <span
                      style={{ backgroundColor: hex }}
                      className="cbdsws-c-colorCardAda__level--white">
                      White
                    </span>
                  </th>
                  <th scope="col">
                    <span>Contrast</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-cell="White">
                    <span>Normal Text</span></td>
                  <td data-cell='Contrast'>
                    <span>{whiteAAScore}</span>
                  </td>
                </tr>
                <tr>
                  <td data-cell="White">
                    <span>Large Text</span>
                  </td>
                  <td data-cell="Contrast">
                    <span>{whiteAALargeScore}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

ColorCardYaml.propTypes = {
  showTokenSCSS: PropTypes.bool,
  showPrint: PropTypes.bool,
};

ColorCardYaml.defaultProps = {
  heading: "h5",
  showTokenSCSS: true,
  showPrint: true,
};

export default ColorCardYaml;
