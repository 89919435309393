import React from 'react';
import HowDoWeShowUp from './HowDoWeShowUp.mdx';

const HowDoWeShowUpPage = {
  mdx: HowDoWeShowUp,
  breadcrumbs: "Brand Guidelines/Consumer Guidelines",
  alias: "brand-guidelines/consumer-guidelines/how-do-we-show-up",
  pathExact: false,
  title: "How do we show up",
  menuLabel: 'How Do We Show Up',
  published: true,
  order: 11,
  date: '2023-02-01', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Consumer',
    'Guidelines',
    'How do we show up',
    'Banners',
    'Emails',
    'Homepage',
  ],
  meta: {
    title: 'How Do We Show Up - Consumer - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default HowDoWeShowUpPage;
