// import React from 'react';
// import Link from '../../../components/Link';
import SocialSecurityNumber from './SocialSecurityNumber.mdx';

const SocialSecurityNumberPage = {
  mdx: SocialSecurityNumber,
  breadcrumbs: "Patterns/Fields",
  alias: "patterns/fields/social-security-number",
  pathExact: false,
  title: "Social Security Number",
  menuLabel: 'Social Security Number',
  published: true,
  order: 2,
  date: '2024-03-15', // Show when last updated
  summary: "",

  // SEO and Search
  tags: [
    "Form",
    "Social Security Number",
    "Field",
    "Patterns",
    "Component",
    "Input",
    "social",
    "security",
    "number",
    "ssn",
  ],
  meta: {
    description: '',
    title: 'Social Security Number - Fields - Patterns',
    keywords: [],
  },
  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "",
    },
    react: {
      status: "na",
      version: "v1.0.0",
      link: "",
    },
    angular: {
      status: "released",
      version: "v1.0.0",
      link: "/?path=/story/patterns-forms-social-security-number--default",
    },
    figma: {
      status: "released",
      version: "",
      link: "https://www.figma.com/file/mj41OHGCokcJ9N7D0q8D9Q/CBDS-UI-Kit-V1.1?node-id=1633%3A6407&t=c0P3NDyo4OqUOeuw-1",
    },
  },
};

export default SocialSecurityNumberPage;
