import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop({ children, appRef }) {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (pathname != "/contact" && !hash) document.getElementById('cbdsws-contentContainer').scrollTo(0, 0);
    appRef.current.focus();
  }, [pathname]);

  return (
    <>
      {children}
    </>
  );
}

export default ScrollToTop;
