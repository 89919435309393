import React from 'react';
import FourPrincipleDesignApproach from './FourPrincipleDesignApproach.mdx';

const FourPrincipleDesignApproachPage = {
  mdx: FourPrincipleDesignApproach,
  breadcrumbs: "Brand Guidelines/Wealth Management Guidelines",
  alias: "brand-guidelines/wealth-management-guidelines/four-principle-design-approach",
  pathExact: false,
  title: "Four Principle Design Approach",
  menuLabel: 'Four Principle Design Approach',
  published: true,
  order: 1,
  date: '2023-12-01', // Show when last updated
  summary: <>There are four key guiding principles to create a uniquely ownable design system for the Wealth portfolio, thus setting it apart from our competition. These principles don’t just refer to a specific element such as the imagery or typeface alone; they should be used to look across the entire body of work, from content (language, photography) to layout (colors, typeface, etc):</>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Wealth Management',
    'Guidelines',
    'Wealth Management Guidelines',
    'Four Principle Design Approach',
  ],
  meta: {
    title: 'Four Principle Design Approach - Wealth Management - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default FourPrincipleDesignApproachPage;
