import React from 'react';
import Cbds2022Cycle06Newsletter from './Cbds2022Cycle06Newsletter.mdx';

const Cbds2022Cycle06NewsletterPage = {
  mdx: Cbds2022Cycle06Newsletter,
  breadcrumbs: "About/Newsletter",
  alias: "about/newsletters/cbds-2022-cycle06-newsletter",
  pathExact: false,
  title: "CBDS 2022 Cycle 06 Newsletter",
  menuLabel: 'Cbds2022Cycle06Newsletter',
  published: true,
  hideFromNav: true,
  //order: 3,
  date: '2023-02-16', // Show when last updated
  summary: <><strong><em>Timeline:</em></strong> <em>October 12, 2022 &rarr; December 06, 2022<br/></em><br/>Ned Stark has been bugging you for years about it and wouldn't you have guessed; Winter has come. <span role="img" aria-label="snowflake">❄</span><span role="img" aria-label="ice cube">🧊</span> Cycle 06 brings new components, code library releases, documentation and more. Don't fret, as with the winds of winter, a dream of spring is right around the corner. There's a lot of exciting work in progress and on the horizon. IN this cycle we focused on 3 main areas, including:</>,

  // SEO and Search
  tags: [
    'CBDS',
    'About',
    'Newsletter',
    'Updates',
    'Cycle CBDS 2022 Cycle 06 Newsletter',
    'Release Notes',
  ],
  meta: {
    description: '',
    title: 'CBDS 2022 Cycle 06 Newsletter - Newsletters - About',
    keywords: [],
  },
};

export default Cbds2022Cycle06NewsletterPage;
