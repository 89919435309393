import React from "react";
import ProgressIndicator from "./ProgressIndicator.mdx";
import Guidelines from "./Guidelines.mdx";
import Accessibility from "./Accessibility.mdx";

const ProgressIndicatorPage = {
  mdx: ProgressIndicator,
  breadcrumbs: "Components/Mobile UI Library",
  alias: "components/mobile-ui-library/progress-indicator",
  pathExact: false,
  title: "Progress Indicator",
  menuLabel: "Progress Indicator",
  published: true,
  date: "2024-05-10", // Show when last updated
  summary: "",
  tabs: [
    {
      label: "Guidelines",
      mdx: Guidelines,
      summary: "Progress indicators let users know the app is doing something.",
      alias: "/components/mobile-ui-library/progress-indicator/guidelines",
      // SEO and Search
      tags: [
        "Component",
        "Mobile UI Library",
        "Progress Indicator",
        "Design",
        "Guidelines",
      ],
      meta: {
        title: "Guidelines - Progress Indicator - Mobile UI Library - Components",
        description: "",
        keywords: [],
      },
    },
    {
      label: "Accessibility",
      mdx: Accessibility,
      summary: <></>,
      alias: "/components/mobile-ui-library/progress-indicator/accessibility",
      // SEO and Search
      tags: [
        "Component",
        "Mobile UI Library",
        "Progress Indicator",
        "Accessibility",
      ],
      meta: {
        title: "Accessibility - Progress Indicator - Mobile UI Library - Components",
        description: "",
        keywords: [],
      },
    },
    /*{
      label: "Code",
      mdx: Code,
      summary: <></>,
      alias: "/components/mobile-ui-library/progress-indicator/code",
      // SEO and Search
      tags: [
        "Component",
        "Mobile UI Library",
        "Progress Indicator",
        "Code",
      ],
      meta: {
        title: "Code - Progress Indicator - Mobile UI Library - Components",
        description: "",
        keywords: [],
      },
    },*/
  ],
  // SEO and Search
  tags: [],
  meta: {
    title: "Progress Indicator - Mobile UI Library - Components",
    description: "",
    keywords: [],
  },

  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "",
    },
    react: {
      status: "",
      version: "v1.0.0",
      link: "",
    },
    angular: {
      status: "na",
      version: "v1.0.0",
      link: "",
    },
    figma: {
      status: "released",
      version: "",
      link: "https://www.figma.com/file/Ps4nHYn45cAUlknFyS13Wl/OneApp-UI-Kit-V1.1?type=design&node-id=167%3A1057&mode=design&t=6ckAfdgkElGr5Cie-1",
    },
  },
};

export default ProgressIndicatorPage;
