import Changelog from './Changelog.mdx';

const ChangelogPage = {
  mdx: Changelog,
  breadcrumbs: "About",
  alias: "about/changelog",
  pathExact: false,
  title: "Changelogs",
  menuLabel: 'Changelogs',
  published: true,
  order: 3,
  date: '2024-01-24', // Show when last updated
  summary: "Learn about the latest components, patterns, tokens and styles with our CDS code library changelogs!",

  // SEO and Search
  tags: [
    'CBDS',
    'About',
    'Changelogs',
    'Updates',
    'Cycle Changelogs',
    'Release Notes',
  ],
  meta: {
    description: '',
    title: 'Changelogs - About',
    keywords: [],
  },
};

export default ChangelogPage;
