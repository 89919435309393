import React from "react";
import ListItem from "./ListItem.mdx";
import Guidelines from "./Guidelines.mdx";
import Accessibility from "./Accessibility.mdx";
// import Code from "./Code.mdx";

const ListItemPage = {
  mdx: ListItem,
  breadcrumbs: "Components/Mobile UI Library",
  alias: "components/mobile-ui-library/list-item",
  pathExact: false,
  title: "List Item",
  menuLabel: "List Item",
  published: true,
  date: "2023-12-08", // Show when last updated
  summary: "",
  tabs: [
    {
      label: "Guidelines",
      mdx: Guidelines,
      summary: "List items contain vertical lines of text about a single subject.",
      alias: "/components/mobile-ui-library/list-item/guidelines",
      // SEO and Search
      tags: [
        "Component",
        "Mobile UI Library",
        "List Item",
        "Design",
        "Guidelines",
      ],
      meta: {
        title: "Guidelines - List Item - Mobile UI Library - Components",
        description: "",
        keywords: [],
      },
    },
    {
      label: "Accessibility",
      mdx: Accessibility,
      summary: <></>,
      alias: "/components/mobile-ui-library/list-item/accessibility",
      // SEO and Search
      tags: [
        "Component",
        "Mobile UI Library",
        "List Item",
        "Accessibility",
      ],
      meta: {
        title: "Accessibility - List Item - Mobile UI Library - Components",
        description: "",
        keywords: [],
      },
    },
    /*{
      label: "Code",
      mdx: Code,
      summary: <></>,
      alias: "/components/mobile-ui-library/list-item/code",
      // SEO and Search
      tags: [
        "Component",
        "Mobile UI Library",
        "List Item",
        "Code",
      ],
      meta: {
        title: "Code - List Item - Mobile UI Library - Components",
        description: "",
        keywords: [],
      },
    },*/
  ],
  // SEO and Search
  tags: [],
  meta: {
    title: "List Item - Mobile UI Library - Components",
    description: "",
    keywords: [],
  },

  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "",
    },
    react: {
      status: "",
      version: "v1.0.0",
      link: "",
    },
    angular: {
      status: "na",
      version: "v1.0.0",
      link: "",
    },
    figma: {
      status: "released",
      version: "",
      link: "https://www.figma.com/file/Ps4nHYn45cAUlknFyS13Wl/OneApp-UI-Kit-V1.1?type=design&node-id=167%3A1057&mode=design&t=6ckAfdgkElGr5Cie-1",
    },
  },
};

export default ListItemPage;
