import Web from "./Web.mdx";

const WebPage = {
  mdx: Web,
  breadcrumbs: "Accessibility/Guidelines",
  alias: "accessibility/guidelines/web",
  pathExact: false,
  title: "Web",
  menuLabel: "Web",
  published: true,
  order: 3,
  date: "2024-09-13", // Show when last updated
  summary: "",

  // SEO and Search
  tags: [
    'Accessibility',
    'A11Y',
    'Guidelines',
    'Web',
  ],
  meta: {
    title: "Web - Guidelines - Accessibility",
    description: "",
    keywords: [],
  },
};

export default WebPage;
