import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ButtonInlineCode from '../ButtonInlineCode';

const FontCard = ({ family, style, weight, label, sample, showSample, showSnippets, codeSample, token }) => {
  const fontWeight = { fontWeight: weight };
  const familyClass = `cbdsws-c-fontCard--${family}`;
  const styleClass = style ? `cbdsws-c-fontCard--${style}` : null;

  return (
    <>
      <div className={classNames("cbdsws-c-fontCard", familyClass, styleClass)}>

        <div className="cbdsws-c-fontCard__example">
          <div className="cbdsws-c-fontCard__textWrap">

            <div className="cbdsws-c-fontCard__text" style={fontWeight} aria-hidden="true">
                ABCDEFGHIJKLMNOPQRSTUVWXYZ<br />abcdefghijklmnopqrstuvwxyz<br />1234567890
            </div>

          </div>

          <div className="cbdsws-c-fontCard__spec">
            <span className="cbdsws-c-fontCard__label">{label}</span>
            <div className="cbdsws-c-fontCard__largeSample" style={fontWeight} aria-hidden="true">Aa</div>
          </div>
        </div>

        {showSample && (
          <div className="cbdsws-c-fontCard__textSample">
            <div className="cbdsws-c-fontCard__sampleText" style={fontWeight}>{sample}</div>
          </div>
        )}

        {showSnippets && (
          <div className="cbdsws-c-fontCard__codeSample">
            {codeSample && (
              <div>font-family: {codeSample}</div>
            )}
            {token && (
              <div>font-family:
                <ButtonInlineCode
                  type="token"
                  codeSnippet={token}/>
              </div>
            )}
          </div>
        )}

      </div>
    </>
  );
};

FontCard.propTypes = {
  family: PropTypes.string,
  style: PropTypes.string,
  weight: PropTypes.string,
  label: PropTypes.string,
  sample: PropTypes.string,
  showSample: PropTypes.bool,
  showSnippets: PropTypes.bool,
  codeSample: PropTypes.string,
  token: PropTypes.string,
};

FontCard.defaultProps = {
  family: "firaSans",
  weight: "400",
  label: "FiraSans",
  showSample: false,
  showSnippets: false,
  codeSample: "FiraSans, Arial, 'Helvetica Neue', Helvetica, sans-serif",
  token: "$cbds-tkn-fontFamily__firaSans",
};

export default FontCard;
