import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import DoDont from '../DoDont';

const DoDontGrid = ({
  customBlockClass,
  customClass,
  dodonts,
  layout,
  sectionLayout,
  sectionType,
}) => {

  let layoutClass = layout ? `cbdsws-c-doDontGrid__grid--${layout}` : "";
  let typeClass = sectionType ? `cbdsws-c-section--${sectionType}` : "";
  let sectionLayoutClass = sectionLayout ? `cbdsws-c-section--${sectionLayout}` : "";
  return (
    <div className={classNames("cbdsws-c-section", sectionLayoutClass, typeClass, customClass)}>
      <div className={classNames("cbdsws-c-section__block", customBlockClass)}>
        <div className="cbdsws-c-doDontGrid">
          <div className={classNames("cbdsws-c-doDontGrid__grid", layoutClass)}>
            {dodonts.map(( dodont, index) => (
              <div className="cbdsws-c-doDontGrid__gridItem" key={`DoDontContainer_${index}`} >
                <DoDont
                  key={`DoDont_${index}`}
                  bodyText={dodont.bodyText}
                  heading={dodont.heading}
                  items= {dodont.items}
                  imageSource={dodont.imageSource}
                  altText={dodont.altText}
                  type={dodont.type}
                  frameBgStyle={dodont.frameBgStyle}
                  frameInsetStyle={dodont.frameInsetStyle}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

DoDontGrid.propTypes = {
  dodonts: PropTypes.array,
  customBlockClass: PropTypes.string,
  customClass: PropTypes.string,
  layout: PropTypes.oneOf(["col1", "col2"]),
  sectionLayout: PropTypes.string,
  sectionType: PropTypes.string,
};

DoDontGrid.defaultProps = {
  layout: "col2",
  sectionType: "dodontGrid",
  dodonts: [
    {
      type: "do",
    },
    {
      type: "dont",
    }
  ],
};

export default DoDontGrid;
