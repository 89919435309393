import React from 'react';
import Photography from './Photography.mdx';

const PhotographyPage = {
  mdx: Photography,
  breadcrumbs: "Brand Guidelines/Wealth Management Guidelines",
  alias: "brand-guidelines/wealth-management-guidelines/photography",
  pathExact: false,
  title: "Photography",
  menuLabel: 'Photography',
  published: true,
  order: 1,
  date: '2023-12-01', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Wealth Management',
    'Guidelines',
    'Wealth Management Guidelines',
    'Photography',
  ],
  meta: {
    title: 'Photography - Wealth Management - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default PhotographyPage;
