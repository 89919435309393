import React from "react";
import Button from "./Button.mdx";
import Guidelines from "./Guidelines.mdx";
import Accessibility from "./Accessibility.mdx";
// import Code from "./Code.mdx";

const ButtonPage = {
  mdx: Button,
  breadcrumbs: "Components/Web Responsive Library",
  alias: "components/web-responsive-library/button",
  pathExact: false,
  title: "Button",
  menuLabel: "Button",
  published: true,
  date: "2023-04-27", // Show when last updated
  summary: "",

  tabs: [
    {
      label: "Guidelines",
      mdx: Guidelines,
      summary: "Buttons allow users to take actions and make choices with a single click or tap.",
      alias: "/components/web-responsive-library/button/guidelines",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Button",
        "Design",
        "Guidelines",
        "Documentation",
        "HTML",
        "React",
        "Angular",
      ],
      meta: {
        title: "Guidelines - Button - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    {
      label: "Accessibility",
      mdx: Accessibility,
      summary: <></>,
      alias: "/components/web-responsive-library/button/accessibility",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Button",
        "Accessibility",
        "Documentation",
        "HTML",
        "React",
        "Angular",
      ],
      meta: {
        title: "Accessibility - Button - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    /*{
      label: "Code",
      mdx: Code,
      summary: <></>,
      alias: "/components/web-responsive-library/button/code",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Button",
        "Developer",
        "Code",
        "HTML",
        "React",
        "Angular",
      ],
      meta: {
        title: "Code - Button - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },*/
  ],

  // SEO and Search
  tags: [],
  meta: {
    title: "Button - Web Responsive Library - Components",
    description: "",
    keywords: [],
  },

  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "",
    },
    react: {
      status: "released",
      version: "v1.0.0",
      link: "?path=/story/components-button--default",
    },
    angular: {
      status: "released",
      version: "v1.0.0",
      link: "index.html?path=/story/components-button--default",
    },
    figma: {
      status: "released",
      version: "",
      link: "https://www.figma.com/design/mj41OHGCokcJ9N7D0q8D9Q/CDS-UI-Kit-V1.1?node-id=1-52",
    },
  },
};

export default ButtonPage;
