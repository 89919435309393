import React from 'react';
import Audience from './Audience.mdx';

const AudiencePage = {
  mdx: Audience,
  breadcrumbs: "Brand Guidelines/Business Banking Guidelines",
  alias: "brand-guidelines/business-banking-guidelines/audience",
  pathExact: false,
  title: "Audience",
  menuLabel: 'Audience',
  published: true,
  order: 2,
  date: '2023-09-28', // Show when last updated
  summary: <>Small-business owners (SBOs) constantly seeking a sense of financial confidence
  and control in their business banking journey.</>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Business Banking',
    'Guidelines',
    'Business Banking Guidelines',
    'Audience'
  ],
  meta: {
    title: 'Audience - Business Banking - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default AudiencePage;
