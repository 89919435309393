import CbdsLogos from './CbdsLogos.mdx';

const CbdsLogosPage = {
  mdx: CbdsLogos,
  breadcrumbs: "About/Changelogs",
  alias: "about/changelogs/cbds-logos",
  pathExact: false,
  title: "CBDS Logos Changelog",
  menuLabel: 'CBDS Logos',
  published: true,
  order: 2,
  date: '2024-01-24', // Show when last updated
  summary: "",

  // SEO and Search
  tags: [
    'CBDS',
    'About',
    'CBDS Logos',
    'Changelog',
    'Release Notes'
  ],
  meta: {
    description: '',
    title: 'CBDS Logos - Changelogs - About',
    keywords: [],
  },
}; 
export default CbdsLogosPage;
