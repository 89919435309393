import Overview from "./Overview.mdx";

const OverviewPage = {
  mdx: Overview,
  breadcrumbs: "Accessibility",
  alias: "accessibility/overview",
  pathExact: false,
  title: "Overview",
  menuLabel: "Overview",
  published: true,
  order: 1,
  date: "2024-09-11", // Show when last updated
  summary: "",

  // SEO and Search
  tags: [
    'Accessibility',
    'A11Y',
    'Overview',
    'W3C',
    'WCAG',
    'Web Content Accessibility Guidelines',
    'POUR',
  ],
  meta: {
    title: "Overview - Accessibility",
    description: "",
    keywords: [],
  },
};

export default OverviewPage;
