//
// NOTE: Color Data updated as of 2022.01.14
//
// Color palette cards now HARD CODED like in Brand / Color page.
//
// The color palette cards aren't being dynamically created using the CBDS Design Tokens palette JSON data.
// This is due to Leadership/Design change to simplify the color palette documentation to "Happy Path".
// However, all the colors in the CBDS Design Tokens Color Palette still exist in the system and
// our components, etc. still utilize colors now not displayed on this page.
//
// In the future, we'll need to decide whether to remove all the colors from the CBDS Design Tokens library
// and components within CBDS Components library, or not.
//
// The updated documentation page reflects the teams updated color direction as a first step.
//


// ==========================================================================
// Tokens
// ==========================================================================

/* eslint-disable */
// Color Tokens - Brand Palette
import tokensColorBrandPalette__data from '@cbds/cbds-design-tokens/dist/json/system/color/color-brand-palette.json';
const tokensColorBrandPalette__category = 'color';
const tokensColorBrandPalette__type = 'brand';

// Color Tokens - Palette (Tint Stacks)
import tokensColorPalette__data from '@cbds/cbds-design-tokens/dist/json/system/color/color-palette.json';
const tokensColorPalette__category = 'color';
const tokensColorPalette__type = 'palette';

// Text Color Tokens
import tokensTextColor__data from '@cbds/cbds-design-tokens/dist/json/app/text/text-color.json';
const tokensTextColor__category = 'color';

// Colors Tokens - Illustration
import tokensColorIllustrationPalette__data from '../../../constants/color-illustration-palette.json';
const tokensColorIllustrationPalette__category = 'color';
const tokensColorIllustrationPalette__type = 'illustration';
/* eslint-enable */

// ==========================================================================
// Tokens Mapping
// ==========================================================================

// Primary Colors
const primaryGreen = tokensColorPalette__data.color.palette.green;
const primaryWhite = tokensColorPalette__data.color.palette.white;
const primaryBlack = tokensColorPalette__data.color.palette.black;
const primaryNeutral = tokensColorPalette__data.color.palette.neutral;
const primaryLimeGreen = tokensColorPalette__data.color.palette.limeGreen;
const primarySage = tokensColorPalette__data.color.palette.sage;


// Commercial colors
const commercialDeepGreen = tokensColorPalette__data.color.palette.deepGreen;


// Button Colors
const btnOrange = tokensColorPalette__data.color.palette.orange;
const btnBlue = tokensColorPalette__data.color.palette.blue;
const btnNeutral = tokensColorPalette__data.color.palette.neutral;

// Messaging Colors
const msgRed = tokensColorPalette__data.color.palette.red;
// const msgBlue = tokensColorPalette__data.color.palette.blue;
// const msgGreen = tokensColorPalette__data.color.palette.green;

// Text Colors
/* eslint-disable */
const textColor = tokensColorPalette__data.color.palette.neutral;
/* eslint-enable */

// Link Colors
/* eslint-disable */
const linkColor = tokensColorPalette__data.color.palette.blue;
/* eslint-enable */
const linkPurple = tokensColorPalette__data.color.palette.purple;

// Illustration Colors
const illustrationNeutral300 = tokensColorIllustrationPalette__data.color.palette.neutral;
const illustrationGold500 = tokensColorIllustrationPalette__data.color.palette.gold;
const illustrationGreen300 = tokensColorIllustrationPalette__data.color.palette.green;
const illustrationOrange500 = tokensColorIllustrationPalette__data.color.palette.orange;
const illustrationFadedBlue400 = tokensColorIllustrationPalette__data.color.palette.fadedBlue;
const illustrationBlue400 = tokensColorIllustrationPalette__data.color.palette.blue;


export default {
  colorBrand: [
    {
      meta: {
        // name: primaryGreen.base.meta.name
        name: 'Citizens Green',
      },
      value: primaryGreen.base.value,
      rgb: primaryGreen.base.rgb,
      name: primaryGreen.base.name,
      ios: primaryGreen.base.ios,
      android: primaryGreen.base.android,
      wcagBlack: {
        aa: 'false',
        aaLarge: primaryGreen.base.wcag.black.aaLarge,
        ratio: '4.48',
      },
      wcagWhite: {
        aa: primaryGreen.base.wcag.white.aa,
        aaLarge: primaryGreen.base.wcag.white.aaLarge,
        ratio: primaryGreen.base.wcag.white.ratio,
      },
    },
    {
      meta: {
        // name: commercialDeepGreen.base.meta.name
        name: 'Citizens Deep Green',
      },
      value: commercialDeepGreen.base.value,
      rgb: commercialDeepGreen.base.rgb,
      name: commercialDeepGreen.base.name,
      ios: commercialDeepGreen.base.ios,
      android: commercialDeepGreen.base.android,
      wcagBlack: {
        aa: 'false',
        aaLarge: 'false',
        ratio: '2.93',
      },
      wcagWhite: {
        aa: commercialDeepGreen.base.wcag.white.aa,
        aaLarge: commercialDeepGreen.base.wcag.white.aaLarge,
        ratio: commercialDeepGreen.base.wcag.white.ratio,
      },
    },
    {
      meta: {
        // name: primarySage.base.meta.name
        name: 'Citizens Sage',
      },
      value: primarySage.base.value,
      rgb: primarySage.base.rgb,
      name: primarySage.base.name,
      ios: primarySage.base.ios,
      android: primarySage.base.android,
      wcagBlack: {
        aa: 'true',
        aaLarge: 'true',
        ratio: '18.31',
      },
      wcagWhite: {
        aa: primarySage.base.wcag.white.aa,
        aaLarge: primarySage.base.wcag.white.aaLarge,
        ratio: primarySage.base.wcag.white.ratio,
      },
    },
    {
      meta: {
        // name: primaryLimeGreen.base.meta.name
        name: 'Citizens Lime Green',
      },
      value: primaryLimeGreen.base.value,
      rgb: primaryLimeGreen.base.rgb,
      name: primaryLimeGreen.base.name,
      ios: primaryLimeGreen.base.ios,
      android: primaryLimeGreen.base.android,
      wcagBlack: {
        aa: 'true',
        aaLarge: 'true',
        ratio: '15.85',
      },
      wcagWhite: {
        aa: primaryLimeGreen.base.wcag.white.aa,
        aaLarge: primaryLimeGreen.base.wcag.white.aaLarge,
        ratio: primaryLimeGreen.base.wcag.white.ratio,
      },
    }

  ],
  colorPrimaryGreen: [
    {
      meta: {
        name: primaryGreen.base.meta.name,
      },
      value: primaryGreen.base.value,
      rgb: primaryGreen.base.rgb,
      name: primaryGreen.base.name,
      ios: primaryGreen.base.ios,
      android: primaryGreen.base.android,
      wcagBlack: {
        aa: primaryGreen.base.wcag.black.aa,
        aaLarge: primaryGreen.base.wcag.black.aaLarge,
        ratio: primaryGreen.base.wcag.black.ratio,
      },
      wcagWhite: {
        aa: primaryGreen.base.wcag.white.aa,
        aaLarge: primaryGreen.base.wcag.white.aaLarge,
        ratio: primaryGreen.base.wcag.white.ratio,
      },
    },
  ],
  colorPrimaryGreenVariations: [
    {
      meta: {
        name: primaryGreen[900].meta.name,
      },
      value: primaryGreen[900].value,
      rgb: primaryGreen[900].rgb,
      name: primaryGreen[900].name,
      ios: primaryGreen[900].ios,
      android: primaryGreen[900].android,
      wcagBlack: {
        aa: 'false',
        aaLarge: 'false',
        ratio: '1.53',
      },
      wcagWhite: {
        aa: 'true',
        aaLarge: 'true',
        ratio: '13.7',
      },
    },
    {
      meta: {
        name: primaryGreen[700].meta.name,
      },
      value: primaryGreen[700].value,
      rgb: primaryGreen[700].rgb,
      name: primaryGreen[700].name,
      ios: primaryGreen[700].ios,
      android: primaryGreen[700].android,
      wcagBlack: {
        aa: 'false',
        aaLarge: 'true',
        ratio: '3.14',
      },
      wcagWhite: {
        aa: 'true',
        aaLarge: 'true',
        ratio: '6.67',
      },
    },
    {
      meta: {
        // name: primaryGreen.base.meta.name
        name: 'Citizens Green',
      },
      value: primaryGreen.base.value,
      rgb: primaryGreen.base.rgb,
      name: primaryGreen.base.name,
      ios: primaryGreen.base.ios,
      android: primaryGreen.base.android,
      wcagBlack: {
        aa: 'false',
        aaLarge: 'true',
        ratio: '4.48',
      },
      wcagWhite: {
        aa: primaryGreen.base.wcag.white.aa,
        aaLarge: primaryGreen.base.wcag.white.aaLarge,
        ratio: primaryGreen.base.wcag.white.ratio,
      },
    },
    {
      meta: {
        name: primaryGreen[500].meta.name,
      },
      value: primaryGreen[500].value,
      rgb: primaryGreen[500].rgb,
      name: primaryGreen[500].name,
      ios: primaryGreen[500].ios,
      android: primaryGreen[500].android,
      wcagBlack: {
        aa: 'true',
        aaLarge: 'true',
        ratio: '6.22',
      },
      wcagWhite: {
        aa: 'false',
        aaLarge: 'true',
        ratio: '3.37',
      },
    },
    {
      meta: {
        name: primaryGreen[300].meta.name,
      },
      value: primaryGreen[300].value,
      rgb: primaryGreen[300].rgb,
      name: primaryGreen[300].name,
      ios: primaryGreen[300].ios,
      android: primaryGreen[300].android,
      wcagBlack: {
        aa: 'true',
        aaLarge: 'true',
        ratio: '11.9',
      },
      wcagWhite: {
        aa: 'false',
        aaLarge: 'false',
        ratio: '1.76',
      },
    },
    {
      meta: {
        name: primaryGreen[100].meta.name,
      },
      value: primaryGreen[100].value,
      rgb: primaryGreen[100].rgb,
      name: primaryGreen[100].name,
      ios: primaryGreen[100].ios,
      android: primaryGreen[100].android,
      wcagBlack: {
        aa: 'true',
        aaLarge: 'true',
        ratio: '18.41',
      },
      wcagWhite: {
        aa: 'false',
        aaLarge: 'false',
        ratio: '1.14',
      },
    },
    {
      meta: {
        name: primaryGreen[40].meta.name,
      },
      value: primaryGreen[40].value,
      rgb: primaryGreen[40].rgb,
      name: primaryGreen[40].name,
      ios: primaryGreen[40].ios,
      android: primaryGreen[40].android,
      wcagBlack: {
        aa: 'true',
        aaLarge: 'true',
        ratio: '19.91',
      },
      wcagWhite: {
        aa: 'false',
        aaLarge: 'false',
        ratio: '1.05',
      },
    },
  ],
  colorPrimaryWhite: [
    {
      meta: {
        // name: primaryWhite.meta.name
        name: 'Citizens White',
      },
      value: primaryWhite.value,
      rgb: primaryWhite.rgb,
      name: primaryWhite.name,
      ios: primaryWhite.ios,
      android: primaryWhite.android,
      wcagBlack: {
        aa: primaryWhite.wcag.black.aa,
        aaLarge: primaryWhite.wcag.black.aaLarge,
        ratio: primaryWhite.wcag.black.ratio,
      },
      wcagWhite: {
        aa: primaryWhite.wcag.white.aa,
        aaLarge: primaryWhite.wcag.white.aaLarge,
        ratio: primaryWhite.wcag.white.ratio,
      },
    },
  ],
  colorPrimaryBlack: [
    {
      meta: {
        // name: primaryBlack.meta.name
        name: 'Citizens Black (Neutral)',
      },
      value: primaryBlack.value,
      rgb: primaryBlack.rgb,
      name: primaryBlack.name,
      ios: primaryBlack.ios,
      android: primaryBlack.android,
      wcagBlack: {
        aa: primaryBlack.wcag.black.aa,
        aaLarge: primaryBlack.wcag.black.aaLarge,
        ratio: primaryBlack.wcag.black.ratio,
      },
      wcagWhite: {
        aa: primaryBlack.wcag.white.aa,
        aaLarge: primaryBlack.wcag.white.aaLarge,
        ratio: primaryBlack.wcag.white.ratio,
      },
    },
  ],
  colorPrimaryBlackVariations: [
    {
      meta: {
        // name: primaryBlack.meta.name
        name: 'Citizens Black (Neutral)',
      },
      value: primaryNeutral.base.value,
      rgb: primaryNeutral.base.rgb,
      name: primaryNeutral.base.name,
      ios: primaryNeutral.base.ios,
      android: primaryNeutral.base.android,
      wcagBlack: {
        aa: 'false',
        aaLarge: 'false',
        ratio: '1.28',
      },
      wcagWhite: {
        aa: primaryNeutral.base.wcag.white.aa,
        aaLarge: primaryNeutral.base.wcag.white.aaLarge,
        ratio: primaryNeutral.base.wcag.white.ratio,
      },
    },
    {
      meta: {
        name: primaryNeutral[900].meta.name,
      },
      value: primaryNeutral[900].value,
      rgb: primaryNeutral[900].rgb,
      name: primaryNeutral[900].name,
      ios: primaryNeutral[900].ios,
      android: primaryNeutral[900].android,
      wcagBlack: {
        aa: 'false',
        aaLarge: 'false',
        ratio: '1.72',
        // Hard coding WCAG - CBDS Design Tokens currently doesn't have this information.
        // aa: primaryNeutral[900].wcag.black.aa,
        // aaLarge: primaryNeutral[900].wcag.black.aaLarge,
        // ratio: primaryNeutral[900].wcag.black.ratio,
      },
      wcagWhite: {
        aa: 'true',
        aaLarge: 'true',
        ratio: '12.18',
        // Hard coding WCAG - CBDS Design Tokens currently doesn't have this information.
        // aa: primaryNeutral[900].wcag.white.aa,
        // aaLarge: primaryNeutral[900].wcag.white.aaLarge,
        // ratio: primaryNeutral[900].wcag.white.ratio,
      },
    },
    {
      meta: {
        name: primaryNeutral[700].meta.name,
      },
      value: primaryNeutral[700].value,
      rgb: primaryNeutral[700].rgb,
      name: primaryNeutral[700].name,
      ios: primaryNeutral[700].ios,
      android: primaryNeutral[700].android,
      wcagBlack: {
        aa: 'false',
        aaLarge: 'true',
        ratio: '3.45',
        // Hard coding WCAG - CBDS Design Tokens currently doesn't have this information.
        // aa: primaryNeutral[700].wcag.black.aa,
        // aaLarge: primaryNeutral[700].wcag.black.aaLarge,
        // ratio: primaryNeutral[700].wcag.black.ratio,
      },
      wcagWhite: {
        aa: 'true',
        aaLarge: 'true',
        ratio: '6.07',
        // Hard coding WCAG - CBDS Design Tokens currently doesn't have this information.
        // aa: primaryNeutral[700].wcag.white.aa,
        // aaLarge: primaryNeutral[700].wcag.white.aaLarge,
        // ratio: primaryNeutral[700].wcag.white.ratio,
      },
    },
    {
      meta: {
        name: primaryNeutral[500].meta.name,
      },
      value: primaryNeutral[500].value,
      rgb: primaryNeutral[500].rgb,
      name: primaryNeutral[500].name,
      ios: primaryNeutral[500].ios,
      android: primaryNeutral[500].android,
      wcagBlack: {
        aa: 'true',
        aaLarge: 'true',
        ratio: '5.78',
      },
      wcagWhite: {
        aa: 'false',
        aaLarge: 'true',
        ratio: '3.63',
      },
    },
    {
      meta: {
        name: primaryNeutral[300].meta.name,
      },
      value: primaryNeutral[300].value,
      rgb: primaryNeutral[300].rgb,
      name: primaryNeutral[300].name,
      ios: primaryNeutral[300].ios,
      android: primaryNeutral[300].android,
      wcagBlack: {
        aa: 'true',
        aaLarge: 'true',
        ratio: '11.32',
      },
      wcagWhite: {
        aa: 'false',
        aaLarge: 'false',
        ratio: '1.85',
      },
    },
    {
      meta: {
        name: primaryNeutral[100].meta.name,
      },
      value: primaryNeutral[100].value,
      rgb: primaryNeutral[100].rgb,
      name: primaryNeutral[100].name,
      ios: primaryNeutral[100].ios,
      android: primaryNeutral[100].android,
      wcagBlack: {
        aa: 'true',
        aaLarge: 'true',
        ratio: '17.29',
      },
      wcagWhite: {
        aa: 'false',
        aaLarge: 'false',
        ratio: '1.21',
      },
    },
    {
      meta: {
        name: primaryNeutral[40].meta.name,
      },
      value: primaryNeutral[40].value,
      rgb: primaryNeutral[40].rgb,
      name: primaryNeutral[40].name,
      ios: primaryNeutral[40].ios,
      android: primaryNeutral[40].android,
      wcagBlack: {
        aa:'true',
        aaLarge: 'true',
        ratio: '19.43',
      },
      wcagWhite: {
        aa: 'false',
        aaLarge: 'false',
        ratio: '1.08',
      },
    },
    {
      meta: {
        name: "Citizens White",
      },
      value: "#ffffff",
      rgb: "rgb(255, 255, 255)",
      name: "$cbds-tkn-color__white",
      ios: null,
      android: null,
      wcagBlack: {
        aa: 'true',
        aaLarge: 'true',
        ratio: '21',
      },
      wcagWhite: {
        aa: 'false',
        aaLarge: 'false',
        ratio: '1',
      },
    },
  ],
  colorButtons: [
    {
      meta: {
        name: btnOrange.base.meta.name,
      },
      value: btnOrange.base.value,
      rgb: btnOrange.base.rgb,
      name: btnOrange.base.name,
      ios: btnOrange.base.ios,
      android: btnOrange.base.android,
      wcagBlack: {
        aa: 'false',
        aaLarge: 'false',
        ratio: '1.52',
      },
      wcagWhite: {
        aa: 'true',
        aaLarge: 'true',
        ratio: '13.75',
      },
    },
    {
      meta: {
        name: btnBlue.base.meta.name,
      },
      value: btnBlue.base.value,
      rgb: btnBlue.base.rgb,
      name: btnBlue.base.name,
      ios: btnBlue.base.ios,
      android: btnBlue.base.android,
      wcagBlack: {
        aa: btnBlue.base.wcag.black.aa,
        aaLarge: btnBlue.base.wcag.black.aaLarge,
        ratio: btnBlue.base.wcag.black.ratio,
      },
      wcagWhite: {
        aa: btnBlue.base.wcag.white.aa,
        aaLarge: btnBlue.base.wcag.white.aaLarge,
        ratio: btnBlue.base.wcag.white.ratio,
      },
    },
    {
      meta: {
        // name: btnNeutral.meta.name
        name: 'Neutral 700',
      },
      value: btnNeutral[700].value,
      rgb: btnNeutral[700].rgb,
      name: btnNeutral[700].name,
      ios: btnNeutral[700].ios,
      android: btnNeutral[700].android,
      wcagBlack: {
        aa: 'false',
        aaLarge: 'false',
        ratio: '2.68',
        // Hard coding WCAG - CBDS Design Tokens currently doesn't have this information.
        // aa: btnNeutral[700].wcag.black.aa,
        // aaLarge: btnNeutral[700].wcag.black.aaLarge,
        // ratio: btnNeutral[700].wcag.black.ratio,
      },
      wcagWhite: {
        aa: 'true',
        aaLarge: 'true',
        ratio: '6.07',
        // Hard coding WCAG - CBDS Design Tokens currently doesn't have this information.
        // aa: btnNeutral[700].wcag.white.aa,
        // aaLarge: btnNeutral[700].wcag.white.aaLarge,
        // ratio: btnNeutral[700].wcag.white.ratio,
      },
    },
  ],
  colorButtonsOrangeVariations: [
    {
      meta: {
        name: btnOrange[900].meta.name,
      },
      value: btnOrange[900].value,
      rgb: btnOrange[900].rgb,
      name: btnOrange[900].name,
      ios: btnOrange[900].ios,
      android: btnOrange[900].android,
      wcagBlack: {
        aa: 'false',
        aaLarge: 'false',
        ratio: '1.52',
      },
      wcagWhite: {
        aa: 'true',
        aaLarge: 'true',
        ratio: '13.75',
      },
    },
    {
      meta: {
        name: btnOrange[700].meta.name,
      },
      value: btnOrange[700].value,
      rgb: btnOrange[700].rgb,
      name: btnOrange[700].name,
      ios: btnOrange[700].ios,
      android: btnOrange[700].android,
      wcagBlack: {
        aa: 'false',
        aaLarge: 'true',
        ratio: '3.19',
      },
      wcagWhite: {
        aa: 'true',
        aaLarge: 'true',
        ratio: '6.56',
      },
    },
    {
      meta: {
        name: btnOrange.base.meta.name,
      },
      value: btnOrange.base.value,
      rgb: btnOrange.base.rgb,
      name: btnOrange.base.name,
      ios: btnOrange.base.ios,
      android: btnOrange.base.android,
      wcagBlack: {
        aa: 'true',
        aaLarge: 'true',
        ratio: '4.61',
      },
      wcagWhite: {
        aa: 'true',
        aaLarge: 'true',
        ratio: '4.54',
      },
    },
  ],
  colorBlueVariations: [
    {
      meta: {
        name: btnBlue.base.meta.name,
      },
      value: btnBlue.base.value,
      rgb: btnBlue.base.rgb,
      name: btnBlue.base.name,
      ios: btnBlue.base.ios,
      android: btnBlue.base.android,
      wcagBlack: {
        aa: 'false',
        aaLarge: 'false',
        ratio: '2.58',
      },
      wcagWhite: {
        aa: 'true',
        aaLarge: 'true',
        ratio: '8.11',
      },
    },
    {
      meta: {
        name: "Blue 40",
      },
      rgb: "245, 248, 251",
      value: "#f5f8fb",
      name: "$cbds-tkn-color__blue--40",
      wcagBlack: {
        aa: 'true',
        aaLarge: 'true',
        ratio: '19.7',
      },
      wcagWhite: {
        aa: 'false',
        aaLarge: 'false',
        ratio: '1.06',
      },
    },
  ],
  colorButtonsNeutralVariations: [
    {
      meta: {
        name: btnNeutral[700].meta.name,
      },
      value: btnNeutral[700].value,
      rgb: btnNeutral[700].rgb,
      name: btnNeutral[700].name,
      ios: btnNeutral[700].ios,
      android: btnNeutral[700].android,
      wcagBlack: {
        aa: 'false',
        aaLarge: 'false',
        ratio: '2.68',
        // Hard coding WCAG - CBDS Design Tokens currently doesn't have this information.
        // aa: btnNeutral[700].wcag.black.aa,
        // aaLarge: btnNeutral[700].wcag.black.aaLarge,
        // ratio: btnNeutral[700].wcag.black.ratio,
      },
      wcagWhite: {
        aa: 'true',
        aaLarge: 'true',
        ratio: '6.07',
        // Hard coding WCAG - CBDS Design Tokens currently doesn't have this information.
        // aa: btnNeutral[700].wcag.white.aa,
        // aaLarge: btnNeutral[700].wcag.white.aaLarge,
        // ratio: btnNeutral[700].wcag.white.ratio,
      },
    },
    {
      meta: {
        name: btnNeutral[300].meta.name,
      },
      value: btnNeutral[300].value,
      rgb: btnNeutral[300].rgb,
      name: btnNeutral[300].name,
      ios: btnNeutral[300].ios,
      android: btnNeutral[300].android,
      wcagBlack: {
        aa: btnNeutral[300].wcag.black.aa,
        aaLarge: btnNeutral[300].wcag.black.aaLarge,
        ratio: btnNeutral[300].wcag.black.ratio,
      },
      wcagWhite: {
        aa: btnNeutral[300].wcag.white.aa,
        aaLarge: btnNeutral[300].wcag.white.aaLarge,
        ratio: btnNeutral[300].wcag.white.ratio,
      },
    },
    {
      meta: {
        name: btnNeutral[100].meta.name,
      },
      value: btnNeutral[100].value,
      rgb: btnNeutral[100].rgb,
      name: btnNeutral[100].name,
      ios: btnNeutral[100].ios,
      android: btnNeutral[100].android,
      wcagBlack: {
        aa: btnNeutral[100].wcag.black.aa,
        aaLarge: btnNeutral[100].wcag.black.aaLarge,
        ratio: btnNeutral[100].wcag.black.ratio,
      },
      wcagWhite: {
        aa: btnNeutral[100].wcag.white.aa,
        aaLarge: btnNeutral[100].wcag.white.aaLarge,
        ratio: btnNeutral[100].wcag.white.ratio,
      },
    },
  ],
  colorCommercial: [
    {
      meta: {
        // name: commercialDeepGreen.base.meta.name
        name: 'Citizens Deep Green',
      },
      value: commercialDeepGreen.base.value,
      rgb: commercialDeepGreen.base.rgb,
      name: commercialDeepGreen.base.name,
      ios: commercialDeepGreen.base.ios,
      android: commercialDeepGreen.base.android,
      wcagBlack: {
        aa: commercialDeepGreen.base.wcag.black.aa,
        aaLarge: commercialDeepGreen.base.wcag.black.aaLarge,
        ratio: commercialDeepGreen.base.wcag.black.ratio,
      },
      wcagWhite: {
        aa: commercialDeepGreen.base.wcag.white.aa,
        aaLarge: commercialDeepGreen.base.wcag.white.aaLarge,
        ratio: commercialDeepGreen.base.wcag.white.ratio,
      },
    },
  ],
  colorIllustration: [
    {
      meta: {
        name: illustrationNeutral300.base.meta.name,
      },
      value: illustrationNeutral300.base.value,
      rgb: illustrationNeutral300.base.rgb,
    },
    {
      meta: {
        name: illustrationGold500.base.meta.name,
      },
      value: illustrationGold500.base.value,
      rgb: illustrationGold500.base.rgb,
    },
    {
      meta: {
        name: illustrationGreen300.base.meta.name,
      },
      value: illustrationGreen300.base.value,
      rgb: illustrationGreen300.base.rgb,
    },
    {
      meta: {
        name: illustrationOrange500.base.meta.name,
      },
      value: illustrationOrange500.base.value,
      rgb: illustrationOrange500.base.rgb,
    },
    {
      meta: {
        name: illustrationFadedBlue400.base.meta.name,
      },
      value: illustrationFadedBlue400.base.value,
      rgb: illustrationFadedBlue400.base.rgb,
    },
    {
      meta: {
        name: illustrationBlue400.base.meta.name,
      },
      value: illustrationBlue400.base.value,
      rgb: illustrationBlue400.base.rgb,
    },
  ],
  colorRedVariations: [
    {
      meta: {
        name: msgRed.base.meta.name,
      },
      value: msgRed.base.value,
      rgb: msgRed.base.rgb,
      name: msgRed.base.name,
      ios: msgRed.base.ios,
      android: msgRed.base.android,
      wcagBlack: {
        aa: 'false',
        aaLarge: 'true',
        ratio: '3.5',
      },
      wcagWhite: {
        aa: 'true',
        aaLarge: 'true',
        ratio: '5.99',
      },
    },
    {
      meta: {
        name: "Red 40",
      },
      rgb: "252, 246, 246",
      value: "#fcf6f6",
      name: "$cbds-tkn-color__red--40",
      wcagBlack: {
        aa: null,
        aaLarge: null,
        ratio: null,
      },
      wcagWhite: {
        aa: null,
        aaLarge: null,
        ratio: null,
      },
    },
  ],
  colorText: [
    {
      meta: {
        // name: primaryBlack.meta.name
        name: 'Citizens Black (Neutral)',
      },
      value: primaryNeutral.base.value,
      rgb: primaryNeutral.base.rgb,
      name: primaryNeutral.base.name,
      ios: primaryNeutral.base.ios,
      android: primaryNeutral.base.android,
      wcagBlack: {
        aa: primaryNeutral.base.wcag.black.aa,
        aaLarge: primaryNeutral.base.wcag.black.aaLarge,
        ratio: primaryNeutral.base.wcag.black.ratio,
      },
      wcagWhite: {
        aa: primaryNeutral.base.wcag.white.aa,
        aaLarge: primaryNeutral.base.wcag.white.aaLarge,
        ratio: primaryNeutral.base.wcag.white.ratio,
      },
    },
    {
      meta: {
        name: primaryNeutral[900].meta.name,
      },
      value: primaryNeutral[900].value,
      rgb: primaryNeutral[900].rgb,
      name: primaryNeutral[900].name,
      ios: primaryNeutral[900].ios,
      android: primaryNeutral[900].android,
      wcagBlack: {
        aa: 'false',
        aaLarge: 'false',
        ratio: '1.33',
        // Hard coding WCAG - CBDS Design Tokens currently doesn't have this information.
        // aa: primaryNeutral[900].wcag.black.aa,
        // aaLarge: primaryNeutral[900].wcag.black.aaLarge,
        // ratio: primaryNeutral[900].wcag.black.ratio,
      },
      wcagWhite: {
        aa: 'true',
        aaLarge: 'true',
        ratio: '16.3',
        // Hard coding WCAG - CBDS Design Tokens currently doesn't have this information.
        // aa: primaryNeutral[900].wcag.white.aa,
        // aaLarge: primaryNeutral[900].wcag.white.aaLarge,
        // ratio: primaryNeutral[900].wcag.white.ratio,
      },
    },
    {
      meta: {
        name: primaryNeutral[700].meta.name,
      },
      value: primaryNeutral[700].value,
      rgb: primaryNeutral[700].rgb,
      name: primaryNeutral[700].name,
      ios: primaryNeutral[700].ios,
      android: primaryNeutral[700].android,
      wcagBlack: {
        aa: 'false',
        aaLarge: 'false',
        ratio: '2.68',
        // Hard coding WCAG - CBDS Design Tokens currently doesn't have this information.
        // aa: primaryNeutral[700].wcag.black.aa,
        // aaLarge: primaryNeutral[700].wcag.black.aaLarge,
        // ratio: primaryNeutral[700].wcag.black.ratio,
      },
      wcagWhite: {
        aa: 'true',
        aaLarge: 'true',
        ratio: '6.07',
        // Hard coding WCAG - CBDS Design Tokens currently doesn't have this information.
        // aa: primaryNeutral[700].wcag.white.aa,
        // aaLarge: primaryNeutral[700].wcag.white.aaLarge,
        // ratio: primaryNeutral[700].wcag.white.ratio,
      },
    },
  ],
  colorPurple: [
    {
      meta: {
        name: linkPurple.base.meta.name,
      },
      value: linkPurple.base.value,
      rgb: linkPurple.base.rgb,
      name: linkPurple.base.name,
      ios: linkPurple.base.ios,
      android: linkPurple.base.android,
      wcagBlack: {
        aa: 'false',
        aaLarge: 'false',
        ratio: '2.27',
      },
      wcagWhite: {
        aa: 'true',
        aaLarge: 'true',
        ratio: '9.23',
      },
    },
  ],
};
