// import React from "react";
import SalesforceOverview from "./SalesforceOverview.mdx";

const SalesforceOverviewPage = {
  mdx: SalesforceOverview,
  breadcrumbs: "Components/Salesforce Library",
  alias: "components/salesforce-library/salesforce-overview",
  pathExact: false,
  title: "Salesforce - Citizens Theme Overview",
  menuLabel: "Overview",
  published: true,
  date: "2023-10-19", // Show when last updated
  summary: "A custom Salesforce theme that aligns the Citizens Brand standards are available for teams.",

  // SEO and Search
  tags: [
    "Components",
    "Salesforce",
    "Overview",
  ],
  meta: {
    title: "Salesforce Overview - Salesforce Library - Components",
    description: "",
    keywords: [],
  },
};

export default SalesforceOverviewPage;
