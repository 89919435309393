import React from 'react';
import Strategy from './Strategy.mdx';

const StrategyPage = {
  mdx: Strategy,
  breadcrumbs: "Brand Guidelines/Consumer Guidelines",
  alias: "brand-guidelines/consumer-guidelines/strategy",
  pathExact: false,
  title: "Strategy",
  menuLabel: 'Strategy',
  published: true,
  order: 3,
  date: '2023-01-30', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Consumer',
    'Guidelines',
    'Strategy',
    'Made Ready',
    'Ambition',
    'insights',
  ],
  meta: {
    title: 'Strategy - Consumer - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default StrategyPage;
