import React from 'react';
import GraphicElements from './GraphicElements.mdx';

const GraphicElementsPage = {
  mdx: GraphicElements,
  breadcrumbs: "Brand Guidelines/Consumer Guidelines",
  alias: "brand-guidelines/consumer-guidelines/graphic-elements",
  pathExact: false,
  title: "Graphic Elements",
  menuLabel: 'Graphic Elements',
  published: true,
  order: 10,
  date: '2023-02-01', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Consumer',
    'Guidelines',
    'Graphic Elements',
    'Daisy Wheel',
    'Panels',
    'notch',
  ],
  meta: {
    title: 'Graphic Elements - Consumer - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default GraphicElementsPage;
