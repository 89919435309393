import React from 'react';
import BrandColorPalette from './BrandColorPalette.mdx';

const BrandColorPalettePage = {
  mdx: BrandColorPalette,
  breadcrumbs: "Brand Guidelines/Enterprise Guidelines",
  alias: "brand-guidelines/enterprise-guidelines/brand-color-palette",
  pathExact: false,
  title: "Brand Color Palette",
  menuLabel: 'Brand Color Palette',
  published: true,
  order: 11,
  date: '2023-01-27', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Enterprise',
    'Guidelines',
    'Brand Color Palette',
    'Primary',
    'Secondary',
    'Extended',
  ],
  meta: {
    title: 'Color Palette - Enterprise - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default BrandColorPalettePage;
