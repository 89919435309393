import React from 'react';
import Introduction from './Introduction.mdx';

const IntroductionPage = {
  mdx: Introduction,
  breadcrumbs: "Getting Started/For Developers/Web",
  alias: "getting-started/for-developers/web/introduction",
  pathExact: false,
  title: "Introduction",
  menuLabel: 'Introduction',
  published: true,
  order: 1,
  date: '2024-01-31', // Show when last updated
  summary: <>The Citizens Design System (CDS) is a framework of web components and visual styles that will provide a consistent platform for developing consistent and uniform user interfaces. The system includes <strong>CDS Digital Foundations</strong> (Fonts, Icons, Logos, and Design Tokens) and <strong>CDS Components</strong> (React, Angular, and plain HTML) libraries.</>,
  tags: [
    'CBDS',
    'CDS',
    'getting started',
    'For Developers',
    'introduction',
    'help',
  ],
  meta: {
    title: 'Introduction - Web - For Developers - Getting Started',
    description: '',
    keywords: [],
  },
};

export default IntroductionPage;
