// import React from 'react';
// import Link from '../../../components/Link';
import EmptyStates from './EmptyStates.mdx';

const EmptyStatesPage = {
  mdx: EmptyStates,
  breadcrumbs: "Patterns",
  alias: "patterns/empty-states",
  pathExact: false,
  title: "Empty States",
  menuLabel: 'Empty States',
  published: true,
  order: 2,
  date: '2025-01-28', // Show when last updated
  summary: "",

  // SEO and Search
  tags: [
    "Accessibility",
    "A11Y",
    "ADA",
    "Patterns",
    "Empty",
    "States",
  ],
  meta: {
    description: '',
    title: 'Empty States - Patterns',
    keywords: [],
  },
  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "",
    },
    react: {
      status: "na",
      version: "v1.0.0",
      link: "",
    },
    angular: {
      status: "released",
      version: "v1.0.0",
      link: "/index.html?path=/story/patterns-empty-state--default",
    },
    figma: {
      status: "released",
      version: "",
      link: "https://www.figma.com/design/mj41OHGCokcJ9N7D0q8D9Q/CDS-UI-Kit-V1.1?node-id=24418-26157&t=qIEgntsZtMsazqVA-4",
    },
  },
};

export default EmptyStatesPage;
