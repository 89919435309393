import React from 'react';
import CdsSeptember2023Newsletter from './CdsSeptember2023Newsletter.mdx';

const CdsSeptember2023NewsletterPage = {
  mdx: CdsSeptember2023Newsletter,
  breadcrumbs: "About/Newsletter",
  alias: "about/newsletters/cds-september-2023-newsletter",
  pathExact: false,
  title: "CDS Newsletter September 2023",
  menuLabel: 'CdsSeptember2023Newsletter',
  published: true,
  hideFromNav: true,
  //order: 3,
  date: '2023-09-27', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CDS',
    'About',
    'Newsletter',
    'Updates',
    'September',
    '2023',
    'Release Notes',
  ],
  meta: {
    description: '',
    title: 'CDS September 2023 Newsletter - Newsletters - About',
    keywords: [],
  },
};

export default CdsSeptember2023NewsletterPage;
