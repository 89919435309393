import React from 'react';
import PropTypes from 'prop-types';
/* eslint-disable */
import { Fancybox } from "@fancyapps/ui";
/* eslint-enable */
import classNames from 'classnames';

const Figure = ({
  autoHeightClass,
  frameBgStyleClass,
  frameInsetSpaceClass,
  hasSandboxLink,
  headingAbove,
  imgAltText,
  imgSrc,
  headingBelow,
  caption
}) => {

  const classAutoHeight = autoHeightClass ? 'cbdsws-c-figure--autoHeight' : null;
  const classFrameBgStyle = frameBgStyleClass ? `cbdsws-c-figure__frame--lightGray` : null;
  const classFrameInsetSpace = frameInsetSpaceClass ? `cbdsws-c-figure__frame--none` : null;
  const fancyboxCaption = caption ? caption : imgAltText;



  return (
    <div className={classNames('cbdsws-c-figure', classAutoHeight, classFrameBgStyle, classFrameInsetSpace)}>
      {headingAbove
        ? <h4 className="cbdsws-c-figure__heading">{headingAbove}</h4>
        : null
      }
      <div className={`cbdsws-c-figure__frame ${frameBgStyleClass ? classFrameBgStyle : ''} ${frameInsetSpaceClass ? classFrameInsetSpace: ''}`}>
        <a
          href={imgSrc}
          className="cbdsws-c-figure__link cbensightenevent"
          aria-hidden={!imgAltText ? "true" : "false"}
          cbdata-type="image"
          cbdata-reason="__figureFancybox"
          data-fancybox="galleryFigure"
          data-caption={!hasSandboxLink ? fancyboxCaption: imgAltText}
          data-src={imgSrc}
        >
          <img
            className="cbdsws-c-figure__image"
            src={imgSrc}
            alt={imgAltText ? `${imgAltText} (Opens in dialog)` : ''}
            role={imgAltText ? 'img' : 'presentation'}
          />
        </a>
      </div>
      {headingBelow
        ? <h4 className="cbdsws-c-figure__heading">{headingBelow}</h4>
        : null
      }
      {caption
        ? <figcaption className="cbdsws-c-figcaption cbdsws-c-figure__caption">
          <p className={hasSandboxLink ? "cbds-u-text__align--left" : ""}>{caption}</p>
        </figcaption>
        : null
      }
    </div>
  );
};

Figure.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  imgAltText: PropTypes.string,
  caption: PropTypes.any,
  headingAbove: PropTypes.string,
  headingBelow: PropTypes.string,
  autoHeightClass: PropTypes.bool,
  frameBgStyleClass: PropTypes.bool,
  frameInsetSpaceClass: PropTypes.bool,
  hasSandboxLink: PropTypes.bool,
};

export default Figure;
