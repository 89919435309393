import React from 'react';
import CdsJuly2024Newsletter from './CdsJuly2024Newsletter.mdx';

const CdsJuly2024NewsletterPage = {
  mdx: CdsJuly2024Newsletter,
  breadcrumbs: "About/Newsletter",
  alias: "about/newsletters/cds-july-2024-newsletter",
  pathExact: false,
  title: "CDS Newsletter July 2024",
  menuLabel: 'Cds July 2024 Newsletter',
  published: true,
  hideFromNav: true,
  //order: 3,
  date: '2024-08-26', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CDS',
    'About',
    'Newsletter',
    'Updates',
    'July',
    '2024',
    'Release Notes',
  ],
  meta: {
    description: '',
    title: 'CDS July 2024 Newsletter - Newsletters - About',
    keywords: [],
  },
};

export default CdsJuly2024NewsletterPage;
