import React from 'react';
import { Link } from 'react-router-dom';
import QuickStarts from './QuickStarts.mdx';

const QuickStartsPage = {
  mdx: QuickStarts,
  breadcrumbs: "Getting Started/For Developers/Web",
  alias: "getting-started/for-developers/web/quick-starts",
  pathExact: false,
  title: "Quick Starts",
  menuLabel: 'Quick Starts',
  published: true,
  order: 1,
  date: '2024-01-31', // Show when last updated
  summary: <>There are a number of use cases for web developers to get started with the Citizens Design System (CDS). It all begins by downloading and <Link to="/getting-started/for-developers/web/installation">installing</Link> our collection of npm packages.</>,
  tags: [
    'CDS',
    'CBDS',
    'getting started',
    'For Developers',
    'quick starts',
    'help',
  ],
  meta: {
    title: 'Quick Starts - Web - For Developers - Getting Started',
    description: '',
    keywords: [],
  },
};

export default QuickStartsPage;
