import React from 'react';
import Photography from './Photography.mdx';

const PhotographyPage = {
  mdx: Photography,
  breadcrumbs: "Brand Guidelines/Commercial Guidelines",
  alias: "brand-guidelines/commercial-guidelines/photography",
  pathExact: false,
  title: "Photography",
  menuLabel: 'Photography',
  published: true,
  order: 3,
  date: '2023-11-28', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Commercial',
    'Guidelines',
    'Commercial Guidelines',
    'Photography',
    'Imagery',
  ],
  meta: {
    title: 'Photography - Commercial - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default PhotographyPage;
