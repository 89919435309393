import React from 'react';
import BrandSonicGuidelines from './BrandSonicGuidelines.mdx';

const BrandSonicGuidelinesPage = {
  mdx: BrandSonicGuidelines,
  breadcrumbs: "Brand Guidelines/Consumer Guidelines",
  alias: "brand-guidelines/consumer-guidelines/brand-sonic-guidelines",
  pathExact: false,
  title: "Brand Sonic Guidelines",
  menuLabel: 'Brand Sonic Guidelines',
  published: true,
  order: 12,
  date: '2023-02-01', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Consumer',
    'Guidelines',
    'Brand Sonic Guidelines',
    'Brand Awareness',
    'Sonic Branding',
    'Signature Sound',
  ],
  meta: {
    title: 'Brand Sonic Guidelines - Consumer - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default BrandSonicGuidelinesPage;
