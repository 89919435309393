// import React from "react";
import Popover from "./Popover.mdx";
import Guidelines from "./Guidelines.mdx";
// import Accessibility from "./Accessibility.mdx";
// import Code from "./Code.mdx";

const PopoverPage = {
  mdx: Popover,
  breadcrumbs: "Components/Web Responsive Library",
  alias: "components/web-responsive-library/popover",
  pathExact: false,
  title: "Popover",
  menuLabel: "Popover",
  published: true,
  date: "2024-11-20", // Show when last updated
  summary: "",
  tabs: [
    {
      label: "Guidelines",
      mdx: Guidelines,
      summary: "Popovers add informative material to help users make decisions. They give more context to on-screen elements and help users navigate unfamiliar territory.",
      searchSummary: "The Citizens Design System uses a Popover instead of a Tool-Tip. Popovers require an interaction to view and dismiss, and can also contain a header that provides better context to screen readers.",
      alias: "/components/web-responsive-library/popover/guidelines",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Popover",
        'Tooltip',
        "Design",
        "Guidelines",
        "React",
        "angular"
      ],
      meta: {
        title: "Guidelines - Popover - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    /*{
      label: "Accessibility",
      mdx: Accessibility,
      summary: <></>,
      alias: "/components/web-responsive-library/popover/accessibility",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Popover",
        'Tooltip",
        "Accessibility",
        "React",
      ],
      meta: {
        title: "Accessibility - Popover - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    {
      label: "Code",
      mdx: Code,
      summary: <></>,
      alias: "/components/web-responsive-library/popover/code",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Popover",
        'Tooltip",
        "Code",
        "React",
      ],
      meta: {
        title: "Code - Popover - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },*/
  ],
  // SEO and Search
  tags: [],
  meta: {
    title: "Popover - Web Responsive Library - Components",
    description: "",
    keywords: [],
  },

  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "index.html?path=/story/components-Overlay",
    },
    react: {
      status: "released",
      version: "v1.0.0",
      link: "index.html?path=/story/components-popover--default",
    },
    angular: {
      status: "released",
      version: "v1.0.0",
      link: "/index.html?path=/story/components-popover--default",
    },
    figma: {
      status: "released",
      version: "",
      link: "https://www.figma.com/design/mj41OHGCokcJ9N7D0q8D9Q/CDS-UI-Kit-V1.1?m=auto&node-id=7076-21600&t=JdUBNI95pRCdgfMZ-1",
    },
  },
};

export default PopoverPage;
