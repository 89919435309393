import React from 'react';
import ApplicationExamples from './ApplicationExamples.mdx';

const ApplicationExamplesPage = {
  mdx: ApplicationExamples,
  breadcrumbs: "Brand Guidelines/Business Banking Guidelines",
  alias: "brand-guidelines/business-banking-guidelines/ApplicationExamples",
  pathExact: false,
  title: "Application Examples",
  menuLabel: 'Application Examples',
  published: true,
  order: 10,
  date: '2023-09-28', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Business Banking',
    'Guidelines',
    'Business Banking Guidelines',
    'ApplicationExamples',
    'Business Banking ApplicationExamples Usage',
  ],
  meta: {
    title: 'Application Examples - Business Banking - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default ApplicationExamplesPage;
