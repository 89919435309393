import ReleaseNotes from './data/releases.json';
import Newsletters from './data/newsletters.json';

export const RELEASE_NOTES_DATA = {
  data: ReleaseNotes,
  dataEntries: ReleaseNotes.releases,
  dataType: 'release'
};

export const NEWSLETTER_DATA = {
  data: Newsletters,
  dataEntries: Newsletters.newsletters,
  dataType: 'newsletter'
};
