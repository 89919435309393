import React from "react";
import Link from '../../../../components/Link';
import Table from "./Table.mdx";
import Guidelines from "./Guidelines.mdx";
// import Accessibility from "./Accessibility.mdx";
// import Code from "./Code.mdx";

const TablePage = {
  mdx: Table,
  breadcrumbs: "Components/Web Responsive Library",
  alias: "components/web-responsive-library/table",
  pathExact: false,
  title: "Table",
  menuLabel: "Table",
  published: true,
  date: "2023-06-12", // Show when last updated
  summary: "",
  tabs: [
    {
      label: "Guidelines",
      mdx: Guidelines,
      summary: <>The Table component displays gridded  information in a way that’s easy to scan and understand hierarchy. For sortable table guidance, see <Link internalUrl="/components/web-responsive-library/table-sorting/guidelines">Table/Sorting</Link>.</>,
      alias: "/components/web-responsive-library/table/guidelines",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Table",
        "Design",
        "Guidelines",
      ],
      meta: {
        title: "Guidelines - Table - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    /*{
      label: "Accessibility",
      mdx: Accessibility,
      summary: <></>,
      alias: "/components/web-responsive-library/table/accessibility",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Table",
        "Accessibility",
      ],
      meta: {
        title: "Accessibility - Table - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    {
      label: "Code",
      mdx: Code,
      summary: <></>,
      alias: "/components/web-responsive-library/table/code",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Table",
        "Code",
      ],
      meta: {
        title: "Code - Table - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },*/
  ],
  // SEO and Search
  tags: [],
  meta: {
    title: "Table - Web Responsive Library - Components",
    description: "",
    keywords: [],
  },

  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "index.html?path=/story/components-Overlay",
    },
    react: {
      status: "na",
      version: "v1.0.0",
      link: "index.html?path=/story/components-Table--default",
    },
    angular: {
      status: "released",
      version: "v1.0.0",
      link: "//index.html?path=/story/components-table--simple-table",
    },
    figma: {
      status: "released",
      version: "",
      link: "https://www.figma.com/design/mj41OHGCokcJ9N7D0q8D9Q/CDS-UI-Kit-V1.1?m=auto&node-id=3152-16771&t=XyqXBNsOTQ56qExN-1",
    },
  },
};

export default TablePage;
