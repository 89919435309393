import React from 'react';
import Contacts from './Contacts.mdx';

const ContactsPage = {
  mdx: Contacts,
  breadcrumbs: "Brand Guidelines/Consumer Guidelines",
  alias: "brand-guidelines/consumer-guidelines/contacts",
  pathExact: false,
  title: "Contacts",
  menuLabel: 'Contacts',
  published: true,
  order: 14,
  date: '2023-02-01', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Consumer',
    'Guidelines',
    'Contacts',
  ],
  meta: {
    title: 'Contact - Consumer - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default ContactsPage;
