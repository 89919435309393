import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ContentContainer = ({ children }) => {
  const location = useLocation();
  const alias = location.pathname;
  const [pageClassName, setPageClassName] = useState('home');

  useEffect(() => {
    if (alias === '/') {
      setPageClassName('cbdsws-l-home');
    } else {
      const _className = alias.replace(/\//g, '-');
      setPageClassName(`cbdsws-l${_className}`);
    }
  }, [alias]);

  return (
    <div id="cbdsws-contentContainer" className={`cbdsws-l-contentContainer ${pageClassName}`}>
      {children}
    </div>
  );
};

export default ContentContainer;
