import React from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';

const TokenExample = ({
  // tokenName,
  value,
  aliasValue,
  color,
  // status,
  // release,
  tableType,
  // tknCat,
  tknType,
  subType,
  subItem,
  tknName,
  platform
}) => {
  // Color
  if (tableType === 'color' || tableType === 'backgroundColor' || tableType === 'textColor') {
    let displayColor = color ? color : value;
    return (
      <td data-use-inline-copy-button="false" data-cell="Example">
        <div className="cbdsws-c-tokensTable__color" style={{ backgroundColor: displayColor, color: displayColor }}></div>
        <span className="cbdsws-u-srOnly">visual example of color: {value}</span>
      </td>
    );
  }

  // Border Color
  if (tableType === 'borderColor') {
    let displayColor = color ? color : value;
    return (
      <td data-use-inline-copy-button="false" data-cell="Example">
        <div className="cbdsws-c-tokensTable__borderColor" style={{ borderColor: displayColor }}></div>
        <span className="cbdsws-u-srOnly">visual example of border-color: { value }</span>
      </td>
    );
  }

  // Border Radius
  if (tableType === 'borderRadius') {
    let displayValue = (platform === 'ios') ? aliasValue : value;
    return (
      <td data-use-inline-copy-button="false" data-cell="Example">
        <div className="cbdsws-c-tokensTable__borderRadius" style={{ borderRadius: displayValue }}></div>
        <span className="cbdsws-u-srOnly">visual example of border-radius: { displayValue }</span>
      </td>
    );
  }

  // Border Width
  if (tableType === 'borderWidth') {
    let displayValue = (platform === 'ios') ? aliasValue : value;
    return (
      <td data-use-inline-copy-button="false" data-cell="Example">
        <div className="cbdsws-c-tokensTable__borderWidth" style={{ borderWidth: displayValue }}></div>
        <span className="cbdsws-u-srOnly">visual example of border-width: { displayValue }</span>
      </td>
    );
  }

  // Size (unit)
  if (tableType === 'size') {
    let displayValue = (platform === 'ios') ? aliasValue : value;
    return (
      <td data-use-inline-copy-button="false" data-cell="Example">
        <div className="cbdsws-c-tokensTable__size" style={{ height: displayValue, width: displayValue }}></div>
        <span className="cbdsws-u-srOnly">visual example of height: { displayValue }, width: { displayValue }</span>
      </td>
    );
  }

  // Space: Base
  if (tableType === 'space' && tknType === 'base') {
    let displayValue = (platform === 'ios') ? `${value}px` : value;
    return (
      <td data-use-inline-copy-button="false" data-cell="Example">
        <div className="cbdsws-c-tokensTable__space--base" style={{ width: displayValue }}></div>
        <span className="cbdsws-u-srOnly">visual example of width: { displayValue }</span>
      </td>
    );
  }

  // Space: Inset
  if (tableType === 'space' && tknType === 'inset') {
    return (
      <td data-use-inline-copy-button="false" data-cell="Example">
        <div className="cbdsws-c-tokensTable__spaceCntr">
          <div className={classNames('cbdsws-c-tokensTable__spaceInsetSpecBlock', 'cbdsws-c-tokensTable__spaceInsetSpecBlock--' + subType)}>
            <div className={classNames('cbdsws-c-tokensTable__spaceInsetElement', 'cbdsws-c-tokensTable__spaceInsetElement--' + subType)}></div>
          </div>
        </div>
        <span className="cbdsws-u-srOnly">visual example of inset space</span>
      </td>
    );
  }

  // Space: Inline
  if (tableType === 'space' && tknType === 'inline') {
    return (
      <td className="cbdsws-c-tokensTable__cell" data-use-inline-copy-button="false" data-cell="Example">
        <div className="cbdsws-c-tokensTable__spaceCntr">
          <div className="cbdsws-c-tokensTable__spaceInlineInner">
            <div className={classNames('cbdsws-c-tokensTable__spaceInlineSpecBlock', 'cbdsws-c-tokensTable__spaceInlineSpecBlock--' + subType, 'cbdsws-c-tokensTable__spaceInlineSpecBlock--' + subType + '-' + subItem)}></div>
            <div className={classNames('cbdsws-c-tokensTable__spaceInlineElement', 'cbdsws-c-tokensTable__spaceInlineElement--' + subType, 'cbdsws-c-tokensTable__spaceInlineElement--' + subType + '-' + subItem)}></div>
          </div>
        </div>
        <span className="cbdsws-u-srOnly">visual example of inline space</span>
      </td>
    );
  }

  // Space: Stack
  if (tableType === 'space' && tknType === 'stack') {
    return (
      <td data-use-inline-copy-button="false" data-cell="Example">
        <div className="cbdsws-c-tokensTable__spaceCntr">
          <div className="cbdsws-c-tokensTable__spaceStackInner">
            <div className={classNames('cbdsws-c-tokensTable__spaceStackSpecBlock', 'cbdsws-c-tokensTable__spaceStackSpecBlock--' + subType)}></div>
            <div className={classNames('cbdsws-c-tokensTable__spaceStackElement', 'cbdsws-c-tokensTable__spaceStackElement--' + subType)}></div>
          </div>
        </div>
        <span className="cbdsws-u-srOnly">visual example of stack space</span>
      </td>
    );
  }

  // Font Family
  if (tableType === 'fontFamily') {
    return (
      <td className="cbdsws-c-tokensTable__fontFamilyExample--cell" data-cell="Example">
        <div className="cbdsws-c-tokensTable__fontFamilyExample--text" style={{ fontFamily: value }}>
          {tknName}
        </div>
      </td>
    );
  }

  // Font Role
  if (tableType === 'fontRole' &&
    (tknName === 'body' ||
      tknName === 'text' ||
      tknName === 'ui')) {
    let displayValue = '';

    if (tknName === 'body') {
      displayValue = 'At every step of your journey, we want to help you do the things that matter most';
    } else if (tknName === 'text') {
      displayValue = 'At every step of your journey, we want to help you do the things that matter most.';
    } else if (tknName === 'ui') {
      displayValue = 'Your payment was successful.';
    }

    return (
      <td className={classNames('cbdsws-c-tokensTable__fontRoleExample', 'cbdsws-c-tokensTable__fontRoleExample--' + tknName)} data-cell="Example">
        <div style={{ fontFamily: value }}>
          { displayValue }
        </div>
      </td>
    );
  } else if (tableType === 'fontRole' &&
    (tknName === 'code')) {
    return (
      <td className={classNames('cbdsws-c-tokensTable__fontRoleExample', 'cbdsws-c-tokensTable__fontRoleExample--' + tknName)} data-cell="Example">
        <code style={{ fontFamily: value }}><pre>
          {`.cbds-c-navbar__closeIcon {
  fill: #dac500;
}`}
        </pre></code>
      </td>
    );
  } else if (tableType === 'fontRole' &&
    (tknName === 'heading' ||
    tknName === 'heading--handwritten')) {
    return (
      <td className={classNames('cbdsws-c-tokensTable__fontRoleExample', 'cbdsws-c-tokensTable__fontRoleExample--' + tknName)} data-cell="Example">
        <h1 style={{ fontFamily: value }}>
          { (tknName === 'heading--handwritten') ? 'Handwritten ' : null }Heading
        </h1>
      </td>
    );
  }

  // Font Size
  if (tableType === 'fontSize') {
    let displayValue = (platform === 'ios') ? `${value}px` : value;
    return (
      <td className="cbdsws-c-tokensTable__fontSizeExample--cell" data-cell="Example">
        <div className="cbdsws-c-tokensTable__fontSizeExample--text" style={{ fontSize: displayValue }}>Made Ready</div>
      </td>
    );
  }

  // Font Style
  if (tableType === 'fontStyle') {
    return (
      <td className="cbdsws-c-tokensTable__fontStyleExample--cell" data-cell="Example">
        <div className="cbdsws-c-tokensTable__fontStyleExample--text" style={{ fontStyle: value }}>Made Ready</div>
      </td>
    );
  }

  // Font Type
  if (tableType === 'fontType') {
    let displayText = '';
    if (tknName === 'sansSerif') {
      displayText = 'Sans Serif Font Type';
    } else if (tknName === 'handwritten') {
      displayText = 'Hand Written Font Type';
    } else if (tknName === 'mono') {
      displayText = 'Monospace Font Type';
    }

    return (
      <td className={classNames('cbdsws-c-tokensTable__fontTypeExample', 'cbdsws-c-tokensTable__fontTypeExample--' + tknName)} data-cell="Example">
        <div className="cbdsws-c-tokensTable__fontTypeExample--text" style={{ fontFamily: value }}>
          { displayText }
        </div>
      </td>
    );
  }

  // Font Weight
  if (tableType === 'fontWeight') {
    return (
      <td className="cbdsws-c-tokensTable__fontWeightExample--cell" data-cell="Example">
        <div className="cbdsws-c-tokensTable__fontWeightExample--text" style={{ fontWeight: value }}>Made Ready</div>
      </td>
    );
  }

  // Headings, Body -- Token groups that have multiple example types to display
  if (tableType === 'headingBase' ||
    tableType === 'heading' ||
    tableType === 'h1' ||
    tableType === 'body') {
    if (tknName === 'color' || tknName === 'bgColor') {
      return (
        <td data-use-inline-copy-button="false" data-cell="Example">
          <div className="cbdsws-c-tokensTable__color" style={{ backgroundColor: value }}></div>
          <span className="cbdsws-u-srOnly">visual example of background-color: { value }</span>
        </td>
      );
    } else if (tknName === 'fontSize') {
      return (
        <td className="cbdsws-c-tokensTable__fontSizeExample--cell" data-cell="Example">
          <div className="cbdsws-c-tokensTable__fontSizeExample--text" style={{ fontSize: value }}>Made Ready</div>
        </td>
      );
    } else if (tknName === 'fontFamily') {
      return (
        <td className="cbdsws-c-tokensTable__fontFamilyExample--cell" data-cell="Example">
          <div className="cbdsws-c-tokensTable__fontFamilyExample--text" style={{ fontFamily: value }}>
            Fira Sans
          </div>
        </td>
      );
    } else if (tknName === 'fontStyle') {
      return (
        <td className="cbdsws-c-tokensTable__fontStyleExample--cell" data-cell="Example">
          <div className="cbdsws-c-tokensTable__fontStyleExample--text" style= {{ fontStyle: value }}>Made Ready</div>
        </td>
      );
    } else if (tknName === 'fontWeight') {
      return (
        <td className="cbdsws-c-tokensTable__fontWeightExample--cell" data-cell="Example">
          <div className="cbdsws-c-tokensTable__fontWeightExample--text" style={{ fontWeight: value }}>Made Ready</div>
        </td>
      );
    } else if (tknName === 'lineHeight') {
      return (
        <td className={classNames('cbdsws-c-tokensTable__' + tknName + 'Example--cell')} data-cell="Example">
          <div className={classNames('cbdsws-c-tokensTable__' + tknName + 'Example--text')} style={{ lineHeight: value }}>Made Ready</div>
        </td>
      );
    } else if (tknName === 'letterSpacing') {
      return (
        <td className={classNames('cbdsws-c-tokensTable__' + tknName + 'Example--cell')} data-cell="Example">
          <div className={classNames('cbdsws-c-tokensTable__' + tknName + 'Example--text')} style={{ letterSpacing: value }}>Made Ready</div>
        </td>
      );
    } else {
      return (
        <td><span className="cbdsws-u-srOnly">no example available</span></td>
      );
    }
  }

  // Line Height
  if (tableType === 'lineHeight') {
    return (
      <td className={classNames('cbdsws-c-tokensTable__' + tableType + 'Example--cell')} data-cell="Example">
        <div className={classNames('cbdsws-c-tokensTable__' + tableType + 'Example--text')} style={{ lineHeight: value }}>Made Ready</div>
      </td>
    );
  }

  // Letter Spacing
  if ( tableType === 'letterSpacing') {
    return (
      <td className={classNames('cbdsws-c-tokensTable__' + tableType + 'Example--cell')} data-cell="Example">
        <div className={classNames('cbdsws-c-tokensTable__' + tableType + 'Example--text')} style={{ letterSpacing: value }}>Made Ready</div>
      </td>
    );
  }

  // Type Scale
  if (tableType === 'typeScale') {
    let displayValue = (platform === 'ios') ? `${value}px` : value;
    return (
      <td className={classNames('cbdsws-c-tokensTable__' + tableType + 'Example--cell')} data-cell="Name">
        <div className={classNames('cbdsws-c-tokensTable__' + tableType + 'Example--text')} style={{ fontSize: displayValue, lineHeight: 'normal' }}>Made Ready</div>
      </td>
    );
  }

  return null;
};

TokenExample.propTypes = {
  tableType: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

TokenExample.defaultProps = {
  tableType: null,
  value: null,
};

export default TokenExample;
