// import React from "react";
import FileUploader from "./FileUploader.mdx";
import Guidelines from "./Guidelines.mdx";
// import Accessibility from "./Accessibility.mdx";
// import Code from "./Code.mdx";

const FileUploaderPage = {
  mdx: FileUploader,
  breadcrumbs: "Components/Web Responsive Library/Forms",
  alias: "components/web-responsive-library/forms/file-uploader",
  pathExact: false,
  title: "File Uploader",
  menuLabel: "File Uploader",
  published: true,
  date: "2023-06-12", // Show when last updated
  summary: "",

  tabs: [
    {
      label: "Guidelines",
      mdx: Guidelines,
      summary: "The File Uploader component allows users to upload content of their own.",
      alias: "/components/web-responsive-library/forms/file-uploader/guidelines",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Form",
        "Forms",
        "File Uploader",
        'File',
        'Uploader',
        "Design",
        "Guidelines",
      ],
      meta: {
        title: "Guidelines - File Uploader - Forms - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    /*{
      label: "Accessibility",
      mdx: Accessibility,
      summary: <></>,
      alias: "/components/web-responsive-library/forms/file-uploader/accessibility",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Form",
        "Forms",
        "File Uploader",
        'File',
        'Uploader',
        "Accessibility",
      ],
      meta: {
        title: "Accessibility - File Uploader - Forms - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    {
      label: "Code",
      mdx: Code,
      summary: <></>,
      alias: "/components/web-responsive-library/forms/file-uploader/code",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Form",
        "Forms",
        "File Uploader",
        'File',
        'Uploader',
        "Code",
      ],
      meta: {
        title: "Code - File Uploader - Forms - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },*/
  ],

  // SEO and Search
  tags: [],
  meta: {
    title: "File Uploader - Forms - Web Responsive Library - Components",
    description: "",
    keywords: [],
  },

  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "",
    },
    react: {
      status: "na",
      version: "v1.0.0",
      link: "//index.html?path=/story/components-forms-text-input--default",
    },
    angular: {
      status: "na",
      version: "v1.0.0",
      link: "index.html?path=/story/components-forms-FileUploader--default",
    },
    figma: {
      status: "released",
      version: "",
      link: "https://www.figma.com/file/mj41OHGCokcJ9N7D0q8D9Q/CBDS-UI-Kit-V1.1?node-id=1794%3A6349&t=EtO1hs1cXNxMVxRj-1",
    },
  },
};

export default FileUploaderPage;
