const setPageTitle = (title, pageData) => {
  document.title = `${title} | Citizens Design System (v${process.env.VERSION})`;
  
  if(pageData){ //Start of Analytics handling when page title is updated
    
    //set all values default to blank values
    var cbType = "";
    var cbFunction = "";
    var cbSubFunction = "";
    var cbPage = "";

    if(pageData.alias){ //Parse from alias the various page titles/sections for analytics
      if(pageData.alias.indexOf("/") == -1){ //has no subsections at all, only use alias
        cbPage = pageData.alias;

      } else {
        var aliasStr;
        if (pageData.alias.indexOf("/") == 0){ //starts with /, prune that
          aliasStr = pageData.alias.slice(1);
        } else {
          aliasStr = pageData.alias;
        }
        var aliasArr = aliasStr.split("/"); //split into an array
        switch(aliasArr.length) { //Switch to handle various scenarios cause its kinda wonky
          case 2:
            cbType = aliasArr[0];
            cbFunction = "";
            cbSubFunction = "";
            cbPage = aliasArr[1];
            break;
          case 3:
            cbType = aliasArr[0];
            cbFunction = aliasArr[1];
            cbSubFunction = "";
            cbPage = aliasArr[2];
            break;
          case 4:
            cbType = aliasArr[0];
            cbFunction = aliasArr[1];
            cbSubFunction = aliasArr[2];
            cbPage = aliasArr[3];
            break;
          default:
            //In cases of 1 or more than 4, only use alias.
            cbPage = pageData.alias;
            break;
        }
      }
    } else {
      cbPage = title;
    }
    
    window.CBDL.pgInfo.cbpgType = cbType; //Top level section
    window.CBDL.pgInfo.cbpgFunction = cbFunction; //Second level header
    window.CBDL.pgInfo.cbpgSubFunction = cbSubFunction; //Third level header
    window.CBDL.pgInfo.cbpgName = cbPage; //Page title/name 

    window.reloadCBDLAnalyticsScript(); //Call reload of script
  }
};

export default setPageTitle;
