// import React from 'react';
// import Link from '../../../components/Link';
import UsAddress from './UsAddress.mdx';

const UsAddressPage = {
  mdx: UsAddress,
  breadcrumbs: "Patterns/Fields",
  alias: "patterns/fields/us-address",
  pathExact: false,
  title: "U.S. Address",
  menuLabel: 'U.S. Address',
  published: true,
  order: 2,
  date: '2024-02-29', // Show when last updated
  summary: "",

  // SEO and Search
  tags: [
    "Form",
    "Address",
    "Field",
    "Patterns",
    "Fieldset",
    "Input",
  ],
  meta: {
    description: '',
    title: 'U.S. Address - Fields - Patterns',
    keywords: [],
  },
  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "",
    },
    react: {
      status: "na",
      version: "v1.0.0",
      link: "",
    },
    angular: {
      status: "released",
      version: "v1.0.0",
      link: "/index.html?path=/story/patterns-forms-u-s-address--default",
    },
    figma: {
      status: "released",
      version: "",
      link: "https://www.figma.com/file/mj41OHGCokcJ9N7D0q8D9Q/CBDS-UI-Kit-V1.1?node-id=1633%3A6407&t=c0P3NDyo4OqUOeuw-1",
    },
  },
};

export default UsAddressPage;
