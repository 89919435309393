import Resources from './Resources.mdx';

const ResourcesPage = {
  mdx: Resources,
  breadcrumbs: "Accessibility",
  alias: "accessibility/resources",
  pathExact: false,
  title: "Resources",
  menuLabel: 'Resources',
  published: true,
  order: 5,
  date: '2023-01-24', // Show when last updated
  summary: "At CBDS, we understand accessibility can be quite overwhelming. We're intending this resource page to be an ever-growing  knowledge repository for any external content to promote good accessibility practices in the design, development and testing of any of our organizational assets.",

  // SEO and Search
  tags: [
    'CBDS',
    'Foundations',
    'accessibility',
    'A11Y',
    'resources',
    'ADA',
    'help',
    'WCAG',
    'Tools'
  ],
  meta: {
    title: 'Resources - Accessibility',
    description: '',
    keywords: [],
  },
};

export default ResourcesPage;
