import React from "react";
import Transactions from "./Transactions.mdx";
import Guidelines from "./Guidelines.mdx";
import Accessibility from "./Accessibility.mdx";

const TransactionsPage = {
  mdx: Transactions,
  breadcrumbs: "Components/Mobile UI Library",
  alias: "components/mobile-ui-library/transactions",
  pathExact: false,
  title: "Transactions",
  menuLabel: "Transactions",
  published: true,
  date: "2023-12-07", // Show when last updated
  summary: "",
  tabs: [
    {
      label: "Guidelines",
      mdx: Guidelines,
      summary: "Transactions show financial activity.",
      alias: "/components/mobile-ui-library/transactions/guidelines",
      // SEO and Search
      tags: [
        "Component",
        "Mobile UI Library",
        "Transactions",
        "Design",
        "Guidelines",
      ],
      meta: {
        title: "Guidelines - Transactions - Mobile UI Library - Components",
        description: "",
        keywords: [],
      },
    },
    {
      label: "Accessibility",
      mdx: Accessibility,
      summary: <></>,
      alias: "/components/mobile-ui-library/transactions/accessibility",
      // SEO and Search
      tags: [
        "Component",
        "Mobile UI Library",
        "Transactions",
        "Accessibility",
      ],
      meta: {
        title: "Accessibility - Transactions - Mobile UI Library - Components",
        description: "",
        keywords: [],
      },
    },
    /*{
      label: "Code",
      mdx: Code,
      summary: <></>,
      alias: "/components/mobile-ui-library/transactions/code",
      // SEO and Search
      tags: [
        "Component",
        "Mobile UI Library",
        "Transactions",
        "Code",
      ],
      meta: {
        title: "Code - Transactions - Mobile UI Library - Components",
        description: "",
        keywords: [],
      },
    },*/
  ],
  // SEO and Search
  tags: [],
  meta: {
    title: "Transactions - Mobile UI Library - Components",
    description: "",
    keywords: [],
  },

  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "",
    },
    react: {
      status: "",
      version: "v1.0.0",
      link: "",
    },
    angular: {
      status: "na",
      version: "v1.0.0",
      link: "",
    },
    figma: {
      status: "released",
      version: "",
      link: "https://www.figma.com/file/Ps4nHYn45cAUlknFyS13Wl/OneApp-UI-Kit-V1.1?type=design&node-id=167%3A1057&mode=design&t=6ckAfdgkElGr5Cie-1",
    },
  },
};

export default TransactionsPage;
