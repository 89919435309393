import React from 'react';
import Introduction from './Introduction.mdx';

const IntroductionPage = {
  mdx: Introduction,
  breadcrumbs: "Brand Guidelines/Private Banking Guidelines",
  alias: "brand-guidelines/private-banking-guidelines/introduction",
  pathExact: false,
  title: "Introduction",
  menuLabel: 'Introduction',
  published: true,
  order: 1,
  date: '2024-01-24', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Private Banking',
    'Guidelines',
    'Private Banking Brand Guidelines',
    'Introduction',
  ],
  meta: {
    title: 'Introduction - Private Banking Guidelines - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default IntroductionPage;
