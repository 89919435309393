import React from 'react';
import Link from '../../../components/Link';
import Space from './Space.mdx';

const SpacePage = {
  mdx: Space,
  breadcrumbs: "Foundations",
  alias: "foundations/space",
  pathExact: false,
  title: "Space",
  menuLabel: 'Space',
  published: true,
  order: 7,
  date: '2023-01-17', // Show when last updated
  summary: <>CBDS UI Kit contains a <Link variant="lead" internalUrl="#scale">spacing system</Link> and corresponding spacing component. We implement spacing using increments of our base unit size of 4px. To learn more about the base unit and pixel grid, see <Link variant="lead" internalUrl="/foundations/layout">Layout</Link>. The spacing system comes with a spacing scale. The spacing scale contains pre-set values of spacing from 4px to 256px that you can apply to your design. This ensures spacing consistency across components, templates, and pages across all Citizens Bank applications.</>,

  // SEO and Search
  tags: [
    'CBDS',
    'Foundations',
    'Space',
    'Spacing',
    'Spacing System',
    'Design',
  ],
  meta: {
    title: 'Space - Foundations',
    description: '',
    keywords: [],
  },
};

export default SpacePage;
