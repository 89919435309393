import Overview from "./Overview.mdx";

const OverviewPage = {
  mdx: Overview,
  breadcrumbs: "Accessibility/Testing",
  alias: "accessibility/testing/overview",
  pathExact: false,
  title: "Overview",
  menuLabel: "Overview",
  published: true,
  order: 4,
  date: "2024-09-17", // Show when last updated
  summary: "",


  // SEO and Search
  tags: [
    "Accessibility",
    "A11Y",
    "ADA",
    "Developers",
    "Manual",
    "Automated",
    "Methodologies",
    "QA",
    "Content Publisher",
    "Developer",
    "WCAG",
  ],
  meta: {
    title: "Overview - Testing - Accessibility",
    description: "",
    keywords: [],
  },
};

export default OverviewPage;
