import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
/* eslint-disable */
import { Fancybox } from "@fancyapps/ui";
/* eslint-enable */

const DoDont = ({
  altText,
  bodyText,
  classes,
  customBlockClass,
  customClass,
  frameBgStyle,
  frameInsetSpace,
  heading,
  imageSource,
  items,
  layout,
  sectionType,
  type,
}) => {
  const frameBgStyleClass = frameBgStyle ? "cbdsws-c-doDont__frame--lightGray" : "";
  const frameInsetSpaceClass = frameInsetSpace ? "cbdsws-c-doDont__frame--none" : "";
  let typeVariantClass = type ? `cbdsws-c-doDont--${type}` : "";
  const imgClass = imageSource ? "cbdsws-c-doDont__image" : "cbdsws-c-doDont--noImg";
  let typeClass = sectionType ? `cbdsws-c-section--${type}` : "";
  let layoutClass = layout ? `cbdsws-c-section--${layout}` : "";

  if (heading || type) {
    if (heading) {
      // return heading;
    } else if (type.toUpperCase() === "DO"){
      typeVariantClass = "cbdsws-c-doDont--do";
      heading = "Do";
    }
    else {
      typeVariantClass = "cbdsws-c-doDont--dont";
      heading = "Don't";
    }
  }

  return (
    <div className={classNames("cbdsws-c-section", { layoutClass }, { typeClass }, { customClass })}>
      <div className={classNames("cbdsws-c-section__block", { customBlockClass })}>
        {!imageSource && (
          <div className={classNames("cbdsws-c-doDont", classes, typeVariantClass, imgClass)}>
            <div className="cbdsws-c-doDont__body">
              <div className="cbdsws-c-doDont__content">
                <h4 className="cbdsws-c-doDont__heading">
                  {heading}
                </h4>
                {!!bodyText && (
                  <p className="cbdsws-c-doDont__bodyText">{bodyText}</p>)
                }
                {!!items && (
                  <ul className="cbdsws-c-doDontList">
                    {items.map((item, index) => (
                      <li className="cbdsws-c-doDontList__item" key={index}>{item.text}</li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        )}
        {imageSource && (
          <figure className={classNames("cbdsws-c-doDont", classes, typeVariantClass, imgClass)}>
            <div className={classNames("cbdsws-c-doDont__frame", frameBgStyleClass, frameInsetSpaceClass)}>
              <a
                href={imageSource}
                className={classNames("cbdsws-c-doDont__link", "cbensightenevent")}
                aria-hidden={!altText ? "true" : "false"}
                cbdata-type="image"
                cbdata-reason="__doDontFancybox"
                data-fancybox="gallery"
                data-caption={heading}
              >
                <img
                  className="cbdsws-c-doDont__image"
                  src={imageSource}
                  alt={altText ? altText + " Press enter to open image in dialog. stays on current page" : ""}
                  role={altText ? "img" : "presentation"}
                />
              </a>
            </div>
            <figcaption className="cbdsws-c-doDont__body">
              <div className="cbdsws-c-doDont__content">
                <h4 className="cbdsws-c-doDont__heading">
                  {heading}
                </h4>
                {!!bodyText && (
                  <p className="cbdsws-c-doDont__bodyText">{bodyText}</p>
                )}
                {!!items && (
                  <ul className="cbdsws-c-doDontList">
                    {items.map((item, index) => (
                      <li className="cbdsws-c-doDontList__item" key={index}>{item.text}</li>
                    ))}
                  </ul>
                )}
              </div>
            </figcaption>
          </figure>
        )}
      </div>
    </div>
  );
};

DoDont.propTypes = {
  altText: PropTypes.string,
  bodyText: PropTypes.string,
  classes: PropTypes.string,
  customBlockClass: PropTypes.string,
  customClass: PropTypes.string,
  frameBgStyle: PropTypes.bool,
  frameInsetSpace: PropTypes.bool,
  heading: PropTypes.string,
  imageSource: PropTypes.string,
  items: PropTypes.array,
  layout: PropTypes.string,
  SectionType: PropTypes.string,
  type: PropTypes.oneOf(["do", "dont"]),
};

DoDont.defaultProps = {
  frameBgStyle: false,
  frameInsetSpace: false,
  sectionType: "dodont",
  type: "do",
};

export default DoDont;
