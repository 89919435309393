import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vs } from 'react-syntax-highlighter/dist/esm/styles/prism';

const MarkdownExtended = ({ data, showLineNumbers = true }) => (
  <ReactMarkdown
    className="cbdsws-c-markdown__extended"
    remarkPlugins={[[remarkGfm]]}
    components={{
      code({ inline, className, children, ...props }) {
        const match = /language-(\w+)/.exec(className || '');
        return !inline && match ? (
          <SyntaxHighlighter
            children={String(children).replace(/\n$/, '')}
            style={vs}
            language={match[1]}
            PreTag="div"
            showLineNumbers={showLineNumbers}
            {...props}
          />
        ) : (
          <code className={className} {...props}>
            {children}
          </code>
        );
      }
    }}
  >{data}</ReactMarkdown>
);

export default MarkdownExtended;
