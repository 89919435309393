import React from 'react';
import MadeReady from './MadeReady.mdx';

const MadeReadyPage = {
  mdx: MadeReady,
  breadcrumbs: "Brand Guidelines/Wealth Management Guidelines",
  alias: "brand-guidelines/wealth-management-guidelines/made-ready",
  pathExact: false,
  title: "Made Ready",
  menuLabel: 'Made Ready',
  published: true,
  order: 1,
  date: '2023-12-01', // Show when last updated
  summary: <>Made Ready is the Enterprise brand platform that showcases nonlinear journeys, reflecting that Citizens understands the personal needs of their customers in a way that is uniquely Citizens (see Made Ready brand guidelines for brand tone & manner). </>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Wealth Management',
    'Guidelines',
    'Wealth Management Guidelines',
    'Made Ready',
  ],
  meta: {
    title: 'Made Ready - Wealth Management - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default MadeReadyPage;
