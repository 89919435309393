import React, { useEffect } from "react";
import PropTypes from 'prop-types';

import { CbdsIconButton } from '@cbds/cbds-components-react';

import Logo from "../Logo";

const MobileMenu = ({
  mobileMenuExpand,
  toggleMenu,
  keyListener,
  clickListener,
}) => {

  // Moved useEffect here instead of keeping it in App.js, to trigger it.
  useEffect(() => {

    if (mobileMenuExpand) {
      document.addEventListener("keydown", keyListener);
      window.addEventListener("click", clickListener);
    }

    return () => {
      document.addEventListener("keydown", keyListener);
      window.addEventListener("click", clickListener);
    };
  }, [mobileMenuExpand]);

  return (
    <div className="cbdsws-l-mobileMenuContainer">
      <Logo className="cbdsws-c-mobileLogo" />
      <CbdsIconButton
        variant="ghost"
        uid="mobile-open-menu-button"
        a11yLabel="Open Navigation Menu"
        icon={{
          iconName: "menu"
        }}
        onClick={() => toggleMenu(true)}
      />
    </div>
  );
};

MobileMenu.defaultProps = {
  mobileMenuExpand: false,
  toggleMenu: null,
};

MobileMenu.propTypes = {
  mobileMenuExpand: PropTypes.bool,
  toggleMenu: PropTypes.func,
  keyListener: PropTypes.func,
  clickListener: PropTypes.func,
  appRef: PropTypes.any,
};

export default MobileMenu;
