// import React from "react";
import Tabs from "./Tabs.mdx";
import Guidelines from "./Guidelines.mdx";
// import Accessibility from "./Accessibility.mdx";
// import Code from "./Code.mdx";

const TabsPage = {
  mdx: Tabs,
  breadcrumbs: "Components/Web Responsive Library",
  alias: "components/web-responsive-library/tabs",
  pathExact: false,
  title: "Tabs",
  menuLabel: "Tabs",
  published: true,
  date: "2024-03-13", // Show when last updated
  summary: "",
  tabs: [
    {
      label: "Guidelines",
      mdx: Guidelines,
      summary: "",
      alias: "/components/web-responsive-library/tabs/guidelines",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Tabs",
        "Design",
        "Guidelines",
        "React",
        "Angular",
      ],
      meta: {
        title: "Guidelines - Tabs - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    /*{
      label: "Accessibility",
      mdx: Accessibility,
      summary: <></>,
      alias: "/components/web-responsive-library/tabs/accessibility",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Tabs",
        "Accessibility",
        "React",
        "Angular",
      ],
      meta: {
        title: "Accessibility - Tabs - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    {
      label: "Code",
      mdx: Code,
      summary: <></>,
      alias: "/components/web-responsive-library/tabs/code",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Tabs",
        "Code",
        "React",
        "Angular",
      ],
      meta: {
        title: "Code - Tabs - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },*/
  ],
  // SEO and Search
  tags: [],
  meta: {
    title: "Tabs - Web Responsive Library - Components",
    description: "",
    keywords: [],
  },

  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "index.html?path=/story/components-Overlay",
    },
    react: {
      status: "released",
      version: "v1.0.0",
      link: "/index.html?path=/story/components-tabs--default",
    },
    angular: {
      status: "released",
      version: "v1.0.0",
      link: "/index.html?path=/story/components-tabs-2--default",
    },
    figma: {
      status: "released",
      version: "",
      link: "https://www.figma.com/design/mj41OHGCokcJ9N7D0q8D9Q/CDS-UI-Kit-V1.1?m=auto&node-id=1481-5541&t=XyqXBNsOTQ56qExN-1",
    },
  },
};

export default TabsPage;
