import React from "react";
import HamburgerMenu from "./HamburgerMenu.mdx";
import Guidelines from "./Guidelines.mdx";
import Accessibility from "./Accessibility.mdx";
// import Code from "./Code.mdx";

const HamburgerMenuPage = {
  mdx: HamburgerMenu,
  breadcrumbs: "Components/Mobile UI Library",
  alias: "components/mobile-ui-library/hamburger-menu",
  pathExact: false,
  title: "Hamburger Menu",
  menuLabel: "Hamburger Menu",
  published: true,
  date: "2023-12-08", // Show when last updated
  summary: "",
  tabs: [
    {
      label: "Guidelines",
      mdx: Guidelines,
      summary: "The hamburger menu gives access to different areas of the app. For reference in the screens below, iOS is on the left and Android is on the right.",
      alias: "/components/mobile-ui-library/hamburger-menu/guidelines",
      // SEO and Search
      tags: [
        "Component",
        "Mobile UI Library",
        "Hamburger Menu",
        "Design",
        "Guidelines",
      ],
      meta: {
        title: "Guidelines - Hamburger Menu - Mobile UI Library - Components",
        description: "",
        keywords: [],
      },
    },
    {
      label: "Accessibility",
      mdx: Accessibility,
      summary: <></>,
      alias: "/components/mobile-ui-library/hamburger-menu/accessibility",
      // SEO and Search
      tags: [
        "Component",
        "Mobile UI Library",
        "Hamburger Menu",
        "Accessibility",
      ],
      meta: {
        title: "Accessibility - Hamburger Menu - Mobile UI Library - Components",
        description: "",
        keywords: [],
      },
    },
    /*{
      label: "Code",
      mdx: Code,
      summary: <></>,
      alias: "/components/mobile-ui-library/hamburger-menu/code",
      // SEO and Search
      tags: [
        "Component",
        "Mobile UI Library",
        "Hamburger Menu",
        "Code",
      ],
      meta: {
        title: "Code - Hamburger Menu - Mobile UI Library - Components",
        description: "",
        keywords: [],
      },
    },*/
  ],
  // SEO and Search
  tags: [],
  meta: {
    title: "Hamburger Menu - Mobile UI Library - Components",
    description: "",
    keywords: [],
  },

  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "",
    },
    react: {
      status: "",
      version: "v1.0.0",
      link: "",
    },
    angular: {
      status: "na",
      version: "v1.0.0",
      link: "",
    },
    figma: {
      status: "released",
      version: "",
      link: "https://www.figma.com/file/Ps4nHYn45cAUlknFyS13Wl/OneApp-UI-Kit-V1.1?type=design&node-id=167%3A1057&mode=design&t=6ckAfdgkElGr5Cie-1",
    },
  },
};

export default HamburgerMenuPage;
