import React from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";

import { CbdsButton, CbdsIcon } from '@cbds/cbds-components-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const ButtonInlineCode = ({ children, customClass, type, codeSnippet, copyType, onCopy }) => {
  const typeClass = type && type !== 'snippet' ? `cbdsws-c-inlineCodeBtn cbdsws-c-inlineCodeBtn--${type}` : null;
  const tokenClass = type === 'token' ? 'cbdsws-c-inlineCodeBtn--token' : null;
  let analyticsReason = '__copyCode';
  let codeSnippetPrefix = "";

  switch (type) {
    case 'token':
      analyticsReason = '__copyToken';
      codeSnippetPrefix = '$';
      break;
    case 'color':
      analyticsReason = '__copyColor';
      break;
    case 'logo':
      analyticsReason = '__copyLogo';
      break;
    case 'icon':
      analyticsReason = '__copyIconUI';
      break;
    case 'iconBrand':
      analyticsReason = '__copyIconBrand';
      break;
    case 'text':
      analyticsReason = '__copyText';
      break;
    default:
      analyticsReason = '__copyCode';
      break;
  }

  // Dynamically notifys screen readers of successful copy. Role of alert overrides button text read to user first.
  function notifyScreenReaders() {
    document.getElementById("targetPageTitle").innerHTML = `<div role="alert">${codeSnippet} Copied to Clipboard</div>`;
  }

  return (
    <>
      {type === 'snippet' && (
        <CopyToClipboard text={codeSnippet} onCopy={onCopy}>
          <CbdsButton
            classes={classNames(typeClass, customClass)}
            a11yLabel="copy code snippet to clipboard"
            analyticsType="button"
            analyticsReason={copyType || '__copyCodeBlock'}
            variant="ghost"
            iconNameLeft={type === 'snippet' ? 'clipboard' : null}
            iconNameRight={type === 'token' ? 'clipboard' : null}
            label={children || "Copy Code"}
            data-copy-type={type}
            data-toggle="tooltip"
            data-placement="top"
          />
        </CopyToClipboard>
      )}
      {type !== 'snippet' && (
        <CopyToClipboard text={`${codeSnippetPrefix}${codeSnippet}`} onCopy={onCopy}>
          <button className={classNames("cbdsws-c-inlineCodeBtn", tokenClass, "cbensightenevent")} aria-label={`${codeSnippet} (Copy Code to Clipboard)`} data-dynamic-copy-type="inline" data-copy-text={codeSnippet} data-is-mounted="true" data-toggle="tooltip" data-placement="top" cbdata-type="button" cbdata-reason={`${analyticsReason}: ${codeSnippet}`} onClick={notifyScreenReaders}>
            <code className="cbdsws-c-inlineCodeBtn__code">{`${codeSnippetPrefix}${codeSnippet}`}</code>
            <CbdsIcon classes="cbdsws-c-inlineCodeBtn__icon" iconName="clipboard" />
          </button>
        </CopyToClipboard>
      )}
    </>
  );
};

ButtonInlineCode.propTypes = {
  customClass: PropTypes.string,
  type: PropTypes.string,
  codeSnippet: PropTypes.string,
  copyType: PropTypes.string,
  onCopy: PropTypes.func,
};

ButtonInlineCode.defaultProps = {
  type: "snippet",
  codeSnippet: '',
  onCopy: () => { },
};

export default ButtonInlineCode;
