import React from 'react';
import EnterpriseAudiences from './EnterpriseAudiences.mdx';

const EnterpriseAudiencesPage = {
  mdx: EnterpriseAudiences,
  breadcrumbs: "Brand Guidelines/Enterprise Guidelines",
  alias: "brand-guidelines/enterprise-guidelines/enterprise-audiences",
  pathExact: false,
  title: "Enterprise Audiences",
  menuLabel: 'Enterprise Audiences',
  published: true,
  order: 6,
  date: '2023-01-27', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Enterprise',
    'Guidelines',
    'Enterprise Audiences',
  ],
  meta: {
    title: 'Enterprise Audiences - Enterprise - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default EnterpriseAudiencesPage;
