import React from 'react';
import Introduction from './Introduction.mdx';

const IntroductionPage = {
  mdx: Introduction,
  breadcrumbs: "Brand Guidelines/Commercial Guidelines",
  alias: "brand-guidelines/commercial-guidelines/introduction",
  pathExact: false,
  title: "Introduction",
  menuLabel: 'Introduction',
  published: true,
  order: 1,
  date: '2023-11-28', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Commercial',
    'Guidelines',
    'Commercial Guidelines',
    'Introduction',
  ],
  meta: {
    title: 'Introduction - Commercial - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default IntroductionPage;
