// import React from "react";
import SelectDropdown from "./SelectDropdown.mdx";
import Guidelines from "./Guidelines.mdx";
// import Accessibility from "./Accessibility.mdx";
// import Code from "./Code.mdx";

const SelectDropdownPage = {
  mdx: SelectDropdown,
  breadcrumbs: "Components/Web Responsive Library/Forms",
  alias: "components/web-responsive-library/forms/select-dropdown",
  pathExact: false,
  title: "Select Dropdown",
  menuLabel: "Select Dropdown",
  published: true,
  date: "2024-03-05", // Show when last updated
  summary: "",
  tabs: [
    {
      label: "Guidelines",
      mdx: Guidelines,
      summary:
        "Select Dropdown is used to select a single option from an expanded, predefined list of options.",
      alias: "/components/web-responsive-library/forms/select-dropdown/guidelines",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Select Dropdown",
        "Dropdown",
        "Design",
        "Guidelines",
        "Form",
        "Forms",
        "HTML",
        "Angular",
        "React",
      ],
      meta: {
        title: "Guidelines - Select Dropdown - Forms - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    /*{
      label: "Accessibility",
      mdx: Accessibility,
      summary: <></>,
      alias: "/components/web-responsive-library/forms/select-dropdown/accessibility",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Select Dropdown",
        "Dropdown",
        "Accessibility",
        "Forms",
        "Form",
        "HTML",
        "Angular",
        "React",
      ],
      meta: {
        title: "Accessibility - Select Dropdown - Forms - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    {
      label: "Code",
      mdx: Code,
      summary: <></>,
      alias: "/components/web-responsive-library/forms/select-dropdown/code",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Select Dropdown",
        "Dropdown",
        "Code",
        "Forms",
        "Form",
        "HTML",
        "Angular",
        "React",
      ],
      meta: {
        title: "Code - Select Dropdown - Forms - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },*/
  ],
  // SEO and Search
  tags: [],
  meta: {
    title: "Select Dropdown - Forms - Web Responsive Library - Components",
    description: "",
    keywords: [],
  },

  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "index.html?path=/story/components-select",
    },
    react: {
      status: "released",
      version: "v1.0.0",
      link: "/index.html?path=/story/components-forms-select-dropdown--default",
    },
    angular: {
      status: "released",
      version: "v1.0.0",
      link: "index.html?path=/story/components-forms-select-dropdown--default",
    },
    figma: {
      status: "released",
      version: "",
      link: "https://www.figma.com/file/mj41OHGCokcJ9N7D0q8D9Q/CBDS-UI-Kit-V1.1?node-id=1794%3A6349&t=EtO1hs1cXNxMVxRj-1",
    },
  },
};

export default SelectDropdownPage;
