import React from 'react';
import ToneOfVoice from './ToneOfVoice.mdx';

const ToneOfVoicePage = {
  mdx: ToneOfVoice,
  breadcrumbs: "Brand Guidelines/Enterprise Guidelines",
  alias: "brand-guidelines/enterprise-guidelines/tone-of-voice",
  pathExact: false,
  title: "Tone of Voice",
  menuLabel: 'Tone Of Voice',
  published: true,
  order: 8,
  date: '2023-01-27', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Enterprise',
    'Guidelines',
    'Tone',
    'Voice',
  ],
  meta: {
    title: 'Tone of Voice - Enterprise - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default ToneOfVoicePage;
