// import React from "react";
import GlobalFooter from "./GlobalFooter.mdx";
import Guidelines from "./Guidelines.mdx";
// import Accessibility from "./Accessibility.mdx";
// import Code from "./Code.mdx";

const GlobalFooterPage = {
  mdx: GlobalFooter,
  breadcrumbs: "Components/Web Responsive Library",
  alias: "components/web-responsive-library/global-footer",
  pathExact: false,
  title: "Global Footer",
  menuLabel: "Global Footer",
  published: true,
  date: "2023-05-09", // Show when last updated
  summary: "",

  tabs: [
    {
      label: "Guidelines",
      mdx: Guidelines,
      summary: "CBDS Global Footer component is found directly below a page’s content area and is displayed globally across all pages on a website. The Global Footer may contain various feature elements to suit the needs of your application.",
      alias: "/components/web-responsive-library/global-footer/guidelines",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Global Footer",
        'Global',
        'Footer',
        "Design",
        "Guidelines",
        "Documentation",
        "HTML",
        "React",
        "Angular",
      ],
      meta: {
        title: "Guidelines - Global Footer - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    /*{
      label: "Accessibility",
      mdx: Accessibility,
      summary: <></>,
      alias: "/components/web-responsive-library/global-footer/accessibility",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Global Footer",
        'Global',
        'Footer',
        "Accessibility",
        "Documentation",
        "HTML",
        "React",
        "Angular",
      ],
      meta: {
        title: "Accessibility - Global Footer - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    {
      label: "Code",
      mdx: Code,
      summary: <></>,
      alias: "/components/web-responsive-library/global-footer/code",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Global Footer",
        'Global',
        'Footer',
        "Developer",
        "Code",
        "HTML",
        "React",
        "Angular",
      ],
      meta: {
        title: "Code - Global Footer - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },*/
  ],

  // SEO and Search
  tags: [],
  meta: {
    title: "Global Footer - Web Responsive Library - Components",
    description: "",
    keywords: [],
  },

  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "",
    },
    react: {
      status: "released",
      version: "v1.0.0",
      link: "?path=/story/components-global-footer--default",
    },
    angular: {
      status: "released",
      version: "v1.0.0",
      link: "index.html?path=/story/components-global-footer--default",
    },
    figma: {
      status: "released",
      version: "",
      link: "https://www.figma.com/design/mj41OHGCokcJ9N7D0q8D9Q/CDS-UI-Kit-V1.1?m=auto&node-id=403-6679&t=XyqXBNsOTQ56qExN-1",
    },
  },
};

export default GlobalFooterPage;
