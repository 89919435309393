import React from 'react';
import Design from './Design.mdx';

const DesignPage = {
  mdx: Design,
  breadcrumbs: "Brand Guidelines/Private Banking Guidelines",
  alias: "brand-guidelines/private-banking-guidelines/design",
  pathExact: false,
  title: "Design",
  menuLabel: 'Design',
  published: true,
  order: 3,
  date: '2024-01-29', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Private Banking',
    'Guidelines',
    'Private Banking Brand Guidelines',
    'Design',
  ],
  meta: {
    title: 'Design - Private Banking Guidelines - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default DesignPage;
