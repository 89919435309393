import React from 'react';
import Cbds2022Cycle05Newsletter from './Cbds2022Cycle05Newsletter.mdx';

const Cbds2022Cycle05NewsletterPage = {
  mdx: Cbds2022Cycle05Newsletter,
  breadcrumbs: "About/Newsletter",
  alias: "about/newsletters/cbds-2022-cycle05-newsletter",
  pathExact: false,
  title: "CBDS 2022 Cycle 05 Newsletter",
  menuLabel: 'Cbds 2022 Cycle05 Newsletter',
  published: true,
  hideFromNav: true,
  //order: 3,
  date: '2023-02-17', // Show when last updated
  summary: <><strong><em>Timeline:</em></strong> <em>August 17, 2022 &rarr; October 11, 2022<br/></em><br/>Fall's here, bringing apple cider doughnuts, spooky Halloween stuff and more pumpkin spice than the entire planet of Arrakis! Cycle 05 brings the all-new release of the Consumer Brand Guidelines, new brand colors, documentation and more. There's a lot of exciting work in progress and on the horizon. IN this cycle we focused on 3 main areas, including:</>,

  // SEO and Search
  tags: [
    'CBDS',
    'About',
    'Newsletter',
    'Updates',
    'CBDS 2022 Cycle 06 Newsletter',
    'Release Notes',
  ],
  meta: {
    description: '',
    title: 'CBDS 2022 Cycle 05 Newsletter - Newsletters - About',
    keywords: [],
  },
};

export default Cbds2022Cycle05NewsletterPage;
