import React from 'react';
import ColorPalette from './ColorPalette.mdx';

const ColorPalettePage = {
  mdx: ColorPalette,
  breadcrumbs: "Brand Guidelines/Business Banking Guidelines",
  alias: "brand-guidelines/business-banking-guidelines/color-palette",
  pathExact: false,
  title: "Color Palette",
  menuLabel: 'Color Palette',
  published: true,
  order: 6,
  date: '2023-09-28', // Show when last updated
  summary: <>Our color palettes utilize Deep Green and Light Gray as our primary colors. This gives the system a confident and elegant presence.

  Citizens Green is still utilized within the secondary palette and is most prominently featured in the Citizens logo.

  This yields a strong nod to the core brand while still setting up Business Banking  with its own color language.</>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Business Banking',
    'Guidelines',
    'Business Banking Guidelines',
    'Color Palette',
    'Business Banking Color Palette',
    'Business Banking Green'
  ],
  meta: {
    title: 'Color Palette - Business Banking - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default ColorPalettePage;
