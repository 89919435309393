
// 05_gettingStarted
import gettingStarted_forDesigners from '../pages/05_gettingStarted/05_forDesigners';
// import getttingStarted_forDevelopers from '../pages/05_gettingStarted/06_ForDevelopers';
import gettingStarted_forDevelopers_Web_Introduction from '../pages/05_gettingStarted/06_ForDevelopers/01_Web/01_Introduction';
import gettingStarted_forDevelopers_Web_Installation from '../pages/05_gettingStarted/06_ForDevelopers/01_Web/02_Installation';
import gettingStarted_forDevelopers_Web_QuickStarts from '../pages/05_gettingStarted/06_ForDevelopers/01_Web/03_QuickStarts';
import gettingStarted_forDevelopers_Web_Usage from '../pages/05_gettingStarted/06_ForDevelopers/01_Web/04_Usage';
import gettingStarted_forDevelopers_Web_CSSNamingConventions from '../pages/05_gettingStarted/06_ForDevelopers/01_Web/05_CSSNamingConventions';
// 10_accessibility
import accessibility_Overview from '../pages/10_accessibility/01_Overview';
import accessibility_GettingStarted_AccessibilityAtEveryStage from '../pages/10_accessibility/02_GettingStarted/AccessibilityAtEveryStage';
import accessibility_GettingStarted_Designers from '../pages/10_accessibility/02_GettingStarted/Designers';
import accessibility_GettingStarted_Developers from '../pages/10_accessibility/02_GettingStarted/Developers';
import accessibility_Guidelines_Designers from '../pages/10_accessibility/03_Guidelines/Designers';
import accessibility_Guidelines_Mobile from '../pages/10_accessibility/03_Guidelines/Mobile';
import accessibility_Guidelines_SinglePageApplications from '../pages/10_accessibility/03_Guidelines/SinglePageApplications';
import accessibility_Guidelines_Web from '../pages/10_accessibility/03_Guidelines/Web';
import accessibility_Testing_Overview from '../pages/10_accessibility/04_Testing/01_Overview';
import accessibility_Testing_Developers from '../pages/10_accessibility/04_Testing/Developers';
import accessibility_Testing_Qa from '../pages/10_accessibility/04_Testing/Qa';
import accessibility_Resources from '../pages/10_accessibility/05_Resources';

// 15_brandGuidelines
import brandGuidelines_BusinessBankingGuidelines_Purpose from '../pages/15_brandGuidelines/BusinessBankingGuidelines/01_Purpose';
import brandGuidelines_BusinessBankingGuidelines_Audience from '../pages/15_brandGuidelines/BusinessBankingGuidelines/02_Audience';
import brandGuidelines_BusinessBankingGuidelines_Strategy from '../pages/15_brandGuidelines/BusinessBankingGuidelines/03_Strategy';
import brandGuidelines_BusinessBankingGuidelines_ToneOfVoice from '../pages/15_brandGuidelines/BusinessBankingGuidelines/04_ToneOfVoice';
import brandGuidelines_BusinessBankingGuidelines_Photography from '../pages/15_brandGuidelines/BusinessBankingGuidelines/05_Photography';
import brandGuidelines_BusinessBankingGuidelines_ColorPalette from '../pages/15_brandGuidelines/BusinessBankingGuidelines/06_ColorPalette';
import brandGuidelines_BusinessBankingGuidelines_Logo from '../pages/15_brandGuidelines/BusinessBankingGuidelines/07_Logo';
import brandGuidelines_BusinessBankingGuidelines_Typography from '../pages/15_brandGuidelines/BusinessBankingGuidelines/08_Typography';
import brandGuidelines_BusinessBankingGuidelines_Layout from '../pages/15_brandGuidelines/BusinessBankingGuidelines/09_Layout';
import brandGuidelines_BusinessBankingGuidelines_ApplicationExamples from '../pages/15_brandGuidelines/BusinessBankingGuidelines/10_ApplicationExamples';
import brandGuidelines_BusinessBankingGuidelines_Contact from '../pages/15_brandGuidelines/BusinessBankingGuidelines/11_Contact';
import brandGuidelines_CommercialGuidelines_Introduction from '../pages/15_brandGuidelines/CommercialGuidelines/01_Introduction';
import brandGuidelines_CommercialGuidelines_ToneAndLanguage from '../pages/15_brandGuidelines/CommercialGuidelines/02_ToneAndLanguage';
import brandGuidelines_CommercialGuidelines_Photography from '../pages/15_brandGuidelines/CommercialGuidelines/03_Photography';
import brandGuidelines_CommercialGuidelines_VisualElement from '../pages/15_brandGuidelines/CommercialGuidelines/04_VisualElement';
import brandGuidelines_CommercialGuidelines_LayoutSystem from '../pages/15_brandGuidelines/CommercialGuidelines/05_LayoutSystem';
import brandGuidelines_CommercialGuidelines_ApplicationExamples from '../pages/15_brandGuidelines/CommercialGuidelines/06_ApplicationExamples';
import brandGuidelines_CommercialGuidelines_Contact from '../pages/15_brandGuidelines/CommercialGuidelines/07_Contact';
import brandGuidelines_ConsumerGuidelines_WhoIsThisGuideFor from '../pages/15_brandGuidelines/ConsumerGuidelines/01_WhoIsThisGuideFor';
import brandGuidelines_ConsumerGuidelines_Mission from '../pages/15_brandGuidelines/ConsumerGuidelines/02_Mission';
import brandGuidelines_ConsumerGuidelines_Strategy from '../pages/15_brandGuidelines/ConsumerGuidelines/03_Strategy';
import brandGuidelines_ConsumerGuidelines_LifeIsAJourney from '../pages/15_brandGuidelines/ConsumerGuidelines/04_LifeIsAJourney';
import brandGuidelines_ConsumerGuidelines_ConsumerColor from '../pages/15_brandGuidelines/ConsumerGuidelines/05_ConsumerColor';
import brandGuidelines_ConsumerGuidelines_ToneOfVoice from '../pages/15_brandGuidelines/ConsumerGuidelines/06_ToneOfVoice';
import brandGuidelines_ConsumerGuidelines_Typefaces from '../pages/15_brandGuidelines/ConsumerGuidelines/07_Typefaces';
import brandGuidelines_ConsumerGuidelines_Photography from '../pages/15_brandGuidelines/ConsumerGuidelines/08_Photography';
import brandGuidelines_ConsumerGuidelines_Illustration from '../pages/15_brandGuidelines/ConsumerGuidelines/09_Illustration';
import brandGuidelines_ConsumerGuidelines_GraphicElements from '../pages/15_brandGuidelines/ConsumerGuidelines/10_GraphicElements';
import brandGuidelines_ConsumerGuidelines_HowDoWeShowUp from '../pages/15_brandGuidelines/ConsumerGuidelines/11_HowDoWeShowUp';
import brandGuidelines_ConsumerGuidelines_BrandSonicGuidelines from '../pages/15_brandGuidelines/ConsumerGuidelines/12_BrandSonicGuidelines';
import brandGuidelines_ConsumerGuidelines_Iconography from '../pages/15_brandGuidelines/ConsumerGuidelines/13_Iconography';
import brandGuidelines_ConsumerGuidelines_Contacts from '../pages/15_brandGuidelines/ConsumerGuidelines/14_Contacts';
import brandGuidelines_EnterpriseGuidelines_WhoIsThisGuideFor from '../pages/15_brandGuidelines/EnterpriseGuidelines/01_WhoIsThisGuideFor';
import brandGuidelines_EnterpriseGuidelines_Mission from '../pages/15_brandGuidelines/EnterpriseGuidelines/02_Mission';
import brandGuidelines_EnterpriseGuidelines_EveryJourneyIsUnique from '../pages/15_brandGuidelines/EnterpriseGuidelines/03_EveryJourneyIsUnique';
import brandGuidelines_EnterpriseGuidelines_Strategy from '../pages/15_brandGuidelines/EnterpriseGuidelines/04_Strategy';
import brandGuidelines_EnterpriseGuidelines_BrandObjectives from '../pages/15_brandGuidelines/EnterpriseGuidelines/05_BrandObjectives';
import brandGuidelines_EnterpriseGuidelines_EnterpriseAudiences from '../pages/15_brandGuidelines/EnterpriseGuidelines/06_EnterpriseAudiences';
import brandGuidelines_EnterpriseGuidelines_PrinciplesOfTheJourney from '../pages/15_brandGuidelines/EnterpriseGuidelines/07_PrinciplesOfTheJourney';
import brandGuidelines_EnterpriseGuidelines_ToneOfVoice from '../pages/15_brandGuidelines/EnterpriseGuidelines/08_ToneOfVoice';
import brandGuidelines_EnterpriseGuidelines_Copy from '../pages/15_brandGuidelines/EnterpriseGuidelines/09_Copy';
import brandGuidelines_EnterpriseGuidelines_EnterpriseLogos from '../pages/15_brandGuidelines/EnterpriseGuidelines/10_EnterpriseLogos';
import brandGuidelines_EnterpriseGuidelines_BrandColorPalette from '../pages/15_brandGuidelines/EnterpriseGuidelines/11_BrandColorPalette';
import brandGuidelines_EnterpriseGuidelines_Accessibility from '../pages/15_brandGuidelines/EnterpriseGuidelines/12_Accessibility';
import brandGuidelines_EnterpriseGuidelines_Fonts from '../pages/15_brandGuidelines/EnterpriseGuidelines/13_Fonts';
import brandGuidelines_EnterpriseGuidelines_EnterpriseIcons from '../pages/15_brandGuidelines/EnterpriseGuidelines/14_EnterpriseIcons';
import brandGuidelines_EnterpriseGuidelines_Contacts from '../pages/15_brandGuidelines/EnterpriseGuidelines/15_Contacts';
import brandGuidelines_PrivateBankingGuidelines_Introduction from '../pages/15_brandGuidelines/PrivateBankingGuidelines/01_Introduction';
import brandGuidelines_PrivateBankingGuidelines_ToneAndLanguage from '../pages/15_brandGuidelines/PrivateBankingGuidelines/02_ToneAndLanguage';
import brandGuidelines_PrivateBankingGuidelines_Design from '../pages/15_brandGuidelines/PrivateBankingGuidelines/03_Design';
import brandGuidelines_WealthManagementGuidelines_Purpose from '../pages/15_brandGuidelines/WealthManagementGuidelines/01_Purpose';
import brandGuidelines_WealthManagementGuidelines_MadeReady from '../pages/15_brandGuidelines/WealthManagementGuidelines/02_MadeReady';
import brandGuidelines_WealthManagementGuidelines_WeSeeYou from '../pages/15_brandGuidelines/WealthManagementGuidelines/03_WeSeeYou';
import brandGuidelines_WealthManagementGuidelines_WealthWrappedInWarmth from '../pages/15_brandGuidelines/WealthManagementGuidelines/04_WealthWrappedInWarmth';
import brandGuidelines_WealthManagementGuidelines_FourPrincipleDesignApproach from '../pages/15_brandGuidelines/WealthManagementGuidelines/05_FourPrincipleDesignApproach';
import brandGuidelines_WealthManagementGuidelines_ToneAndLanguage from '../pages/15_brandGuidelines/WealthManagementGuidelines/06_ToneAndLanguage';
import brandGuidelines_WealthManagementGuidelines_Photography from '../pages/15_brandGuidelines/WealthManagementGuidelines/07_Photography';
import brandGuidelines_WealthManagementGuidelines_VisualDesignSystem from '../pages/15_brandGuidelines/WealthManagementGuidelines/08_VisualDesignSystem';
import brandGuidelines_WealthManagementGuidelines_Examples from '../pages/15_brandGuidelines/WealthManagementGuidelines/09_Examples';
import brandGuidelines_WealthManagementGuidelines_BringingItTogether from '../pages/15_brandGuidelines/WealthManagementGuidelines/10_BringingItTogether';
import brandGuidelines_WealthManagementGuidelines_Contacts from '../pages/15_brandGuidelines/WealthManagementGuidelines/11_Contacts';

// 20_components
import components_MobileUILibrary_AccountCards from '../pages/20_components/MobileUILibrary/AccountCards';
import components_MobileUILibrary_AlertDialogs from '../pages/20_components/MobileUILibrary/AlertDialogs';
import components_MobileUILibrary_ArticleContainer from '../pages/20_components/MobileUILibrary/ArticleContainer';
import components_MobileUILibrary_BottomNavigation from '../pages/20_components/MobileUILibrary/BottomNavigation';
import components_MobileUILibrary_Buttons from '../pages/20_components/MobileUILibrary/Buttons';
import components_MobileUILibrary_FeatureCards from '../pages/20_components/MobileUILibrary/FeatureCards';
import components_MobileUILibrary_HamburgerMenu from '../pages/20_components/MobileUILibrary/HamburgerMenu';
import components_MobileUILibrary_ListItem from '../pages/20_components/MobileUILibrary/ListItem';
import components_MobileUILibrary_PickerCells from '../pages/20_components/MobileUILibrary/PickerCells';
import components_MobileUILibrary_ProgressIndicator from '../pages/20_components/MobileUILibrary/ProgressIndicator';
import components_MobileUILibrary_QuickLinks from '../pages/20_components/MobileUILibrary/QuickLinks';
import components_MobileUILibrary_RadioButtons from '../pages/20_components/MobileUILibrary/RadioButtons';
import components_MobileUILibrary_Tabs from '../pages/20_components/MobileUILibrary/Tabs';
import components_MobileUILibrary_Toggles from '../pages/20_components/MobileUILibrary/Toggles';
import components_MobileUILibrary_TopAppBar from '../pages/20_components/MobileUILibrary/TopAppBar';
import components_MobileUILibrary_Transactions from '../pages/20_components/MobileUILibrary/Transactions';
import components_SalesforceLibrary_SalesforceOverview from '../pages/20_components/SalesforceLibrary/01_SalesforceOverview';
import components_SalesforceLibrary_Accordion from '../pages/20_components/SalesforceLibrary/Accordion';
import components_SalesforceLibrary_ActivityTimeline from '../pages/20_components/SalesforceLibrary/ActivityTimeline';
import components_SalesforceLibrary_Alert from '../pages/20_components/SalesforceLibrary/Alert';
import components_SalesforceLibrary_Avatar from '../pages/20_components/SalesforceLibrary/Avatar';
import components_SalesforceLibrary_Badge from '../pages/20_components/SalesforceLibrary/Badge';
import components_SalesforceLibrary_BrandBand from '../pages/20_components/SalesforceLibrary/BrandBand';
import components_SalesforceLibrary_Breadcrumb from '../pages/20_components/SalesforceLibrary/Breadcrumb';
import components_SalesforceLibrary_Button from '../pages/20_components/SalesforceLibrary/Button';
import components_SalesforceLibrary_ButtonGroup from '../pages/20_components/SalesforceLibrary/ButtonGroup';
import components_SalesforceLibrary_ButtonIcon from '../pages/20_components/SalesforceLibrary/ButtonIcon';
import components_SalesforceLibrary_Card from '../pages/20_components/SalesforceLibrary/Card';
import components_SalesforceLibrary_Chat from '../pages/20_components/SalesforceLibrary/Chat';
import components_SalesforceLibrary_Checkbox from '../pages/20_components/SalesforceLibrary/Checkbox';
import components_SalesforceLibrary_CheckboxButton from '../pages/20_components/SalesforceLibrary/CheckboxButton';
import components_SalesforceLibrary_CheckboxToggle from '../pages/20_components/SalesforceLibrary/CheckboxToggle';
import components_SalesforceLibrary_ColorPicker from '../pages/20_components/SalesforceLibrary/ColorPicker';
import components_SalesforceLibrary_Combobox from '../pages/20_components/SalesforceLibrary/Combobox';
import components_SalesforceLibrary_Counter from '../pages/20_components/SalesforceLibrary/Counter';
import components_SalesforceLibrary_DataTable from '../pages/20_components/SalesforceLibrary/DataTable';
import components_SalesforceLibrary_Datepicker from '../pages/20_components/SalesforceLibrary/Datepicker';
import components_SalesforceLibrary_DockedComposer from '../pages/20_components/SalesforceLibrary/DockedComposer';
import components_SalesforceLibrary_DockedUtilityBar from '../pages/20_components/SalesforceLibrary/DockedUtilityBar';
import components_SalesforceLibrary_DuelingPicklist from '../pages/20_components/SalesforceLibrary/DuelingPicklist';
import components_SalesforceLibrary_ExpandableSection from '../pages/20_components/SalesforceLibrary/ExpandableSection';
import components_SalesforceLibrary_Expression from '../pages/20_components/SalesforceLibrary/Expression';
import components_SalesforceLibrary_Feed from '../pages/20_components/SalesforceLibrary/Feed';
import components_SalesforceLibrary_Files from '../pages/20_components/SalesforceLibrary/Files';
import components_SalesforceLibrary_FileSelector from '../pages/20_components/SalesforceLibrary/FileSelector';
import components_SalesforceLibrary_GlobalHeader from '../pages/20_components/SalesforceLibrary/GlobalHeader';
import components_SalesforceLibrary_GlobalNavigation from '../pages/20_components/SalesforceLibrary/GlobalNavigation';
import components_SalesforceLibrary_Input from '../pages/20_components/SalesforceLibrary/Input';
import components_SalesforceLibrary_Listbox from '../pages/20_components/SalesforceLibrary/Listbox';
import components_SalesforceLibrary_ListBuilder from '../pages/20_components/SalesforceLibrary/ListBuilder';
import components_SalesforceLibrary_Map from '../pages/20_components/SalesforceLibrary/Map';
import components_SalesforceLibrary_Menu from '../pages/20_components/SalesforceLibrary/Menu';
import components_SalesforceLibrary_Modal from '../pages/20_components/SalesforceLibrary/Modal';
import components_SalesforceLibrary_Notification from '../pages/20_components/SalesforceLibrary/Notification';
import components_SalesforceLibrary_PageHeader from '../pages/20_components/SalesforceLibrary/PageHeader';
import components_SalesforceLibrary_Panel from '../pages/20_components/SalesforceLibrary/Panel';
import components_SalesforceLibrary_Path from '../pages/20_components/SalesforceLibrary/Path';
import components_SalesforceLibrary_Pill from '../pages/20_components/SalesforceLibrary/Pill';
import components_SalesforceLibrary_Popover from '../pages/20_components/SalesforceLibrary/Popover';
import components_SalesforceLibrary_ProgressBar from '../pages/20_components/SalesforceLibrary/ProgressBar';
import components_SalesforceLibrary_ProgressIndicator from '../pages/20_components/SalesforceLibrary/ProgressIndicator';
import components_SalesforceLibrary_ProgressRing from '../pages/20_components/SalesforceLibrary/ProgressRing';
import components_SalesforceLibrary_Prompt from '../pages/20_components/SalesforceLibrary/Prompt';
import components_SalesforceLibrary_Radio from '../pages/20_components/SalesforceLibrary/Radio';
import components_SalesforceLibrary_RichTextEditor from '../pages/20_components/SalesforceLibrary/RichTextEditor';
import components_SalesforceLibrary_ScopedNotification from '../pages/20_components/SalesforceLibrary/ScopedNotification';
import components_SalesforceLibrary_ScopedTabs from '../pages/20_components/SalesforceLibrary/ScopedTabs';
import components_SalesforceLibrary_SetupAssistant from '../pages/20_components/SalesforceLibrary/SetupAssistant';
import components_SalesforceLibrary_Slider from '../pages/20_components/SalesforceLibrary/Slider';
import components_SalesforceLibrary_Spinner from '../pages/20_components/SalesforceLibrary/Spinner';
import components_SalesforceLibrary_Tabs from '../pages/20_components/SalesforceLibrary/Tabs';
import components_SalesforceLibrary_TextArea from '../pages/20_components/SalesforceLibrary/TextArea';
import components_SalesforceLibrary_Timepicker from '../pages/20_components/SalesforceLibrary/Timepicker';
import components_SalesforceLibrary_Toast from '../pages/20_components/SalesforceLibrary/Toast';
import components_SalesforceLibrary_Tooltip from '../pages/20_components/SalesforceLibrary/Tooltip';
import components_SalesforceLibrary_Tree from '../pages/20_components/SalesforceLibrary/Tree';
import components_SalesforceLibrary_TrialBar from '../pages/20_components/SalesforceLibrary/TrialBar';
import components_SalesforceLibrary_VerticalNavigation from '../pages/20_components/SalesforceLibrary/VerticalNavigation';
import components_SalesforceLibrary_VisualPicker from '../pages/20_components/SalesforceLibrary/VisualPicker';
import components_SalesforceLibrary_WelcomeMat from '../pages/20_components/SalesforceLibrary/WelcomeMat';
import components_WebResponsiveLibrary_Accordion from '../pages/20_components/WebResponsiveLibrary/Accordion';
import components_WebResponsiveLibrary_Banner from '../pages/20_components/WebResponsiveLibrary/Banner';
import components_WebResponsiveLibrary_Breadcrumbs from '../pages/20_components/WebResponsiveLibrary/Breadcrumbs';
import components_WebResponsiveLibrary_Button from '../pages/20_components/WebResponsiveLibrary/Button';
import components_WebResponsiveLibrary_Card from '../pages/20_components/WebResponsiveLibrary/Card';
import components_WebResponsiveLibrary_Disclaimer from '../pages/20_components/WebResponsiveLibrary/Disclaimer';
import components_WebResponsiveLibrary_Disclosure from '../pages/20_components/WebResponsiveLibrary/Disclosure';
import components_WebResponsiveLibrary_Divider from '../pages/20_components/WebResponsiveLibrary/Divider';
import components_WebResponsiveLibrary_Footnote from '../pages/20_components/WebResponsiveLibrary/Footnote';
import components_WebResponsiveLibrary_Forms_FormElementsOverview from '../pages/20_components/WebResponsiveLibrary/Forms/01_FormElementsOverview';
import components_WebResponsiveLibrary_Forms_Checkbox from '../pages/20_components/WebResponsiveLibrary/Forms/Checkbox';
import components_WebResponsiveLibrary_Forms_CheckboxGroup from '../pages/20_components/WebResponsiveLibrary/Forms/CheckboxGroup';
import components_WebResponsiveLibrary_Forms_ColorPicker from '../pages/20_components/WebResponsiveLibrary/Forms/ColorPicker';
import components_WebResponsiveLibrary_Forms_DatePicker from '../pages/20_components/WebResponsiveLibrary/Forms/DatePicker';
import components_WebResponsiveLibrary_Forms_DateRangePicker from '../pages/20_components/WebResponsiveLibrary/Forms/DateRangePicker';
import components_WebResponsiveLibrary_Forms_ErrorSummary from '../pages/20_components/WebResponsiveLibrary/Forms/ErrorSummary';
import components_WebResponsiveLibrary_Forms_FileUploader from '../pages/20_components/WebResponsiveLibrary/Forms/FileUploader';
import components_WebResponsiveLibrary_Forms_Form from '../pages/20_components/WebResponsiveLibrary/Forms/Form';
import components_WebResponsiveLibrary_Forms_FormFieldset from '../pages/20_components/WebResponsiveLibrary/Forms/FormFieldset';
import components_WebResponsiveLibrary_Forms_FormLabel from '../pages/20_components/WebResponsiveLibrary/Forms/FormLabel';
import components_WebResponsiveLibrary_Forms_FormLegend from '../pages/20_components/WebResponsiveLibrary/Forms/FormLegend';
import components_WebResponsiveLibrary_Forms_FormMicroalert from '../pages/20_components/WebResponsiveLibrary/Forms/FormMicroalert';
import components_WebResponsiveLibrary_Forms_FormMicrocopy from '../pages/20_components/WebResponsiveLibrary/Forms/FormMicrocopy';
import components_WebResponsiveLibrary_Forms_NumberInput from '../pages/20_components/WebResponsiveLibrary/Forms/NumberInput';
import components_WebResponsiveLibrary_Forms_RadioButton from '../pages/20_components/WebResponsiveLibrary/Forms/RadioButton';
import components_WebResponsiveLibrary_Forms_RadioButtonGroup from '../pages/20_components/WebResponsiveLibrary/Forms/RadioButtonGroup';
import components_WebResponsiveLibrary_Forms_RequiredFieldKey from '../pages/20_components/WebResponsiveLibrary/Forms/RequiredFieldKey';
import components_WebResponsiveLibrary_Forms_Search from '../pages/20_components/WebResponsiveLibrary/Forms/Search';
import components_WebResponsiveLibrary_Forms_SelectDropdown from '../pages/20_components/WebResponsiveLibrary/Forms/SelectDropdown';
import components_WebResponsiveLibrary_Forms_TextArea from '../pages/20_components/WebResponsiveLibrary/Forms/TextArea';
import components_WebResponsiveLibrary_Forms_TextInput from '../pages/20_components/WebResponsiveLibrary/Forms/TextInput';
import components_WebResponsiveLibrary_Forms_ToggleSwitch from '../pages/20_components/WebResponsiveLibrary/Forms/ToggleSwitch';

import components_WebResponsiveLibrary_GlobalFooter from '../pages/20_components/WebResponsiveLibrary/GlobalFooter';
import components_WebResponsiveLibrary_GlobalHero from '../pages/20_components/WebResponsiveLibrary/GlobalHero';
import components_WebResponsiveLibrary_Icon from '../pages/20_components/WebResponsiveLibrary/Icon';
import components_WebResponsiveLibrary_IconButton from '../pages/20_components/WebResponsiveLibrary/IconButton';
import components_WebResponsiveLibrary_LayoutGrid from '../pages/20_components/WebResponsiveLibrary/LayoutGrid';
import components_WebResponsiveLibrary_Link from '../pages/20_components/WebResponsiveLibrary/Link';
import components_WebResponsiveLibrary_Logo from '../pages/20_components/WebResponsiveLibrary/Logo';
import components_WebResponsiveLibrary_LogoButton from '../pages/20_components/WebResponsiveLibrary/LogoButton';
import components_WebResponsiveLibrary_Masthead from '../pages/20_components/WebResponsiveLibrary/Masthead';
import components_WebResponsiveLibrary_Modal from '../pages/20_components/WebResponsiveLibrary/Modal';
import components_WebResponsiveLibrary_Navigation from '../pages/20_components/WebResponsiveLibrary/Navigation';
import components_WebResponsiveLibrary_Overlay from '../pages/20_components/WebResponsiveLibrary/Overlay';
import components_WebResponsiveLibrary_Popover from '../pages/20_components/WebResponsiveLibrary/Popover';
import components_WebResponsiveLibrary_ProgressStepper from '../pages/20_components/WebResponsiveLibrary/ProgressStepper';
import components_WebResponsiveLibrary_ProgressTracker from '../pages/20_components/WebResponsiveLibrary/ProgressTracker';
import components_WebResponsiveLibrary_Spinner from '../pages/20_components/WebResponsiveLibrary/Spinner';
import components_WebResponsiveLibrary_Table from '../pages/20_components/WebResponsiveLibrary/Table';
import components_WebResponsiveLibrary_TableSorting from '../pages/20_components/WebResponsiveLibrary/TableSorting';
import components_WebResponsiveLibrary_Tabs from '../pages/20_components/WebResponsiveLibrary/Tabs';

// root
import contactUs from '../pages/45_contactUs';
import toiletSink from '../pages/50_toiletSink';

// 30_foundations
import foundations_uxWritingGuidelines from '../pages/30_foundations/02_uxWritingGuidelines';
import foundations_Color from '../pages/30_foundations/03_Color';
import foundations_Icons from '../pages/30_foundations/04_Icons';
import foundations_Layout from '../pages/30_foundations/05_Layout';
import foundations_Logos from '../pages/30_foundations/06_Logos';
import foundations_Space from '../pages/30_foundations/07_Space';
import foundations_Typography from '../pages/30_foundations/08_Typography';
import foundations_DesignTokens from '../pages/30_foundations/09_DesignTokens';
import foundations_utilities_overview from '../pages/30_foundations/11_utilities/01_overview';
import foundations_utilities_Display from '../pages/30_foundations/11_utilities/Display';
import foundations_utilities_Flex from '../pages/30_foundations/11_utilities/Flex';
import foundations_utilities_Font from '../pages/30_foundations/11_utilities/Font';
import foundations_utilities_Misc from '../pages/30_foundations/11_utilities/Misc';
import foundations_utilities_Spacing from '../pages/30_foundations/11_utilities/Spacing';
import foundations_utilities_Text from '../pages/30_foundations/11_utilities/Text';
import foundations_utilities_Visibility from '../pages/30_foundations/11_utilities/Visibility';

// 35_patterns
import patterns_ContinueAndFinishLater from '../pages/35_patterns/ContinueAndFinishLater';
import patterns_ConversationalDesign from '../pages/35_patterns/ConversationalDesign';
import patterns_EmptyStates from '../pages/35_patterns/EmptyStates';
import patterns_FdicPlacementGuidelines from '../pages/35_patterns/FdicPlacementGuidelines';
import patterns_Fields_CodeAuthentication from '../pages/35_patterns/Fields/CodeAuthentication';
import patterns_Fields_CreateAPin from '../pages/35_patterns/Fields/CreateAPin';
import patterns_Fields_CurrencyNotation from '../pages/35_patterns/Fields/CurrencyNotation';
import patterns_Fields_DateOfBirth from '../pages/35_patterns/Fields/DateOfBirth';
import patterns_Fields_EmailAddress from '../pages/35_patterns/Fields/EmailAddress';
import patterns_Fields_Login from '../pages/35_patterns/Fields/Login';
import patterns_Fields_OccupationDetails from '../pages/35_patterns/Fields/OccupationDetails';
import patterns_Fields_Password from '../pages/35_patterns/Fields/Password';
import patterns_Fields_PhoneNumber from '../pages/35_patterns/Fields/PhoneNumber';
import patterns_Fields_PrimarySourceOfIncome from '../pages/35_patterns/Fields/PrimarySourceOfIncome';
import patterns_Fields_SocialSecurityNumber from '../pages/35_patterns/Fields/SocialSecurityNumber';
import patterns_Fields_UsAddress from '../pages/35_patterns/Fields/UsAddress';
import patterns_Fields_UserId from '../pages/35_patterns/Fields/UserId';
import patterns_FlexLayouts from '../pages/35_patterns/FlexLayouts';
import patterns_FocusOrder from '../pages/35_patterns/FocusOrder';
import patterns_Forms from '../pages/35_patterns/Forms';
import patterns_ReviewScreens from '../pages/35_patterns/ReviewScreens';
// 40_about
import about_ourMission from '../pages/40_about/01_ourMission';
import about_greenPixelStudios from '../pages/40_about/02_greenPixelStudios';
import about_newsletter from '../pages/40_about/03_newsletter';
import about_changelog from '../pages/40_about/04_changelog';
import about_changelogs_CbdsComponents from '../pages/40_about/changelogs/CbdsComponents';
import about_changelogs_CbdsComponentsAngular from '../pages/40_about/changelogs/CbdsComponentsAngular';
import about_changelogs_CbdsComponentsReact from '../pages/40_about/changelogs/CbdsComponentsReact';
import about_changelogs_CbdsDesignTokens from '../pages/40_about/changelogs/CbdsDesignTokens';
import about_changelogs_CbdsFonts from '../pages/40_about/changelogs/CbdsFonts';
import about_changelogs_CbdsIcons from '../pages/40_about/changelogs/CbdsIcons';
import about_changelogs_CbdsLogos from '../pages/40_about/changelogs/CbdsLogos';
import about_changelogs_CbdsThemeSalesforce from '../pages/40_about/changelogs/CbdsThemeSalesforce';
import about_changelogs_CdsWebsite from '../pages/40_about/changelogs/CdsWebsite';
import about_newsletters_Cbds2022Cycle02Newsletter from '../pages/40_about/newsletters/Cbds2022Cycle02Newsletter';
import about_newsletters_Cbds2022Cycle03Newsletter from '../pages/40_about/newsletters/Cbds2022Cycle03Newsletter';
import about_newsletters_Cbds2022Cycle04Newsletter from '../pages/40_about/newsletters/Cbds2022Cycle04Newsletter';
import about_newsletters_Cbds2022Cycle05Newsletter from '../pages/40_about/newsletters/Cbds2022Cycle05Newsletter';
import about_newsletters_Cbds2022Cycle06Newsletter from '../pages/40_about/newsletters/Cbds2022Cycle06Newsletter';
import about_newsletters_CdsSeptember2023Newsletter from '../pages/40_about/newsletters/CdsSeptember2023Newsletter';
import about_newsletters_CdsOctober2023Newsletter from '../pages/40_about/newsletters/CdsOctober2023Newsletter';
import about_newsletters_CdsJanuary2024Newsletter from '../pages/40_about/newsletters/CdsJanuary2024Newsletter';
import about_newsletters_CdsMarch2024Newsletter from '../pages/40_about/newsletters/CdsMarch2024Newsletter';
import about_newsletters_CdsApril2024Newsletter from '../pages/40_about/newsletters/CdsApril2024Newsletter';
import about_newsletters_CdsA11yPropsNewsletter from '../pages/40_about/newsletters/CdsA11yPropsNewsletter';
import about_newsletters_CdsJune2024Newsletter from '../pages/40_about/newsletters/CdsJune2024Newsletter';
import about_newsletters_CdsJuly2024Newsletter from '../pages/40_about/newsletters/CdsJuly2024Newsletter';
import about_newsletters_CdsNovember2024Newsletter from '../pages/40_about/newsletters/CdsNovember2024Newsletter';
import about_newsletters_CdsFebruary2025Newsletter from '../pages/40_about/newsletters/CdsFebruary2025Newsletter';


const Pages = {
  "05_gettingStarted": [
    { parent: '05_gettingStarted', component: gettingStarted_forDesigners },
    {
      parent: '06_ForDevelopers',
      children: [
        {
          parent: '01_Web', children: [
            { component: gettingStarted_forDevelopers_Web_Introduction },
            { component: gettingStarted_forDevelopers_Web_Installation },
            { component: gettingStarted_forDevelopers_Web_QuickStarts },
            { component: gettingStarted_forDevelopers_Web_Usage },
            { component: gettingStarted_forDevelopers_Web_CSSNamingConventions },
          ]
        },
      ],
    }
  ],
  "10_accessibility": [
    { parent: '10_accessibility', component: accessibility_Overview },
    {
      parent: '02_GettingStarted',
      children: [
        { parent: '02_GettingStarted', component: accessibility_GettingStarted_AccessibilityAtEveryStage },
        { parent: '02_GettingStarted', component: accessibility_GettingStarted_Designers },
        { parent: '02_GettingStarted', component: accessibility_GettingStarted_Developers },
      ],
    },
    {
      parent: '03_Guidelines',
      children: [
        { parent: '03_Guidelines', component: accessibility_Guidelines_Designers },
        { parent: '03_Guidelines', component: accessibility_Guidelines_Mobile },
        { parent: '03_Guidelines', component: accessibility_Guidelines_SinglePageApplications },
        { parent: '03_Guidelines', component: accessibility_Guidelines_Web },
      ],
    },
    {
      parent: '04_Testing',
      children: [
        { parent: '04_Testing', component: accessibility_Testing_Overview },
        { parent: '04_Testing', component: accessibility_Testing_Developers },
        { parent: '04_Testing', component: accessibility_Testing_Qa },
      ],
    },
    { parent: '10_accessibility', component: accessibility_Resources },
  ],
  "15_brandGuidelines": [
    {
      parent: 'BusinessBankingGuidelines',
      children: [
        { parent: 'BusinessBankingGuidelines', component: brandGuidelines_BusinessBankingGuidelines_Purpose },
        { parent: 'BusinessBankingGuidelines', component: brandGuidelines_BusinessBankingGuidelines_Audience },
        { parent: 'BusinessBankingGuidelines', component: brandGuidelines_BusinessBankingGuidelines_Strategy },
        { parent: 'BusinessBankingGuidelines', component: brandGuidelines_BusinessBankingGuidelines_ToneOfVoice },
        { parent: 'BusinessBankingGuidelines', component: brandGuidelines_BusinessBankingGuidelines_Photography },
        { parent: 'BusinessBankingGuidelines', component: brandGuidelines_BusinessBankingGuidelines_ColorPalette },
        { parent: 'BusinessBankingGuidelines', component: brandGuidelines_BusinessBankingGuidelines_Logo },
        { parent: 'BusinessBankingGuidelines', component: brandGuidelines_BusinessBankingGuidelines_Typography },
        { parent: 'BusinessBankingGuidelines', component: brandGuidelines_BusinessBankingGuidelines_Layout },
        { parent: 'BusinessBankingGuidelines', component: brandGuidelines_BusinessBankingGuidelines_ApplicationExamples },
        { parent: 'BusinessBankingGuidelines', component: brandGuidelines_BusinessBankingGuidelines_Contact },
      ],
    },
    {
      parent: 'CommercialGuidelines',
      children: [
        { parent: 'CommercialGuidelines', component: brandGuidelines_CommercialGuidelines_Introduction },
        { parent: 'CommercialGuidelines', component: brandGuidelines_CommercialGuidelines_ToneAndLanguage },
        { parent: 'CommercialGuidelines', component: brandGuidelines_CommercialGuidelines_Photography },
        { parent: 'CommercialGuidelines', component: brandGuidelines_CommercialGuidelines_VisualElement },
        { parent: 'CommercialGuidelines', component: brandGuidelines_CommercialGuidelines_LayoutSystem },
        { parent: 'CommercialGuidelines', component: brandGuidelines_CommercialGuidelines_ApplicationExamples },
        { parent: 'CommercialGuidelines', component: brandGuidelines_CommercialGuidelines_Contact },
      ],
    },
    {
      parent: 'ConsumerGuidelines',
      children: [
        { parent: 'ConsumerGuidelines', component: brandGuidelines_ConsumerGuidelines_WhoIsThisGuideFor },
        { parent: 'ConsumerGuidelines', component: brandGuidelines_ConsumerGuidelines_Mission },
        { parent: 'ConsumerGuidelines', component: brandGuidelines_ConsumerGuidelines_Strategy },
        { parent: 'ConsumerGuidelines', component: brandGuidelines_ConsumerGuidelines_LifeIsAJourney },
        { parent: 'ConsumerGuidelines', component: brandGuidelines_ConsumerGuidelines_ConsumerColor },
        { parent: 'ConsumerGuidelines', component: brandGuidelines_ConsumerGuidelines_ToneOfVoice },
        { parent: 'ConsumerGuidelines', component: brandGuidelines_ConsumerGuidelines_Typefaces },
        { parent: 'ConsumerGuidelines', component: brandGuidelines_ConsumerGuidelines_Photography },
        { parent: 'ConsumerGuidelines', component: brandGuidelines_ConsumerGuidelines_Illustration },
        { parent: 'ConsumerGuidelines', component: brandGuidelines_ConsumerGuidelines_GraphicElements },
        { parent: 'ConsumerGuidelines', component: brandGuidelines_ConsumerGuidelines_HowDoWeShowUp },
        { parent: 'ConsumerGuidelines', component: brandGuidelines_ConsumerGuidelines_BrandSonicGuidelines },
        { parent: 'ConsumerGuidelines', component: brandGuidelines_ConsumerGuidelines_Iconography },
        { parent: 'ConsumerGuidelines', component: brandGuidelines_ConsumerGuidelines_Contacts },
      ],
    },
    {
      parent: 'EnterpriseGuidelines',
      children: [
        { parent: 'EnterpriseGuidelines', component: brandGuidelines_EnterpriseGuidelines_WhoIsThisGuideFor },
        { parent: 'EnterpriseGuidelines', component: brandGuidelines_EnterpriseGuidelines_Mission },
        { parent: 'EnterpriseGuidelines', component: brandGuidelines_EnterpriseGuidelines_EveryJourneyIsUnique },
        { parent: 'EnterpriseGuidelines', component: brandGuidelines_EnterpriseGuidelines_Strategy },
        { parent: 'EnterpriseGuidelines', component: brandGuidelines_EnterpriseGuidelines_BrandObjectives },
        { parent: 'EnterpriseGuidelines', component: brandGuidelines_EnterpriseGuidelines_EnterpriseAudiences },
        { parent: 'EnterpriseGuidelines', component: brandGuidelines_EnterpriseGuidelines_PrinciplesOfTheJourney },
        { parent: 'EnterpriseGuidelines', component: brandGuidelines_EnterpriseGuidelines_ToneOfVoice },
        { parent: 'EnterpriseGuidelines', component: brandGuidelines_EnterpriseGuidelines_Copy },
        { parent: 'EnterpriseGuidelines', component: brandGuidelines_EnterpriseGuidelines_EnterpriseLogos },
        { parent: 'EnterpriseGuidelines', component: brandGuidelines_EnterpriseGuidelines_BrandColorPalette },
        { parent: 'EnterpriseGuidelines', component: brandGuidelines_EnterpriseGuidelines_Accessibility },
        { parent: 'EnterpriseGuidelines', component: brandGuidelines_EnterpriseGuidelines_Fonts },
        { parent: 'EnterpriseGuidelines', component: brandGuidelines_EnterpriseGuidelines_EnterpriseIcons },
        { parent: 'EnterpriseGuidelines', component: brandGuidelines_EnterpriseGuidelines_Contacts },
      ],
    },
    {
      parent: 'PrivateBankingGuidelines',
      children: [
        { parent: 'PrivateBankingGuidelines', component: brandGuidelines_PrivateBankingGuidelines_Introduction },
        { parent: 'PrivateBankingGuidelines', component: brandGuidelines_PrivateBankingGuidelines_ToneAndLanguage },
        { parent: 'PrivateBankingGuidelines', component: brandGuidelines_PrivateBankingGuidelines_Design },
      ],
    },
    {
      parent: 'WealthManagementGuidelines',
      children: [
        { parent: 'WealthManagementGuidelines', component: brandGuidelines_WealthManagementGuidelines_Purpose },
        { parent: 'WealthManagementGuidelines', component: brandGuidelines_WealthManagementGuidelines_MadeReady },
        { parent: 'WealthManagementGuidelines', component: brandGuidelines_WealthManagementGuidelines_WeSeeYou },
        { parent: 'WealthManagementGuidelines', component: brandGuidelines_WealthManagementGuidelines_WealthWrappedInWarmth },
        { parent: 'WealthManagementGuidelines', component: brandGuidelines_WealthManagementGuidelines_FourPrincipleDesignApproach },
        { parent: 'WealthManagementGuidelines', component: brandGuidelines_WealthManagementGuidelines_ToneAndLanguage },
        { parent: 'WealthManagementGuidelines', component: brandGuidelines_WealthManagementGuidelines_Photography },
        { parent: 'WealthManagementGuidelines', component: brandGuidelines_WealthManagementGuidelines_VisualDesignSystem },
        { parent: 'WealthManagementGuidelines', component: brandGuidelines_WealthManagementGuidelines_Examples },
        { parent: 'WealthManagementGuidelines', component: brandGuidelines_WealthManagementGuidelines_BringingItTogether },
        { parent: 'WealthManagementGuidelines', component: brandGuidelines_WealthManagementGuidelines_Contacts },
      ],
    },
  ],
  "20_components": [
    {
      parent: 'MobileUILibrary',
      children: [
        { parent: 'MobileUILibrary', component: components_MobileUILibrary_AccountCards },
        { parent: 'MobileUILibrary', component: components_MobileUILibrary_AlertDialogs },
        { parent: 'MobileUILibrary', component: components_MobileUILibrary_ArticleContainer },
        { parent: 'MobileUILibrary', component: components_MobileUILibrary_BottomNavigation },
        { parent: 'MobileUILibrary', component: components_MobileUILibrary_Buttons },
        { parent: 'MobileUILibrary', component: components_MobileUILibrary_FeatureCards },
        { parent: 'MobileUILibrary', component: components_MobileUILibrary_HamburgerMenu },
        { parent: 'MobileUILibrary', component: components_MobileUILibrary_ListItem },
        { parent: 'MobileUILibrary', component: components_MobileUILibrary_PickerCells },
        { parent: 'MobileUILibrary', component: components_MobileUILibrary_ProgressIndicator },
        { parent: 'MobileUILibrary', component: components_MobileUILibrary_QuickLinks },
        { parent: 'MobileUILibrary', component: components_MobileUILibrary_RadioButtons },
        { parent: 'MobileUILibrary', component: components_MobileUILibrary_Tabs },
        { parent: 'MobileUILibrary', component: components_MobileUILibrary_Toggles },
        { parent: 'MobileUILibrary', component: components_MobileUILibrary_TopAppBar },
        { parent: 'MobileUILibrary', component: components_MobileUILibrary_Transactions },
      ],
    },
    {
      parent: 'SalesforceLibrary',
      children: [
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_SalesforceOverview },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Accordion },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_ActivityTimeline },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Alert },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Avatar },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Badge },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_BrandBand },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Breadcrumb },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Button },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_ButtonGroup },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_ButtonIcon },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Card },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Chat },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Checkbox },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_CheckboxButton },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_CheckboxToggle },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_ColorPicker },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Combobox },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Counter },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_DataTable },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Datepicker },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_DockedComposer },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_DockedUtilityBar },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_DuelingPicklist },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_ExpandableSection },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Expression },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Feed },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Files },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_FileSelector },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_GlobalHeader },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_GlobalNavigation },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Input },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Listbox },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_ListBuilder },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Map },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Menu },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Modal },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Notification },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_PageHeader },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Panel },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Path },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Pill },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Popover },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_ProgressBar },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_ProgressIndicator },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_ProgressRing },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Prompt },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Radio },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_RichTextEditor },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_ScopedNotification },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_ScopedTabs },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_SetupAssistant },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Slider },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Spinner },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Tabs },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_TextArea },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Timepicker },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Toast },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Tooltip },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_Tree },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_TrialBar },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_VerticalNavigation },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_VisualPicker },
        { parent: 'SalesforceLibrary', component: components_SalesforceLibrary_WelcomeMat },
      ],
    },
    {
      parent: 'WebResponsiveLibrary',
      children: [
        { parent: 'WebResponsiveLibrary', component: components_WebResponsiveLibrary_Accordion },
        { parent: 'WebResponsiveLibrary', component: components_WebResponsiveLibrary_Banner },
        { parent: 'WebResponsiveLibrary', component: components_WebResponsiveLibrary_Breadcrumbs },
        { parent: 'WebResponsiveLibrary', component: components_WebResponsiveLibrary_Button },
        { parent: 'WebResponsiveLibrary', component: components_WebResponsiveLibrary_Card },
        { parent: 'WebResponsiveLibrary', component: components_WebResponsiveLibrary_Disclaimer },
        { parent: 'WebResponsiveLibrary', component: components_WebResponsiveLibrary_Disclosure },
        { parent: 'WebResponsiveLibrary', component: components_WebResponsiveLibrary_Divider },
        { parent: 'WebResponsiveLibrary', component: components_WebResponsiveLibrary_Footnote },
        {
          parent: 'forms',
          children: [
            { parent: 'forms', component: components_WebResponsiveLibrary_Forms_FormElementsOverview },
            { parent: 'forms', component: components_WebResponsiveLibrary_Forms_Checkbox },
            { parent: 'forms', component: components_WebResponsiveLibrary_Forms_CheckboxGroup },
            { parent: 'forms', component: components_WebResponsiveLibrary_Forms_ColorPicker },
            { parent: 'forms', component: components_WebResponsiveLibrary_Forms_DatePicker },
            { parent: 'forms', component: components_WebResponsiveLibrary_Forms_DateRangePicker },
            { parent: 'forms', component: components_WebResponsiveLibrary_Forms_ErrorSummary },
            { parent: 'forms', component: components_WebResponsiveLibrary_Forms_FileUploader },
            { parent: 'forms', component: components_WebResponsiveLibrary_Forms_Form },
            { parent: 'forms', component: components_WebResponsiveLibrary_Forms_FormFieldset },
            { parent: 'forms', component: components_WebResponsiveLibrary_Forms_FormLabel },
            { parent: 'forms', component: components_WebResponsiveLibrary_Forms_FormLegend },
            { parent: 'forms', component: components_WebResponsiveLibrary_Forms_FormMicroalert },
            { parent: 'forms', component: components_WebResponsiveLibrary_Forms_FormMicrocopy },
            { parent: 'forms', component: components_WebResponsiveLibrary_Forms_NumberInput },
            { parent: 'forms', component: components_WebResponsiveLibrary_Forms_RadioButton },
            { parent: 'forms', component: components_WebResponsiveLibrary_Forms_RadioButtonGroup },
            { parent: 'forms', component: components_WebResponsiveLibrary_Forms_RequiredFieldKey },
            { parent: 'forms', component: components_WebResponsiveLibrary_Forms_Search },
            { parent: 'forms', component: components_WebResponsiveLibrary_Forms_SelectDropdown },
            { parent: 'forms', component: components_WebResponsiveLibrary_Forms_TextArea },
            { parent: 'forms', component: components_WebResponsiveLibrary_Forms_TextInput },
            { parent: 'forms', component: components_WebResponsiveLibrary_Forms_ToggleSwitch },
          ],
        },
        { parent: 'WebResponsiveLibrary', component: components_WebResponsiveLibrary_GlobalFooter },
        { parent: 'WebResponsiveLibrary', component: components_WebResponsiveLibrary_GlobalHero },
        { parent: 'WebResponsiveLibrary', component: components_WebResponsiveLibrary_Icon },
        { parent: 'WebResponsiveLibrary', component: components_WebResponsiveLibrary_IconButton },
        { parent: 'WebResponsiveLibrary', component: components_WebResponsiveLibrary_LayoutGrid },
        { parent: 'WebResponsiveLibrary', component: components_WebResponsiveLibrary_Link },
        { parent: 'WebResponsiveLibrary', component: components_WebResponsiveLibrary_Logo },
        { parent: 'WebResponsiveLibrary', component: components_WebResponsiveLibrary_LogoButton },
        { parent: 'WebResponsiveLibrary', component: components_WebResponsiveLibrary_Masthead },
        { parent: 'WebResponsiveLibrary', component: components_WebResponsiveLibrary_Modal },
        { parent: 'WebResponsiveLibrary', component: components_WebResponsiveLibrary_Navigation },
        { parent: 'WebResponsiveLibrary', component: components_WebResponsiveLibrary_Overlay },
        { parent: 'WebResponsiveLibrary', component: components_WebResponsiveLibrary_Popover },
        { parent: 'WebResponsiveLibrary', component: components_WebResponsiveLibrary_ProgressStepper },
        { parent: 'WebResponsiveLibrary', component: components_WebResponsiveLibrary_ProgressTracker },
        { parent: 'WebResponsiveLibrary', component: components_WebResponsiveLibrary_Spinner },
        { parent: 'WebResponsiveLibrary', component: components_WebResponsiveLibrary_Table },
        { parent: 'WebResponsiveLibrary', component: components_WebResponsiveLibrary_TableSorting },
        { parent: 'WebResponsiveLibrary', component: components_WebResponsiveLibrary_Tabs },
      ],
    },
  ],
  "root": [
    { parent: 'root', component: contactUs },
    { parent: 'root', component: toiletSink },
  ],
  "30_foundations": [
    { parent: '30_foundations', component: foundations_uxWritingGuidelines },
    { parent: '30_foundations', component: foundations_Color },
    { parent: '30_foundations', component: foundations_Icons },
    { parent: '30_foundations', component: foundations_Layout },
    { parent: '30_foundations', component: foundations_Logos },
    { parent: '30_foundations', component: foundations_Space },
    { parent: '30_foundations', component: foundations_Typography },
    { parent: '30_foundations', component: foundations_DesignTokens },
    {
      parent: '11_utilities',
      children: [
        { parent: '11_utilities', component: foundations_utilities_overview },
        { parent: '11_utilities', component: foundations_utilities_Display },
        { parent: '11_utilities', component: foundations_utilities_Flex },
        { parent: '11_utilities', component: foundations_utilities_Font },
        { parent: '11_utilities', component: foundations_utilities_Misc },
        { parent: '11_utilities', component: foundations_utilities_Spacing },
        { parent: '11_utilities', component: foundations_utilities_Text },
        { parent: '11_utilities', component: foundations_utilities_Visibility },
      ],
    },
  ],
  "35_patterns": [
    { parent: '35_patterns', component: patterns_ContinueAndFinishLater },
    { parent: '35_patterns', component: patterns_ConversationalDesign },
    { parent: '35_patterns', component: patterns_EmptyStates },
    { parent: '35_patterns', component: patterns_FdicPlacementGuidelines },
    {
      parent: 'Fields',
      children: [
        { parent: 'Fields', component: patterns_Fields_CodeAuthentication },
        { parent: 'Fields', component: patterns_Fields_CreateAPin },
        { parent: 'Fields', component: patterns_Fields_CurrencyNotation },
        { parent: 'Fields', component: patterns_Fields_DateOfBirth },
        { parent: 'Fields', component: patterns_Fields_EmailAddress },
        { parent: 'Fields', component: patterns_Fields_Login },
        { parent: 'Fields', component: patterns_Fields_OccupationDetails },
        { parent: 'Fields', component: patterns_Fields_Password },
        { parent: 'Fields', component: patterns_Fields_PhoneNumber },
        { parent: 'Fields', component: patterns_Fields_PrimarySourceOfIncome },
        { parent: 'Fields', component: patterns_Fields_SocialSecurityNumber },
        { parent: 'Fields', component: patterns_Fields_UsAddress },
        { parent: 'Fields', component: patterns_Fields_UserId },
      ],
    },
    { parent: '35_patterns', component: patterns_FlexLayouts },
    { parent: '35_patterns', component: patterns_FocusOrder },
    { parent: '35_patterns', component: patterns_Forms },
    { parent: '35_patterns', component: patterns_ReviewScreens },
  ],
  "40_about": [
    { parent: '40_about', component: about_ourMission },
    { parent: '40_about', component: about_greenPixelStudios },
    { parent: '40_about', component: about_newsletter },
    { parent: '40_about', component: about_changelog },
    {
      parent: 'changelogs',
      children: [
        { parent: 'changelogs', component: about_changelogs_CbdsComponents },
        { parent: 'changelogs', component: about_changelogs_CbdsComponentsAngular },
        { parent: 'changelogs', component: about_changelogs_CbdsComponentsReact },
        { parent: 'changelogs', component: about_changelogs_CbdsDesignTokens },
        { parent: 'changelogs', component: about_changelogs_CbdsFonts },
        { parent: 'changelogs', component: about_changelogs_CbdsIcons },
        { parent: 'changelogs', component: about_changelogs_CbdsLogos },
        { parent: 'changelogs', component: about_changelogs_CbdsThemeSalesforce },
        { parent: 'changelogs', component: about_changelogs_CdsWebsite },
      ],
    },
    {
      parent: 'newsletters',
      children: [
        { parent: 'newsletters', component: about_newsletters_Cbds2022Cycle02Newsletter },
        { parent: 'newsletters', component: about_newsletters_Cbds2022Cycle03Newsletter },
        { parent: 'newsletters', component: about_newsletters_Cbds2022Cycle04Newsletter },
        { parent: 'newsletters', component: about_newsletters_Cbds2022Cycle05Newsletter },
        { parent: 'newsletters', component: about_newsletters_Cbds2022Cycle06Newsletter },
        { parent: 'newsletters', component: about_newsletters_CdsSeptember2023Newsletter },
        { parent: 'newsletters', component: about_newsletters_CdsOctober2023Newsletter },
        { parent: 'newsletters', component: about_newsletters_CdsJanuary2024Newsletter },
        { parent: 'newsletters', component: about_newsletters_CdsMarch2024Newsletter },
        { parent: 'newsletters', component: about_newsletters_CdsApril2024Newsletter },
        { parent: 'newsletters', component: about_newsletters_CdsA11yPropsNewsletter },
        { parent: 'newsletters', component: about_newsletters_CdsJune2024Newsletter },
        { parent: 'newsletters', component: about_newsletters_CdsJuly2024Newsletter },
        { parent: 'newsletters', component: about_newsletters_CdsNovember2024Newsletter },
        { parent: 'newsletters', component: about_newsletters_CdsFebruary2025Newsletter },
      ],
    },
  ],
};

const filterPublishedPage = (p => {
  if (p.component) {
    return p.component.published;
  } else if (p.children) {
    return true;
  }
});

const mapPublishedPages = (p => {
  if (p.children) {
    return {
      ...p,
      children: p.children.filter(filterPublishedPage),
    };
  } else {
    return p;
  }
});

const PublishedPages = {
  "05_gettingStarted": Pages["05_gettingStarted"].filter(filterPublishedPage).map(mapPublishedPages),
  "10_accessibility": Pages["10_accessibility"].filter(filterPublishedPage).map(mapPublishedPages),
  "15_brandGuidelines": Pages["15_brandGuidelines"].filter(filterPublishedPage).map(mapPublishedPages),
  "20_components": Pages["20_components"].filter(filterPublishedPage).map(mapPublishedPages),
  "root": Pages["root"].filter(filterPublishedPage).map(mapPublishedPages),
  "30_foundations": Pages["30_foundations"].filter(filterPublishedPage).map(mapPublishedPages),
  "35_patterns": Pages["35_patterns"].filter(filterPublishedPage).map(mapPublishedPages),
  "40_about": Pages["40_about"].filter(filterPublishedPage).map(mapPublishedPages),
};

export default PublishedPages;
