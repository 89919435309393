// import React from 'react';
// import Link from '../../../components/Link';
import ConversationalDesign from './ConversationalDesign.mdx';

const ConversationalDesignPage = {
  mdx: ConversationalDesign,
  breadcrumbs: "Patterns",
  alias: "patterns/conversational-design",
  pathExact: false,
  title: "Conversational Design",
  menuLabel: 'Conversational Design',
  published: true,
  order: 2,
  date: '2024-06-10', // Show when last updated
  summary: "Chat bots, digital assistants, and support humans",

  // SEO and Search
  tags: [
    "Patterns",
    "Conversational",
    "Design",
    'AI Chatbot',
    'chatbot',
    'Artificial Intelligence'
  ],
  meta: {
    description: '',
    title: 'Conversational Design - Patterns',
    keywords: [],
  },
};

export default ConversationalDesignPage;
