import React from 'react';
import WhoIsThisGuideFor from './WhoIsThisGuideFor.mdx';

const WhoIsThisGuideForPage = {
  mdx: WhoIsThisGuideFor,
  breadcrumbs: "Brand Guidelines/Enterprise Guidelines",
  alias: "brand-guidelines/enterprise-guidelines/who-is-this-guide-for",
  pathExact: false,
  title: "Who is This Guide For",
  menuLabel: 'Who Is This Guide For',
  published: true,
  order: 1,
  date: '2023-01-27', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Enterprise',
    'Guidelines',
    'Who is this guide for',
  ],
  meta: {
    title: 'Who is This Guide For - Enterprise - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default WhoIsThisGuideForPage;
