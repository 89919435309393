import React from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";

import { CbdsIcon } from '@cbds/cbds-components-react';

import CodeSnippet from "../CodeSnippet";

const getSpecKey = key => {
  switch(key) {
    case 'ff':
      return 'Family';
    case 'fs':
      return 'Size';
    case 'lh':
      return 'Line-height';
    case 'fw':
      return 'Weight';
    case 'ls':
      return 'Letter-spacing';
    case 'tt':
      return 'Text-transform';
  }
};

const CodeDuo = ({ childComponentKey, childComponents, customClass, displayType, hideSnippet, previewSpecSize, previewOnDark, previewHeight, showSpecs, showLayoutGridViz, showHtmlSnip, showReactSnip, showAngularSnip, showCssSnip }) => {
  const iconClass = childComponentKey === 'cbds-icon' ? 'cbdsws-c-codeDuo--icon' : null;
  const displayTypeClass = displayType ? `cbdsws-c-codeDuo--${displayType}` : null;
  const hideSnippetClass = hideSnippet ? `cbdsws-c-codeDuo--noSnippet` : null;
  const previewSpecSizeClass = previewSpecSize ? `cbdsws-c-codeDuo__previewSpec--${previewSpecSize}` : null;
  const previewOnDarkClass = previewOnDark ? `cbdsws-c-codeDuo__preview--onDark` : null;
  const _previewHeight = previewHeight ? { height: previewHeight } : null;
  const showSpecsClass = showSpecs ? `cbdsws-c-codeDuo--specs` : null;
  const showLayoutGridVizClass = showLayoutGridViz ? `has-layoutGridViz` : null;

  // Data
  const originalData = childComponents[0].data;

  return (
    <div className={classNames('cbdsws-c-codeDuo', customClass, iconClass, displayTypeClass, hideSnippetClass, showSpecsClass, showLayoutGridVizClass)}>
      {childComponents && childComponents.map((c, index) => {
        const hasHeaderClass = c.childComponentHeader ? 'cbdsws-c-codeDuo__preview--header' : null;
        const name = c.childComponentData && c.childComponentData.name ? `--${c.childComponentData.name}` : '';
        const previewURL = `/components/preview/${c.childComponentKey}${name}`;

        const component = React.cloneElement(c.childComponentElement, c.childComponentData);

        return (
          <div key={`codeDuo__preview_${index}`} className={classNames('cbdsws-c-codeDuo__preview', hasHeaderClass, previewOnDarkClass)}>
            <div className="cbdsws-c-codeDuo__previewExample" style={_previewHeight}>
              {!!c.childComponentHeader && (
                <strong className="cbdsws-c-codeDuo__header">{c.childComponentHeader}</strong>
              )}

              {c.includePreviewLink && (
                <a href={previewURL} className="Pen-previewLink cbdsws-c-codeDuo__previewLink" title="Component preview" target="_blank" rel="noopener" aria-label="component preview (opens in a new tab)">
                  <svg role="presentation" focusable="false" className="cbdsws-c-codeDuo__previewLinkIcon" height="22" viewBox="0 0 24 24" width="22" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0h24v24H0z" fill="none"/>
                    <path d="M19 4H5c-1.11 0-2 .9-2 2v12c0 1.1.89 2 2 2h4v-2H5V8h14v10h-4v2h4c1.1 0 2-.9 2-2V6c0-1.1-.89-2-2-2zm-7 6l-4 4h3v6h2v-6h3l-4-4z"/>
                  </svg>
                </a>
              )}

              {component}
            </div>

            {c.showSpecs && (
              <div className={classNames('cbdsws-c-codeDuo__previewSpec', previewSpecSizeClass)}>
                <ul className="cbdsws-c-codeDuo__previewSpecList">
                  {c.specs && c.specs.map(spec => {
                    const specKey = getSpecKey(spec.key);
                    return (
                      <li key={`previewSpecListItem__${specKey}_${spec.value}`} className="cbdsws-c-codeDuo__previewSpecListItem">
                        <span className="cbdsws-c-codeDuo__previewSpecListKey">{specKey}:</span>
                        <span className="cbdsws-c-codeDuo__previewSpecListValue">{spec.value}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        );
      })}

      {!hideSnippet && (
        <CodeSnippet
          childComponents={childComponents}
          originalData={originalData}
          customClass="cbdsws-c-codeDuo__snippet"
          showTabs={{
            html: showHtmlSnip,
            react: showReactSnip,
            angular: showAngularSnip,
            css: showCssSnip
          }}
        />
      )}
    </div>
  );
};

CodeDuo.propTypes = {
  classes: PropTypes.string,
  customClass: PropTypes.string,
  childComponentKey: PropTypes.string,
  childComponents: PropTypes.arrayOf(PropTypes.shape({
    childComponentElement: PropTypes.element,
    childComponentKey: PropTypes.string,
    childComponentHeader: PropTypes.string,
    includePreviewLink: PropTypes.bool,
    childComponentData: PropTypes.object,
    customCssSnippet: PropTypes.string,
    showSpecs: PropTypes.bool,
    specs: PropTypes.array,
  })).isRequired,
  displayType: PropTypes.string,
  hideSnippet: PropTypes.bool,
  previewOnDark: PropTypes.bool,
  previewSpecSize: PropTypes.string,
  previewHeight: PropTypes.string,
  showHtmlSnip: PropTypes.bool,
  showReactSnip: PropTypes.bool,
  showAngularSnip: PropTypes.bool,
  showCssSnip: PropTypes.bool,
  showLayoutGridViz: PropTypes.bool,
};

CodeDuo.defaultProps = {
  childComponentKey: 'cbds-icon',
  childComponents: [
    {
      childComponentElement: <CbdsIcon />,
      childComponentKey: 'cbds-icon',
      childComponentData: {
        iconName: 'close',
        a11yRole: 'img',
      },
    }
  ],
  hideSnippet: false,
  showHtmlSnip: true,
  showReactSnip: false,
  showAngularSnip: false,
  showCssSnip: false,
  showLayoutGridViz: false,
};

export default CodeDuo;
