import React from 'react';
import ToneOfVoice from './ToneOfVoice.mdx';

const ToneOfVoicePage = {
  mdx: ToneOfVoice,
  breadcrumbs: "Brand Guidelines/Business Banking Guidelines",
  alias: "brand-guidelines/business-banking-guidelines/toneOfVoice",
  pathExact: false,
  title: "Tone of Voice",
  menuLabel: 'Tone of Voice',
  published: true,
  order: 4,
  date: '2023-09-28', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Business Banking',
    'Guidelines',
    'Business Banking Guidelines',
    'ToneOfVoice',
  ],
  meta: {
    title: 'Tone of Voice - Business Banking - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default ToneOfVoicePage;
