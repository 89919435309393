import React from 'react';
import CdsJanuary2024Newsletter from './CdsJanuary2024Newsletter.mdx';

const CdsJanuary2024NewsletterPage = {
  mdx: CdsJanuary2024Newsletter,
  breadcrumbs: "About/Newsletter",
  alias: "about/newsletters/cds-january-2024-newsletter",
  pathExact: false,
  title: "CDS Newsletter January 2024",
  menuLabel: 'Cds January 2024 Newsletter',
  published: true,
  hideFromNav: true,
  //order: 3,
  date: '2024-02-01', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CDS',
    'About',
    'Newsletter',
    'Updates',
    'January',
    '2024',
    'Release Notes',
  ],
  meta: {
    description: '',
    title: 'CDS January 2024 Newsletter - Newsletters - About',
    keywords: [],
  },
};

export default CdsJanuary2024NewsletterPage;
