import React from 'react';
import CdsMarch2024Newsletter from './CdsMarch2024Newsletter.mdx';

const CdsMarch2024NewsletterPage = {
  mdx: CdsMarch2024Newsletter,
  breadcrumbs: "About/Newsletter",
  alias: "about/newsletters/cds-march-2024-newsletter",
  pathExact: false,
  title: "CDS Newsletter March 2024",
  menuLabel: 'Cds March 2024 Newsletter',
  published: true,
  hideFromNav: true,
  //order: 3,
  date: '2024-03-25', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CDS',
    'About',
    'Newsletter',
    'Updates',
    'March',
    '2024',
    'Release Notes',
  ],
  meta: {
    description: '',
    title: 'CDS March 2024 Newsletter - Newsletters - About',
    keywords: [],
  },
};

export default CdsMarch2024NewsletterPage;
