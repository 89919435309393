// import React from 'react';
// import Link from '../../../components/Link';
import ContinueAndFinishLater from './ContinueAndFinishLater.mdx';

const ContinueAndFinishLaterPage = {
  mdx: ContinueAndFinishLater,
  breadcrumbs: "Patterns",
  alias: "patterns/continue-and-finish-later",
  pathExact: false,
  title: "Continue and Finish Later",
  menuLabel: 'Continue and Finish Later',
  published: true,
  order: 2,
  date: '2025-02-13', // Show when last updated
  summary: "",

  // SEO and Search
  tags: [
    "Patterns",
    "Continue",
    "Finish",
    'AI Later',
    'Button',
    'Link'
  ],
  meta: {
    description: '',
    title: 'Continue and Finish Later - Patterns',
    keywords: [],
  },
  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "",
    },
    react: {
      status: "na",
      version: "v1.0.0",
      link: "",
    },
    angular: {
      status: "released",
      version: "v1.0.0",
      link: "/index.html?path=/story/patterns-continue-finish-later--default",
    },
    figma: {
      status: "released",
      version: "",
      link: "https://www.figma.com/design/1mBwaeZVlOxKn2rc9hQH4N/Ross-Work-2025?node-id=201-13815&t=WYtV9Wepe7IMizK0-1",
    },
  },
};

export default ContinueAndFinishLaterPage;
