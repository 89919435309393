import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import '../../assets/images/icons/pass.svg';
import '../../assets/images/icons/fail.svg';

const ColorCard = ({ displayType, customClass, layout, wcagBlackAA, wcagBlackAALarge, wcagWhiteAA, wcagWhiteAALarge, hex, name, rgb, cmyk, pms, scss, ios, android, sketch, text }) => {
  const pass = <img src="../../assets/images/icons/pass.svg" />;
  const fail = <img src="../../assets/images/icons/fail.svg" />;

  const typeClass = displayType ? `cbdsws-c-colorCard--${displayType}` : null;
  const layoutClass = layout ? `cbdsws-c-colorCard--${layout}` : null;
  const backgroundColor = { backgroundColor: hex };

  const blackAAScore = wcagBlackAA ? pass : fail;
  const blackAALargeScore = wcagBlackAALarge ? pass : fail;
  const whiteAAScore = wcagWhiteAA ? pass : fail;
  const whiteAALargeScore = wcagWhiteAALarge ? pass : fail;

  const showPrint = displayType === 'tintStack' ? false : true;
  const showSketch = displayType === 'tintStack' ? false : true;
  const showWCAG = displayType === 'tintStack' ? false : true;

  const showTokenNative = null;
  const showTokenSCSS = null;

  return (
    <div className={classNames('cbdsws-c-colorCard', typeClass, customClass, layoutClass)}>

      {/* Color */}
      <div className="cbdsws-c-colorCard__color" style={backgroundColor}></div>

      {/* Body */}
      <div className="cbdsws-c-colorCard__body">
        <h4 className="cbdsws-c-colorCard__title">{name}</h4>

        <ul className="cbdsws-c-colorCard__meta">

          <li className="cbdsws-c-colorCard__metaItem cbdsws-c-colorCard__metaItem--hex">Hex: {!!hex && hex}</li>
          <li className="cbdsws-c-colorCard__metaItem cbdsws-c-colorCard__metaItem--rgb">RGB: {!!rgb && rgb}</li>

          {showPrint && (
            <>
              <li className="cbdsws-c-colorCard__metaItem cbdsws-c-colorCard__metaItem--cmyk">CMYK: {!!cmyk && cmyk}</li>
              {pms && (
                <li className="cbdsws-c-colorCard__metaItem cbdsws-c-colorCard__metaItem--pms">PMS: {pms}</li>
              )}
            </>
          )}

          {showTokenSCSS && (
            <li className="cbdsws-c-colorCard__metaItem cbdsws-c-colorCard__metaItem--scss">Scss: {!!scss && scss}</li>
          )}

          {showTokenNative && (
            <>
              <li className="cbdsws-c-colorCard__metaItem cbdsws-c-colorCard__metaItem--ios">iOS: {!!ios && ios}</li>
              <li className="cbdsws-c-colorCard__metaItem cbdsws-c-colorCard__metaItem--android">Android: {!!android && android}</li>
            </>
          )}

          {showSketch && (
            <li className="cbdsws-c-colorCard__metaItem cbdsws-c-colorCard__metaItem--sketch">Sketch: {!!sketch && sketch}</li>
          )}

        </ul>

        {text && (
          <p className="cbdsws-c-colorCard__text">{text}</p>
        )}
      </div>
      
      {showWCAG && (
        <div className="cbdsws-c-colorCard__body__status">
          <p className="cbds-u-screenReaderOnly">
            WCAG Color Contrast Ratios
          </p>

          <div className="cbdsws-c-colorCard__status--colLeft">
            <div className="cbdsws-c-colorCardAda">
              <span
                style={{ backgroundColor: hex }}
                aria-hidden="true"
                className="cbdsws-c-colorCardAda__level"
              >Black</span>&nbsp;
              <span
                aria-hidden="true"
                className="cbdsws-c-colorCardAda__level"
              >Ratio</span>
            </div>
            <div className="cbdsws-c-colorCardAda">
              <span className="cbds-u-screenReaderOnly">
                Black over {name}
                <br />
              </span>
              <span
                aria-hidden="true"
                className="cbdsws-c-colorCardAda__level"
              >
                Normal Text
              </span>
              <span className="cbds-u-screenReaderOnly">
                Normal Text,&nbsp;
              </span>
              <span className="cbdsws-c-colorCardAda__status">
                {blackAAScore}
              </span>
            </div>
            <div className="cbdsws-c-colorCardAda">
              <span
                aria-hidden="true"
                className="cbdsws-c-colorCardAda__level"
              >
                Large Text
              </span>
              <span className="cbds-u-screenReaderOnly">
                <br />
                Large Text,&nbsp;
              </span>
              <span className="cbdsws-c-colorCardAda__status">
                {blackAALargeScore}
              </span>
            </div>
          </div>

          <div className="cbdsws-c-colorCard__status--colRight">
            <div className="cbdsws-c-colorCardAda cbdsws-c-colorCardAda">
              <span
                style={{ backgroundColor: hex }}
                aria-hidden="true"
                className="cbdsws-c-colorCardAda__level"
              >White</span>&nbsp;
              <span
                aria-hidden="true"
                className="cbdsws-c-colorCardAda__level"
              >Ratio</span>
            </div>
            <div className="cbdsws-c-colorCardAda cbdsws-c-colorCardAda--whiteAA">
              <span className="cbds-u-screenReaderOnly">
                White over {name}
                <br />
              </span>
              <span
                aria-hidden="true"
                className="cbdsws-c-colorCardAda__level"
              >
                Normal Text
              </span>
              <span className="cbds-u-screenReaderOnly">
                Normal Text,&nbsp;
              </span>
              <span className="cbdsws-c-colorCardAda__status">
                {whiteAAScore}
              </span>
            </div>
            <div className="cbdsws-c-colorCardAda cbdsws-c-colorCardAda--whiteAA+">
              <span
                aria-hidden="true"
                className="cbdsws-c-colorCardAda__level"
              >
                Large Text
              </span>
              <span className="cbds-u-screenReaderOnly">
                <br />
                Large Text,&nbsp;
              </span>
              <span className="cbdsws-c-colorCardAda__status">
                {whiteAALargeScore}
              </span>
            </div>
          </div>

        </div>
      )}

    </div>
  );
};

ColorCard.propTypes = {
  displayType: PropTypes.string,
  customClass: PropTypes.string,
  layout: PropTypes.string,
  wcagBlackAA: PropTypes.string,
  wcagBlackAALarge: PropTypes.string,
  wcagWhiteAA: PropTypes.string,
  wcagWhiteAALarge: PropTypes.string,
  hex: PropTypes.string,
  name: PropTypes.string,
  rgb: PropTypes.string,
  cmyk: PropTypes.string,
  pms: PropTypes.string,
  scss: PropTypes.string,
  ios: PropTypes.string,
  android: PropTypes.string,
  sketch: PropTypes.string,
  text: PropTypes.string
};

export default ColorCard;
