export default {
  colorPalette: {
    primary: [
      {
        meta: {
          name: "Deep Green",
        },
        rgb: "0, 100, 80",
        value: "#006450",
        cmyk: "99, 36, 74, 26",
        pms: "336",
        wcagBlack: {
          aa: "false",
          aaLarge: "false",
          ratio: "2.94",
        },
        wcagWhite: {
          aa: "true",
          aaLarge: "true",
          ratio: "7.15",
        },
      },
      {
        meta: {
          name: "Gray",
        },
        rgb: "137, 138, 142",
        value: "#898A8E",
        cmyk: "49, 40, 38, 1",
        pms: "Cool Gray 8",
        wcagBlack: {
          aa: "true",
          aaLarge: "true",
          ratio: "6.09",
        },
        wcagWhite: {
          aa: "false",
          aaLarge: "true",
          ratio: "3.45",
        },
      },
      {
        meta: {
          name: "80% Black",
          description: "For text only",
        },
        rgb: "88, 89, 91",
        value: "#58595B",
        cmyk: "0, 0, 0, 80",
        pms: null,
        wcagBlack: {
          aa: "false",
          aaLarge: "false",
          ratio: "2.99",
        },
        wcagWhite: {
          aa: "true",
          aaLarge: "true",
          ratio: "7.01",
        },
      },
      {
        meta: {
          name: "Black",
        },
        rgb: "0, 0, 0",
        value: "#000",
        cmyk: "0, 0, 0, 100",
        pms: null,
        wcagBlack: {
          aa: "false",
          aaLarge: "false",
          ratio: "0.00",
        },
        wcagWhite: {
          aa: "true",
          aaLarge: "true",
          ratio: "16.3",
        },
      },
      {
        meta: {
          name: "White",
        },
        rgb: "255, 255, 255",
        value: "#fff",
        cmyk: "0, 0, 0, 0",
        pms: null,
        wcagBlack: {
          aa: "true",
          aaLarge: "true",
          ratio: "21.0",
        },
        wcagWhite: {
          aa: "false",
          aaLarge: "false",
          ratio: "0.00",
        },
      },
    ],
    secondary: [
      {
        meta: {
          name: "Citizens Green",
        },
        rgb: "0, 133, 85",
        value: "#008555",
        cmyk: "100, 0, 74, 0",
        pms: "340",
        wcagBlack: {
          aa: "false",
          aaLarge: "true",
          ratio: "4.49",
        },
        wcagWhite: {
          aa: "true",
          aaLarge: "true",
          ratio: "4.68",
        },
      },
      {
        meta: {
          name: "Deep Blue",
        },
        rgb: "35, 96, 147",
        value: "#236093",
        cmyk: "91, 63, 20, 3",
        pms: "647",
        wcagBlack: {
          aa: "false",
          aaLarge: "false",
          ratio: "3.17",
        },
        wcagWhite: {
          aa: "true",
          aaLarge: "true",
          ratio: "6.63",
        },
      },
      {
        meta: {
          name: "Light Blue",
        },
        rgb: "155, 183, 212",
        value: "#9BB7D4",
        cmyk: "39, 19, 6, 0",
        pms: "644",
        wcagBlack: {
          aa: "true",
          aaLarge: "true",
          ratio: "10.12",
        },
        wcagWhite: {
          aa: "false",
          aaLarge: "false",
          ratio: "2.08",
        },
      },
      {
        meta: {
          name: "Light Gray",
        },
        rgb: "218, 217, 215",
        value: "#DAD9D7",
        cmyk: "13, 10, 12, 0",
        pms: "Cool Gray 1",
        wcagBlack: {
          aa: "true",
          aaLarge: "true",
          ratio: "14.89",
        },
        wcagWhite: {
          aa: "false",
          aaLarge: "false",
          ratio: "1.41",
        },
      },
    ],
    tertiary: [
      {
        meta: {
          name: "Chart 1",
        },
        rgb: "140, 60, 103",
        value: "#8C3C67",
        cmyk: "44, 87, 36, 13",
        pms: "689",
        wcagBlack: {
          aa: "false",
          aaLarge: "false",
          ratio: "2.96",
        },
        wcagWhite: {
          aa: "true",
          aaLarge: "true",
          ratio: "7.1",
        },
      },
      {
        meta: {
          name: "Chart 2",
        },
        rgb: "0, 145, 179",
        value: "#0091B3",
        cmyk: "92, 24, 23, 0",
        pms: "632",
        wcagBlack: {
          aa: "false",
          aaLarge: "true",
          ratio: "5.70",
        },
        wcagWhite: {
          aa: "false",
          aaLarge: "true",
          ratio: "3.68",
        },
      },
      {
        meta: {
          name: "Chart 3",
        },
        rgb: "225, 133, 51",
        value: "#E18533",
        cmyk: "9, 56, 92, 1",
        pms: "7413",
        wcagBlack: {
          aa: "true",
          aaLarge: "true",
          ratio: "7.60",
        },
        wcagWhite: {
          aa: "false",
          aaLarge: "false",
          ratio: "2.76",
        },
      },
      {
        meta: {
          name: "Chart 4",
        },
        rgb: "254, 219, 101",
        value: "#FEDB65",
        cmyk: "1, 12, 72, 0",
        pms: "120",
        wcagBlack: {
          aa: "true",
          aaLarge: "true",
          ratio: "15.53",
        },
        wcagWhite: {
          aa: "false",
          aaLarge: "false",
          ratio: "1.35",
        },
      },
    ],
  },
};
