import React from 'react';
import Layout from './Layout.mdx';

const LayoutPage = {
  mdx: Layout,
  breadcrumbs: "Brand Guidelines/Business Banking Guidelines",
  alias: "brand-guidelines/business-banking-guidelines/line-element",
  pathExact: false,
  title: "Layout",
  menuLabel: 'Layout',
  published: true,
  order: 9,
  date: '2023-09-28', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Business Banking',
    'Guidelines',
    'Business Banking Guidelines',
    'Layout'
  ],
  meta: {
    title: 'Layout - Business Banking - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default LayoutPage;
