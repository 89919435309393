import SinglePageApplications from "./SinglePageApplications.mdx";

const SinglePageApplicationsPage = {
  mdx: SinglePageApplications,
  breadcrumbs: "Accessibility/Guidelines",
  alias: "accessibility/guidelines/single-page-applications",
  pathExact: false,
  title: "Single Page Applications",
  menuLabel: "Single Page Applications",
  published: true,
  order: 3,
  date: "2024-09-13", // Show when last updated
  summary: "",

  // SEO and Search
  tags: [
    'Accessibility',
    'A11Y',
    'Guidelines',
    'SPAs',
    'Single Page Applications',
    'Focus Management',
    'Page Titles',
    'Live Announcements',
  ],
  meta: {
    title: "Single Page Applications - Guidelines - Accessibility",
    description: "",
    keywords: [],
  },
};

export default SinglePageApplicationsPage;
