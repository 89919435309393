// import React from 'react';
import FocusOrder from './FocusOrder.mdx';

const FocusOrderPage = {
  mdx: FocusOrder,
  breadcrumbs: "Patterns",
  alias: "patterns/focus-order",
  pathExact: false,
  title: "Focus Order",
  menuLabel: 'Focus Order',
  published: true,
  order: 2,
  date: '2023-10-04', // Show when last updated
  summary: "",

  // SEO and Search
  tags: [
    "Accessibility",
    "A11Y",
    "ADA",
    "Patterns",
    "Rule of Thumb",
    "Focus",
    "Order",
    "Focus Management",
    "Keyboard Navigation",
    "Outline",
    "State",
  ],
  meta: {
    description: '',
    title: 'Focus Order - Patterns',
    keywords: [],
  },
};

export default FocusOrderPage;
