import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card from '../Card/Card';

const CardGrid = props => {
  const gridColClass = (props.gridCol && props.gridCol > 0) ?  `cbdsws-c-cardGrid__grid--col${props.gridCol}` : 'cbdsws-c-cardGrid__grid--col3';
  const variantClass = props.variant ? `cbdsws-c-cardGridd--${props.variant}` : null;
  const spaceClass = (props.space && props.space.mt) ? `cbdsws-u-sp__mt${props.space.mt}x` : null;
  const dataEntries = Object.entries(props.cardData.dataEntries);
  const dataType = props.cardData.dataType;
  let headingValue = 'heading';
  let descriptionValue = 'description';
  let urlValue = '';

  if (dataType === 'release' ||
    dataType === 'newsletter') {
    headingValue = 'title';
    descriptionValue = 'summary';
  }

  return (
    <div className={classNames('cbdsws-c-cardGrid', props.classes, variantClass, spaceClass)}>
      <div className={classNames('cbdsws-c-cardGrid__grid', gridColClass)}>
        {dataEntries.map((item, index) => {
          if (props.limitNumItems && index >= props.limitNumItems) {
            return null;
          }

          if (dataType === 'release') {
            // const releaseVersion = item[1].version.replaceAll(/[.]/g, '-');
            {/* Update URL path for changelogs on homepage.  EdD 1/24/2024 */}
            urlValue = `/about/changelogs/${item[1].type}`;
          }

          if (dataType === 'newsletter') {
            urlValue = `/about/newsletters/${item[1].id}`;
          }

          return (
            <div key={index} className="cbdsws-c-cardGrid__item">
              <Card
                heading={item[1][headingValue]}
                description={item[1][descriptionValue]}
                eyebrow={item[1].eyebrow}
                subHeading={item[1].subHeading}
                meta={item[1].meta}
                url={urlValue ? urlValue : item[1].url}
                urlType={item[1].urlType}
                hasMedia={item[1].hasMedia}
                mediaClasses={item[1].mediaClasses}
                media={
                  {
                    type: (item[1].media && item[1].media.type) ? item[1].media.type : null,
                    srcUrl: (item[1].media && item[1].media.srcUrl) ? item[1].media.srcUrl : ""
                  }
                }
                mediaIcon={
                  {
                    name: (item[1].mediaIcon && item[1].mediaIcon.name) ? item[1].mediaIcon.name : ""
                  }
                }
                showContinueAction={item[1].showContinueAction}
                continueIconName={item[1].continueIconName}
                layoutDir={item[1].layoutDir}
                size={item[1].size}
                variant={item[1].variant}
                isAction={item[1].isAction}
                isDisabled={item[1].isDisabled}
                disabledAttr={item[1].disabledAttr}
                devStage={item[1].devStage}
                devStageText={item[1].devStageText}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

CardGrid.propTypes = {
  gridCol: PropTypes.number,
  variant: PropTypes.string,
  space: PropTypes.object
};

CardGrid.defaultProps = {
  space: {}
};

export default CardGrid;
