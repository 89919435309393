import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Header from '../Header';
import _setPageTitle from '../../_helpers/setPageTitle';
import { CbdsLink } from '@cbds/cbds-components-react';

let ALIASES = ['/', '/search'];

const getAliases = (pages, isRoot = false) => {
  if (isRoot) {
    for (var prop in pages) {
      const page = pages[prop];

      for (var i = 0; i < page.length; i++) {
        const component = page[i];
        if (component.children) {
          getAliases(component.children);
        } else if (component.component) {

          if (component.component.tabs) {
            for (var i2 = 0; i2 < component.component.tabs.length; i2++) {
              const tab = component.component.tabs[i2];
              ALIASES.push(`${tab.alias}`);
            }
          } else {
            ALIASES.push(`/${component.component.alias}`);
          }
        }
      }
    }
  } else {
    for (var j = 0; j < pages.length; j++) {
      const page = pages[j];
      if (page.children) {
        getAliases(page.children);
      } else if (page.component) {
        if (page.component.tabs) {
          for (var j2 = 0; j2 < page.component.tabs.length; j2++) {
            const tab = page.component.tabs[j2];
            ALIASES.push(`${tab.alias}`);
          }
        } else {
          ALIASES.push(`/${page.component.alias}`);
        }
      }
    }
  }

};

const getResults = (aliases, currentPath) => {
  const results = aliases.filter(a => a !== '/' && a !== '/search').filter(a => checkPaths(currentPath, a, 'filter')).map(a => checkPaths(currentPath, a, 'map'));
  const maxCount = results.sort((a, b) => a.count - b.count).map(r => r.count)[results.length - 1];
  return results.filter(r => r.count === maxCount).map(r => r.path);
};

// Fine tune results closest to current path
const checkPaths = (currentPath, path, type) => {
  const currentArr = currentPath.split('/');
  const pathArr = path.split('/');
  let isPath = 0;

  if (currentArr) {
    currentArr.forEach((c, i) => {
      if (i > 0) {
        const _path = pathArr[i];
        if (c === _path) {
          isPath += 1;
        }
      }
    });
  }

  if (type === 'filter') {
    if (currentArr) return isPath > 0;
    return false;
  }

  if (type === 'map') {
    return {
      path,
      count: isPath
    };
  }
};

const NotFound = ({ Pages, pageExist, setPageExist }) => {
  const location = useLocation();

  useEffect(() => {
    getAliases(Pages, true);
    ALIASES = [...new Set(ALIASES)]; // Remove duplicates

    setPageExist(ALIASES.includes(location.pathname));

    if (pageExist) {
      var tempADObj = { alias: '404' }; // Analytics Object
      _setPageTitle('404', tempADObj);
      document.getElementById("targetPageTitle").innerHTML = `404 | Citizens Design System (v${process.env.VERSION})`;
    }
  }, [location.pathname]);

  const result = getResults(ALIASES, location.pathname);

  return (
    <>
      {!pageExist && (
        <>
          <Header data={{ title: "Sorry, we can’t find that page!", summary: (result.length > 0) ? "Did you mean?" : "Try using our site search, and please update older bookmarks to our new website address." }} />

          <p class="cbdsws-c-lead">If your problem persists please contact us at <CbdsLink 
            label="designsystem@citizensbank.com" 
            url="mailto:designsystem@citizensbank.com"/>
          </p>

          <div className="cbdsws-c-searchResult">
            {result && result.map((a, i) => (
              <div key={`list_${i}`} className="cbdsws-c-searchResult__item">
                <Link className="cbds-c-link cbdsws-c-searchResult__link cbensightenevent" cbdata-type="link" cbdata-reason={`__404Result: ${a}`} to={a}>
                  <h2 className="cbdsws-c-searchResult__itemTitle">{a.replace('/', '').replace(/-/g, ' ')}</h2>
                </Link>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default NotFound;
