import React from 'react';
import Fonts from './Fonts.mdx';

const FontsPage = {
  mdx: Fonts,
  breadcrumbs: "Brand Guidelines/Enterprise Guidelines",
  alias: "brand-guidelines/enterprise-guidelines/fonts",
  pathExact: false,
  title: "Fonts",
  menuLabel: 'Fonts',
  published: true,
  order: 13,
  date: '2023-01-27', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Enterprise',
    'Guidelines',
    'Fonts',
    'Fira',
    'Sans',
  ],
  meta: {
    title: 'Fonts - Enterprise - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default FontsPage;
