import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { CbdsLogo } from '@cbds/cbds-components-react';
import ButtonInlineCode from '../ButtonInlineCode';

const LogoCard = ({
  logoName,
  logoSet,
  logoWidth,
  logoHeight,
  a11yLabel
}) => {

  let darkClass = logoName.includes("white") ? "cbdsws-c-logoCard__media--dark" : null;

  return (
    <div className="cbdsws-c-logoCard">
      <div className={classNames("cbdsws-c-logoCard__media", darkClass )}>
        <CbdsLogo
          classes="cbdsws-c-logoCard__svg"
          logoName={logoName}
          logoWidth={logoWidth}
          logoHeight={logoHeight}
          logoSet={logoSet}
          a11yRole="img"
          a11yLabel={a11yLabel ? a11yLabel : logoName}
        />
      </div>
      <div className="cbdsws-c-logoCard__body">
        <div className={classNames("cbdsws-c-logoCard__copyBtn", "cbdsws-c-logoCard__longName")}>
          <ButtonInlineCode codeSnippet={logoName} type="logo" />
        </div>
      </div>
    </div>
  );
};

LogoCard.propTypes = {
  logoName: PropTypes.string,
  a11yLabel: PropTypes.string,
  logoWidth: PropTypes.number,
  logoHeight: PropTypes.number,
  logoSet: PropTypes.string
};

LogoCard.defaultProps = {
  logoName: "citizens-horz-green",
};

export default LogoCard;
