export default {
  colorPalette: {
    primary: [
      {
        meta: {
          name: "Deep Green",
        },
        rgb: "0, 100, 80",
        value: "#006450",
        cmyk: "99, 36, 74, 26",
        pms: "336",
        wcagBlack: {
          aa: "false",
          aaLarge: "false",
          ratio: "2.94",
        },
        wcagWhite: {
          aa: "true",
          aaLarge: "true",
          ratio: "7.15",
        },
      },
      {
        meta: {
          name: "Light Gray",
        },
        rgb: "218, 217, 215",
        value: "#DAD9D7",
        cmyk: "13, 10, 12, 0",
        pms: "Cool Gray 1",
        wcagBlack: {
          aa: "true",
          aaLarge: "true",
          ratio: "14.89",
        },
        wcagWhite: {
          aa: "false",
          aaLarge: "false",
          ratio: "1.41",
        },
      },
    ],
    secondary: [
      {
        meta: {
          name: "Citizens Green",
        },
        rgb: "0, 133, 85",
        value: "#008555",
        cmyk: "100, 0, 74, 0",
        pms: "340",
        wcagBlack: {
          aa: "false",
          aaLarge: "true",
          ratio: "4.49",
        },
        wcagWhite: {
          aa: "true",
          aaLarge: "true",
          ratio: "4.68",
        },
      },
      {
        meta: {
          name: 'Green 300'
        },
        rgb: '153, 206, 187',
        value: '#99cebb',
        cmyk: '100, 0, 74, 0',
        pms: null,
        wcagBlack: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '11.9'
        },
        wcagWhite: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '1.76'
        }
      },
      {
        meta: {
          name: "Black",
        },
        rgb: "0, 0, 0",
        value: "#000",
        cmyk: "0, 0, 0, 100",
        pms: null,
        wcagBlack: {
          aa: "false",
          aaLarge: "false",
          ratio: "0.00",
        },
        wcagWhite: {
          aa: "true",
          aaLarge: "true",
          ratio: "16.3",
        },
      },
      {
        meta: {
          name: "Dark Gray",
        },
        rgb: "77, 89, 83",
        value: "#4c4f53",
        cmyk: "67, 58, 53, 34",
        pms: "Cool Gray 11",
        wcagBlack: {
          aa: "false",
          aaLarge: "false",
          ratio: "0.00",
        },
        wcagWhite: {
          aa: "true",
          aaLarge: "true",
          ratio: "16.3",
        },
      }
    ]
  },
};
