// import React from 'react';
// import Link from '../../../components/Link';
import DateOfBirth from './DateOfBirth.mdx';

const DateOfBirthPage = {
  mdx: DateOfBirth,
  breadcrumbs: "Patterns/Fields",
  alias: "patterns/fields/date-of-birth",
  pathExact: false,
  title: "Date of Birth",
  menuLabel: 'Date of Birth',
  published: true,
  order: 2,
  date: '2024-02-22', // Show when last updated
  summary: "",

  // SEO and Search
  tags: [
    "Form",
    "Date of Birth",
    "Field",
    "Patterns",
    "DOB",
    "Input",
  ],
  meta: {
    description: '',
    title: 'Date of Birth - Fields - Patterns',
    keywords: [],
  },

  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "index.html?path=/stitry/components-Accordion",
    },
    react: {
      status: "released",
      version: "v1.0.0",
      link: "index.html?path=/story/components-forms-date-of-birth--default",
    },
    angular: {
      status: "released",
      version: "v1.0.0",
      link: "index.html?path=/story/patterns-forms-date-of-birth--default",
    },
    figma: {
      status: "released",
      version: "",
      link: "https://www.figma.com/file/mj41OHGCokcJ9N7D0q8D9Q/CBDS-UI-Kit-V1.1?node-id=1633%3A6407&t=c0P3NDyo4OqUOeuw-1",
    },
  },
};

export default DateOfBirthPage;
