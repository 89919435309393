import React from 'react';
import CdsFebruary2025Newsletter from './CdsFebruary2025Newsletter.mdx';

const CdsFebruary2025NewsletterPage = {
  mdx: CdsFebruary2025Newsletter,
  breadcrumbs: "About/Newsletter",
  alias: "about/newsletters/cds-february-2025-newsletter",
  pathExact: false,
  title: "CDS Newsletter February 2025",
  menuLabel: 'Cds February 2025 Newsletter',
  published: true,
  hideFromNav: true,
  //order: 3,
  date: '2025-03-11', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CDS',
    'About',
    'Newsletter',
    'Updates',
    'February',
    '2025',
    'Release Notes',
  ],
  meta: {
    description: '',
    title: 'CDS February 2025 Newsletter - Newsletters - About',
    keywords: [],
  },
};

export default CdsFebruary2025NewsletterPage;
