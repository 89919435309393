import React from 'react';
import CdsOctober2023Newsletter from './CdsOctober2023Newsletter.mdx';

const CdsOctober2023NewsletterPage = {
  mdx: CdsOctober2023Newsletter,
  breadcrumbs: "About/Newsletter",
  alias: "about/newsletters/cds-october-2023-newsletter",
  pathExact: false,
  title: "CDS Newsletter October 2023",
  menuLabel: 'CdsOctober2023Newsletter',
  published: true,
  hideFromNav: true,
  //order: 3,
  date: '2023-11-15', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CDS',
    'About',
    'Newsletter',
    'Updates',
    'October',
    '2023',
    'Release Notes',
  ],
  meta: {
    description: '',
    title: 'CDS October 2023 Newsletter - Newsletters - About',
    keywords: [],
  },
};

export default CdsOctober2023NewsletterPage;
