import React from 'react';
import EveryJourneyIsUnique from './EveryJourneyIsUnique.mdx';

const EveryJourneyIsUniquePage = {
  mdx: EveryJourneyIsUnique,
  breadcrumbs: "Brand Guidelines/Enterprise Guidelines",
  alias: "brand-guidelines/enterprise-guidelines/every-journey-is-unique",
  pathExact: false,
  title: "Every Journey is Unique",
  menuLabel: 'Every Journey Is Unique',
  published: true,
  order: 3,
  date: '2023-01-27', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Enterprise',
    'Guidelines',
    'Every journey is unique',
    'Journey',
  ],
  meta: {
    title: 'Every Journey is Unique - Enterprise - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default EveryJourneyIsUniquePage;
