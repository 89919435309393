import React from 'react';
import ApplicationExamples from './ApplicationExamples.mdx';

const ApplicationExamplesPage = {
  mdx: ApplicationExamples,
  breadcrumbs: "Brand Guidelines/Commercial Guidelines",
  alias: "brand-guidelines/commercial-guidelines/application-examples",
  pathExact: false,
  title: "Application Examples",
  menuLabel: 'Application Examples',
  published: true,
  order: 6,
  date: '2023-11-28', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Commercial',
    'Guidelines',
    'Commercial Guidelines',
    'Application Examples'
  ],
  meta: {
    title: 'Application Examples - Commercial - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default ApplicationExamplesPage;
