/**
 * Keep the variables in alphabetical order
 *
 *
 * data
 * the entire json file contents.
 *
 * category
 * the first key in the object.
 *
 * type
 * the second key in the object.
 *
 * dataEntries
 * the keys and values of all of the actual data to be used.
 *
 * tableType
 * used for determining what TokenExample component data to render.
 * this may not always be used
 *
 */

import BackgroundColor from '@cbds/cbds-design-tokens/dist/json/app/background/background-color.json';
import Body from '@cbds/cbds-design-tokens/dist/json/app/body/body.json';
import BorderColor from '@cbds/cbds-design-tokens/dist/json/app/border/border-color.json';
import BorderRadius from '@cbds/cbds-design-tokens/dist/json/app/border/border-radius.json';
import BorderWidth from '@cbds/cbds-design-tokens/dist/json/app/border/border-width.json';
import Border from '@cbds/cbds-design-tokens/dist/json/app/border/border.json';
import Breakpoints from '@cbds/cbds-design-tokens/dist/json/system/breakpoint/breakpoint.json';
import Button from '@cbds/cbds-design-tokens/dist/json/components/button.json';
import ColorsBrand from '@cbds/cbds-design-tokens/dist/json/system/color/color-brand-palette.json';
import ColorsBrandAndroid from '@cbds/cbds-design-tokens/dist/json-android/system/color/color-brand-palette.json';
import ColorsBrandIos from '@cbds/cbds-design-tokens/dist/json-ios/system/color/color-brand-palette.json';
import ColorsPalette from '@cbds/cbds-design-tokens/dist/json/system/color/color-palette.json';
import ColorsPaletteAndroid from '@cbds/cbds-design-tokens/dist/json-android/system/color/color-palette.json';
import ColorsPaletteIos from '@cbds/cbds-design-tokens/dist/json-ios/system/color/color-palette.json';
import Grid from '@cbds/cbds-design-tokens/dist/json/system/grid/grid.json';
import Heading from '@cbds/cbds-design-tokens/dist/json/app/typography/heading.json';
import Icon from '@cbds/cbds-design-tokens/dist/json/components/icon.json';
import Link from '@cbds/cbds-design-tokens/dist/json/components/link.json';
import Space from '@cbds/cbds-design-tokens/dist/json/system/space/space.json';
import Spinner from '@cbds/cbds-design-tokens/dist/json/components/spinner.json';
import TextColor from '@cbds/cbds-design-tokens/dist/json/app/text/text-color.json';
import TypographyFontFamily from '@cbds/cbds-design-tokens/dist/json/system/typography/font-family.json';
import TypographyFontRole from '@cbds/cbds-design-tokens/dist/json/app/typography/font-role.json';
import TypographyFontSize from '@cbds/cbds-design-tokens/dist/json/app/typography/font-size.json';
import TypographyFontStyle from '@cbds/cbds-design-tokens/dist/json/app/typography/font-style.json';
import TypographyFontType from '@cbds/cbds-design-tokens/dist/json/app/typography/font-type.json';
import TypographyFontWeightApp from './typeScale/font-weight.json';
import TypographyFontWeight from '@cbds/cbds-design-tokens/dist/json/system/typography/font-weight.json';
import TypographyLetterSpacing from '@cbds/cbds-design-tokens/dist/json/system/typography/letter-spacing.json';
import TypographyLineHeight from './typeScale/line-height.json';
import TypographyTypeScale from './typeScale/type-scale.json';
import Unit from '@cbds/cbds-design-tokens/dist/json/system/unit/unit.json';
import ZIndex from '@cbds/cbds-design-tokens/dist/json/system/z-index/z-index.json';

export const BACKGROUND_COLOR_TOKENS = {
  data: BackgroundColor,
  category: 'background',
  type: 'color',
  dataEntries: BackgroundColor.background.color,
  tableType: 'backgroundColor'
};

export const BODY_TOKENS = {
  data: Body,
  category: 'body',
  type: 'base',
  dataEntries: Body.body.base,
  tableType: 'body'
};

export const BORDER_COLOR_TOKENS = {
  data: BorderColor,
  category: 'border',
  type: 'color',
  dataEntries: BorderColor.border.color,
  tableType: 'borderColor'
};

export const BORDER_RADIUS_TOKENS = {
  data: BorderRadius,
  category: 'border',
  type: 'radius',
  dataEntries: BorderRadius.border.radius,
  tableType: 'borderRadius'
};

export const BORDER_WIDTH_TOKENS = {
  data: BorderWidth,
  category: 'border',
  type: 'width',
  dataEntries: BorderWidth.border.width,
  tableType: 'borderWidth'
};

export const BORDER_TOKENS = {
  data: Border,
  category: 'border',
  type: 'color',
  dataEntries: Border.border.color,
  tableType: 'border'
};

export const BREAKPOINT_TOKENS = {
  data: Breakpoints,
  category: 'breakpoint',
  type: 'base',
  dataEntries: Breakpoints.breakpoint.base,
  tableType: 'breakpoint'
};

export const BUTTON_TOKENS = {
  data: Button,
  category: 'button',
  type: 'primary',
  dataEntries: Button.button.primary,
  tableType: 'button'
};

export const COLORS_BRAND_TOKENS = {
  data: ColorsBrand,
  category: 'color',
  type: 'brand',
  dataEntries: ColorsBrand.color.brand,
  tableType: 'color'
};

export const COLORS_BRAND_ANDROID_TOKENS = {
  data: ColorsBrandAndroid,
  category: 'color',
  type: 'brand',
  dataEntries: ColorsBrandAndroid.color.brand,
  tableType: 'color'
};

export const COLORS_BRAND_IOS_TOKENS = {
  data: ColorsBrandIos,
  category: 'color',
  type: 'brand',
  dataEntries: ColorsBrandIos.color.brand,
  tableType: 'color'
};

export const COLORS_PALETTE_TOKENS = {
  data: ColorsPalette,
  category: 'color',
  type: 'palette',
  dataEntries: ColorsPalette.color.palette,
  tableType: 'color'
};

export const COLORS_PALETTE_ANDROID_TOKENS = {
  data: ColorsPaletteAndroid,
  category: 'color',
  type: 'palette',
  dataEntries: ColorsPaletteAndroid.color.palette,
  tableType: 'color'
};

export const COLORS_PALETTE_IOS_TOKENS = {
  data: ColorsPaletteIos,
  category: 'color',
  type: 'palette',
  dataEntries: ColorsPaletteIos.color.palette,
  tableType: 'color'
};

export const GRID_TOKENS = {
  data: Grid,
  category: 'grid',
  type: 'base',
  dataEntries: Grid.grid.base,
  tableType: 'grid'
};

export const HEADING_TOKENS = {
  data: Heading,
  category: 'heading',
  type: '',
  dataEntries: Heading.heading,
  tableType: 'heading'
};

export const ICON_TOKENS = {
  data: Icon,
  category: 'icon',
  type: 'base',
  dataEntries: Icon.icon.base,
  tableType: 'icon'
};

export const LINK_TOKENS = {
  data: Link,
  category: 'link',
  type: 'primary',
  dataEntries: Link.link.primary.color,
  tableType: 'link'
};

export const SPACE_BASE_TOKENS = {
  data: Space,
  category: 'space',
  type: 'base',
  dataEntries: Space.space.base,
  tableType: 'space'
};

export const SPACE_INSET_TOKENS = {
  data: Space,
  category: 'space',
  type: 'inset',
  dataEntries: Space.space.inset,
  tableType: 'space'
};

export const SPACE_INLINE_TOKENS = {
  data: Space,
  category: 'space',
  type: 'inline',
  dataEntries: Space.space.inline,
  tableType: 'space'
};

export const SPACE_STACK_TOKENS = {
  data: Space,
  category: 'space',
  type: 'stack',
  dataEntries: Space.space.stack,
  tableType: 'space'
};

export const SPINNER_TOKENS = {
  data: Spinner,
  category: 'spinner',
  type: 'base',
  dataEntries: Spinner.spinner.base,
  tableType: 'spinner'
};

export const TEXT_COLOR_TOKENS = {
  data: TextColor,
  category: 'text',
  type: 'color',
  dataEntries: TextColor.text.color,
  tableType: 'textColor'
};

export const TYPOGRAPHY_FONT_FAMILY_TOKENS = {
  data: TypographyFontFamily,
  category: 'font',
  type: 'family',
  dataEntries: TypographyFontFamily.font.family,
  tableType: 'fontFamily'
};

export const TYPOGRAPHY_FONT_ROLE_TOKENS = {
  data: TypographyFontRole,
  category: 'font',
  type: 'role',
  dataEntries: TypographyFontRole.font.role,
  tableType: 'fontRole'
};

export const TYPOGRAPHY_FONT_SIZE_TOKENS = {
  data: TypographyFontSize,
  category: 'font',
  type: 'size',
  dataEntries: TypographyFontSize.font.size,
  tableType: 'fontSize'
};

export const TYPOGRAPHY_FONT_STYLE_TOKENS = {
  data: TypographyFontStyle,
  category: 'font',
  type: 'style',
  dataEntries: TypographyFontStyle.font.style,
  tableType: 'fontStyle'
};

export const TYPOGRAPHY_FONT_TYPE_TOKENS = {
  data: TypographyFontType,
  category: 'font',
  type: 'type',
  dataEntries: TypographyFontType.font.type,
  tableType: 'fontType'
};

export const TYPOGRAPHY_FONT_WEIGHT_APP_TOKENS = {
  data: TypographyFontWeightApp,
  category: 'font',
  type: 'weight',
  dataEntries: TypographyFontWeightApp.font.weight,
  tableType: 'fontWeight'
};

export const TYPOGRAPHY_FONT_WEIGHT_TOKENS = {
  data: TypographyFontWeight,
  category: 'system-font',
  type: 'weight',
  dataEntries: TypographyFontWeight['system-font'].weight,
  tableType: 'fontWeight'
};

export const TYPOGRAPHY_LETTER_SPACING_TOKENS = {
  data: TypographyLetterSpacing,
  category: 'letterSpacing',
  type: 'base',
  dataEntries: TypographyLetterSpacing.letterSpacing.base,
  tableType: 'letterSpacing'
};

export const TYPOGRAPHY_LINE_HEIGHT_TOKENS = {
  data: TypographyLineHeight,
  category: 'lineHeight',
  type: 'base',
  dataEntries: TypographyLineHeight.lineHeight,
  tableType: 'lineHeight'
};

export const TYPOGRAPHY_TYPE_SCALE_TOKENS = {
  data: TypographyTypeScale,
  category: 'type',
  type: 'scale',
  dataEntries: TypographyTypeScale.type.scale,
  tableType: 'typeScale'
};

export const UNIT_TOKENS = {
  data: Unit,
  category: 'unit',
  type: 'base',
  dataEntries: Unit.unit.base,
  tableType: 'size'
};

export const ZINDEX_TOKENS = {
  data: ZIndex,
  category: 'stack',
  type: 'zIndex',
  dataEntries: ZIndex.stack.zIndex,
  tableType: 'zIndex'
};
