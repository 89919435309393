import React from 'react';
import Forms from './Forms.mdx';

const FormsPage = {
  mdx: Forms,
  breadcrumbs: "Patterns",
  alias: "patterns/forms",
  pathExact: false,
  title: "Forms",
  menuLabel: 'Forms',
  published: true,
  order: 3,
  date: '2023-10-06', // Show when last updated
  summary: <>We use forms frequently. Below are some time-tested (and researched) practices and recommendations for making some of our most commonly-used form elements and patterns as usable as possible.</>,

  // SEO and Search
  tags: [
    'Patterns',
    'Forms',
    'Form',
    'Form Elements',
    'Common Inputs',
    'Best Practices'
  ],
  meta: {
    description: '',
    title: 'Forms - Patterns',
    keywords: [],
  },
};

export default FormsPage;
