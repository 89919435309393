export default {
  colorPalette: {
    primary: [
      {
        meta: {
          name: 'Citizens Green'
        },
        rgb: '0, 133, 85',
        value: '#008555',
        cmyk: '100, 0, 74, 0',
        pms: '340',
        wcagBlack: {
          aa: 'false',
          aaLarge: 'true',
          ratio: '3.48'
        },
        wcagWhite: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '4.68'
        }
      },
      {
        meta: {
          name: 'Green 700'
        },
        rgb: '0, 106, 68',
        value: '#006A44',
        cmyk: '100, 0, 36, 58',
        pms: '7728 C',
        wcagBlack: {
          aa: 'false',
          aaLarge: 'true',
          ratio: '3.14'
        },
        wcagWhite: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '6.67'
        }
      },
      {
        meta: {
          name: 'White'
        },
        rgb: '255, 255, 255',
        value: '#fff',
        cmyk: '0, 0, 0, 0',
        pms: null,
        wcagBlack: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '16.30'
        },
        wcagWhite: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '0.00'
        }
      },
      {
        meta: {
          name: 'Lime Green'
        },
        rgb: '74, 255, 117',
        value: '#4aff75',
        cmyk: '44, 0, 68, 0, Spot Color Only',
        pms: '7487',
        wcagBlack: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '12.30'
        },
        wcagWhite: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '1.32'
        }
      },
      {
        meta: {
          name: 'Sage'
        },
        rgb: '240, 240, 230',
        value: '#f0f0e6',
        cmyk: '5, 3, 9, 0',
        pms: null,
        wcagBlack: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '18.31'
        },
        wcagWhite: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '1.14'
        }
      },
      {
        meta: {
          name: 'Neutral 100'
        },
        rgb: '233, 233, 233',
        value: '#e9e9e9',
        cmyk: '0, 0, 0, 9',
        pms: '663 C',
        wcagBlack: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '17.19'
        },
        wcagWhite: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '1.21'
        }
      },
      {
        meta: {
          name: 'Neutral 500'
        },
        rgb: '135, 134, 134',
        value: '#878686',
        cmyk: '0, 1, 1, 47',
        pms: '877 C',
        wcagBlack: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '5.78'
        },
        wcagWhite: {
          aa: 'false',
          aaLarge: 'true',
          ratio: '3.63'
        }
      },
      {
        meta: {
          name: 'Black'
        },
        rgb: '35, 31, 32',
        value: '#231f20',
        cmyk: '74, 70, 64, 100',
        pms: null,
        wcagBlack: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '0.00'
        },
        wcagWhite: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '16.30'
        }
      }
    ],
    secondary: [
      {
        meta: {
          name: 'CTA Button'
        },
        rgb: '217, 61, 0',
        value: '#d93d00',
        cmyk: '10, 89, 100, 2',
        pms: '1665 C',
        wcagBlack: {
          aa: 'false',
          aaLarge: 'true',
          ratio: '3.58'
        },
        wcagWhite: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '4.55'
        }
      },
      {
        meta: {
          name: 'Informative Messaging'
        },
        rgb: '0, 105, 143',
        value: '#00698f',
        cmyk: '92, 53, 27, 6',
        pms: '7706 C',
        wcagBlack: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '2.65'
        },
        wcagWhite: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '6.15'
        }
      },
      {
        meta: {
          name: 'Hyperlink'
        },
        rgb: '4, 77, 162',
        value: '#044da2',
        cmyk: '100, 79, 2, 0',
        pms: '2945 C',
        wcagBlack: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '2.01'
        },
        wcagWhite: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '8.11'
        }
      },
      {
        meta: {
          name: 'Alerts'
        },
        rgb: '188, 42, 42',
        value: '#bc2a2a',
        cmyk: '18, 97, 96, 8',
        pms: '7620 C',
        wcagBlack: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '2.72'
        },
        wcagWhite: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '6.00'
        }
      }
    ],
    illustration: [
      {
        meta: {
          name: 'Neutral 300'
        },
        rgb: '191, 190, 190',
        value: '#BFBEBE',
        cmyk: null,
        pms: null,
        wcagBlack: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '11.32'
        },
        wcagWhite: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '1.85'
        }
      },
      {
        meta: {
          name: 'Gold 500'
        },
        rgb: '225, 208, 51',
        value: '#e1d033',
        cmyk: null,
        pms: null,
        wcagBlack: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '13.27'
        },
        wcagWhite: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '1.58'
        }
      },
      {
        meta: {
          name: 'Green 300'
        },
        rgb: '153, 206, 187',
        value: '#99cebb',
        cmyk: '100, 0, 74, 0',
        pms: null,
        wcagBlack: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '11.9'
        },
        wcagWhite: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '1.76'
        }
      },
      {
        meta: {
          name: 'Orange 500'
        },
        rgb: '224, 99, 51',
        value: '#e06333',
        cmyk: null,
        pms: null,
        wcagBlack: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '6'
        },
        wcagWhite: {
          aa: 'false',
          aaLarge: 'true',
          ratio: '3.49'
        }
      },
      {
        meta: {
          name: 'Faded Blue 400'
        },
        rgb: '102, 165, 187',
        value: '#66a5bb',
        cmyk: null,
        pms: null,
        wcagBlack: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '7.66'
        },
        wcagWhite: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '2.73'
        }
      },
      {
        meta: {
          name: 'Blue 400'
        },
        rgb: '104, 148, 199',
        value: '#6894c7',
        cmyk: null,
        pms: null,
        wcagBlack: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '6.64'
        },
        wcagWhite: {
          aa: 'false',
          aaLarge: 'true',
          ratio: 'true'
        }
      }
    ],
    text: [
      {
        meta: {
          name: 'Primary'
        },
        rgb: '35, 31, 32',
        value: '#231f20',
        cmyk: '0, 0, 0, 100',
        pms: null,
        wcagBlack: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '0.00'
        },
        wcagWhite: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '16.30'
        }
      },
      {
        meta: {
          name: 'Secondary'
        },
        rgb: '55, 53, 53',
        value: '#373535',
        cmyk: '68, 63, 62, 56',
        pms: null,
        wcagBlack: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '1.34'
        },
        wcagWhite: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '12.19'
        }
      },
      {
        meta: {
          name: 'Tertiary'
        },
        rgb: '99, 98, 98',
        value: '#636262',
        cmyk: '60, 53, 52, 22',
        pms: null,
        wcagBlack: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '2.68'
        },
        wcagWhite: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '6.08'
        }
      }
    ],
    background: [
      {
        meta: {
          name: 'Dark'
        },
        rgb: '211, 210, 210',
        value: '#d3d2d2',
        cmyk: '16, 13, 13, 0',
        pms: null,
        wcagBlack: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '10.80'
        },
        wcagWhite: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '1.51'
        }
      },
      {
        meta: {
          name: 'Medium'
        },
        rgb: '237, 237, 237',
        value: '#ededed',
        cmyk: '6, 4, 4, 0',
        pms: null,
        wcagBlack: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '13.92'
        },
        wcagWhite: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '1.17'
        }
      },
      {
        meta: {
          name: 'Light'
        },
        rgb: '246, 246, 246',
        value: '#f6f6f6',
        cmyk: '2, 2, 2, 0',
        pms: null,
        wcagBlack: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '15.08'
        },
        wcagWhite: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '1.08'
        }
      }
    ],
    border: [
      {
        meta: {
          name: 'Dark'
        },
        rgb: '191, 190, 190',
        value: '#bfbebe',
        cmyk: '25, 20, 21, 0',
        pms: null,
        wcagBlack: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '8.79'
        },
        wcagWhite: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '1.85'
        }
      },
      {
        meta: {
          name: 'Medium'
        },
        rgb: '211, 210, 210',
        value: '#d3d2d2',
        cmyk: '16, 13, 13, 0',
        pms: null,
        wcagBlack: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '10.80'
        },
        wcagWhite: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '1.51'
        }
      },
      {
        meta: {
          name: 'Light'
        },
        rgb: '237, 237, 237',
        value: '#ededed',
        cmyk: '6, 4, 4, 0',
        pms: null,
        wcagBlack: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '13.92'
        },
        wcagWhite: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '1.17'
        }
      }
    ],
    tint: [
      {
        meta: {
          name: '100'
        },
        rgb: '35, 31, 32',
        value: '#231f20',
        cmyk: '0, 0, 0, 100',
        pms: null,
        wcagBlack: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '0.00'
        },
        wcagWhite: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '16.30'
        }
      },
      {
        meta: {
          name: '90'
        },
        rgb: '65, 64, 66',
        value: '#414042',
        cmyk: '0, 0, 0, 90',
        pms: null,
        wcagBlack: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '1.58'
        },
        wcagWhite: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '10.31'
        }
      },
      {
        meta: {
          name: '75'
        },
        rgb: '99, 100, 102',
        value: '#636466',
        cmyk: '0, 0, 0, 75',
        pms: null,
        wcagBlack: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '2.75'
        },
        wcagWhite: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '5.92'
        }
      },
      {
        meta: {
          name: '30'
        },
        rgb: '188, 190, 192',
        value: '#bcbec0',
        cmyk: '0, 0, 0, 30',
        pms: null,
        wcagBlack: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '8.74'
        },
        wcagWhite: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '1.86'
        }
      },
      {
        meta: {
          name: '20'
        },
        rgb: '209, 211, 212',
        value: '#d1d3d4',
        cmyk: '0, 0, 0, 20',
        pms: null,
        wcagBlack: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '10.85'
        },
        wcagWhite: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '1.50'
        }
      },
      {
        meta: {
          name: '5'
        },
        rgb: '241, 242, 242',
        value: '#f1f2f2',
        cmyk: '0, 0, 0, 5',
        pms: null,
        wcagBlack: {
          aa: 'true',
          aaLarge: 'true',
          ratio: '14.53'
        },
        wcagWhite: {
          aa: 'false',
          aaLarge: 'false',
          ratio: '1.12'
        }
      }
    ]
  }
};
