import React from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";

const ColorChip = ({ color, style }) => {
  const hexColor = `#${color}`;
  const styleClass = style ? `cbdsws-c-colorChip--${style}` : null;
  return (
    <div className={classNames("cbdsws-c-colorChip", styleClass)} aria-label={`color value: ${hexColor}`} role="complementary">
      <span style={{ backgroundColor: hexColor }} className="cbdsws-c-colorChip__swatch"></span>
      {hexColor}
    </div>
  );
};

ColorChip.propTypes = {
  color: PropTypes.string,
  style: PropTypes.string,
};

export default ColorChip;
