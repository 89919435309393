import CbdsComponentsAngular from './CbdsComponentsAngular.mdx';

const CbdsComponentsAngularPage = {
  mdx: CbdsComponentsAngular,
  breadcrumbs: "About/Changelogs",
  alias: "about/changelogs/cbds-components-angular",
  pathExact: false,
  title: "CBDS Components (Angular) Changelog",
  menuLabel: 'CBDS Components (Angular)',
  published: true,
  order: 2,
  date: '2024-01-24', // Show when last updated
  summary: "",

  // SEO and Search
  tags: [
    'CBDS',
    'About',
    'CBDS Components (Angular)',
    'Changelog',
    'Release Notes'
  ],
  meta: {
    description: '',
    title: 'CBDS Components (Angular) - Changelogs - About',
    keywords: [],
  },
}; 
export default CbdsComponentsAngularPage;
