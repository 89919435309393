import React from 'react';
import WeSeeYou from './WeSeeYou.mdx';

const WeSeeYouPage = {
  mdx: WeSeeYou,
  breadcrumbs: "Brand Guidelines/Wealth Management Guidelines",
  alias: "brand-guidelines/wealth-management-guidelines/we-see-you",
  pathExact: false,
  title: "We See You",
  menuLabel: 'We See You',
  published: true,
  order: 1,
  date: '2023-12-01', // Show when last updated
  summary: <>Wealth means something different to everyone.</>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Wealth Management',
    'Guidelines',
    'Wealth Management Guidelines',
    'We See You',
  ],
  meta: {
    title: 'We See You - Wealth Management - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default WeSeeYouPage;
