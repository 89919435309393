import CbdsIcons from './CbdsIcons.mdx';

const CbdsIconsPage = {
  mdx: CbdsIcons,
  breadcrumbs: "About/Changelogs",
  alias: "about/changelogs/cbds-icons",
  pathExact: false,
  title: "CBDS Icons Changelog",
  menuLabel: 'CBDS Icons',
  published: true,
  order: 2,
  date: '2024-03-01', // Show when last updated
  summary: "",

  // SEO and Search
  tags: [
    'CBDS',
    'About',
    'CBDS Icons',
    'Changelog',
    'Release Notes'
  ],
  meta: {
    description: '',
    title: 'CBDS Icons - Changelogs - About',
    keywords: [],
  },
}; 
export default CbdsIconsPage;
