import React from 'react';
import Cbds2022Cycle04Newsletter from './Cbds2022Cycle04Newsletter.mdx';

const Cbds2022Cycle04NewsletterPage = {
  mdx: Cbds2022Cycle04Newsletter,
  breadcrumbs: "About/Newsletter",
  alias: "about/newsletters/cbds-2022-cycle04-newsletter",
  pathExact: false,
  title: "CBDS 2022 Cycle 04 Newsletter",
  menuLabel: 'Cbds 2022 Cycle 04 Newsletter',
  published: true,
  hideFromNav: true,
  //order: 3,
  date: '2023-02-17', // Show when last updated
  summary: <><strong><em>Timeline:</em></strong> <em>June 22, 2022 &rarr; August 16, 2022<br/></em><br/>Wow! It's been a hot one over here, and we're not just talking about the heat. Cycle 04 came in hot with the much anticipated release of the CBDS Masthead Navigation component, new guidance on Bobby font and more. There's a lot of exciting work in progress and on the horizon. IN this cycle we focused on 3 main areas, including:</>,

  // SEO and Search
  tags: [
    'CBDS',
    'About',
    'Newsletter',
    'Updates',
    'CBDS 2022 Cycle 04 Newsletter',
    'Release Notes',
  ],
  meta: {
    description: '',
    title: 'CBDS 2022 Cycle 04 Newsletter - Newsletters - About',
    keywords: [],
  },
};

export default Cbds2022Cycle04NewsletterPage;
