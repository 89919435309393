import React from 'react';
import PropType from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

const Logo = ({
  className,
  uid,
  sidebarLogoRef,
}) => (
  <div className={classNames("cbdsws-c-logo", className)}>
    <Link id={uid} ref={sidebarLogoRef} to="/" className="cbdsws-c-logo__link cbensightenevent" cbdata-type="link" cbdata-reason="__inPageNav: Home">
      <img src="/assets/images/horizontal_citizens_logo.svg" classes="cbdsws-c-logo__logo" alt="Home" />
    </Link>
  </div>
);

Logo.defaultProps = {
  className: null,
  uid: null,
  sidebarLogoRef: null,
};

Logo.propTypes = {
  className: PropType.string,
  uid: PropType.string,
  sidebarLogoRef: PropType.any,
};

export default Logo;
