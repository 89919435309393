import React from 'react';
import LifeIsAJourney from './LifeIsAJourney.mdx';

const LifeIsAJourneyPage = {
  mdx: LifeIsAJourney,
  breadcrumbs: "Brand Guidelines/Consumer Guidelines",
  alias: "brand-guidelines/consumer-guidelines/life-is-a-journey",
  pathExact: false,
  title: "Life is A Journey",
  menuLabel: 'Life is A Journey',
  published: true,
  order: 4,
  date: '2023-01-30', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Consumer',
    'Guidelines',
    'Life is a Journey',
  ],
  meta: {
    title: 'Life is a Journey - Consumer - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default LifeIsAJourneyPage;
