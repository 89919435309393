import OurMission from './OurMission.mdx';

const OurMissionPage = {
  mdx: OurMission,
  breadcrumbs: "About",
  alias: "about/our-mission",
  pathExact: false,
  title: "Our Mission",
  menuLabel: 'Our Mission',
  published: true,
  order: 1,
  date: '2023-04-12', // Show when last updated
  summary: "We believe that designing and developing for the web and mobile applications should be a breeze, not a headache. That's why we are building our library of pre-made, accessible code and design elements to help you save time and create beautiful, functional interfaces without breaking a sweat. So say goodbye to tedious coding and design work and let's make the web a more accessible and stylish place, one line of code at a time.",

  // SEO and Search
  tags: [
    'CBDS',
    'About',
    'Our Mission',
    'Mission',
  ],
  meta: {
    description: '',
    title: 'Our Mission - About',
    keywords: [],
  },
};

export default OurMissionPage;
