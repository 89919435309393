import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { CbdsIcon } from '@cbds/cbds-components-react';

const Search = ({ uid, appRef, toggleMenu, isMobile = false }) => {
  const [ search, setSearch ] = useState('');
  const history = useHistory();

  const onSearch = e => {
    e.preventDefault();
    if (search.length > 2) {
      history.replace({
        pathname: '/search',
        search: `?s=${search}`,
      });
      if (toggleMenu && !isMobile) {
        toggleMenu(false, 'click');
      }
      document.getElementById('cbdsws-contentContainer').scrollTo(0, 0);
      appRef.current.focus();
    }
  };

  return (
    <form className="cbdsws-c-searchBar" role="search">
      <input id={uid} className="cbdsws-c-searchBar__input" aria-label="Search" type="text" onChange={e => setSearch(e.target.value.trim())} />
      <button
        className="cbdsws-c-searchBar__button"
        type="submit"
        onClick={onSearch}
        aria-label="Search"
        disabled={search.length < 3}
        cbdata-type="button"
        cbdata-reason={`__search: ${search}`}
      >
        <CbdsIcon iconName="search" classes="cbdsws-c-searchIcon" a11yHidden="true" />
      </button>
    </form>
  );
};

export default Search;
