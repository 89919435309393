import ToiletSink from './ToiletSink.mdx';
import Markdown from './Markdown.mdx';
import Components from './Components.mdx';

const ToiletSinkPage = {
  mdx: ToiletSink,
  alias: "toiletsink",
  pathExact: false,
  title: "Toilet Sink",
  menuLabel: 'Toilet Sink',
  published: process.env.NODE_ENV === 'development',
  date: '2022-01-11', // Show when last updated
  summary: 'This is the toilet sink (kitchen sink) of all markdown and CBDSWS components',
  tabs: [
    {
      label: 'Markdown',
      mdx: Markdown,
      alias: '/toiletsink/markdown',
      meta: {
        title: 'ToiletSink - Markdown',
        description: '',
        keywords: [],
      },
    },
    {
      label: 'Components',
      mdx: Components,
      alias: '/toiletsink/components',
      meta: {
        title: 'ToiletSink - Components',
        description: '',
        keywords: [],
      },
    },
  ],

  // SEO and Search
  tags: [
    'Example',
    'CBDS',
    'Test',
  ],
  meta: {
    title: 'ToiletSink',
    description: '',
    keywords: [],
  },
};

export default ToiletSinkPage;
