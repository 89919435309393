import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const PatternsFlexLayouts = ({ example }) => {
  return (
    <>
      {example === "1" && (
        <div className="cbds-l-grid">
          <div className="cbds-l-grid__row">
            <div
              className={classNames(
                "cbds-l-grid__col--@sm",
                "cbds-u-display__flex",
                "cbds-u-flexJustifyContent__end",
                "cbds-u-flexOrder__2@sm"
              )}
            >
              <button
                className={classNames(
                  "cbds-c-button",
                  "cbds-c-button--contained",
                  "cbds-u-flexGrow__1",
                  "cbds-u-flexGrow__0@sm"
                )}
              >
                <span className="cbds-c-button__label">Submit</span>
              </button>
            </div>
            <div
              className={classNames(
                "cbds-l-grid__col--@sm",
                "cbds-u-display__flex"
              )}
            >
              <button
                className={classNames(
                  "cbds-c-button",
                  "cbds-c-button--outlined",
                  "cbds-u-marginTop__4x",
                  "cbds-u-flexGrow__1",
                  "cbds-u-margin__0@sm",
                  "cbds-u-flexGrow__0@sm"
                )}
              >
                <span className="cbds-c-button__label">Cancel</span>
              </button>
            </div>
          </div>
        </div>
      )}
      {example === "2" && (
        <ul
          className={classNames(
            "cbds-u-display__flex@sm",
            "cbds-u-list__unstyled"
          )}
        >
          <li>
            <button
              className={classNames(
                "cbds-c-button",
                "cbds-c-button--ghost",
                "cbds-c-button--sm"
              )}
            >
              <svg
                className={classNames("cbds-c-icon", "cbds-c-button__icon")}
                focusable="false"
                aria-hidden="true"
              >
                <use
                  href="/assets/icons/cbds-icons-ui.svg#gear"
                  xlinkHref="/assets/icons/cbds-icons-ui.svg#gear"
                ></use>
              </svg>
              <span className="cbds-c-button__label">Action 1</span>
            </button>
          </li>
          <li>
            <button
              className={classNames(
                "cbds-c-button",
                "cbds-c-button--ghost",
                "cbds-c-button--sm"
              )}
            >
              <svg
                className={classNames("cbds-c-icon", "cbds-c-button__icon")}
                focusable="false"
                aria-hidden="true"
              >
                <use
                  href="/assets/icons/cbds-icons-ui.svg#gear"
                  xlinkHref="/assets/icons/cbds-icons-ui.svg#gear"
                ></use>
              </svg>
              <span className="cbds-c-button__label">Action 2</span>
            </button>
          </li>
        </ul>
      )}
      {example === "3" && (
        <div className="cbds-l-grid">
          <div className="cbds-l-grid__row">
            <article className="cbds-l-grid__col--9@md">
              <h3>Article content</h3>
              <p>Content here ... </p>
            </article>
            <aside className="cbds-l-grid__col--3@md">
              <h4>Aside</h4>
              <ul>
                <li>sidebar item 1</li>
                <li>sidebar item 2</li>
                <li>sidebar item 3</li>
              </ul>
            </aside>
          </div>
        </div>
      )}
    </>
  );
};

PatternsFlexLayouts.propTypes = {
  example: PropTypes.number,
};

export default PatternsFlexLayouts;
