import React from "react";
import Link from '../../../../components/Link';
import TableSorting from "./TableSorting.mdx";
import Guidelines from "./Guidelines.mdx";
// import Accessibility from "./Accessibility.mdx";
// import Code from "./Code.mdx";

const TableSortingPage = {
  mdx: TableSorting,
  breadcrumbs: "Components/Web Responsive Library",
  alias: "components/web-responsive-library/table-sorting",
  pathExact: false,
  title: "Table/Sorting",
  menuLabel: "Table/Sorting",
  published: true,
  date: "2025-03-11", // Show when last updated
  summary: "",
  tabs: [
    {
      label: "Guidelines",
      mdx: Guidelines,
      summary: <>The Table displays and sorts data. For non-sorting simple table guidance,see <Link internalUrl="/components/web-responsive-library/table/guidelines">Table</Link>.</>,
      alias: "/components/web-responsive-library/table-sorting/guidelines",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Table",
        "Sorting",
        "Sortable",
        "Angular",
        "Design",
        "Guidelines",
      ],
      meta: {
        title: "Guidelines - Table Sorting - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    /*{
      label: "Accessibility",
      mdx: Accessibility,
      summary: <></>,
      alias: "/components/web-responsive-library/table/accessibility",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Table Sorting",
        "Accessibility",
      ],
      meta: {
        title: "Accessibility - Table Sorting - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    {
      label: "Code",
      mdx: Code,
      summary: <></>,
      alias: "/components/web-responsive-library/table/code",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Table Sorting",
        "Code",
      ],
      meta: {
        title: "Code - Table Sorting - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },*/
  ],
  // SEO and Search
  tags: [],
  meta: {
    title: "Table Sorting - Web Responsive Library - Components",
    description: "",
    keywords: [],
  },

  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "index.html?path=/story/components-Overlay",
    },
    react: {
      status: "na",
      version: "v1.0.0",
      link: "index.html?path=/story/components-TableSorting--default",
    },
    angular: {
      status: "released",
      version: "v1.0.0",
      link: "/index.html?path=/story/components-table--default",
    },
    figma: {
      status: "released",
      version: "",
      link: "https://www.figma.com/design/mj41OHGCokcJ9N7D0q8D9Q/CDS-UI-Kit-V1.1?m=auto&node-id=3152-16771&t=sE4DJHgQseSIquTz-1",
    },
  },
};

export default TableSortingPage;
