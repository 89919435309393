import React from 'react';
import CdsJune2024Newsletter from './CdsJune2024Newsletter.mdx';

const CdsJune2024NewsletterPage = {
  mdx: CdsJune2024Newsletter,
  breadcrumbs: "About/Newsletter",
  alias: "about/newsletters/cds-june-2024-newsletter",
  pathExact: false,
  title: "CDS Newsletter June 2024",
  menuLabel: 'Cds June 2024 Newsletter',
  published: true,
  hideFromNav: true,
  //order: 3,
  date: '2024-08-26', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CDS',
    'About',
    'Newsletter',
    'Updates',
    'June',
    '2024',
    'Release Notes',
  ],
  meta: {
    description: '',
    title: 'CDS June 2024 Newsletter - Newsletters - About',
    keywords: [],
  },
};

export default CdsJune2024NewsletterPage;
