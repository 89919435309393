import React from 'react';
import Cbds2022Cycle03Newsletter from './Cbds2022Cycle03Newsletter.mdx';

const Cbds2022Cycle03NewsletterPage = {
  mdx: Cbds2022Cycle03Newsletter,
  breadcrumbs: "About/Newsletter",
  alias: "about/newsletters/cbds-2022-cycle03-newsletter",
  pathExact: false,
  title: "CBDS 2022 Cycle 03 Newsletter",
  menuLabel: 'Cbds 2022 Cycle 03 Newsletter',
  published: true,
  hideFromNav: true,
  //order: 3,
  date: '2023-02-17', // Show when last updated
  summary: <><strong><em>Timeline:</em></strong> <em>April 27, 2022 &rarr; June 21, 2022<br/></em><br/>Summer's here and we couldn't be more excited! We hope you take some time in the next couple weeks to get out and enjoy the beautiful weather with family. There's a lot of exciting work in progress and on the horizon. IN this cycle we focused on 3 main areas, including:</>,

  // SEO and Search
  tags: [
    'CBDS',
    'About',
    'Newsletter',
    'Updates',
    'CBDS 2022 Cycle 03 Newsletter',
    'Release Notes',
  ],
  meta: {
    description: '',
    title: 'CBDS 2022 Cycle 03 Newsletter - Newsletters - About',
    keywords: [],
  },
};

export default Cbds2022Cycle03NewsletterPage;
