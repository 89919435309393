import React from 'react';
import Display from './Display.mdx';

const DisplayPage = {
  mdx: Display,
  breadcrumbs: "Foundations/Utilities",
  alias: "foundations/utilities/display",
  pathExact: false,
  title: "Display",
  menuLabel: 'Display',
  published: true,
  order: 2,
  date: '2023-02-07', // Show when last updated
  summary: <>A display class applies a display property to an element.</>,

  // SEO and Search
  tags: [
    'CBDS',
    'Foundations',
    'Display',
    'Utility',
    'Utility Classes System',
    'Utility Class Display for Developers',
  ],
  meta: {
    title: 'Display - Utilities - Foundations',
    description: '',
    keywords: [],
  },
};

export default DisplayPage;
