import Typography from './Typography.mdx';

const TypographyPage = {
  mdx: Typography,
  breadcrumbs: "Foundations",
  alias: "foundations/typography",
  pathExact: false,
  title: "Typography",
  menuLabel: 'Typography',
  published: true,
  order: 8,
  date: '2023-01-26', // Show when last updated
  summary: 'Typography is one of the key foundations of a consistent and accessible design system. Typography rules help Designers and Developers create content and copy that is clear, accessible, and effective.',

  // SEO and Search
  tags: [
    'Typography',
    'CBDS',
    'Type',
    'Font',
  ],
  meta: {
    title: 'Typography - Foundations',
    description: '',
    keywords: [],
  },
};

export default TypographyPage;
