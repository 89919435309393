// import React from "react";
import Navigation from "./Navigation.mdx";
import Guidelines from "./Guidelines.mdx";
// import Accessibility from "./Accessibility.mdx";
// import Code from "./Code.mdx";

const NavigationPage = {
  mdx: Navigation,
  breadcrumbs: "Components/Web Responsive Library",
  alias: "components/web-responsive-library/navigation",
  pathExact: false,
  title: "Navigation",
  menuLabel: "Navigation",
  published: true,
  date: "2023-10-05", // Show when last updated
  summary: "",
  tabs: [
    {
      label: "Guidelines",
      mdx: Guidelines,
      summary: "Navigation menu provides access to destinations and app functionality.",
      alias: "/components/web-responsive-library/navigation/guidelines",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Navigation",
        "Design",
        "Guidelines",
        "HTML",
        "Angular",
      ],
      meta: {
        title: "Guidelines - Navigation - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    /*{
      label: "Accessibility",
      mdx: Accessibility,
      summary: <>Add A11Y summary</>,
      alias: "/components/web-responsive-library/navigation/accessibility",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Navigation",
        "Accessibility",
        "HTML",
        "Angular",
      ],
      meta: {
        title: "Accessibility - Navigation - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    {
      label: "Code",
      mdx: Code,
      summary: <></>,
      alias: "/components/web-responsive-library/navigation/code",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Navigation",
        "Code",
        "HTML",
        "Angular",
      ],
      meta: {
        title: "Code - Navigation - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },*/
  ],
  // SEO and Search
  tags: [],
  meta: {
    title: "Navigation - Web Responsive Library - Components",
    description: "",
    keywords: [],
  },

  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "",
    },
    react: {
      status: "released",
      version: "v1.0.0",
      link: "/index.html?path=/story/components-navigation--with-submenu-active",
    },
    angular: {
      status: "released",
      version: "v1.0.0",
      link: "/index.html?path=/story/components-navigation--with-submenu-active",
    },
    figma: {
      status: "released",
      version: "",
      link: "https://www.figma.com/design/mj41OHGCokcJ9N7D0q8D9Q/CDS-UI-Kit-V1.1?m=auto&node-id=1241-5474&t=JdUBNI95pRCdgfMZ-1",
    },
  },
};

export default NavigationPage;
