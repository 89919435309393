import Color from './Color.mdx';

const ColorPage = {
  mdx: Color,
  breadcrumbs: "Foundations",
  alias: "foundations/color",
  pathExact: false,
  title: "Color",
  menuLabel: 'Color',
  published: true,
  order: 3,
  date: '2024-06-11', // Show when last updated
  summary: 'Designers have flexibility when it comes to using CDS color tokens. The preselected palette ensures that Citizens digital experiences are accessible, consistent, and visually appealing.',

  // SEO and Search
  tags: [
    'CBDS',
    'Foundations',
    'Color',
    'Design',
  ],
  meta: {
    title: 'Color - Foundations',
    description: '',
    keywords: [],
  },
};

export default ColorPage;
