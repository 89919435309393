export default {
  colorPalette: {
    primary: [
      {
        meta: {
          name: "Ciz Eggshell",
        },
        rgb: "255, 248, 241",
        value: "#fff8f1",
        cmyk: "0, 2, 4, 0",
        pms: "20% of PMS 7506U",
        wcagBlack: {
          aa: "true",
          aaLarge: "true",
          ratio: "19.94",
        },
        wcagWhite: {
          aa: "false",
          aaLarge: "false",
          ratio: "1.05",
        },
      },
      {
        meta: {
          name: "Black",
        },
        rgb: "0, 0, 0",
        value: "#000",
        cmyk: "0, 0, 0, 100",
        pms: "Black",
        wcagBlack: {
          aa: "false",
          aaLarge: "false",
          ratio: "0.00",
        },
        wcagWhite: {
          aa: "true",
          aaLarge: "true",
          ratio: "16.3",
        },
      },
      {
        meta: {
          name: "Deep Green",
        },
        rgb: "0, 100, 80",
        value: "#006450",
        cmyk: "99, 36, 74, 26",
        pms: "336",
        wcagBlack: {
          aa: "false",
          aaLarge: "false",
          ratio: "2.94",
        },
        wcagWhite: {
          aa: "true",
          aaLarge: "true",
          ratio: "7.15",
        },
      },
    ],
    special: [
      {
        meta: {
          name: "White",
        },
        rgb: "255, 255, 255",
        value: "#ffffff",
        cmyk: "0, 0, 0, 0",
        pms: "N/A",
        wcagBlack: {
          aa: "true",
          aaLarge: "true",
          ratio: "21",
        },
        wcagWhite: {
          aa: "false",
          aaLarge: "false",
          ratio: "1",
        },
      },
    ],
    eggshell: [
      {
        meta: {
          name: "Eggshell +10%",
        },
        rgb: "231, 225, 129",
        value: "#e7e1db",
        cmyk: "0, 2, 4, 10",
        pms: "N/A",
        wcagBlack: {
          aa: "true",
          aaLarge: "true",
          ratio: "16.19",
        },
        wcagWhite: {
          aa: "false",
          aaLarge: "false",
          ratio: "1.29",
        },
      },
      {
        meta: {
          name: "Eggshell +20%",
        },
        rgb: "231, 225, 129",
        value: "#d2cdc9",
        cmyk: "0, 2, 4, 20",
        pms: "N/A",
        wcagBlack: {
          aa: "true",
          aaLarge: "true",
          ratio: "13.31",
        },
        wcagWhite: {
          aa: "false",
          aaLarge: "false",
          ratio: "1.57",
        },
      },
      {
        meta: {
          name: "Eggshell +30%",
        },
        rgb: "188, 185, 182",
        value: "#bcb9b6",
        cmyk: "0, 2, 4, 30",
        pms: "N/A",
        wcagBlack: {
          aa: "true",
          aaLarge: "true",
          ratio: "10.75",
        },
        wcagWhite: {
          aa: "false",
          aaLarge: "false",
          ratio: "1.95",
        },
      },
      {
        meta: {
          name: "Eggshell +40%",
        },
        rgb: "168, 165, 163",
        value: "#a8a5a3",
        cmyk: "0, 2, 4, 40",
        pms: "N/A",
        wcagBlack: {
          aa: "true",
          aaLarge: "true",
          ratio: "8.57",
        },
        wcagWhite: {
          aa: "false",
          aaLarge: "false",
          ratio: "2.44",
        },
      },
      {
        meta: {
          name: "Eggshell +50%",
        },
        rgb: "148, 145, 144",
        value: "#949190",
        cmyk: "0, 2, 4, 50",
        pms: "N/A",
        wcagBlack: {
          aa: "true",
          aaLarge: "true",
          ratio: "6.71",
        },
        wcagWhite: {
          aa: "false",
          aaLarge: "true",
          ratio: "3.12",
        },
      },
      {
        meta: {
          name: "Eggshell +60%",
        },
        rgb: "128, 125, 124",
        value: "#817f7d",
        cmyk: "0, 2, 4, 60",
        pms: "N/A",
        wcagBlack: {
          aa: "true",
          aaLarge: "true",
          ratio: "5.26",
        },
        wcagWhite: {
          aa: "false",
          aaLarge: "true",
          ratio: "3.98",
        },
      },
      {
        meta: {
          name: "Eggshell +70%",
        },
        rgb: "109, 107, 106",
        value: "#6d6b6a",
        cmyk: "0, 2, 4, 70",
        pms: "N/A",
        wcagBlack: {
          aa: "false",
          aaLarge: "true",
          ratio: "3.96",
        },
        wcagWhite: {
          aa: "true",
          aaLarge: "true",
          ratio: "5.3",
        },
      },
      {
        meta: {
          name: "Eggshell +80%",
        },
        rgb: "88, 86, 86",
        value: "#585656",
        cmyk: "0, 2, 4, 80",
        pms: "N/A",
        wcagBlack: {
          aa: "false",
          aaLarge: "false",
          ratio: "2.88",
        },
        wcagWhite: {
          aa: "true",
          aaLarge: "true",
          ratio: "7.29",
        },
      },
      {
        meta: {
          name: "Eggshell +90%",
        },
        rgb: "65, 62, 61",
        value: "#413e3d",
        cmyk: "0, 2, 4, 90",
        pms: "N/A",
        wcagBlack: {
          aa: "false",
          aaLarge: "false",
          ratio: "1.98",
        },
        wcagWhite: {
          aa: "true",
          aaLarge: "true",
          ratio: "10.59",
        },
      },
    ],
    deepGreen: [
      {
        meta: {
          name: "15% Deep Green"
        },
        rgb: "210, 216, 209",
        value: "#d2d8d1",
        cmyk: "17, 9, 16, 0",
        pms: "N/A",
        wcagBlack: {
          aa: "true",
          aaLarge: "true",
          ratio: "14.48",
        },
        wcagWhite: {
          aa: "false",
          aaLarge: "false",
          ratio: "1.44",
        },
      },
      {
        meta: {
          name: "30% Deep Green"
        },
        rgb: "210, 216, 209",
        value: "#aebcb1",
        cmyk: "33, 18, 30, 0",
        pms: "N/A",
        wcagBlack: {
          aa: "true",
          aaLarge: "true",
          ratio: "10.62",
        },
        wcagWhite: {
          aa: "false",
          aaLarge: "false",
          ratio: "1.97",
        },
      },
      {
        meta: {
          name: "45% Deep Green"
        },
        rgb: "140, 162, 149",
        value: "#8ca295",
        cmyk: "48, 26, 42, 1",
        pms: "N/A",
        wcagBlack: {
          aa: "true",
          aaLarge: "true",
          ratio: "7.71",
        },
        wcagWhite: {
          aa: "false",
          aaLarge: "false",
          ratio: "2.72",
        },
      },
      {
        meta: {
          name: "60% Deep Green"
        },
        rgb: "110, 143, 130",
        value: "#6e8f82",
        cmyk: "60, 31, 50, 5",
        pms: "N/A",
        wcagBlack: {
          aa: "true",
          aaLarge: "true",
          ratio: "5.91",
        },
        wcagWhite: {
          aa: "false",
          aaLarge: "true",
          ratio: "3.55",
        },
      },
      {
        meta: {
          name: "75% Deep Green"
        },
        rgb: "81, 127, 109",
        value: "#517f6d",
        cmyk: "71, 34, 60, 12",
        pms: "N/A",
        wcagBlack: {
          aa: "true",
          aaLarge: "true",
          ratio: "4.6",
        },
        wcagWhite: {
          aa: "true",
          aaLarge: "true",
          ratio: "4.55",
        },
      },
      {
        meta: {
          name: "90% Deep Green"
        },
        rgb: "41, 110, 91",
        value: "#296e5b",
        cmyk: "83, 36, 68, 21",
        pms: "N/A",
        wcagBlack: {
          aa: "false",
          aaLarge: "true",
          ratio: "3.47",
        },
        wcagWhite: {
          aa: "true",
          aaLarge: "true",
          ratio: "6.04",
        },
      },
      {
        meta: {
          name: "Deep Green"
        },
        rgb: "0, 100, 80",
        value: "#006450",
        cmyk: "99, 36, 74, 26",
        pms: "336",
        wcagBlack: {
          aa: "false",
          aaLarge: "false",
          ratio: "2.93",
        },
        wcagWhite: {
          aa: "true",
          aaLarge: "true",
          ratio: "7.41",
        },
      },
    ],
  },
};
