import React from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";

import ColorCardYaml from '../ColorCard/ColorCardYaml';

const ColorGridYaml = ({ classes, colors, layoutColumns, displayType, spaceMT, colorsConfig, type }) => {
  const layoutColumnsClass = layoutColumns ? `cbdsws-c-colorGrid__grid--${layoutColumns}` : null;
  const typeClass = displayType ? `cbdsws-c-colorGrid--${displayType}` : null;
  const spaceClass = spaceMT ? `cbdsws-u-sp__mt${spaceMT}x` : null;

  return (
    <div className={classNames("cbdsws-c-colorGrid", typeClass, spaceClass, classes)}>
      <div className={classNames("cbdsws-c-colorGrid__grid", layoutColumnsClass)}>
        {colors && colors.map((item, index) => {
          const lastIndex = colors.length - 1;
          let gridItemFirstClass = '';
          let cardItemFirstClass = '';
          let gridItemLastClass = '';
          let cardItemLastClass = '';

          if (index === 0) {
            gridItemFirstClass = 'cbdsws-c-colorGrid__gridItem--first';
            cardItemFirstClass = 'cbdsws-c-colorCard--first';
          }

          if (index === lastIndex) {
            gridItemLastClass = 'cbdsws-c-colorGrid__gridItem--last';
            cardItemLastClass = 'cbdsws-c-colorCard--last';
          }

          return (
            <div key={`colorGridYaml_${index}`} className={classNames("cbdsws-c-colorGrid__gridItem", `cbdsws-c-colorGrid__gridItem-${index+1}`, gridItemFirstClass, gridItemLastClass)}>
              <ColorCardYaml
                classes={`cbdsws-c-colorCard-${index+1}${cardItemFirstClass}${cardItemLastClass}`}
                layout={colorsConfig.layout}
                displayType={displayType}
                type={type}
                showHEX={colorsConfig.showHEX}
                showRGB={colorsConfig.showRGB}
                showPrint={colorsConfig.showPrint}
                showSketch={colorsConfig.showSketch}
                showTokenNative={colorsConfig.showTokenNative}
                showTokenSCSS={colorsConfig.showTokenSCSS}
                showWCAG={colorsConfig.showWCAG}
                name={item.meta.name}
                hex={item.value}
                rgb={item.rgb}
                cmyk={item.cmyk}
                pms={item.pms}
                scss={item.name}
                text={item.meta.description}
                wcagBlackAA={item.wcagBlack.aa}
                wcagBlackAALarge={item.wcagBlack.aaLarge}
                wcagWhiteAA={item.wcagWhite.aa}
                wcagWhiteAALarge={item.wcagWhite.aaLarge}
                wcagBlackRatio={item.wcagBlack.ratio}
                wcagWhiteRatio={item.wcagWhite.ratio}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

ColorGridYaml.propTypes = {
  colorsConfig: PropTypes.object,
};

ColorGridYaml.defaultProps = {

};


export default ColorGridYaml;
