// import React from "react";
import ErrorSummary from "./ErrorSummary.mdx";
import Guidelines from "./Guidelines.mdx";
import Accessibility from "./Accessibility.mdx";
// import Code from "./Code.mdx";

const ErrorSummaryPage = {
  mdx: ErrorSummary,
  breadcrumbs: "Components/Web Responsive Library/Forms",
  alias: "components/web-responsive-library/forms/error-summary",
  pathExact: false,
  title: "Error Summary",
  menuLabel: "Error Summary",
  published: true,
  date: "2024-12-17", // Show when last updated
  summary: "",
  tabs: [
    {
      label: "Guidelines",
      mdx: Guidelines,
      summary: "The Error Summary component displays user input errors preventing the successful submission of a form.",
      alias: "/components/web-responsive-library/forms/error-summary/guidelines",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        'Form',
        'Forms',
        "Summary",
        'Message',
        'Messaging',
        'Error',
        'Notification',
        "Design",
        "Guidelines",
      ],
      meta: {
        title: "Guidelines - Error Summary - Forms - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    {
      label: "Accessibility",
      mdx: Accessibility,
      summary: "",
      alias: "/components/web-responsive-library/forms/error-summary/accessibility",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Form",
        "Forms",
        "Summary",
        "Message",
        "Messaging",
        "Error",
        "Notification",
        "Accessibility",
        "a11y",
      ],
      meta: {
        title: "Accessibility - Error Summary - Forms - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    /*{
      label: "Code",
      mdx: Code,
      summary: <></>,
      alias: "/components/web-responsive-library/forms/error-summary/code",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        'Form',
        'Forms',
        "Error Summary",
        'Message',
        'Messaging',
        'Error',
        'Notification',
        "code",
      ],
      meta: {
        title: "Code - Error Summary - Forms - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },*/
  ],
  // SEO and Search
  tags: [],
  meta: {
    title: "Error Summary - Forms - Web Responsive Library - Components",
    description: "",
    keywords: [],
  },

  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "index.html?path=/story/components-Overlay",
    },
    react: {
      status: "na",
      version: "v1.0.0",
      link: "index.html?path=/story/components-ErrorSummary--default",
    },
    angular: {
      status: "released",
      version: "v1.0.0",
      link: "/index.html?path=/story/components-forms-error-summary--default",
    },
    figma: {
      status: "released",
      version: "",
      link: "https://www.figma.com/file/mj41OHGCokcJ9N7D0q8D9Q/CBDS-UI-Kit-V1.1?node-id=2159%3A7071&t=BbsVUyRwx8GP3fqN-1",
    },
  },
};

export default ErrorSummaryPage;
