import React from 'react';
import Flex from './Flex.mdx';
import Link from '../../../../components/Link';

const FlexPage = {
  mdx: Flex,
  breadcrumbs: "Foundations/Utilities",
  alias: "foundations/utilities/flex",
  pathExact: false,
  title: "Flex",
  menuLabel: 'Flex',
  published: true,
  order: 3,
  date: '2023-02-07', // Show when last updated
  summary: <>Flex utility classes can be used to apply <strong>flexbox</strong> properties to elements. <Link variant="lead" url="https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Basic_Concepts_of_Flexbox"   a11yLabel="CSS Flexbox layout (opens in new tab)">CSS Flexbox layout</Link> module provides a way to align and distribute the size and space among items in a container, both horizontally and vertically.</>,

  // SEO and Search
  tags: [
    'CBDS',
    'Foundations',
    'Flex',
    'Utility',
    'Utility Classes System',
    'Flex  Utility Classes for Developers',
  ],
  meta: {
    title: 'Flex - Utilities - Foundations',
    description: '',
    keywords: [],
  },
};

export default FlexPage;
