import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CbdsIcon } from '@cbds/cbds-components-react';
import Tag from '../Tag';
import IconInline from '../IconInline/IconInline';

const CardContent = ({ data }) => {
  const devStageTextContent = (data.devStage === 'preAlpha') ? 'PRE-ALPHA' : data.devStageText;
  let continueIconName = '';
  let mediaTypeClass = null;

  if (data.media && data.media.type) {
    mediaTypeClass = `cbdsws-c-card__media--${data.media.type}`;
  }

  // Action Url Type
  switch(data.urlType) {
    case ('email'):
      continueIconName = 'envelope-o';
      break;
    case ('external'):
      continueIconName = 'launch';
      break;
    case ('download'):
      continueIconName = 'download';
      break;
    default:
      continueIconName = 'arrow-right';
      break;
  }

  return (
    <>
      <div className="cbdsws-c-card__bar"></div>
      <div className="cbdsws-c-card__inner">

        {!!data.showDevStage && data.devStage && (
          <Tag
            classes="cbdsws-c-card__releaseTag"
            color="compPreAlpha"
            fontWeight="normal"
            size="sm"
            text={devStageTextContent}
          />
        )}

        {(data.media && data.media.srcUrl) && (
          <figure className={classNames("cbdsws-c-card__media", mediaTypeClass, data.mediaClasses)}>

            {(data.media.type === 'icon' || !data.media.type) && (
              <IconInline
                classes="cbdsws-c-card__mediaIcon"
                name={data.mediaIcon.name}
                size="md80"
              />
            )}
            {(data.media.type === 'illustration' ||
              data.media.type === 'photo' ||
              data.media.type === 'component')
            && (
              <img className={classNames("cbdsws-c-card__mediaImg", `cbdsws-c-card__mediaImg--${data.media.type}`)} src={data.media.srcUrl} alt="" />
            )}
          </figure>
        )}

        <div className="cbdsws-c-card__content">
          {!!data.heading && (
            <div className="cbdsws-c-card__title">
              {!!data.eyebrow && (
                <span className="cbdsws-c-card__eyebrow">{data.eyebrow}</span>
              )}
              {!!data.heading && (
                <h3 className="cbdsws-c-card__heading">{data.heading}</h3>
              )}
              {!!data.subHeading && (
                <h4 className="cbdsws-c-card__subHeading">{data.subHeading}</h4>
              )}
              {!!data.meta && (
                <div className="cbdsws-c-card__meta">{data.meta}</div>
              )}
            </div>
          )}

          {!!data.description && (
            <div className="cbdsws-c-card__body">
              <p className="cbdsws-c-card__desc">{data.description}</p>
            </div>
          )}

          {(data.url && !data.isDisabled) && (
            <div className="cbdsws-c-card__continue">
              <CbdsIcon classes="cbdsws-c-card__continueIcon" iconName={continueIconName} size='16' library='ui' a11yHidden={true} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

CardContent.propTypes = {
  urlType: PropTypes.oneOf(['email', 'external']),
  media: PropTypes.shape({
    type: PropTypes.oneOf([null, 'icon', 'illustration', 'photo', 'component']),
    srcUrl: PropTypes.string
  }),
  analytics: PropTypes.shape({
    reason: PropTypes.string
  }),
  size: PropTypes.oneOf(['small']),
  variant: PropTypes.oneOf(['list']),
  mediaIcon: PropTypes.shape({
    name: PropTypes.string
  })
};

CardContent.defaultProps = {
  a11yLabel: null,
  analytics: {},
  heading: 'Card heading',
  media: {
    type: null,
    srcUrl: ""
  },
  mediaIcon: {
    name: ""
  }
};

export default CardContent;
