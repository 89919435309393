import CbdsComponents from './CbdsComponents.mdx';

const CbdsComponentsPage = {
  mdx: CbdsComponents,
  breadcrumbs: "About/Changelogs",
  alias: "about/changelogs/cbds-components",
  pathExact: false,
  title: "CBDS Components Changelog",
  menuLabel: 'CBDS Components',
  published: true,
  order: 2,
  date: '2024-01-24', // Show when last updated
  summary: "",

  // SEO and Search
  tags: [
    'CBDS',
    'About',
    'CBDS Components',
    'Changelog',
    'Release Notes'
  ],
  meta: {
    description: '',
    title: 'CBDS Components - Changelogs - About',
    keywords: [],
  },
}; 
export default CbdsComponentsPage;
