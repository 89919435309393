import React from "react";
import { useState, useEffect } from "react";
import { CbdsIcon } from "@cbds/cbds-components-react";

const JumpToTop = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const container = document.getElementById("cbdsws-contentContainer");

    container.addEventListener("scroll", () => {
      const scrolled = container.scrollTop;
      if (scrolled > 300) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    });
  }, []);

  const jumpToTop = () => {
    const target = document.getElementById("cbdsws-l-mainContainer");

    document.getElementById("cbdsws-contentContainer").scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    window.setTimeout(target.focus(), 1000);
  };

  return (
    <>
      {visible && (
        <button
          id="cbdsws-c-buttonTop"
          className="cbdsws-c-buttonTop"
          aria-label="scroll to top of page"
          data-click-track="false"
          onClick={jumpToTop}
        >
          <CbdsIcon iconName="chevron-up" a11yHidden={true} />
        </button>
      )}
    </>
  );
};
export default JumpToTop;
