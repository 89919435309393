import React from 'react';
import PropType from 'prop-types';
import classNames from 'classnames';
import { CbdsLink } from '@cbds/cbds-components-react';
import { Link } from 'react-router-dom';

const _Link = ({
  classes,
  a11yLabel,
  variant,
  isExternal,
  url,
  internalUrl,
  overrideStyles,
  children
}) => (
  <>
    {url && (
      <CbdsLink
        classes={overrideStyles ? classes : classNames(
          'cbdsws-c-markdown__a',
          `cbdsws-link__size--${variant}`,
          classes,
        )}
        url={url}
        isExternal={isExternal}
        label={children}
        a11yLabel={a11yLabel}
        analyticsType="link"
        analyticsReason={`__link: ${children} - ${url}`}
      />
    )}
    {internalUrl && (
      <Link
        className={overrideStyles ? classNames(classes, 'cbensightenevent') : classNames(
          `cbds-c-link cbdsws-c-markdown__a cbensightenevent`,
          `cbdsws-link__size--${variant}`,
          classes,
        )}
        cbdata-type="link"
        cbdata-reason={`__link: ${children} - ${internalUrl}`}
        to={internalUrl}
        aria-label={a11yLabel}
      >
        {children}
      </Link>
    )}
  </>
);

_Link.defaultProps = {
  a11yLabel: undefined,
  variant: 'body',
  overrideStyles: false,
  isExternal: true,
};

_Link.propTypes = {
  classes: PropType.string,
  a11yLabel: PropType.string,
  variant: PropType.oneOf(['body', 'heading', 'lead']),
  isExternal: PropType.bool,
  url: PropType.string,
  internalUrl: PropType.string,
  overrideStyles: PropType.bool,
};

export default _Link;
