// import React from "react";
import Modal from "./Modal.mdx";
import Guidelines from "./Guidelines.mdx";
// import Accessibility from "./Accessibility.mdx";
// import Code from "./Code.mdx";

const ModalPage = {
  mdx: Modal,
  breadcrumbs: "Components/Web Responsive Library",
  alias: "components/web-responsive-library/modal",
  pathExact: false,
  title: "Modal",
  menuLabel: "Modal",
  published: true,
  date: "2023-07-13", // Show when last updated
  summary: "",
  tabs: [
    {
      label: "Guidelines",
      mdx: Guidelines,
      summary:
        "The modal component allows the user to see additional or detailed information about a related subject on the page.  It can also inform the user of consequences of a destructive action.",
      alias: "/components/web-responsive-library/modal/guidelines",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Modal",
        "Design",
        "Guidelines",
        "HTML",
        "Angular",
      ],
      meta: {
        title: "Guidelines - Modal - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    /*{
      label: "Accessibility",
      mdx: Accessibility,
      summary: <></>,
      alias: "/components/web-responsive-library/modal/accessibility",
      // SEO and Search
      tags: ["Component", "CBDS", "Modal", "Accessibility", "HTML", "Angular"],
      meta: {
        title: "Accessibility - Modal - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    {
      label: "Code",
      mdx: Code,
      summary: <></>,
      alias: "/components/web-responsive-library/modal/code",
      // SEO and Search
      tags: ["Component", "CBDS", "Modal", "Code", "HTML", "Angular"],
      meta: {
        title: "Code - Modal - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },*/
  ],
  // SEO and Search
  tags: [],
  meta: {
    title: "Modal - Web Responsive Library - Components",
    description: "",
    keywords: [],
  },

  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "index.html?path=/story/components-modal",
    },
    react: {
      status: "released",
      version: "v1.0.0",
      link: "/index.html?path=/story/components-modal--default",
    },
    angular: {
      status: "released",
      version: "v1.0.0",
      link: "/index.html?path=/story/components-modal--default&args=isOpen:true",
    },
    figma: {
      status: "released",
      version: "",
      link: "https://www.figma.com/file/mj41OHGCokcJ9N7D0q8D9Q/CBDS-UI-Kit-V1.1?node-id=2394-11813&t=EtO1hs1cXNxMVxRj-0",
    },
  },
};

export default ModalPage;
