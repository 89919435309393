import React from "react";
import PropTypes from "prop-types";
// import classNames from "classnames";

const LayoutGridExamples = ({ example }) => {
  return (
    <>
      {/* How it Works (hiw)*/}
      {example === "1" && (
        <div class="cbds-l-grid {{wrapperCss}}">
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col--@sm">1 of 3 columns</div>
            <div class="cbds-l-grid__col--@sm">2 of 3 columns</div>
            <div class="cbds-l-grid__col--@sm">3 of 3 columns</div>
          </div>
        </div>
      )}

      {/* Container Base */}
      {example === "2" && (
        <div class="cbds-l-grid {{wrapperCss}}">
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col">Base Container</div>
          </div>
        </div>
      )}

      {/* Container Adaptive Width (Fixed) */}
      {example === "3" && (
        <div class="cbds-l-grid--fixed {{ wrapperCss }}">
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col">Container with adaptive width</div>
          </div>
        </div>
      )}

      {/*Container Breakpoint 2XS */}
      {example === "4" && (
        <div class="cbds-l-grid--@2xs {{ wrapperCss }}">
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col">
              Container with the 2xs (2 Extra Small) breakpoint
            </div>
          </div>
        </div>
      )}

      {/* Container Breakpoint SM */}
      {example === "5" && (
        <div class="cbds-l-grid--@sm {{ wrapperCss }}">
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col">
              Container with the sm (Small) breakpoint
            </div>
          </div>
        </div>
      )}

      {/* Container Breakpoint MD */}
      {example === "6" && (
        <div class="cbds-l-grid--@md {{ wrapperCss }}">
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col">
              Container with the md (Medium) breakpoint
            </div>
          </div>
        </div>
      )}

      {/* Container Breakpoint LG */}
      {example === "7" && (
        <div class="cbds-l-grid--@lg {{ wrapperCss }}">
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col">
              Container with the lg (Large) breakpoint
            </div>
          </div>
        </div>
      )}

      {/* Container Breakpoint XL */}
      {example === "8" && (
        <div class="cbds-l-grid--@xl {{ wrapperCss }}">
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col">
              Container with the xl (Extra Large) breakpoint
            </div>
          </div>
        </div>
      )}

      {/* Container Breakpoint 2XL */}
      {example === "9" && (
        <div class="cbds-l-grid--@2xl {{ wrapperCss }}">
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col">
              Container with the 2xl (2 Extra Large) breakpoint
            </div>
          </div>
        </div>
      )}

      {/* Container Breakpoint 3XL */}
      {example === "10" && (
        <div class="cbds-l-grid--@3xl {{ wrapperCss }}">
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col">
              Container with the 3xl (3 Extra Large) breakpoint
            </div>
          </div>
        </div>
      )}

      {/* Container Breakpoint 4XL */}
      {example === "11" && (
        <div class="cbds-l-grid--@4xl {{ wrapperCss }}">
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col">
              Container with the 4xl (4 Extra Large) breakpoint
            </div>
          </div>
        </div>
      )}

      {/* Vertical Alignment  */}
      {example === "12" && (
        <div class="cbds-l-grid {{wrapperCss}}">
          <div class="cbds-l-grid__row cbds-u-flexAlignItems__start">
            <div class="cbds-l-grid__col">1 of 3 columns</div>
            <div class="cbds-l-grid__col">1 of 3 columns</div>
            <div class="cbds-l-grid__col">1 of 3 columns</div>
          </div>
          <div class="cbds-l-grid__row cbds-u-flexAlignItems__center">
            <div class="cbds-l-grid__col">1 of 3 columns</div>
            <div class="cbds-l-grid__col">1 of 3 columns</div>
            <div class="cbds-l-grid__col">1 of 3 columns</div>
          </div>
          <div class="cbds-l-grid__row cbds-u-flexAlignItems__end">
            <div class="cbds-l-grid__col">1 of 3 columns</div>
            <div class="cbds-l-grid__col">1 of 3 columns</div>
            <div class="cbds-l-grid__col">1 of 3 columns</div>
          </div>
        </div>
      )}

      {/* Vertical Alignment Self */}
      {example === "13" && (
        <div class="cbds-l-grid {{ wrapperCss }}">
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col cbds-u-flexAlignSelf__start">
              1 of 3 columns
            </div>
            <div class="cbds-l-grid__col cbds-u-flexAlignSelf__center">
              1 of 3 columns
            </div>
            <div class="cbds-l-grid__col cbds-u-flexAlignSelf__end">
              1 of 3 columns
            </div>
          </div>
        </div>
      )}

      {/* All Breakpoints */}
      {example === "14" && (
        <div class="cbds-l-grid {{wrapperCss}}">
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col">.cbds-l-grid__col</div>
            <div class="cbds-l-grid__col">.cbds-l-grid__col</div>
            <div class="cbds-l-grid__col">.cbds-l-grid__col</div>
            <div class="cbds-l-grid__col">.cbds-l-grid__col</div>
          </div>
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col--8">.cbds-l-grid__col--8</div>
            <div class="cbds-l-grid__col--4">.cbds-l-grid__col--4</div>
          </div>
        </div>
      )}

      {/* Column Breaks  */}
      {example === "15" && (
        <div class="cbds-l-grid {{wrapperCss}}">
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col--6 cbds-l-grid__col--3@sm">
              .cbds-l-grid__col--6 .cbds-l-grid__col--3&#64;sm
            </div>
            <div class="cbds-l-grid__col--6 cbds-l-grid__col--3@sm">
              .cbds-l-grid__col--6 .cbds-l-grid__col--3&#64;sm
            </div>
            {/*<!--Force next columns to break to new line-->*/}
            <div class="cbds-u-w__100"></div>
            <div class="cbds-l-grid__col--6 cbds-l-grid__col--3@sm">
              .cbds-l-grid__col--6 .cbds-l-grid__col--3&#64;sm
            </div>
            <div class="cbds-l-grid__col--6 cbds-l-grid__col--3@sm">
              .cbds-l-grid__col--6 .cbds-l-grid__col--3&#64;sm
            </div>
          </div>
        </div>
      )}

      {/* Column Breaks Specific */}
      {example === "16" && (
        <div class="cbds-l-grid {{wrapperCss}}">
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col--6 cbds-l-grid__col--3@sm">
              .cbds-l-grid__col--6 .cbds-l-grid__col--3&#64;sm
            </div>
            <div class="cbds-l-grid__col--6 cbds-l-grid__col--3@sm">
              .cbds-l-grid__col--6 .cbds-l-grid__col--3&#64;sm
            </div>
            {/*<!--Force next columns to break to new line at md breakpoint and up-->*/}
            <div class="cbds-u-w__100 cbds-u-display__none cbds-u-display__block@md"></div>
            <div class="cbds-l-grid__col--6 cbds-l-grid__col--3@sm">
              .cbds-l-grid__col--6 .cbds-l-grid__col--3&#64;sm
            </div>
            <div class="cbds-l-grid__col--6 cbds-l-grid__col--3@sm">
              .cbds-l-grid__col--6 .cbds-l-grid__col--3&#64;sm
            </div>
          </div>
        </div>
      )}

      {/* Column Wrapping */}
      {example === "17" && (
        <div class="cbds-l-grid {{wrapperCss}}">
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col--9">.cbds-l-grid__col--9</div>
            <div class="cbds-l-grid__col--4">
              .cbds-l-grid__col--4
              <br />
              Since 9 + 4 = 13 &gt; 12, this 4-column-wide div gets wrapped onto
              a new line as one contiguous unit.
            </div>
            <div class="cbds-l-grid__col--6">
              .cbds-l-grid__col--6
              <br />
              Subsequent columns continue along the new line.
            </div>
          </div>
        </div>
      )}

      {/* Equal Width */}
      {example === "18" && (
        <div class="cbds-l-grid {{wrapperCss}}">
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col">1 of 2 columns (50%)</div>
            <div class="cbds-l-grid__col">2 of 2 columns (50%)</div>
          </div>
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col">1 of 3 columns (33.3%)</div>
            <div class="cbds-l-grid__col">2 of 3 columns (33.3%)</div>
            <div class="cbds-l-grid__col">3 of 3 columns (33.3%)</div>
          </div>
        </div>
      )}

      {/* Equal Width Multi-Line */}
      {example === "19" && (
        <div class="cbds-l-grid {{wrapperCss}}">
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col">1 of 2 columns (50%)</div>
            <div class="cbds-l-grid__col">2 of 2 columns (50%)</div>
            <div class="cbds-u-w__100"></div>
            <div class="cbds-l-grid__col">1 of 2 columns (50%)</div>
            <div class="cbds-l-grid__col">2 of 2 columns (50%)</div>
          </div>
        </div>
      )}

      {/* Equal Width Multi-Row */}
      {example === "20" && (
        <div class="cbds-l-grid {{wrapperCss}}">
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col">.cbds-l-grid__col</div>
            <div class="cbds-l-grid__col">.cbds-l-grid__col</div>
            <div class="cbds-u-w__100"></div>
            <div class="cbds-l-grid__col">.cbds-l-grid__col</div>
            <div class="cbds-l-grid__col">.cbds-l-grid__col</div>
          </div>
        </div>
      )}

      {/* Gutters */}
      {example === "21" && (
        <div class="{{ wrapperCss }}">
          <div class="cbds-l-grid cbds-u-paddingX__6x@lg">
            <div class="cbds-l-grid__row cbds-u-marginX__neg6x@lg">
              <div class="cbds-l-grid__col cbds-u-paddingY__6x cbds-u-paddingX__6x@lg">
                Custom column padding
              </div>
              <div class="cbds-l-grid__col cbds-u-paddingY__6x cbds-u-paddingX__6x@lg">
                Custom column padding
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Horizontal Alignment  */}
      {example === "22" && (
        <div class="cbds-l-grid {{wrapperCss}}">
          <div class="cbds-l-grid__row cbds-u-flexJustifyContent__start">
            <div class="cbds-l-grid__col--4">One of two columns</div>
            <div class="cbds-l-grid__col--4">One of two columns</div>
          </div>
          <div class="cbds-l-grid__row cbds-u-flexJustifyContent__center">
            <div class="cbds-l-grid__col--4">One of two columns</div>
            <div class="cbds-l-grid__col--4">One of two columns</div>
          </div>
          <div class="cbds-l-grid__row cbds-u-flexJustifyContent__end">
            <div class="cbds-l-grid__col--4">One of two columns</div>
            <div class="cbds-l-grid__col--4">One of two columns</div>
          </div>
          <div class="cbds-l-grid__row cbds-u-flexJustifyContent__around">
            <div class="cbds-l-grid__col--4">One of two columns</div>
            <div class="cbds-l-grid__col--4">One of two columns</div>
          </div>
          <div class="cbds-l-grid__row cbds-u-flexJustifyContent__between">
            <div class="cbds-l-grid__col--4">One of two columns</div>
            <div class="cbds-l-grid__col--4">One of two columns</div>
          </div>
        </div>
      )}

      {/* Margin Utilities */}
      {example === "23" && (
        <div class="cbds-l-grid {{wrapperCss}}">
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col--4@md">
              .cbds-l-grid__col--4&#64;md
            </div>
            <div class="cbds-l-grid__col--4@md cbds-u-marginLeft__auto">
              .cbds-l-grid__col--4&#64;md .cbds-u-marginLeft__auto
            </div>
          </div>
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col--3@md cbds-u-marginLeft__auto@md">
              .cbds-l-grid__col--3&#64;md .cbds-u-marginLeft__auto&#64;md
            </div>
            <div class="cbds-l-grid__col--3@md cbds-u-marginLeft__auto@md">
              .cbds-l-grid__col--3&#64;md .cbds-u-marginLeft__auto&#64;md
            </div>
          </div>
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col--auto cbds-u-marginRight__auto">
              .cbds-l-grid__col--auto .cbds-u-marginRight__auto
            </div>
            <div class="cbds-l-grid__col--auto">.cbds-l-grid__col--auto</div>
          </div>
        </div>
      )}

      {/* Mix and Match */}
      {example === "24" && (
        <div class="cbds-l-grid {{wrapperCss}}">
          {/*<!-- Stack the columns on mobile by making one full-width and the other half-width -->*/}
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col--12 cbds-l-grid__col--8@md">
              .cbds-l-grid__col--12 .cbds-l-grid__col--8&#64;md
            </div>
            <div class="cbds-l-grid__col--6 cbds-l-grid__col--4@md">
              .cbds-l-grid__col--6 .cbds-l-grid__col--4&#64;md
            </div>
          </div>
          {/*<!-- Columns start at 50% wide on mobile and bump up to 33.3% wide on desktop -->*/}
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col--6 cbds-l-grid__col--4@md">
              .cbds-l-grid__col--6 .cbds-l-grid__col--4&#64;md
            </div>
            <div class="cbds-l-grid__col--6 cbds-l-grid__col--4@md">
              .cbds-l-grid__col--6 .cbds-l-grid__col--4&#64;md
            </div>
            <div class="cbds-l-grid__col--6 cbds-l-grid__col--4@md">
              .cbds-l-grid__col--6 .cbds-l-grid__col--4&#64;md
            </div>
          </div>
          {/*<!-- Columns are always 50% wide, on mobile and desktop -->*/}
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col--6">.cbds-l-grid__col--6</div>
            <div class="cbds-l-grid__col--6">.cbds-l-grid__col--6</div>
          </div>
        </div>
      )}

      {/* Nesting */}
      {example === "25" && (
        <div class="cbds-l-grid {{wrapperCss}}">
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col--9@sm">
              Level 1: .cbds-l-grid__col--9&#64;sm
              <div class="cbds-l-grid__row">
                <div class="cbds-l-grid__col--8 cbds-l-grid__col--6@sm">
                  Level 2: .cbds-l-grid__col--8 .cbds-l-grid__col--6&#64;sm
                </div>
                <div class="cbds-l-grid__col--4 cbds-l-grid__col--6@sm">
                  Level 2: .cbds-l-grid__col--4 .cbds-l-grid__col--6&#64;sm
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* No Gutters */}
      {example === "26" && (
        <div class="{{wrapperCss}}">
          <div class="cbds-l-grid__row cbds-l-grid__row--noGutters">
            <div class="cbds-l-grid__col--6@sm cbds-l-grid__col--8@md">
              .cbds-l-grid__col--6&#64;sm .cbds-l-grid__col--8&#64;md
            </div>
            <div class="cbds-l-grid__col--6 cbds-l-grid__col--4@md">
              .cbds-l-grid__col--6 .cbds-l-grid__col--4&#64;md
            </div>
          </div>
        </div>
      )}

      {/* Offset Classes */}
      {example === "27" && (
        <div class="cbds-l-grid {{wrapperCss}}">
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col--4@md">.col-md-4</div>
            <div class="cbds-l-grid__col--4@md cbds-l-grid__col--offset4@md">
              .cbds-l-grid__col--4&#64;md .cbds-l-grid__col--offset4&#64;md
            </div>
          </div>
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col--3@md cbds-l-grid__col--offset3@md">
              .cbds-l-grid__col--3&#64;md .cbds-l-grid__col--offset3&#64;md
            </div>
            <div class="cbds-l-grid__col--3@md cbds-l-grid__col--offset3@md">
              .cbds-l-grid__col--3&#64;md .cbds-l-grid__col--offset3&#64;md
            </div>
          </div>
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col--6@md cbds-l-grid__col--offset3@md">
              .cbds-l-grid__col--6&#64;md .cbds-l-grid__col--offset3&#64;md
            </div>
          </div>
        </div>
      )}

      {/* Offset Classes Reset */}
      {example === "28" && (
        <div class="cbds-l-grid {{wrapperCss}}">
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col--5@sm cbds-l-grid__col--6@md">
              .cbds-l-grid__col--5&#64;sm .cbds-l-grid__col--6&#64;md
            </div>
            <div class="cbds-l-grid__col--5@sm cbds-l-grid__col--offset2@sm cbds-l-grid__col--6@md cbds-l-grid__col--offset0@md">
              .cbds-l-grid__col--5&#64;sm .cbds-l-grid__col--offset2&#64;sm
              .cbds-l-grid__col--6&#64;md .cbds-l-grid__col--offset0&#64;md
            </div>
          </div>
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col--6@sm cbds-l-grid__col--5@md cbds-l-grid__col--6@lg">
              .cbds-l-grid__col--6&#64;sm .cbds-l-grid__col--5&#64;md
              .cbds-l-grid__col--6&#64;lg
            </div>
            <div class="cbds-l-grid__col--6@sm cbds-l-grid__col--5@md cbds-l-grid__col--offset2@md cbds-l-grid__col--6@lg cbds-l-grid__col--offset0@lg">
              .cbds-l-grid__col--6&#64;sm .cbds-l-grid__col--5&#64;md
              .cbds-l-grid__col--offset2&#64;md .cbds-l-grid__col--6&#64;lg
              .cbds-l-grid__col--offset0&#64;lg
            </div>
          </div>
        </div>
      )}

      {/* Order Classes Reset */}
      {example === "29" && (
        <div class="cbds-l-grid {{wrapperCss}}">
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col">First in DOM, no order applied</div>
            <div class="cbds-l-grid__col cbds-l-grid__col--order12">
              Second in DOM, with a larger order
            </div>
            <div class="cbds-l-grid__col cbds-l-grid__col--order1">
              Third in DOM, with an order of 1
            </div>
          </div>
        </div>
      )}

      {/* Order Classes First Last */}
      {example === "30" && (
        <div class="cbds-l-grid {{wrapperCss}}">
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col cbds-l-grid__col--orderLast">
              First in DOM, ordered last
            </div>
            <div class="cbds-l-grid__col">Second in DOM, unordered</div>
            <div class="cbds-l-grid__col cbds-l-grid__col--orderFirst">
              Third in DOM, ordered first
            </div>
          </div>
        </div>
      )}

      {/* Row Columns A */}
      {example === "31" && (
        <div class="cbds-l-grid {{wrapperCss}}">
          <div class="cbds-l-grid__row cbds-l-grid__row-cols--2">
            <div class="cbds-l-grid__col">Column</div>
            <div class="cbds-l-grid__col">Column</div>
            <div class="cbds-l-grid__col">Column</div>
            <div class="cbds-l-grid__col">Column</div>
          </div>
        </div>
      )}

      {/* Row Columns B */}
      {example === "32" && (
        <div class="cbds-l-grid {{wrapperCss}}">
          <div class="cbds-l-grid__row cbds-l-grid__row-cols--3">
            <div class="cbds-l-grid__col">Column</div>
            <div class="cbds-l-grid__col">Column</div>
            <div class="cbds-l-grid__col">Column</div>
            <div class="cbds-l-grid__col">Column</div>
          </div>
        </div>
      )}

      {/* Row Columns C */}
      {example === "33" && (
        <div class="cbds-l-grid {{wrapperCss}}">
          <div class="cbds-l-grid__row cbds-l-grid__row-cols--4">
            <div class="cbds-l-grid__col">Column</div>
            <div class="cbds-l-grid__col">Column</div>
            <div class="cbds-l-grid__col">Column</div>
            <div class="cbds-l-grid__col">Column</div>
          </div>
        </div>
      )}

      {/* Row Columns D */}
      {example === "34" && (
        <div class="cbds-l-grid {{wrapperCss}}">
          <div class="cbds-l-grid__row cbds-l-grid__row-cols--4">
            <div class="cbds-l-grid__col">Column</div>
            <div class="cbds-l-grid__col">Column</div>
            <div class="cbds-l-grid__col cbds-l-grid__col--6">Column</div>
            <div class="cbds-l-grid__col">Column</div>
          </div>
        </div>
      )}

      {/* Row Columns E */}
      {example === "35" && (
        <div class="cbds-l-grid {{wrapperCss}}">
          <div class="cbds-l-grid__row cbds-l-grid__rowCols--1 cbds-l-grid__rowCols--2@sm cbds-l-grid__rowCols--4@md">
            <div class="cbds-l-grid__col">Column</div>
            <div class="cbds-l-grid__col">Column</div>
            <div class="cbds-l-grid__col">Column</div>
            <div class="cbds-l-grid__col">Column</div>
          </div>
        </div>
      )}

      {/* Setting One Col Width */}
      {example === "36" && (
        <div class="cbds-l-grid {{wrapperCss}}">
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col">1 of 3</div>
            <div class="cbds-l-grid__col--6">2 of 3 (wider)</div>
            <div class="cbds-l-grid__col">3 of 3</div>
          </div>
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col">1 of 3</div>
            <div class="cbds-l-grid__col--5">2 of 3 (wider)</div>
            <div class="cbds-l-grid__col">3 of 3</div>
          </div>
        </div>
      )}

      {/* Stacked To Horizontal */}
      {example === "37" && (
        <div class="cbds-l-grid {{wrapperCss}}">
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col--8@sm">
              .cbds-l-grid__col--8&#64;sm
            </div>
            <div class="cbds-l-grid__col--4@sm">
              .cbds-l-grid__col--4&#64;sm
            </div>
          </div>
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col--@sm">.cbds-l-grid__col--&#64;sm</div>
            <div class="cbds-l-grid__col--@sm">.cbds-l-grid__col--&#64;sm</div>
            <div class="cbds-l-grid__col--@sm">.cbds-l-grid__col--&#64;sm</div>
          </div>
        </div>
      )}

      {/* Variable Width  */}
      {example === "38" && (
        <div class="cbds-l-grid {{wrapperCss}}">
          <div class="cbds-l-grid__row cbds-u-flexJustifyContent__center@md">
            <div class="cbds-l-grid__col cbds-l-grid__col--2@lg">1 of 3</div>
            <div class="cbds-l-grid__col--auto@md">Variable width content</div>
            <div class="cbds-l-grid__col cbds-l-grid__col--2@lg">3 of 3</div>
          </div>
          <div class="cbds-l-grid__row">
            <div class="cbds-l-grid__col">1 of 3</div>
            <div class="cbds-l-grid__col--auto@md">Variable width content</div>
            <div class="cbds-l-grid__col cbds-l-grid__col--2@lg">3 of 3</div>
          </div>
        </div>
      )}
    </>
  );
};

LayoutGridExamples.propTypes = {
  example: PropTypes.number,
};

export default LayoutGridExamples;
