import React from 'react';
import Visibility from './Visibility.mdx';

const VisibilityPage = {
  mdx: Visibility,
  breadcrumbs: "Foundations/Utilities",
  alias: "foundations/utilities/visibility",
  pathExact: false,
  title: "Visibility",
  menuLabel: 'Visibility',
  published: true,
  order: 8,
  date: '2023-02-07', // Show when last updated
  summary: <>Utility classes for controlling the visibility of an element visually and for assistive technology / screen reader users. These classes will not affect display values or the layout.</>,

  // SEO and Search
  tags: [
    'CBDS',
    'Foundations',
    'Visibility',
    'Utility',
    'Utility Classes System',
    'Visibility  Utility Classes for Developers',
    'screen reader users',
    'assistive technology',
    'Invisible',
  ],
  meta: {
    title: 'Visibility - Utilities - Foundations',
    description: '',
    keywords: [],
  },
};

export default VisibilityPage;
