import GreenPixelStudios from './GreenPixelStudios.mdx';

const GreenPixelStudiosPage = {
  mdx: GreenPixelStudios,
  breadcrumbs: "About",
  alias: "about/gps",
  pathExact: false,
  title: "Green Pixel Studios Capabilities",
  menuLabel: 'Green Pixel Studios (GPS)',
  published: true,
  order: 2,
  date: '2023-11-15', // Show when last updated
  summary: "Today, the Green Pixel Studios team encompasses multiple Experience disciplines supporting a wide range of capabilities that complement each other.",

  // SEO and Search
  tags: [
    'CBDS',
    'About',
    'Green Pixel Studios',
    'GPS'
  ],
  meta: {
    description: '',
    title: 'Green Pixel Studios (GPS) - About',
    keywords: [],
  },
};
export default GreenPixelStudiosPage;
