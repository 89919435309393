import React from 'react';
import Strategy from './Strategy.mdx';

const StrategyPage = {
  mdx: Strategy,
  breadcrumbs: "Brand Guidelines/Enterprise Guidelines",
  alias: "brand-guidelines/enterprise-guidelines/strategy",
  pathExact: false,
  title: "Strategy",
  menuLabel: 'Strategy',
  published: true,
  order: 4,
  date: '2023-01-27', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Enterprise',
    'Guidelines',
    'Strategy',
    'Made',
    'Ready',
    'Champions',
    'Journey',
  ],
  meta: {
    title: 'Strategy - Enterprise - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default StrategyPage;
