import Qa from "./Qa.mdx";

const QaPage = {
  mdx: Qa,
  breadcrumbs: "Accessibility/Testing",
  alias: "accessibility/testing/qa",
  pathExact: false,
  title: "QA",
  menuLabel: "QA",
  published: true,
  order: 4,
  date: "2024-09-17", // Show when last updated
  summary: "",


  // SEO and Search
  tags: [
    "Accessibility",
    "A11Y",
    "ADA",
    "Developers",
    "AMP",
    "ANDI",
    "Accessibility Management Platform",
    "NVDA",
    "Non Visual Desktop Access",
    "QA",
    "WCAG",
  ],
  meta: {
    title: "QA - Testing - Accessibility",
    description: "",
    keywords: [],
  },
};

export default QaPage;
