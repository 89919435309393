import React from 'react';
import CdsA11yPropsNewsletter from './CdsA11yPropsNewsletter.mdx';

const CdsA11yPropsNewsletterPage = {
  mdx: CdsA11yPropsNewsletter,
  breadcrumbs: "About/Newsletter",
  alias: "about/newsletters/cds-a11y-props-newsletter",
  pathExact: false,
  title: "Accessibility & Props in the CDS",
  menuLabel: 'Cds A11Y Props Newsletter',
  published: true,
  hideFromNav: true,
  //order: 3,
  date: '2024-05-15', // Show when last updated
  summary: <>Introducing our first newsletter in a new series of all things accessibility! Design Engineering invites you to learn a little more about accessible component implementation, what props are, how accessibility relates to props and what accessibility-related props are available in the Citizens Design System.</>,

  // SEO and Search
  tags: [
    'CDS',
    'About',
    'Newsletter',
    'Updates',
    'A11Y',
    'Properties',
    'Accessibilitys',
    'ARIA',
  ],
  meta: {
    description: '',
    title: 'Accessibility & Props in the CDS - Newsletters - About',
    keywords: [],
  },
};

export default CdsA11yPropsNewsletterPage;
