import Designers from "./Designers.mdx";

const DesignersPage = {
  mdx: Designers,
  breadcrumbs: "Accessibility/Guidelines",
  alias: "accessibility/guidelines/designers",
  pathExact: false,
  title: "Designers",
  menuLabel: "Designers",
  published: true,
  order: 2,
  date: "2024-09-12", // Show when last updated
  summary: "",


  // SEO and Search
  tags: [
    'Accessibility',
    'A11Y',
    'Designers',
    'Designing',
    'Guidelines',
  ],
  meta: {
    title: "Designers - Guidelines - Accessibility",
    description: "",
    keywords: [],
  },
};

export default DesignersPage;
