import React from 'react';
import ToneAndLanguage from './ToneAndLanguage.mdx';

const ToneAndLanguagePage = {
  mdx: ToneAndLanguage,
  breadcrumbs: "Brand Guidelines/Commercial Guidelines",
  alias: "brand-guidelines/commercial-guidelines/tone-and-language",
  pathExact: false,
  title: "Tone and Language",
  menuLabel: 'Tone and Language',
  published: true,
  order: 2,
  date: '2023-11-28', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Commercial',
    'Guidelines',
    'Commercial Guidelines',
    'Tone and Language',

  ],
  meta: {
    title: 'Tone and Language - Commercial - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default ToneAndLanguagePage;
