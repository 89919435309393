import CbdsThemeSalesforce from './CbdsThemeSalesforce.mdx';

const CbdsThemeSalesforcePage = {
  mdx: CbdsThemeSalesforce,
  breadcrumbs: "About/Changelogs",
  alias: "about/changelogs/cbds-theme-salesforce",
  pathExact: false,
  title: "CBDS Theme Salesforce Changelog",
  menuLabel: 'CBDS Theme Salesforce',
  published: true,
  order: 2,
  date: '2024-01-24', // Show when last updated
  summary: "",

  // SEO and Search
  tags: [
    'CBDS',
    'About',
    'CBDS Theme Salesforce',
    'Changelog',
    'Release Notes'
  ],
  meta: {
    description: '',
    title: 'CBDS Theme Salesforce - Changelogs - About',
    keywords: [],
  },
}; 
export default CbdsThemeSalesforcePage;
