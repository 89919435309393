// import React from 'react';
// import Link from '../../../components/Link';
import CodeAuthentication from './CodeAuthentication.mdx';

const CodeAuthenticationPage = {
  mdx: CodeAuthentication,
  breadcrumbs: "Patterns/Fields",
  alias: "patterns/fields/code-authentication",
  pathExact: false,
  title: "Code Authentication (2FA)",
  menuLabel: 'Code Authentication',
  published: true,
  order: 2,
  date: '2024-11-08', // Show when last updated
  summary: "",

  // SEO and Search
  tags: [
    "Form",
    "Code",
    "Field",
    "Patterns",
    "Component",
    "Input",
    "2FA",
    "MFA",
    "Two",
    "Factor",
    "Authentication",
    "Multi",
    "One",
    "Time",
    "Pad",
    "1TP",
    "OTP",
  ],
  meta: {
    description: '',
    title: 'Code Authentication (2FA) - Fields - Patterns',
    keywords: [],
  },
  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "",
    },
    react: {
      status: "na",
      version: "v1.0.0",
      link: "",
    },
    angular: {
      status: "released",
      version: "v1.0.0",
      link: "/?path=/story/patterns-forms-code-authentication--default",
    },
    figma: {
      status: "released",
      version: "",
      link: "https://www.figma.com/design/1mBwaeZVlOxKn2rc9hQH4N/Verify-Code?node-id=1-2&t=zuvpHzzBHvqsFheb-1",
    },
  },
};

export default CodeAuthenticationPage;
