//import React from 'react';
//import Link from '../../../components/Link';
import FdicPlacementGuidelines from './FdicPlacementGuidelines.mdx';

const FdicPlacementGuidelinesPage = {
  mdx: FdicPlacementGuidelines,
  breadcrumbs: "Patterns",
  alias: "patterns/fdic-placement-guidelines",
  pathExact: false,
  title: "FDIC Placement Guidelines",
  menuLabel: 'FDIC Placement',
  published: true,
  order: 2,
  date: '2025-01-29', // Show when last updated
  summary: "",

  // SEO and Search
  tags: [
    "Patterns",
    "FDIC",
    "Federal Deposit Insurance Corporation",
    "Signage",
    "Placement",
    "Guidelines",
  ],
  meta: {
    description: '',
    title: 'FDIC Placement Guidelines - Patterns',
    keywords: [],
  },
  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "",
    },
    react: {
      status: "released",
      version: "v1.0.0",
      link: "/index.html?path=/story/components-fdic--default",
    },
    angular: {
      status: "released",
      version: "v1.0.0",
      link: "/index.html?path=/story/patterns-fdic-compliance--default",
    },
    figma: {
      status: "released",
      version: "",
      link: "https://www.figma.com/design/mj41OHGCokcJ9N7D0q8D9Q/CDS-UI-Kit-V1.1?node-id=24418-26157&t=qIEgntsZtMsazqVA-4",
    },
  },
};

export default FdicPlacementGuidelinesPage;
