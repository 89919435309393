import React from "react";
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

import { CbdsTabs } from '@cbds/cbds-components-react';

const Header = ({ data }) => {
  const history = useHistory();
  const location = useLocation();
  const locationPathname = location.pathname;
  const tabList = data.tabs ? data.tabs.map(tab => {
    return {
      id: tab.alias,
      label: tab.label,
      analyticsType: 'link',
      analyticsReason: `__inPageNav: ${data.title} - ${tab.label}`,
    };
  }) : null;
  let activeTab = data.tabs ? data.tabs.map((t, i) => {
    return {
      index: i + 1,
      alias: t.alias,
    };
  }).filter(t => t.alias === locationPathname) : null;

  // If tab exist
  if (activeTab !== null) {
    if (activeTab.length !== 0) {
      activeTab = activeTab[0].index;
    } else {
      activeTab = null;
    }
  }

  return (
    <>
      <div className="cbdsws-c-header">
        {data.breadcrumbs && (
          <p className="cbdsws-c-header__breadcrumbs">{data.breadcrumbs}</p>
        )}
        <h1 className="cbdsws-c-header__title">{data.title}</h1>
        {data.summary && (
          <p className="cbdsws-c-lead">{data.summary}</p>
        )}
        {data.releases && (
          <div className="cbdsws-c-header__releases">
            <p className="cbdsws-c-header__releasesLabel">Releases:</p>
            {data.releases.html.status === 'released' && (
              <div className="cbdsws-c-header__releasesLink">
                <a className="cbds-c-link cbensightenevent" cbdata-type="link" cbdata-reason={`__componentReleaseLink: ${data.title} HTML`} href={`/components-html/${data.releases.html.link}`} target="_blank">
                  <img
                    className="cbdsws-c-header__releasesLink__iconHTML"
                    src="/assets/images/icons/HTMLIcon.svg"
                    alt=""
                  />
                  HTML
                </a>
              </div>
            )}
            {data.releases.angular.status === 'released' && (
              <div className="cbdsws-c-header__releasesLink">
                <a className="cbds-c-link cbensightenevent" cbdata-type="link" cbdata-reason={`__componentReleaseLink: ${data.title} Angular`} href={`/components-angular/${data.releases.angular.link}`} target="_blank">
                  <img
                    className="cbdsws-c-header__releasesLink__icon"
                    src="/assets/images/icons/AngularIcon.svg"
                    alt=""
                  />
                  Angular
                </a>
              </div>
            )}
            {data.releases.react.status === 'released' && (
              <div className="cbdsws-c-header__releasesLink">
                <a className="cbds-c-link cbensightenevent" cbdata-type="link" cbdata-reason={`__componentReleaseLink: ${data.title} React`} href={`/components-react/${data.releases.react.link}`} target="_blank">
                  <img
                    className="cbdsws-c-header__releasesLink__icon"
                    src="/assets/images/icons/ReactIcon.svg"
                    alt=""
                  />
                  React
                </a>
              </div>
            )}
            {data.releases.figma.status === 'released' && (
              <div className="cbdsws-c-header__releasesLink">
                <a className="cbds-c-link cbensightenevent" cbdata-type="link" cbdata-reason={`__componentReleaseLink: ${data.title} Figma`} href={data.releases.figma.link} target="_blank">
                  <img
                    className="cbdsws-c-header__releasesLink__iconFigma"
                    src="/assets/images/icons/FigmaIcon.svg"
                    alt=""
                  />
                  Figma
                </a>
              </div>
            )}
          </div>
        )}
      </div>
      {data.tabs && activeTab && (
        <nav aria-label="Page Tabs">
          <div role="application">
            <CbdsTabs
              classes="cbdsws-c-headerTabs"
              eventOnClick={tab => history.push(tab.id)}
              activeTab={activeTab}
              tabList={tabList}
            />
          </div>
        </nav>
      )}
    </>
  );
};

Header.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    breadcrumbs: PropTypes.string,
    summary: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    releases: PropTypes.object,
  }),
};

Header.defaultProps = {
  data: {
    title: "Page Title",
  }
};

export default Header;
