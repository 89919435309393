import React, { Fragment, useState } from 'react';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';

import { CbdsButton } from '@cbds/cbds-components-react';

const createMenuLabel = label => {
  let menuLabel = label.replace(/([0-9]+_)/g, ''); // Remove index
  menuLabel = (menuLabel.charAt(0).toUpperCase() + menuLabel.slice(1)); // Upper case first letter
  menuLabel = menuLabel.replace(/((?<=[a-z])[A-Z]|[A-Z](?=[a-z]))/g, " $1"); // Space word by cap letters
  menuLabel = menuLabel.replace(' ', ''); // Remove first space
  return menuLabel;
};

const NavigationButton = ({ label, url, isParent = false, expanded = false, onToggleExpand = null, tabs = null, toggleMenu }) => {

  const location = useLocation();
  const alias = location.pathname;
  let isActive = url ? url === alias : false;
  isActive = tabs ? tabs.map(t => t.alias).includes(alias) : isActive;
  const parentButtonClasses = classNames("cbdsws-c-navigation__button", { 'cbdsws-c-navigation__button--expanded' : expanded });
  const childButtonClasses = classNames("cbdsws-c-navigation__button", { 'cbdsws-c-navigation__button--active' : isActive }, "cbds-c-button", "cbds-c-button--ghost", "cbds-c-button--fullWidth", "cbensightenevent");

  return (
    <>
      {isParent && (
        <CbdsButton
          classes={parentButtonClasses}
          variant="ghost"
          iconNameRight={expanded ? "chevron-down" : "chevron-right"}
          label={label}
          onClick={onToggleExpand}
          isFullWidth
          a11yExpanded={!expanded ? "false" : "true"}
        />
      )}
      {!isParent && (
        <Link
          className={childButtonClasses}
          to={tabs ? tabs[0].alias : url}
          aria-current={isActive ? "page" : null}
          cbdata-type="link"
          cbdata-reason={`__inPageNav: ${label}`}
          onClick={toggleMenu ? () => toggleMenu(false, 'click') : null}
        >
          {label}
        </Link>
      )}
    </>
  );
};

const SubNavigation = ({ label, data, toggleMenu }) => {
  const [ expanded, setExpanded ] = useState(false);

  const onToggleExpand = () => {
    setExpanded(!expanded);
  };

  if (label === 'Newsletters') return null;
  if (label === 'Changelogs') return null;

  return (
    <>
      <NavigationButton
        label={label}
        isParent
        expanded={expanded}
        onToggleExpand={onToggleExpand}
      />
      <ul className={classNames("cbdsws-c-navigation__list", { 'cbdsws-c-navigation__list--show': expanded })}>
        {data.map((c, index) => (
          <Fragment key={`navigation_${index}`}>
            {!!c.component && !c.component.hideFromNav && (
              <li className="cbdsws-c-navigation__listItem">
                <NavigationButton
                  label={c.component.menuLabel ? c.component.menuLabel : c.component.title}
                  url={`/${c.component.alias}`}
                  tabs={c.component.tabs || null}
                  toggleMenu={toggleMenu}
                />
              </li>
            )}
            {!!c.children && (
              <li className="cbdsws-c-navigation__listItem">
                <SubNavigation
                  label={createMenuLabel(c.parent)}
                  data={c.children}
                  toggleMenu={toggleMenu}
                />
              </li>
            )}
          </Fragment>
        ))}
      </ul>
    </>
  );
};

const ListItem = ({ pages, toggleMenu }) => {
  let List = [];
  const TopDivider = ['15_brandGuidelines', '20_components'];
  const BottomDivider = ['35_patterns'];
  for (var key in pages) {
    const label = createMenuLabel(key);

    if (key !== 'root') {
      List.push(
        <li key={`navigation-${key}`}
          className="cbdsws-c-navigation__listItem"
        >
          {TopDivider.includes(key) && (
            <div className="cbdsws-c-navigation__divider"></div>
          )}
          {key !== 'root' && (
            <SubNavigation
              label={label}
              data={pages[key]}
              toggleMenu={toggleMenu}
            />
          )}
          {BottomDivider.includes(key) && (
            <div className="cbdsws-c-navigation__divider"></div>
          )}
        </li>
      );
    }
  }

  // For root, append to bottom of navigation
  for (var key2 in pages) {
    if (key2 === 'root') {
      pages[key2].forEach(data => {
        if (!data.component.hideFromNav) {
          List.push(
            <li
              key={`navigation-${key2}`}
              className="cbdsws-c-navigation__listItem"
            >
              <NavigationButton
                label={data.component.menuLabel}
                url={`/${data.component.alias}`}
                tabs={data.component.tabs || null}
              />
            </li>
          );
        }
      });
    }
  }

  return List;
};

const Navigation = ({ pages, toggleMenu }) => {
  return (
    <ul className="cbdsws-c-navigation">
      <ListItem pages={pages} toggleMenu={toggleMenu} />
    </ul>
  );
};

export default Navigation;
