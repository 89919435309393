import React from 'react';
import Iconography from './Iconography.mdx';

const IconographyPage = {
  mdx: Iconography,
  breadcrumbs: "Brand Guidelines/Consumer Guidelines",
  alias: "brand-guidelines/consumer-guidelines/iconography",
  pathExact: false,
  title: "Iconography",
  menuLabel: 'Iconography',
  published: true,
  order: 13,
  date: '2023-02-01', // Show when last updated
  summary: <>Please always use the Citizens Icon Library rather than stock art or system generated icons.</>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Consumer',
    'Guidelines',
    'Iconography',
    'Citizens Icon Library',
    'Icons',
  ],
  meta: {
    title: 'Iconography - Consumer - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default IconographyPage;
