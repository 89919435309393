// import React from "react";
import Overlay from "./Overlay.mdx";
import Guidelines from "./Guidelines.mdx";
// import Accessibility from "./Accessibility.mdx";
// import Code from "./Code.mdx";

const OverlayPage = {
  mdx: Overlay,
  breadcrumbs: "Components/Web Responsive Library",
  alias: "components/web-responsive-library/overlay",
  pathExact: false,
  title: "Overlay",
  menuLabel: "Overlay",
  published: true,
  date: "2023-04-13", // Show when last updated
  summary: "",
  tabs: [
    {
      label: "Guidelines",
      mdx: Guidelines,
      summary: "The Overlay component is used to provide emphasis on a particular elements or parts of them.",
      alias: "/components/web-responsive-library/overlay/guidelines",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Overlay",
        "Design",
        "Guidelines",
        "HTML",
        "React",
        "Angular",
      ],
      meta: {
        title: "Guidelines - Overlay - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    /*{
      label: "Accessibility",
      mdx: Accessibility,
      summary: <></>,
      alias: "/components/web-responsive-library/overlay/accessibility",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Overlay",
        "Accessibility",
        "HTML",
        "React",
        "Angular",
      ],
      meta: {
        title: "Accessibility - Overlay - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    {
      label: "Code",
      mdx: Code,
      summary: <></>,
      alias: "/components/web-responsive-library/overlay/code",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Overlay",
        "Code",
        "HTML",
        "React",
        "Angular",
      ],
      meta: {
        title: "Code - Overlay - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },*/
  ],
  // SEO and Search
  tags: [],
  meta: {
    title: "Overlay - Web Responsive Library - Components",
    description: "",
    keywords: [],
  },

  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "index.html?path=/story/components-Overlay",
    },
    react: {
      status: "na",
      version: "v1.0.0",
      link: "index.html?path=/story/components-Overlay--default",
    },
    angular: {
      status: "na",
      version: "v1.0.0",
      link: "index.html?path=/story/components-Overlay",
    },
    figma: {
      status: "released",
      version: "",
      link: "https://www.figma.com/file/mj41OHGCokcJ9N7D0q8D9Q/CBDS-UI-Kit-V1.1?node-id=2159%3A7071&t=BbsVUyRwx8GP3fqN-1",
    },
  },
};

export default OverlayPage;
