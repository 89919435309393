import React from 'react';
import LayoutSystem from './LayoutSystem.mdx';

const LayoutSystemPage = {
  mdx: LayoutSystem,
  breadcrumbs: "Brand Guidelines/Commercial Guidelines",
  alias: "brand-guidelines/commercial-guidelines/layout-system",
  pathExact: false,
  title: "Layout System",
  menuLabel: 'Layout System',
  published: true,
  order: 5,
  date: '2023-11-28', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Commercial',
    'Guidelines',
    'Commercial Guidelines',
    'Layout System'
  ],
  meta: {
    title: 'Layout System - Commercial - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default LayoutSystemPage;
