// import React from "react";
import Breadcrumbs from "./Breadcrumbs.mdx";
import Guidelines from "./Guidelines.mdx";
// import Accessibility from "./Accessibility.mdx";
// import Code from "./Code.mdx";

const BreadcrumbsPage = {
  mdx: Breadcrumbs,
  breadcrumbs: "Components/Web Responsive Library",
  alias: "components/web-responsive-library/breadcrumbs",
  pathExact: false,
  title: "Breadcrumbs",
  menuLabel: "Breadcrumbs",
  published: true,
  date: "2023-06-29", // Show when last updated
  summary: "",
  tabs: [
    {
      label: "Guidelines",
      mdx: Guidelines,
      summary: "Breadcrumbs shows the users where they are, and how they got there.",
      alias: "/components/web-responsive-library/breadcrumbs/guidelines",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Breadcrumbs",
        "Design",
        "Guidelines",
      ],
      meta: {
        title: "Guidelines - Breadcrumbs - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    /*{
      label: "Accessibility",
      mdx: Accessibility,
      summary: <></>,
      alias: "/components/web-responsive-library/breadcrumbs/accessibility",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Breadcrumbs",
        "Accessibility",
      ],
      meta: {
        title: "Accessibility - Breadcrumbs - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    {
      label: "Code",
      mdx: Code,
      summary: <></>,
      alias: "/components/web-responsive-library/breadcrumbs/code",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Breadcrumbs",
        "Code",
      ],
      meta: {
        title: "Code - Breadcrumbs - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },*/
  ],
  // SEO and Search
  tags: [],
  meta: {
    title: "Breadcrumbs - Web Responsive Library - Components",
    description: "",
    keywords: [],
  },

  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "index.html?path=/story/components-Breadcrumbs",
    },
    react: {
      status: "na",
      version: "v1.0.0",
      link: "index.html?path=/story/components-Breadcrumbs--default",
    },
    angular: {
      status: "na",
      version: "v1.0.0",
      link: "index.html?path=/story/components-Breadcrumbs",
    },
    figma: {
      status: "released",
      version: "",
      link: "https://www.figma.com/file/mj41OHGCokcJ9N7D0q8D9Q/CBDS-UI-Kit-V1.1?node-id=1633%3A6407&t=c0P3NDyo4OqUOeuw-1",
    },
  },
};

export default BreadcrumbsPage;
