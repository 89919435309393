import React from 'react';
import VisualElement from './VisualElement.mdx';

const VisualElementPage = {
  mdx: VisualElement,
  breadcrumbs: "Brand Guidelines/Commercial Guidelines",
  alias: "brand-guidelines/commercial-guidelines/visual-element",
  pathExact: false,
  title: "Visual Element",
  menuLabel: 'Visual Element',
  published: true,
  order: 4,
  date: '2023-11-28', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Commercial',
    'Guidelines',
    'Commercial Guidelines',
    'Visual Element'
  ],
  meta: {
    title: 'Visual Element - Commercial - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default VisualElementPage;
