import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Figure from '../Figure';

const FigureGrid = ({
  figures,
  layout,
}) => {

  let layoutClass = layout ? `cbdsws-c-figureGrid__grid--${layout}` : "";

  return (
    <div className="cbdsws-c-figureGrid">
      <div className={classNames("cbdsws-c-figureGrid__grid", layoutClass)}>
        {figures.map(( figure, index) => (
          <div className="cbdsws-c-figureGrid__gridItem" key={`FigureContainer_${index}`} >
            <Figure
              imgSrc={figure.imgSrc}
              imgAltText={figure.imgAltText}
              caption={figure.caption}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

FigureGrid.propTypes = {
  figures: PropTypes.arrayOf(PropTypes.shape({
    imgAltText: PropTypes.string,
    caption: PropTypes.string,
    imgSrc: PropTypes.string,
  })),
  layout: PropTypes.oneOf(["col2", "col3", "col4"]),
};

FigureGrid.defaultProps = {
  layout: "col2",
  figures: [
    {
      imgAltText: "Rectangular placeholder image reads 720 by 360 pixels.",
      imgSrc: "/assets/images/placeholder__postCard--720x360.png",
    },
    {
      imgAltText: "Rectangular placeholder image reads 720 by 360 pixels.",
      imgSrc: "/assets/images/placeholder__postCard--720x360.png",
    }
  ],
};

export default FigureGrid;
