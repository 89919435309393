import React from "react";
import { useState, useEffect } from "react";
import { CbdsIcon } from "@cbds/cbds-components-react";
import Pages from '../../constants/pages';
import { useLocation, useHistory } from 'react-router-dom';

const Pagination = () => {
  const location = useLocation();
  let history = useHistory();

  const [isGuidelinesPage, setIsGuidelinesPage] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // Only show prev/next buttons on Brand Guidelines pages
    if (window.location.href.indexOf("brand-guidelines") > -1) {
      setIsGuidelinesPage(true);

      Pages['15_brandGuidelines']
        .flatMap(brandGuideline => brandGuideline.children)
        .forEach((child, index) => {
          if (`/${child.component.alias}` === location.pathname) setCurrentIndex(index);
        });
    } else {
      setIsGuidelinesPage(false);
    }
  }, [location.pathname]);

  // Create array of Brand Guidelines Aliases from data
  const brandGuidelinesAliasArr = Pages['15_brandGuidelines']
    .flatMap(brandGuideline => brandGuideline.children
      .map(child => child.component.alias));

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      history.push(`/${brandGuidelinesAliasArr[currentIndex - 1]}`);
    }
  };

  const handleNextClick = () => {
    if (currentIndex < brandGuidelinesAliasArr.length - 1) {
      history.push(`/${brandGuidelinesAliasArr[currentIndex + 1]}`);
    }
  };

  return (
    isGuidelinesPage && (
      <nav className="cbds-next-and-back-nav" aria-label="pagination">
        <button onClick={handlePrevClick}
          disabled={currentIndex <= 0}
          id="cbdsws-c-buttonPrev"
          className="cbdsws-c-buttonPrev"
          aria-label="go to previous page"
          data-click-track="true">
          <CbdsIcon iconName="chevron-left" a11yHidden={true} />
        </button>

        <button onClick={handleNextClick}
          disabled={currentIndex >= brandGuidelinesAliasArr.length - 1}
          id="cbdsws-c-buttonNext"
          className="cbdsws-c-buttonNext"
          aria-label="go to next page"
          data-click-track="true" >
          <CbdsIcon iconName="chevron-right" a11yHidden={true} />
        </button>
      </nav>
    )
  );
};
export default Pagination;
