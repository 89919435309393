import uxWritingGuidelines from './uxWritingGuidelines.mdx';

const uxWritingGuidelinesPage = {
  mdx: uxWritingGuidelines,
  breadcrumbs: "Foundations",
  alias: "foundations/ux-writing-guidelines",
  pathExact: false,
  title: "UX Writing Guidelines",
  menuLabel: 'UX Writing Guidelines',
  published: true,
  order: 2,
  date: '2023-01-19', // Show when last updated

  // SEO and Search
  tags: [
    'CBDS',
    'Foundations',
    'UX Writing Guidelines',
    'Design',
  ],
  meta: {
    title: 'UX Writing Guidelines - Foundations',
    description: '',
    keywords: [],
  },
};

export default uxWritingGuidelinesPage;
