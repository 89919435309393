import React from 'react';
import WhoIsThisGuideFor from './WhoIsThisGuideFor.mdx';

const WhoIsThisGuideForPage = {
  mdx: WhoIsThisGuideFor,
  breadcrumbs: "Brand Guidelines/Consumer Guidelines",
  alias: "brand-guidelines/consumer-guidelines/who-is-this-guide-for",
  pathExact: false,
  title: "Who is This Guide for",
  menuLabel: 'Who is This Guide for',
  published: true,
  order: 1,
  date: '2023-01-30', // Show when last updated
  summary: <>This guide is a primer for Citizens to share who we are, what we stand for and how we show up in the world.< br />While this guide acts as an introduction, more specific and detailed guidelines will follow that can be leveraged by creators and designers making the work.</>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Consumer',
    'Guidelines',
    'Strategy',
    'Tone of Voice',
    'Color Palette',
    'Illustration',
  ],
  meta: {
    title: 'Who is This Guide for - Consumer - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default WhoIsThisGuideForPage;
