import React from 'react';
import CdsApril2024Newsletter from './CdsApril2024Newsletter.mdx';

const CdsApril2024NewsletterPage = {
  mdx: CdsApril2024Newsletter,
  breadcrumbs: "About/Newsletter",
  alias: "about/newsletters/cds-april-2024-newsletter",
  pathExact: false,
  title: "CDS Newsletter April 2024",
  menuLabel: 'Cds April 2024 Newsletter',
  published: true,
  hideFromNav: true,
  //order: 3,
  date: '2024-05-10', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CDS',
    'About',
    'Newsletter',
    'Updates',
    'April',
    '2024',
    'Release Notes',
  ],
  meta: {
    description: '',
    title: 'CDS April 2024 Newsletter - Newsletters - About',
    keywords: [],
  },
};

export default CdsApril2024NewsletterPage;
