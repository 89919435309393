import React from "react";
import Link from '../../../components/Link';
import DesignTokens from "./DesignTokens.mdx";
import Overview from "./Overview.mdx";
import Scss from "./Scss.mdx";
import Ios from "./Ios.mdx";
import Android from "./Android.mdx";

const DesignTokensPage = {
  mdx: DesignTokens,
  breadcrumbs: "Foundations/Design Tokens",
  alias: "foundations/tokens",
  pathExact: false,
  title: "Design Tokens",
  menuLabel: "Design Tokens",
  published: true,
  order: 9,
  date: "2024-02-14", // Show when last updated
  summary: <></>,
  tabs: [
    {
      label: "Overview",
      mdx: Overview,
      summary: "A quick overview of CBDS Design Tokens.",
      alias: "/foundations/tokens/overview",
      // SEO and Search
      tags: [
        "CBDS",
        "Foundations",
        "Overview",
        "Tokens",
        "Design Tokens",
        "Design Tokens Overview for Developers",
      ],
      meta: {
        title: "Overview - Design Tokens - Foundations",
        description: "",
        keywords: [],
      },
    },
    {
      label: "SCSS",
      mdx: Scss,
      summary: (
        <>
          Design Tokens are available as SCSS variables for use on web-based
          platforms.
          <br />
          <br />
          <p className="cbdsws-c-lead cbdsws-c-lead--sm">
            In order to implement design tokens into your project, you must
            first install the CBDS Design Tokens NPM package. For instructions
            on how to do this, see our{" "}
            <Link variant="lead" internalUrl="/getting-started/for-developers/web/introduction">
              Getting Started for Developers (Web)
            </Link>{" "}
            guide.
          </p>
        </>
      ),
      alias: "/foundations/tokens/scss",
      // SEO and Search
      tags: [
        "CBDS",
        "Foundations",
        "Tokens",
        "Design Tokens",
        "SCSS",
        "SASS",
        "Variable",
        "NPM",
        "JSON",
        "",
      ],
      meta: {
        title: "SCSS - Design Tokens - Foundations",
        description: "",
        keywords: [],
      },
    },
    {
      label: "iOS",
      mdx: Ios,
      summary: (
        <>
          Design Tokens are available as iOS Swift enums for use on native iOS
          platforms.
          <br />
          <br />
          <p className="cbdsws-c-lead cbdsws-c-lead--sm">
            In order to implement design tokens into your project, you must
            first install the CBDS Design Tokens NPM package. For instructions
            on how to do this, see our{" "}
            <Link variant="lead" internalUrl="/getting-started/for-developers/ios/getting-started">
              Getting Started for Developers (iOS)
            </Link>{" "}
            .
          </p>
        </>
      ),
      alias: "/foundations/tokens/ios",
      // SEO and Search
      tags: [
        "CBDS",
        "Foundations",
        "Tokens",
        "Design Tokens",
        "Color",
        "Swift",
        "iOS",
        "CocoaPod",
        "ENUM",
        "Swift ENUM",
        "",
      ],
      meta: {
        title: "iOS - Design Tokens - Foundations",
        description: "",
        keywords: [],
      },
    },
    {
      label: "Android",
      mdx: Android,
      summary: (
        <>
          Design Tokens are available as enums for use in Android development.
          <br />
          <br />
          <p className="cbdsws-c-lead cbdsws-c-lead--sm">
            In order to implement design tokens into your project, you must
            first install the CBDS Design Tokens NPM package. For instructions
            on how to do this, see our{" "}
            <Link variant="lead" internalUrl="/getting-started/for-developers/android/get-started">
              Getting Started for Developers (Android)
            </Link>{" "}
            .
          </p>
        </>
      ),
      alias: "/foundations/tokens/android",
      // SEO and Search
      tags: [
        "CBDS",
        "Foundations",
        "Tokens",
        "Design Tokens",
        "Color",
        "Android",
        "Object",
        "ENUM",
        "",
      ],
      meta: {
        title: "Android - Design Tokens - Foundations",
        description: "",
        keywords: [],
      },
    },
  ],
  // SEO and Search
  tags: [],
  meta: {
    title: "Foundations - Design Tokens - Overview",
    description: "",
    keywords: [],
  },
};

export default DesignTokensPage;
