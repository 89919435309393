// import React from 'react';
// import Link from '../../../components/Link';
import UserId from './UserId.mdx';

const UserIdPage = {
  mdx: UserId,
  breadcrumbs: "Patterns/Fields",
  alias: "patterns/fields/user-id",
  pathExact: false,
  title: "User I.D.",
  menuLabel: 'User I.D.',
  published: true,
  order: 2,
  date: '2024-03-15', // Show when last updated
  summary: "",

  // SEO and Search
  tags: [
    "Form",
    "User ID",
    "Identification",
    "Field",
    "Patterns",
    "Fieldset",
    "Input",
  ],
  meta: {
    description: '',
    title: 'User I.D. - Fields - Patterns',
    keywords: [],
  },
  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "",
    },
    react: {
      status: "na",
      version: "v1.0.0",
      link: "",
    },
    angular: {
      status: "released",
      version: "v1.0.0",
      link: "/index.html?path=/story/patterns-forms-user-id--default",
    },
    figma: {
      status: "released",
      version: "",
      link: "https://www.figma.com/file/mj41OHGCokcJ9N7D0q8D9Q/CBDS-UI-Kit-V1.1?node-id=1633%3A6407&t=c0P3NDyo4OqUOeuw-1",
    },
  },
};

export default UserIdPage;
