import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Load able-player scripts when component mounts, and unload scripts when component unmounts.
const useExternalScripts = urls => {
  useEffect(() => {
    const head = document.querySelector("head");
    const body = document.querySelector("body");
    const css = document.createElement("link");
    const scripts = [];
    const scriptExist = document.getElementById('able-player-script-1');

    if (!scriptExist) {
      css.setAttribute("rel", "stylesheet");
      css.setAttribute("href", "/assets/able/build/ableplayer.min.css");
      head.appendChild(css);

      urls.forEach((url, i) => {
        const script = document.createElement("script");
        scripts.push(script);
        script.setAttribute("src", url);
        script.setAttribute("id", `able-player-script-${i}`);
        body.appendChild(script);
      });
    }

    return () => {
      head.removeChild(css);
      scripts.forEach(script => {
        body.removeChild(script);
      });
      const ableModalDialogs = document.querySelectorAll('.able-modal-dialog');
      ableModalDialogs.forEach(ele => {
        ele.remove();
      });
    };
  }, [urls]);
};

const AblePlayer = ({
  videoId,
  customHeadingLevel,
  preload,
  width,
  height,
  poster,
  webMSource,
  webMDescribedSource,
  mp4Source,
  mp4DescribedSource,
  closedCaptionsSource,
  audioDescriptionSource
}) => {
  useExternalScripts([
    "//ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js",
    "//cdn.jsdelivr.net/npm/js-cookie@3.0.1/dist/js.cookie.min.js",
    "/assets/able/build/ableplayer.min.js",
  ]);

  return (
    <div>
      <video
        className="cbdsws-c-able"
        id={videoId}
        data-able-player
        data-heading-level={customHeadingLevel || null}
        preload={preload}
        width={width}
        height={height}
        poster={poster}
      >
        {webMSource && (
          <source type="video/webm" src={webMSource} data-desc-src={webMDescribedSource || null} />
        )}

        {mp4Source && (
          <source type="video/mp4" src={mp4Source} data-desc-src={mp4DescribedSource || null} />
        )}

        {closedCaptionsSource && (
          <track kind="captions" src={closedCaptionsSource} />
        )}

        {audioDescriptionSource && (
          <track kind="descriptions" src={audioDescriptionSource} />
        )}
      </video>
    </div>
  );
};

AblePlayer.propTypes = {
  videoId: PropTypes.string,
  transcriptId: PropTypes.string,
  preload: PropTypes.string,
  customHeadingLevel: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  poster: PropTypes.string,
  webMSource: PropTypes.string,
  webMDescribedSource: PropTypes.string,
  mp4Source: PropTypes.string,
  mp4DescribedSource: PropTypes.string,
  closedCaptionsSource: PropTypes.string,
  audioDescriptionSource: PropTypes.string,
};

AblePlayer.defaultProps = {
  width: 1000,
  height: 720,
  videoId: "layoutGridPart1",
  mp4Source: "https://designsystem.citizensbank.com/videos/cbds-layoutGridTutorial__p1.mp4",
  preload: "auto",
};

export default AblePlayer;
