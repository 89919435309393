import React from 'react';
import PropTypes from 'prop-types';

import { CbdsButton, CbdsIcon } from '@cbds/cbds-components-react';
import ButtonInlineCode from '../ButtonInlineCode';


const IconCard = ({ iconName, size, color, library, a11yRole, a11yLabel }) => {

  return (
    <div className="cbdsws-c-iconCard">

      <div className="cbdsws-c-iconCard__media">
        <CbdsIcon iconName={iconName} size={size} color={color} library={library} a11yRole={a11yRole} a11yLabel={a11yLabel ? a11yLabel : iconName} />
      </div>

      <div className="cbdsws-c-iconCard__body">
        <div className="cbdsws-c-iconCard__copyBtn cbdsws-c-iconCard__longName">
          <ButtonInlineCode
            codeSnippet={iconName}
            type={library === "ui" ? "icon" : "iconBrand"} />

          <br />

          <CbdsButton
            as="a"
            size="sm"
            variant="ghost"
            label="Download"
            a11yLabel={iconName + "(Download)"}
            url={`assets/icons/${library === "ui" ? "svg-ui" : "svg-brand"}/${iconName}/${iconName}.svg`}
            download={`${iconName}.svg`} />
        </div>
      </div>
    </div>
  );
};

IconCard.propTypes = {
  iconName: PropTypes.string.isRequired,
  size: PropTypes.oneOf([
    "sm",
    "md",
    "12",
    "16",
    "20",
    "24",
    "28",
    "60",
    "80",
    "120",
  ]),
  color: PropTypes.oneOf(["primary", "light", "dark"]),
  library: PropTypes.oneOf(["ui", "brand"]),
  a11yRole: PropTypes.string,
  a11yLabel: PropTypes.string,
};

IconCard.defaultProps = {
  iconName: "close",
  size: null,
  color: null,
  library: "ui",
  a11yRole: "img",
};

export default IconCard;
