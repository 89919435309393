import ForDesigners from './ForDesigners.mdx';

const ForDesignersPage = {
  mdx: ForDesigners,
  breadcrumbs: "Getting Started",
  alias: "getting-started/for-designers",
  pathExact: false,
  title: "For Designers",
  menuLabel: 'For Designers',
  published: true,
  order: 1,
  date: '2023-11-20', // Show when last updated
  summary: "",

  // SEO and Search
  tags: [
    'CBDS',
    'CDS',
    'getting started',
    'For Designers',
    'help',
  ],
  meta: {
    title: 'For Designers - Getting Started',
    description: '',
    keywords: [],
  },
};

export default ForDesignersPage;
