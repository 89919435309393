import React from 'react';
import Contacts from './Contacts.mdx';

const ContactsPage = {
  mdx: Contacts,
  breadcrumbs: "Brand Guidelines/Wealth Management Guidelines",
  alias: "brand-guidelines/wealth-management-guidelines/contacts",
  pathExact: false,
  title: "Contacts",
  menuLabel: 'Contacts',
  published: true,
  order: 15,
  date: '2023-12-01', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Wealth Management',
    'Guidelines',
    'Contacts',
    'Greenhouse',
  ],
  meta: {
    title: 'Contacts - Wealth Management - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default ContactsPage;
