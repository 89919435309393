import React from 'react';
import Typography from './Typography.mdx';

const TypographyPage = {
  mdx: Typography,
  breadcrumbs: "Brand Guidelines/Business Banking Guidelines",
  alias: "brand-guidelines/business-banking-guidelines/typography",
  pathExact: false,
  title: "Typography",
  menuLabel: 'Typography',
  published: true,
  order: 6,
  date: '2023-02-14', // Show when last updated
  summary: <>Our typography styling takes its cues from our core branding. We utilize the Bobby font in the Light weight. Within the headline, a word or portion of a phrase may be set in italics. This is meant to act as a visual highlight within the headline as well as to reinforce the concept of progress and action. The word/phrase in italics should connote a sense of action and empowerment. All subheads and body copy appear in Fira Sans.</>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Business Banking',
    'Guidelines',
    'Business Banking Guidelines',
    'Typography',
    'FiraSans',
    'Disclosures',
  ],
  meta: {
    title: 'Typography - Business Banking - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default TypographyPage;
