import React from 'react';
import Accessibility from './Accessibility.mdx';

const AccessibilityPage = {
  mdx: Accessibility,
  breadcrumbs: "Brand Guidelines/Enterprise Guidelines",
  alias: "brand-guidelines/enterprise-guidelines/accessibility",
  pathExact: false,
  title: "Accessibility",
  menuLabel: 'Accessibility',
  published: true,
  order: 12,
  date: '2023-01-27', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Enterprise',
    'Guidelines',
    'Accessibility',
  ],
  meta: {
    title: 'Accessibility - Enterprise - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default AccessibilityPage;
