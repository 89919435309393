// import React from "react";
import QuickLinks from "./QuickLinks.mdx";
import Guidelines from "./Guidelines.mdx";
// import Accessibility from "./Accessibility.mdx";

const QuickLinksPage = {
  mdx: QuickLinks,
  breadcrumbs: "Components/Mobile UI Library",
  alias: "components/mobile-ui-library/quick-links",
  pathExact: false,
  title: "Quick Links",
  menuLabel: "Quick Links",
  published: true,
  date: "2023-11-07", // Show when last updated
  summary: "",
  tabs: [
    {
      label: "Guidelines",
      mdx: Guidelines,
      summary: "Quick links allow users to complete top actions with a single click.",
      alias: "/components/mobile-ui-library/quick-links/guidelines",
      // SEO and Search
      tags: [
        "Component",
        "Mobile UI Library",
        "Quick Links",
        "Design",
        "Guidelines",
      ],
      meta: {
        title: "Guidelines - Quick Links - Mobile UI Library - Components",
        description: "",
        keywords: [],
      },
    },
    /*{
      label: "Accessibility",
      mdx: Accessibility,
      summary: <></>,
      alias: "/components/mobile-ui-library/quick-links/accessibility",
      // SEO and Search
      tags: [
        "Component",
        "Mobile UI Library",
        "Quick Links",
        "Accessibility",
      ],
      meta: {
        title: "Accessibility - Quick Links - Mobile UI Library - Components",
        description: "",
        keywords: [],
      },
    },
    {
      label: "Code",
      mdx: Code,
      summary: <></>,
      alias: "/components/mobile-ui-library/quick-links/code",
      // SEO and Search
      tags: [
        "Component",
        "Mobile UI Library",
        "Quick Links",
        "Code",
      ],
      meta: {
        title: "Components - Mobile UI Library - Quick Links - Code",
        description: "",
        keywords: [],
      },
    },*/
  ],
  // SEO and Search
  tags: [],
  meta: {
    title: "Components - Mobile UI Library - QuickLinks",
    description: "",
    keywords: [],
  },

  // Components
  releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "",
    },
    react: {
      status: "",
      version: "v1.0.0",
      link: "",
    },
    angular: {
      status: "na",
      version: "v1.0.0",
      link: "",
    },
    figma: {
      status: "released",
      version: "",
      link: "https://www.figma.com/file/GsvMEgGQFUXRNBhd6LIF6j/Mobile-UI-Kit-V1.1?type=design&node-id=167-1057&mode=design&t=gYzBxegRBPxOimgr-0",
    },
  },
};

export default QuickLinksPage;
