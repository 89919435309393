// import React from "react";
import FormElementsOverview from "./FormElementsOverview.mdx";

const FormElementsOverviewPage = {
  mdx: FormElementsOverview,
  breadcrumbs: "Components/Web Responsive Library/Forms",
  alias: "components/web-responsive-library/forms/form-elements-overview",
  pathExact: false,
  title: "Form Elements Overview",
  menuLabel: "Form Elements Overview",
  published: true,
  date: "2023-06-08", // Show when last updated
  summary: "",

  // SEO and Search
  tags: [
    "Components",
    "Form",
    "Forms",
    "Form Elements Overview",
    "Form Layouts",
    "Spacing",
    "Adjustable Widths",
    "States",
    "Desktop",
    "Tablet",
    "Mobile",
  ],
  meta: {
    title: "Form Elements Overview - Forms - Web Responsive Library - Components",
    description: "",
    keywords: [],
  },
};

export default FormElementsOverviewPage;
