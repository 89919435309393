// import React from "react";
import LayoutGrid from "./LayoutGrid.mdx";
import Overview from "./Overview.mdx";
import Examples from "./Examples.mdx";
import Utilities from "./Utilities.mdx";


const LayoutGridPage = {
  mdx: LayoutGrid,
  breadcrumbs: "Components/Web Responsive Library",
  alias: "components/web-responsive-library/layout-grid",
  pathExact: false,
  title: "Layout Grid",
  menuLabel: "Layout Grid",
  published: true,
  date: "2023-05-04", // Show when last updated
  summary: "",
  tabs: [
    {
      label: "Overview",
      mdx: Overview,
      summary: "",
      alias: "/components/web-responsive-library/layout-grid/overview",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Layout Grid",
        'Layout',
        'Grid',
        'Code',
      ],
      meta: {
        title: "Overview - Layout Grid - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    {
      label: "Examples",
      mdx: Examples,
      summary: "",
      alias: "/components/web-responsive-library/layout-grid/examples",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Layout Grid",
        'Layout',
        'Grid',
        'Code',
        'Examples',
      ],
      meta: {
        title: "Examples - Layout Grid - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
    {
      label: "Utilities",
      mdx: Utilities,
      summary: "",
      alias: "/components/web-responsive-library/layout-grid/utilities",
      // SEO and Search
      tags: [
        "Component",
        "CBDS",
        "Layout Grid",
        'Layout',
        'Grid',
        'Code',
        'Utilities',
      ],
      meta: {
        title: "Utilities - Layout Grid - Web Responsive Library - Components",
        description: "",
        keywords: [],
      },
    },
  ],
  // SEO and Search
  tags: [],
  meta: {
    title: "Layout Grid - Web Responsive Library - Components",
    description: "",
    keywords: [],
  },

  // Components
  /*releases: {
    html: {
      status: "na", // released, na
      version: "v1.0.0", // Render as v1.0.0+
      link: "index.html?path=/story/components-Overlay",
    },
    react: {
      status: "na",
      version: "v1.0.0",
      link: "index.html?path=/story/components-layout-grid--default",
    },
    angular: {
      status: "na",
      version: "v1.0.0",
      link: "index.html?path=/story/components-layout-grid--default",
    },
    figma: {
      status: "na",
      version: "",
      link: "https://www.figma.com/file/mj41OHGCokcJ9N7D0q8D9Q/CBDS-UI-Kit-V1.1?node-id=2159%3A7071&t=BbsVUyRwx8GP3fqN-1",
    },
  },*/

};

function AddTableARIA() {
  /* eslint-disable */
  try {
    var allTables = document.querySelectorAll('table');
    for (var i = 0; i < allTables.length; i++) {
      allTables[i].setAttribute('role','table');
    }
    var allCaptions = document.querySelectorAll('caption');
    for (var i = 0; i < allCaptions.length; i++) {
      allCaptions[i].setAttribute('role','caption');
    }
    var allRowGroups = document.querySelectorAll('thead, tbody, tfoot');
    for (var i = 0; i < allRowGroups.length; i++) {
      allRowGroups[i].setAttribute('role','rowgroup');
    }
    var allRows = document.querySelectorAll('tr');
    for (var i = 0; i < allRows.length; i++) {
      allRows[i].setAttribute('role','row');
    }
    var allCells = document.querySelectorAll('td');
    for (var i = 0; i < allCells.length; i++) {
      allCells[i].setAttribute('role','cell');
    }
    var allHeaders = document.querySelectorAll('th');
    for (var i = 0; i < allHeaders.length; i++) {
      allHeaders[i].setAttribute('role','columnheader');
    }
    // this accounts for scoped row headers
    var allRowHeaders = document.querySelectorAll('th[scope=row]');
    for (var i = 0; i < allRowHeaders.length; i++) {
      allRowHeaders[i].setAttribute('role','rowheader');
    }
  } catch (e) {
    console.log("AddTableARIA(): " + e);
  }
  /* eslint-enable */
}

AddTableARIA();

export default LayoutGridPage;
