import React from 'react';
import ReviewScreens from './ReviewScreens.mdx';

const ReviewScreensPage = {
  mdx: ReviewScreens,
  breadcrumbs: "Patterns",
  alias: "patterns/review-screens",
  pathExact: false,
  title: "Review Screens",
  menuLabel: 'Review Screens',
  published: true,
  order: 3,
  date: '2024-06-11', // Show when last updated
  summary: <>In user flows where accuracy is valued over speed, we employ review screens to increase customer confidence and reduce critical errors.</>,

  // SEO and Search
  tags: [
    'Patterns',
    'Review Screens',
    'Move Money',
    'Transfers',
    'Flow',
    'Best Practices'
  ],
  meta: {
    description: '',
    title: 'Review Screens - Patterns',
    keywords: [],
  },
};

export default ReviewScreensPage;
