import React from 'react';
import Font from './Font.mdx';

const FontPage = {
  mdx: Font,
  breadcrumbs: "Foundations/Utilities",
  alias: "foundations/utilities/font",
  pathExact: false,
  title: "Font",
  menuLabel: 'Font',
  published: true,
  order: 4,
  date: '2024-02-13', // Show when last updated
  summary: <>Font utility classes include font family, styling and weight.</>,

  // SEO and Search
  tags: [
    'CBDS',
    'Foundations',
    'Font',
    'Utility',
    'Utility Classes System',
    'Font  Utility Classes for Developers',
    'Font family',
    'Font Weight',
  ],
  meta: {
    title: 'Font - Utilities - Foundations',
    description: '',
    keywords: [],
  },
};

export default FontPage;
