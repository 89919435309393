import React from 'react';
import ToneOfVoice from './ToneOfVoice.mdx';

const ToneOfVoicePage = {
  mdx: ToneOfVoice,
  breadcrumbs: "Brand Guidelines/Consumer Guidelines",
  alias: "brand-guidelines/consumer-guidelines/tone-of-voice",
  pathExact: false,
  title: "Tone of Voice",
  menuLabel: 'Tone of Voice',
  published: true,
  order: 6,
  date: '2023-01-31', // Show when last updated
  summary: <></>,

  // SEO and Search
  tags: [
    'CBDS',
    'Brand',
    'Consumer',
    'Guidelines',
    'ToneOfVoice',
    'Brand Voice',
  ],
  meta: {
    title: 'Tone of Voice - Consumer - Brand Guidelines',
    description: '',
    keywords: [],
  },
};

export default ToneOfVoicePage;
