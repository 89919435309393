import React from 'react';
import CSSNamingConventions from './CSSNamingConventions.mdx';
import Link from '../../../../../components/Link';

const CSSNamingConventionsPage = {
  mdx: CSSNamingConventions,
  breadcrumbs: "Getting Started/For Developers/Web",
  alias: "getting-started/for-developers/web/css-naming-conventions",
  pathExact: false,
  title: "CSS Naming Conventions",
  menuLabel: 'CSS Naming Conventions',
  published: true,
  order: 1,
  date: '2024-01-31', // Show when last updated
  summary: <>CBDS uses <Link url="https://en.bem.info/methodology/" a11yLabel="BEM (Opens in New Tab)">BEM</Link> + <Link url="https://www.google.com/search?q=itcss+architecture&safe=active" a11yLabel="ITCSS (Opens in New Tab)">ITCSS</Link> = <strong>BEMIT</strong> w/ the addition of <Link url="https://sparkbox.com/foundry/responsive_class_suffixes_automating_classes_with_sass_mixins_and_sass_maps" a11yLabel="responsive suffixes (Opens in New Tab)">responsive suffixes</Link>.</>,
  tags: [
    'CBDS',
    'CDS',
    'getting started',
    'For Developers',
    'css naming conventions',
    'help',
  ],
  meta: {
    title: 'CSS Naming Conventions - Web - For Developers - Getting Started',
    description: '',
    keywords: [],
  },
};

export default CSSNamingConventionsPage;
