import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Header from '../Header';
import Search from '../Search';
// import { getPropsChildrenForSummary } from '../../_helpers/getPropsChildren';
import { capitalizeFirstLetter } from '../../_helpers/capitalizeFirstLetter';
import _setPageTitle from '../../_helpers/setPageTitle';

const SearchResult = ({ data, appRef }) => {
  const location = useLocation();
  const query = decodeURIComponent(location.search.replace('?s=', '').toLowerCase());
  const [ result, setResult ] = useState([]);

  const queryData = () => {
    let _data = [];
    const keys = Object.keys(data);

    const loopData = d => {
      d.forEach(_d => {
        if (_d.component) {
          if (_d.component.tabs) {
            _data = [
              ..._data,
              ..._d.component.tabs.map(t => {
                return {
                  parent: _d.component.title,
                  component: t,
                };
              }),
            ];
          } else {
            _data = [
              ..._data,
              _d,
            ];
          }
        } else if (_d.children) {
          loopData(_d.children);
        }
      });
    };

    keys.forEach(key => {
      loopData(data[key]);
    });

    const _result = _data
      .filter(d => {
        if (d.component) {
          let count = 0;
          if (d.component.breadcrumbs) {
            count += d.component.breadcrumbs.toLowerCase().includes(query) ? 1 : 0;
          }
          if (d.component.title) {
            count += d.component.title.toLowerCase().includes(query) ? 1 : 0;
          }
          if (d.component.label) { // From tabs
            count += d.component.label.toLowerCase().includes(query) ? 1 : 0;
          }
          if (d.component.alias) {
            count += d.component.alias.toLowerCase().includes(query) ? 1 : 0;
          }
          if (d.component.menuLabel) {
            count += d.component.menuLabel.toLowerCase().includes(query) ? 1 : 0;
          }

          if (d.component.summary) {
            if (typeof d.component.summary === 'string') {
              count += d.component.summary.toLowerCase().includes(query) ? 1 : 0;
            } // else if (typeof d.component.summary === 'object') { // JSX
            //   const _value = getPropsChildrenForSummary(d.component.summary);
            //   count += _value.toLowerCase().includes(query) ? 1 : 0;
            // }
          }

          if (d.component.searchSummary) {
            if (typeof d.component.searchSummary === 'string') {
              count += d.component.searchSummary.toLowerCase().includes(query) ? 1 : 0;
            } // else if (typeof d.component.searchSummary === 'object') { // JSX
            //   const _value = getPropsChildrenForsearchSummary(d.component.searchSummary);
            //   count += _value.toLowerCase().includes(query) ? 1 : 0;
            // }
          }

          if (d.component.tags) {
            const tags = d.component.tags.map(t => t.toLowerCase());
            count += tags.includes(query) ? 1 : 0;
          }

          return count > 0;
        } else {
          return false;
        }
      });

    setResult(_result);

    // Dynamically injecting results into aria live region for screen reader title notification.
    var tempADObj;
    if (_result.length > 0) {
      tempADObj = { alias:'search' }; //Analytics object
      _setPageTitle(`${_result.length} Search Results for ${query}`, tempADObj);
      document.getElementById("targetPageTitle").innerHTML = `${_result.length} Search Results for ${query} | Citizens Design System (v${process.env.VERSION})`;
    } else {
      tempADObj = { alias:'search' }; //Analytics object
      _setPageTitle(`0 Search Results for ${query}`, tempADObj);
      document.getElementById("targetPageTitle").innerHTML = `0 Search Results for ${query} | Citizens Design System (v${process.env.VERSION})`;
    }
  };

  // Unable to get full parent level with tab data, so using the alias
  const getTabLabelTitle = alias => {
    const arr = alias.split('/');
    const titleArr = [];

    arr.forEach((text, index) => {
      if (index > 0) {
        const textArr = text.split('-');
        const newTextArr = [];
        textArr.forEach(t => {
          newTextArr.push(capitalizeFirstLetter(t));
        });
        titleArr.push(newTextArr.join(' '));
      }
    });
    return titleArr.join('/');
  };

  useEffect(() => {
    queryData();
  }, [query]);

  return (
    <>
      <Header
        data={{ title: "Search Results", summary: (<>
          <div className="cbdsws-l-mobileSearchBarContainer">
            <Search
              appRef={appRef}
              uid="mobile-search-input"
              isMobile
            />
          </div>
          <br />
          {result.length} Results
        </>) }}
      />
      <div className="cbdsws-c-searchResult">
        {!!result.length === true && result.map(r => (
          <div key={`searchQuery_${r.component.alias}`} className="cbdsws-c-searchResult__item">
            {r.component.title && (
              <Link className="cbds-c-link cbdsws-c-searchResult__link cbensightenevent" cbdata-type="link" cbdata-reason={`__searchResult: ${`${r.component.breadcrumbs ? `${r.component.breadcrumbs}/` : ''}${r.component.title}`}`} to={r.component.alias}>
                <h2 className="cbdsws-c-searchResult__itemTitle">{`${r.component.breadcrumbs ? `${r.component.breadcrumbs}/` : ''}${r.component.title}`}</h2>
              </Link>
            )}
            {(r.component.label) && (
              <Link className="cbds-c-link cbdsws-c-searchResult__link cbensightenevent" cbdata-type="link" cbdata-reason={`__searchResult: ${`${r.component.breadcrumbs ? `${r.component.breadcrumbs}/` : ''}${r.component.title}`}`} to={r.component.alias}>
                <h2 className="cbdsws-c-searchResult__itemTitle">{getTabLabelTitle(r.component.alias)}</h2>
              </Link>
            )}
            {r.component.summary && !r.component.searchSummary && (
              <p className="cbdsws-c-searchResult__itemSummary">{r.component.summary}</p>
            )}
            {r.component.searchSummary && (
              <p className="cbdsws-c-searchResult__itemSummary">{r.component.searchSummary}</p>
            )}
          </div>
        ))}
        {(!!result.length === false) && (
          <p>0 Search Results for "{query}"</p>
        )}
      </div>
    </>
  );
};

export default SearchResult;
